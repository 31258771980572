<div class="btn-group bootstrap-select form-control w-100">
  <button type="button" class="btn dropdown-toggle btn-default w-100" data-toggle="dropdown" role="button"
    data-id="per-page" title="10">
    <span class="filter-option pull-left w-100">{{ value ? (showProperty ? (showProperty === 'descr' ? value[showProperty][lang] : value[showProperty]) : value) : value }}</span>&nbsp;
    <span class="bs-caret">
      <span class="caret"></span>
    </span>
  </button>
  <div class="dropdown-menu show-tick open" role="combobox">
    <div class="dropdown-menu show-tick inner" role="listbox" aria-expanded="false">
      <a *ngFor="let item of list" class="dropdown-item  " data-original-index="0"
        (click)="changeValue(item)">
        <span tabindex="0"
          class="dropdown-item-inner d-flex justify-content-between {{ value ===  (showProperty ? (showProperty === 'descr' ? item[showProperty][lang] : item[showProperty]) : item) ? 'selected': '' }}"
          data-tokens="null" role="option" aria-disabled="false" [attr.aria-selected]="value === (showProperty ? (showProperty === 'descr' ? item[showProperty][lang] : item[showProperty]) : item)">
          <span class="text">{{ showProperty ? (showProperty === 'descr' ? item[showProperty][lang] : item[showProperty]) : item }}</span>
          <span class="fa fa-check check-mark ">
          </span>
        </span>
      </a>
    </div>
  </div>
</div>
