import { Component, OnInit, Output, EventEmitter, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Subscription } from 'rxjs';

import { UiHelper } from '../_utils/ui';
import { LangswitchService } from '../_services';
import { DocumentService } from '../_services/document.service';
import { NotificationComponent } from '../_common/notification/notification.component';
import { Doctype, DocDoctypeGroup } from 'src/app/_domain/doctype.interface';

@Component({
  selector: 'app-document-simple',
  templateUrl: './document-simple.component.html',
  styleUrls: ['./document-simple.component.scss']
})
export class DocumentSimpleComponent extends NotificationComponent implements OnInit, OnDestroy {
  @Input() context: string;
  @Output() upload: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  lang: string;
  simpleDocForm: FormGroup;
  file: File;
  doctypeGroups: Array<DocDoctypeGroup>;
  doctypesByGroup: Map<number, Doctype>;
  doctypes: Array<Doctype>;
  selectedTypeContext: string;
  showWarning = false;
  showAllowedFileTypesWarning = false;
  allowedExtentions = [];

  private subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    private uiHelper: UiHelper,
    private langswitchService: LangswitchService,
    private documentService: DocumentService
  ) {
    super();
  }

  ngOnInit() {
    this.subscription.add(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );

    this.subscription.add(
      this.documentService.getAllowedExtentions().subscribe(
        (res: any) => {
          this.allowedExtentions = res;
        })
    );



    this.buildForm();
    this.uiHelper.initSelectPicker();

    this.subscription.add(
      this.documentService.getDocTypes(this.context).subscribe(
        response => {
          this.doctypes = response.data.docTypes;
          const groups = response.data.docTypes.map(d => d.docDoctypeGroupByGroupCode);
          this.doctypeGroups = groups.reduce((acc, current) => {
            const result = acc.find(({ code }) => code === current.code);
            if (!result) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.doctypesByGroup = response.data.docTypes.reduce((acc, current) => {
            const key = current.groupCode;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(current);
            return acc;
          }, {});
          // LAB as default set docType where context=P_FOTO
          const defaultType = this.doctypes.find(el => el.context === 'P_FOTO');
          if (defaultType) {
            this.simpleDocForm.get('docType').setValue(defaultType.code);
          }
          this.uiHelper.refreshSelectPicker();
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );

    this.subscription.add(
      this.simpleDocForm.get('docType').valueChanges.subscribe(docType => {
      this.selectedTypeContext = this.doctypes.find(el => el.code === +docType).context;
      this.uiHelper.refreshSelectPicker();
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  buildForm() {
    this.simpleDocForm = this.fb.group({
      docType: [null, Validators.required],
      docTitle: [null],
      fileOriginName: [null, Validators.required]
    });
  }

  getFile(event: any) {
    this.file = event.target.files[0];
    this.simpleDocForm.get('fileOriginName').setValue(this.file.name);
    this.showWarning = false;
  }

  removeFile() {
    this.file = null;
    this.simpleDocForm.get('fileOriginName').setValue(null);
  }

  onCancel() {
    this.cancel.emit();
  }

  onSubmit() {
    if (!this.file) {
      this.showWarning = true;
      return;
    }
    const simpleDoc = this.simpleDocForm.value;
    simpleDoc.file = this.file;
    const ext = this.file ? this.file.name.split('.').pop() : '';
    if(this.file && this.allowedExtentions.includes(ext)) {
      this.upload.emit(simpleDoc);
    } else {
      this.showAllowedFileTypesWarning = true;
    }
  }
}
