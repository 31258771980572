import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  Input,
  TemplateRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { AutPerson, Country, UserInfo, AppOwnership } from 'src/app/_domain';
import {
  AutRole,
  Citizenship,
  ContactType,
  OwnershipType,
  PersonType
} from 'src/app/_enumeration/enums';
import { Validators, FormGroup, FormBuilder, AbstractControl } from '@angular/forms';
import { BaseComponent } from '../base/base.component';
import { AplService } from '../../_services';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import * as cloneDeep from 'lodash/cloneDeep';
import { GlobalConstants } from 'src/app/common/global-constants';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-isik',
  templateUrl: './add-isik.component.html',
  styleUrls: ['./add-isik.component.scss']
})
export class AddIsikComponent extends BaseComponent implements OnInit, OnDestroy {
  ESTONIA_COUNTRY_ID = GlobalConstants.ESTONIA_COUNTRY_ID;

  @ViewChild('chooseSavedPersonTbl') chooseSavedPersonTbl: TemplateRef<any>;
  /* @Input() parentForm: FormGroup;
  @Input() aplData: AplRequest; */
  @Input() mode = 'rw'; // default rw ?
  @Input() isUpdate = false;
  @Input() ownershipType: OwnershipType;
  @Input() ownership: AppOwnership;
  @Input() showContactsFields = true;
  @Input() isAuthorityDetailPage = false;

  @Output() refreshFormEv: EventEmitter<any> = new EventEmitter();
  @Output() saveEv: EventEmitter<any> = new EventEmitter();
  @Output() saveAuthPersonEv: EventEmitter<any> = new EventEmitter();
  @Output() closeModalEv: EventEmitter<any> = new EventEmitter();

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  dialogTitle: string;

  env: string;
  canAddPersonFromForeignCountry = true;

  loadingAddresses = false;
  personType = PersonType.J;
  PersonTypeEnum = PersonType;
  citizenship = Citizenship.EST;
  CitizenshipEnum = Citizenship;
  AutRoleEnum = AutRole;

  representedPersonId: number;
  OwnershipTypeEnum = OwnershipType;
  ownershipModalForm: FormGroup;
  autPerson: AutPerson;
  delOwnership: AppOwnership;
  editedOwnership: AppOwnership;
  countries: Country[] = [];
  personsFound: AutPerson[] = [];
  addresses: string[] = [];
  emails: string[] = [];
  codes: string[] = [];
  showOwners = false;
  showShipHusbands = false;
  owners: AppOwnership[] = [];
  shipHusband: AppOwnership;
  shipHusbandsAgent: AppOwnership;
  operator: AppOwnership;
  applicant: AppOwnership;
  // autApplicant: AutPerson;
  ownersAndAgents = new Map<number, AppOwnership>();
  countryMap = new Map<number, string>();
  countryObjectMap = new Map<number, any>();
  authorizedUser: UserInfo;
  selectedCountry: Country;
  formSubmitted = false;
  searchType = 0;
  showSavedPerson = false;

  deleteOwnershipMessages = new Map<OwnershipType, string[]>();
  isReferenced: boolean;
  modalRef: BsModalRef;
  dialogContentPlaceholder: TemplateRef<any>;
  dialogFooterPlaceholder: TemplateRef<any>;
  // omanikudVastutavadIsikud: boolean;
  showAddrSearch: boolean;
  hideAddressList: boolean;
  userAddresses = [] as string[];
  showEmailList: boolean;

  isReadDataLoading = false;
  canSearchNew = true;
  showIsSearching = false;
  emailValid: boolean;

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    // UGLY SUREFIRE WAY TO HIDE OUR ADDRESS/ETC LISTS WHEN WE CLICK OUTSIDE OF THEM
    if (!event.target.classList.contains('data-list-custom')) {
      // clicked outside => close dropdown list
      if (!event.target.classList.contains('dropdown-item-custom')) {
        this.hideAddressList = true;
      }
    } else {
      this.showSavedPerson = false;
    }
  }

  constructor(private aplService: AplService, private formBuilder: FormBuilder, private route: ActivatedRoute) {
    super();
    this.ownershipModalForm = this.formBuilder.group({
      country: ['', Validators.required],
      idOrRegistryCode: [''], // [Validators.pattern('^([0-9]{8}|[0-9]{11})$')]
      firstName: ['', Validators.required],
      name: ['', Validators.required],
      address: [''],
      email: ['', Validators.email],
      fractureParts: [''],
      fractureWhole: ['']
    });
  }

  ngOnInit() {
    this.addSubscriptionToList(
      this.userService.loadUser().subscribe((user: UserInfo) => {
        this.authorizedUser = user;
      })
    );

    this.addSubscriptionToList(this.route.data.subscribe(data => this.env = data.env));

    this.initLang();
    this.getCountries();

    // this.autApplicant = this.aplData.applicant;

    this.addIdOrRegistryCodeValueChangesListener();
    this.addNameValueChangesListener();
    this.addCountryValueChangesListener();
    this.addEmailValueChangesListener();
    this.addOnlyNumbersValueChangesFilter(this.ownershipModalForm.controls.fractureParts, 'fractureParts');
    this.addOnlyNumbersValueChangesFilter(this.ownershipModalForm.controls.fractureWhole, 'fractureWhole');
    this.addAddressValueChangesListener();
    // this.initData();

    this.fillDeleteOwnershipMessages();

    if (!this.isUpdate) {
      this.openCreateOwnershipModal();
    } else {
      this.openUpdateOwnershipModal();
    }
    this.uiHelper.initSelectPicker();
    // this.omanikudVastutavadIsikud = ![21031, 21032, 21033].some(a => a === this.aplData.appType);

    // DO NOT LET TO SUBMIT IF E-MAIL FALSE
    this.ownershipModalForm.controls.email.statusChanges.subscribe(valid => {
      this.emailValid = valid === 'VALID' ? true : false;
    });

    // In 'IK' env on the authority details page, dont display 'Foreign countries' radio
    if (this.isAuthorityDetailPage) {
      this.canAddPersonFromForeignCountry = this.env === 'mk';
    }
  }

  ngOnDestroy() {
    this.unsubscribeAllFromList();
  }

  getCountries() {
    this.addSubscriptionToList(
      this.aplService.getCountries().subscribe((response: Country[]) => {
        this.setAndSortCountries(response);
        response.forEach(country => {
          this.countryMap.set(country.id, country.name[this.lang]);
          this.countryObjectMap.set(country.id, country);
        });
      })
    );
  }

  openCreateOwnershipModal(): void {
    /* if (this.ownershipType) { */
    this.editedOwnership = null;
    this.representedPersonId = this.ownership ? this.ownership.id : null;
    // this.ownershipType = ownershipType;
    this.setCitizenshipAndUpdateValidators(Citizenship.EST);
    this.resetModalData();
    this.setModalTitle('APL.REQ_OWN_ADD_PERSON_MODAL_TITLE');
    /*   } */
    /* this.dialogContentPlaceholder = this.modalBodyCreateOwnershipTemplateRef;
    this.dialogFooterPlaceholder = this.modalFooterCreateOwnershipTemplateRef;
    this.modalRef = this.modalService.show(this.dialogTemplate, Object.assign({}, { class: 'modal-lg' })); */
  }

  openUpdateOwnershipModal(): void {
    if (this.ownership && this.ownershipType) {
      this.autPerson = null;
      this.ownershipModalForm.reset('', { emitEvent: false });

      this.ownershipModalForm.controls.idOrRegistryCode.patchValue(this.ownership.personRegCode, {
        emitEvent: false
      });

      // this.getAutPerson(this.ownershipModalForm.controls.idOrRegistryCode.value);

      // this.ownershipType = ownershipType;
      this.editedOwnership = this.ownership;
      this.citizenship =
        this.editedOwnership.personCountryId === this.ESTONIA_COUNTRY_ID ? Citizenship.EST : Citizenship.FGN;
      if (this.citizenship === Citizenship.FGN) {
        this.selectedCountry = this.countryObjectMap.get(this.editedOwnership.personCountryId);
        this.ownershipModalForm.controls.firstName.patchValue(this.ownership.personFirstName, {
          emitEvent: false
        });
        this.ownershipModalForm.controls.name.patchValue(this.ownership.personName, { emitEvent: false });
      }
      this.addresses = this.ownership.autPersonAddresses;
      this.emails = this.ownership.autPersonEmails;

      this.ownershipModalForm.controls.address.patchValue(this.ownership.personAddress, { emitEvent: false });
      /*  if (this.ownershipModalForm.controls.address.value) {
         this.selectAutPersonsAddress(this.ownershipModalForm.controls.address.value);
       }
  */
      this.ownershipModalForm.controls.firstName.patchValue(this.ownership.personFirstName, {
        emitEvent: false
      });
      this.ownershipModalForm.controls.name.patchValue(this.ownership.personName, { emitEvent: false });

      this.ownershipModalForm.controls.email.patchValue(this.ownership.personEmail, { emitEvent: false });
      if (this.ownershipType === OwnershipType.OWNER) {
        this.ownershipModalForm.controls.fractureParts.patchValue(this.ownership.fractureParts, {
          emitEvent: false
        });
        this.ownershipModalForm.controls.fractureWhole.patchValue(this.ownership.fractureWhole, {
          emitEvent: false
        });
      }

      this.setModalTitle('APL.REQ_OWN_UPDATE_PERSON_MODAL_TITLE');
    }

    /* this.dialogContentPlaceholder = this.modalBodyUpdateOwnershipTemplateRef;
    this.dialogFooterPlaceholder = this.modalFooterUpdateOwnershipTemplateRef;
    this.modalRef = this.modalService.show(this.dialogTemplate, Object.assign({}, { class: 'modal-lg' })); */
  }

  setModalTitle(translationKey: string): void {
    this.addSubscriptionToList(
      this.translateServ.get(translationKey).subscribe((text: string) => {
        this.dialogTitle = text;
      })
    );
  }

  resetModalData(): void {
    this.formSubmitted = false;
    this.ownershipModalForm.reset();
    this.autPerson = null;
    this.personType = PersonType.J;
    this.citizenship = Citizenship.EST;
    this.setDefaultCountryForSelect();
  }

  fillDeleteOwnershipMessages(): void {
    this.deleteOwnershipMessages.set(OwnershipType.OWNER, [
      'APL.REQ_OWN_DEL_OWNER',
      'APL.REQ_OWN_DEL_OWNER_WITH_AGENT'
    ]);
    this.deleteOwnershipMessages.set(OwnershipType.AGENT, ['APL.REQ_OWN_DEL_AGENT']);
    this.deleteOwnershipMessages.set(OwnershipType.OPERATOR, [
      'APL.REQ_OWN_DEL_OPERATOR',
      'APL.REQ_OWN_DEL_OPERATOR_WITH_AGENT'
    ]);
    this.deleteOwnershipMessages.set(OwnershipType.APPLICANT, [
      'APL.REQ_OWN_DEL_APPLICANT',
      'APL.REQ_OWN_DEL_APPLICANT_WITH_AGENT'
    ]);
    this.deleteOwnershipMessages.set(OwnershipType.SHIPHUSBAND, [
      'APL.REQ_OWN_DEL_SHIPHUSBAND',
      'APL.REQ_OWN_DEL_SHIPHUSBAND_WITH_AGENT'
    ]);
  }

  /*
    LISTENER
  */
  addIdOrRegistryCodeValueChangesListener(): void {
    this.addSubscriptionToList(
      this.ownershipModalForm.controls.idOrRegistryCode.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val: string) => {
          if (!isNullOrUndefined(val)) {
            val = val.replace(/\s/g, '');
            const isValidInput = new RegExp(
              this.citizenship === Citizenship.EST ? '^[0-9]*$' : '^[0-9a-zA-Z-]*$'
            ).test(val);
            if (isValidInput) {
              this.searchType = 1;
              this.ownershipModalForm.controls.idOrRegistryCode.patchValue(val);
              if (this.citizenship === Citizenship.FGN && val.length >= 3) {
                this.addSubscriptionToList(
                  this.aplService
                    .getForeignPersonByCode(
                      this.ownershipModalForm.get('country').value.id,
                      val,
                      this.personType
                    )
                    .pipe(debounceTime(100))
                    .subscribe((response: AutPerson[]) => {
                      this.personsFound = response;
                    })
                );
              } else {
                this.personsFound = [];
              }
            } else {
              this.ownershipModalForm.controls.idOrRegistryCode.patchValue(
                this.ownershipModalForm.value.idOrRegistryCode
              );
            }
          }
        })
    );
  }

  addNameValueChangesListener(): void {
    this.addSubscriptionToList(
      this.ownershipModalForm.controls.name.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val: string) => {
          if (!isNullOrUndefined(val)) {
            this.searchType = 2;
            this.ownershipModalForm.controls.name.patchValue(val);
            if (this.citizenship === Citizenship.FGN && val.length >= 3) {
              this.addSubscriptionToList(
                this.aplService
                  .getForeignPersonByName(
                    this.ownershipModalForm.get('country').value.id,
                    val,
                    this.personType
                  )
                  .pipe(debounceTime(100))
                  .subscribe((response: AutPerson[]) => {
                    this.personsFound = response;
                  })
              );
            } else {
              this.personsFound = [];
            }
          }
        })
    );
  }

  addCountryValueChangesListener(): void {
    this.addSubscriptionToList(
      this.ownershipModalForm.controls.country.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((val: string) => {
          if (!isNullOrUndefined(val)) {
            this.searchType = 5;
            /* this.ownershipModalForm.controls.country.patchValue(val); */
            if (
              this.citizenship === Citizenship.FGN &&
              this.ownershipModalForm.value.name &&
              this.ownershipModalForm.value.name.length >= 3
            ) {
              this.addSubscriptionToList(
                this.aplService
                  .getForeignPersonByName(
                    this.ownershipModalForm.get('country').value.id,
                    this.ownershipModalForm.value.name,
                    this.personType
                  )
                  .pipe(debounceTime(100))
                  .subscribe((response: AutPerson[]) => {
                    this.personsFound = response;
                  })
              );
            } else {
              this.personsFound = [];
            }
          }
        })
    );
  }

  addEmailValueChangesListener(): void {
    this.addSubscriptionToList(
      this.ownershipModalForm.controls.email.valueChanges
        .pipe(distinctUntilChanged())
        .subscribe((value: string) => {
          this.searchType = 4;
          this.emails = [];
          if (this.autPerson) {
            this.autPerson.emails.forEach((email: string) => {
              if (value && email.toLowerCase().includes(value.toLowerCase())) {
                this.emails.push(email);
              }
            });
          }
          if (this.editedOwnership) {
            this.editedOwnership.autPersonEmails.forEach((email: string) => {
              if (value && email.toLowerCase().includes(value.toLowerCase())) {
                this.emails.push(email);
              }
            });
          }
        })
    );
  }

  isEmpty(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  showSearchedAddresses() {
    if (this.isEmpty(this.addresses) === false) {
      this.hideAddressList = false;
    }
  }

  async addAddressValueChangesListener() {
    this.addSubscriptionToList(
      this.ownershipModalForm.controls.address.valueChanges
        .pipe(
          debounceTime(2000),
          distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
        )
        .subscribe(async addressInput => {
          this.hideAddressList = true;
          if (addressInput && addressInput.length > 2) {
            this.makeCall(addressInput);
          } else {
            // no user input
          }
        })
    );
  }

  async makeCall(addressInput) {
    // TODO ADD PROPER ERROR MESSAGES
    this.showIsSearching = true;
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';

    if (this.canSearchNew === true) {
      try {
        this.canSearchNew = false;
        this.hideAddressList = true;
        this.addresses = [];
        this.addSubscriptionToList(
          this.aplService.getAddresses(addressInput).subscribe(
            response => {
              const addressesDataResponse = response;

              if (addressesDataResponse) {
                if (!this.isEmpty(addressesDataResponse)) {
                  // RESULT IS NOT EMPTY
                  this.hideAddressList = false;
                  this.addresses = addressesDataResponse;
                } else {
                  // RESULT IS EMPTY
                }
                this.showIsSearching = false;
                this.canSearchNew = true;
              } else {
                this.showIsSearching = false;
                this.canSearchNew = true;
                this.showDbErrorMessage(defaultErrorMsgLabel, '');
              }
            },
            error => {
              this.showDbErrorMessage(defaultErrorMsgLabel, '');
            }
          )
        );
      } catch (error) {
        this.showDbErrorMessage(defaultErrorMsgLabel, error);
      }
    } else {
      setTimeout(() => {
        this.makeCall(addressInput);
      }, 100);
    }
  }

  addOnlyNumbersValueChangesFilter(control: AbstractControl, controlName: string): void {
    this.addSubscriptionToList(
      control.valueChanges.pipe(distinctUntilChanged()).subscribe((val: any) => {
        if (val) {
          val = String(val).replace(/\s/g, '');
          if (!new RegExp('^[1-9][0-9]*$').test(val)) {
            control.patchValue(this.ownershipModalForm.value[controlName]);
          } else {
            control.patchValue(Number(val.trim()));
          }
        }
      })
    );
  }

  setAndSortCountries(countries: Country[]): void {
    countries.forEach((country: Country) => {
      if (Object.values(country.name).find(x => x === '' || x === '-') === undefined) {
        // x.toUpperCase() === 'EESTI' ||
        if (country.id !== this.ESTONIA_COUNTRY_ID) {
          this.countries.push(country);
        }
      }
    });
    if (countries.length > 0) {
      Object.keys(countries[0].name).forEach(key => {
        if (key.toLowerCase() === this.lang) {
          this.countries.sort((a: Country, b: Country) =>
            a.name[key] > b.name[key] ? 1 : b.name[key] > a.name[key] ? -1 : 0
          );
        }
      });
      const tempCountry = cloneDeep(this.countries[0]);
      tempCountry.id = 0;
      tempCountry.name = this.getDefaultNameRecordMap('-');
      // { id: null, name: this.getDefaultNameRecordMap('-'), short2: null, short3: null }
      this.countries = [tempCountry as Country, ...this.countries];
      // console.log('this.countries', this.countries);
    }
  }

  setCitizenshipAndUpdateValidators(citizenship: Citizenship): void {
    this.citizenship = citizenship;
    if (citizenship === Citizenship.EST) {
      this.setIdOrRegistryValidatorEstPerson();
    } else {
      this.setIdOrRegistryValidatorFgnPerson();
    }
    this.resetModalFormValidation();
    this.uiHelper.refreshSelectPicker();
  }

  setIdOrRegistryValidatorEstPerson() {
    this.ownershipModalForm.controls.idOrRegistryCode.setValidators([
      Validators.required,
      Validators.pattern('^([0-9]{8}|[0-9]{11})$')
    ]);
  }

  setIdOrRegistryValidatorFgnPerson() {
    this.ownershipModalForm.controls.idOrRegistryCode.setValidators([
      Validators.pattern('^[0-9a-zA-Z-]{0,20}$')
    ]);
  }

  resetModalFormValidation(): void {
    this.ownershipModalForm.reset();
    this.formSubmitted = false;
    this.setDefaultCountryForSelect();
  }

  setDefaultCountryForSelect(): void {
    this.ownershipModalForm.controls.country.patchValue(this.countries[0], { emitEvent: false });
    this.selectedCountry = this.countries[0];
    this.uiHelper.refreshSelectPicker();
  }

  getAutPerson(countryId: number, idOrRegistryCode: string, personType): void {
    this.formSubmitted = true;
    if (!this.isReadDataLoading && this.ownershipModalForm.controls.idOrRegistryCode.valid) {
      // Show spinner and disable button
      if (
        this.ownershipModalForm.controls.idOrRegistryCode.value.length === 8 ||
        this.ownershipModalForm.controls.idOrRegistryCode.value.length === 11
      ) {
        this.isReadDataLoading = true;
      }

      if (
        countryId === this.ESTONIA_COUNTRY_ID &&
        !personType &&
        (idOrRegistryCode.length === 8 || idOrRegistryCode.length === 11)
      ) {
        personType = idOrRegistryCode.length === 8 ? PersonType.J : PersonType.F;
      }
      if (personType === null) {
        this.getLongMsgError('ERROR.ID_OR_REGISTRY_CODE_ERROR');
      }


      if (this.isAuthorityDetailPage) {
        const currentUser = this.userService.getUser();
        if (currentUser.registerCode === idOrRegistryCode && this.env === 'ik') {
          this.isReadDataLoading = false;
          this.getLongMsgError('ERROR.CANT_ADD_RIGHTS_AS_REP_TO_LOGGED_IN_USER');
          throw Error('Not allowed to give rights from representative to a logged in user!');
        }
      }

      this.addSubscriptionToList(
        this.aplService.getPersonByCountryAndCodeAndType(countryId, +idOrRegistryCode, personType).subscribe(
          (response: any) => {
            if (response) {
              if (response.success === true && response.data) {
                if (response.data.xteeMsg) {
                  this.showDbErrorMessage(response.data.xteeMsg, response);
                } else {
                  this.autPerson = response.data;
                  this.addAutPersonContacts(this.autPerson);
                  this.formSubmitted = false;
                }
              } else {
                if (personType === PersonType.F) {
                  this.showDbErrorMessage('ERROR.RR_PERSON_NOT_FOUND', response);
                } else {
                  this.showDbErrorMessage('ERROR.ARI_PERSON_NOT_FOUND', response);
                }
              }
            } else {
              if (personType === PersonType.F) {
                this.showDbErrorMessage('ERROR.RR_PERSON_NOT_FOUND', response);
              } else {
                this.showDbErrorMessage('ERROR.ARI_PERSON_NOT_FOUND', response);
              }
            }
            this.isReadDataLoading = false;
          },
          error => {
            console.log(error);
            if (personType === PersonType.F) {
              this.showDbErrorMessage('ERROR.RR_PERSON_NOT_FOUND', error);
            } else {
              this.showDbErrorMessage('ERROR.ARI_PERSON_NOT_FOUND', error);
            }
            this.isReadDataLoading = false;
          }
        )
      );
    }
  }

  addAutPersonContacts(person: AutPerson): void {
    if (person && person.addresses) {
      if (person.addresses.length > 0 && !this.ownershipModalForm.controls.address.value) {
        this.ownershipModalForm.controls.address.patchValue(person.addresses[0], { emitEvent: false });
      }
      if (person.emails && person.emails.length > 0 && !this.ownershipModalForm.controls.email.value) {
        this.ownershipModalForm.controls.email.patchValue(person.emails[0], { emitEvent: false });
      }
    }

    /*
    this.addresses = person.contacts
      .filter(contact => contact.contactType === ContactType.AADRESS)
      .map(contact => contact.contactText);
    */
    if (person && person.contacts) {
      this.emails = person.contacts
        .filter(contact => contact.contactType === ContactType.EMAIL)
        .map(contact => contact.contactText);
    } else {
      this.emails = [];
    }
    if (!this.ownershipModalForm.controls.email.valid) {
      this.emailValid = false;
    } else if (this.ownershipModalForm.controls.email.valid) {
      this.emailValid = true;
    }
  }

  selectAutPersonsAddress(address: any): void {
    this.hideAddressList = true;
    this.ownershipModalForm.controls.address.patchValue(address, { emitEvent: false });
    this.addresses = [];
    this.searchType = 0;
    this.closePopover();
  }

  selectAutPersonsEmail(email: string): void {
    this.showEmailList = false;
    this.ownershipModalForm.controls.email.patchValue(email, { emitEvent: false });
    this.searchType = 0;
  }

  changePersonType(personType: PersonType): void {
    this.personType = personType;
    if (this.citizenship === Citizenship.FGN) {
      this.resetModalFormValidation();
      Object.keys(this.ownershipModalForm.controls).forEach(key => {
        const control = this.ownershipModalForm.get(key);
        if (key === 'firstName') {
          control.setValidators(personType === PersonType.J ? null : Validators.required);
        }
        control.updateValueAndValidity();
      });
    }
  }

  selectCountry(country: Country): void {
    // console.log('selectCountry ', country);
    this.selectedCountry = country;
  }

  selectPerson(person: AutPerson): void {
    this.personsFound = [];
    this.searchType = 0;
    this.autPerson = person;
    this.ownershipModalForm.controls.idOrRegistryCode.patchValue(person.registerCode, { emitEvent: false });
    this.ownershipModalForm.controls.firstName.patchValue(
      person.personType === PersonType.F ? person.firstName : '',
      { emitEvent: false }
    );
    this.ownershipModalForm.controls.name.patchValue(person.name, { emitEvent: false });
    this.addAutPersonContacts(person);
  }

  showSavedAddresses() {
    this.hideAddressList = true;
    this.userAddresses = [];
    this.loadingAddresses = true;
    if (this.autPerson && this.autPerson.addresses) {
      this.autPerson.addresses.forEach((address: string) => {
        this.userAddresses.push(address);
      });
    }
    if (this.editedOwnership && this.editedOwnership.autPersonAddresses) {
      this.editedOwnership.autPersonAddresses.forEach((address: string) => {
        if (this.userAddresses.indexOf(address) === -1) {
          this.userAddresses.push(address);
        }
      });
    }
    this.loadingAddresses = false;
  }

  showSavedEmails() {
    this.showEmailList = true;
  }

  save() {
    if (!this.ownershipModalForm.controls.email.valid) {
      this.getLongMsgWarning('ERROR.EMAIL');
      return;
    }

    const b =
      (this.personType === this.PersonTypeEnum.F &&
        ((this.ownershipModalForm.controls.country.value &&
          this.ownershipModalForm.controls.country.value.id !== 0) ||
          this.ownershipModalForm.value.firstName ||
          this.ownershipModalForm.value.name)) ||
      (this.personType === this.PersonTypeEnum.J &&
        ((this.ownershipModalForm.controls.country.value &&
          this.ownershipModalForm.controls.country.value.id !== 0) ||
          this.ownershipModalForm.value.name));

    if (
      this.citizenship === this.CitizenshipEnum.EST ||
      (this.citizenship === this.CitizenshipEnum.FGN && b)
    ) {
      const formData = cloneDeep(this.ownershipModalForm);

      formData.value.relationType = this.ownershipType ? +this.ownershipType : null;
      formData.value.representedPersonId = this.representedPersonId ? +this.representedPersonId : null;
      formData.value.personId = this.autPerson ? this.autPerson.id : null;
      formData.value.personFirstName = this.ownershipModalForm.controls.firstName.value
        ? this.ownershipModalForm.controls.firstName.value
        : this.autPerson
          ? this.autPerson.firstName
          : null;
      formData.value.personName = this.ownershipModalForm.controls.name.value
        ? this.ownershipModalForm.controls.name.value
        : this.autPerson
          ? this.autPerson.name
          : null;
      if (
        this.citizenship === Citizenship.FGN &&
        !this.selectedCountry &&
        this.editedOwnership &&
        this.editedOwnership.personCountryId
      ) {
        this.selectedCountry = this.countryObjectMap.get(this.editedOwnership.personCountryId);
      }
      formData.value.personCountryId =
        this.selectedCountry && this.selectedCountry.id
          ? this.selectedCountry.id
          : this.autPerson
            ? this.autPerson.countryId
            : null;
      formData.value.personRegCode = this.ownershipModalForm.controls.idOrRegistryCode.value
        ? this.ownershipModalForm.controls.idOrRegistryCode.value
        : this.autPerson
          ? this.autPerson.registerCode
          : null;
      formData.value.personType = this.autPerson ? this.autPerson.personType : this.personType;

      // console.log('save ', this.autPerson, formData);
      this.saveEv.emit(formData);
      this.saveAuthPersonEv.emit({ formData, autPerson: this.autPerson });
    }
  }

  /* updateOwnership(): void {
    if (this.ownershipModalForm.controls.email.valid) {
      this.editedOwnership.personAddress = this.ownershipModalForm.value.address;
      this.editedOwnership.personEmail = this.ownershipModalForm.value.email;
      this.editedOwnership.fractureParts = this.ownershipModalForm.value.fractureParts;
      this.editedOwnership.fractureWhole = this.ownershipModalForm.value.fractureWhole;
      this.closeModal();
      this.aplService.updateOwnership(this.editedOwnership).subscribe((response: AppOwnership) => {
        if (response) {
          // this.refreshOwnerships();
          this.refreshForm.emit('refreshOwnerships');
          this.translateServ.get('APL.REQ_OWN_UPDATE_SUCCESS').subscribe((text: string) => {
            this.getLongMsgSuccess(text);
          });
        }
      }, () => {
        this.translateServ.get('ERROR.UPDATE_DATA').subscribe((text: string) => {
          this.getLongMsgError(text);
        });
      });
    } else {
      this.formSubmitted = true;
    }
  } */

  /*   refreshOwnerships(): void {
      if (this.aplData.appVersionsById && this.aplData.appVersionsById[0]) {
        this.aplService.getOwnerships(this.aplData.id, this.aplData.appVersionsById[0].id).subscribe(response => {
          this.aplData.appOwnershipsById = response;
          this.initData();
        });
      }
    } */

  /* initData(): void {
    this.showOwners = [21001, 21002, 21003, 21004].indexOf(this.aplData.appType) !== -1;
    this.showShipHusbands = this.aplData.appType === 21004;
    this.owners = this.aplData.appOwnershipsById.filter(owner => owner.relationType === 24001);
    this.operator = this.aplData.appOwnershipsById.filter(operator => operator.relationType === 24003).pop();
    this.applicant = this.aplData.appOwnershipsById.filter(applicant => applicant.relationType === 24011).pop();
    this.shipHusband = this.aplData.appOwnershipsById.filter(shipHusband => shipHusband.relationType === 24004).pop();
    if (this.aplData.appType === 21004 && this.shipHusband) {
      this.shipHusbandsAgent = this.aplData.appOwnershipsById.filter(agent => agent.representedId === this.shipHusband.id).pop();
    }
    this.connectOwnersWithAgents();
  } */

  /* connectOwnersWithAgents(): void {
    this.owners
      .sort((a, b) => a.id > b.id ? 1 : (b.id > a.id ? -1 : 0))
      .forEach(owner => {
        const agent = this.getOwnersAgents(owner.id);
        this.ownersAndAgents.set(owner.id, agent);
      });
  } */

  /* getOwnersAgents(ownerId: number): AppOwnership {
    return this.aplData.appOwnershipsById.find(owner => owner.representedId === ownerId);
  } */

  /*  createOwnershipObject(): AppOwnership {
    return {
      applicationId: this.aplData.id,
      versionId: this.aplData.appVersionsById[0].id,
      relationType: Number(this.ownershipType),
      personId: this.autPerson ? this.autPerson.id : null,
      personFirstName: this.autPerson ? this.autPerson.firstName : this.ownershipModalForm.controls.firstName.value,
      personName: this.autPerson ? this.autPerson.name : this.ownershipModalForm.controls.name.value,
      personRegCode: this.autPerson ? this.autPerson.registerCode : this.ownershipModalForm.controls.idOrRegistryCode.value,
      personType: this.autPerson ? this.autPerson.personType : this.personType,
      personCountryId: this.autPerson ? this.autPerson.countryId : this.selectedCountry.id,
      personAddress: this.ownershipModalForm.controls.address.value,
      personEmail: this.ownershipModalForm.controls.email.value,
      representedId: this.representedPersonId,
      fractureParts: this.ownershipModalForm.value.fractureParts,
      fractureWhole: this.ownershipModalForm.value.fractureWhole
    } as AppOwnership;
  } */

  /* createOwnership(): void {
   if (this.citizenship === Citizenship.EST ? this.ownershipModalForm.controls.email.valid : this.ownershipModalForm.valid) {
     const ownership = this.createOwnershipObject();
     this.closeModal();
     this.aplService.saveOwnership(ownership).subscribe((response: AppOwnership) => {
       if (response) {
         this.refreshOwnerships();
         this.translateServ.get('APL.REQ_OWN_SAVE_SUCCESS').subscribe((text: string) => {
           this.getLongMsgSuccess(text);
         });
       }
     }, () => {
       this.translateServ.get('ERROR.CREATE_DATA').subscribe((text: string) => {
         this.getLongMsgError(text);
       });
     });
   } else {
     this.formSubmitted = true;
   }
 } */

  closeModal() {
    this.closeModalEv.emit(true);
  }
}
