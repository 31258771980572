import { Component, OnInit, OnDestroy, EventEmitter, Output, ViewChild, TemplateRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Subscription, forkJoin, of } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { saveAs } from 'file-saver';

import { DocumentService } from '../_services/document.service';
import { DocDocument, DocTemplate } from 'src/app/_domain/docdocument.interface';
import {
  dateIssuedStartEndValidation,
  dateLessThanOrEqualToDoday,
  dateStartEndValidation,
  isDateValidator
} from '../_utils/common/utils';
import { DateConstant } from '../_utils/date';
import { BaseComponent } from '../_common/base/base.component';
import { DatePipe } from '@angular/common';
import { UserInfo } from 'src/app/_domain';
import { API_ENDPOINT } from 'src/app/app.constants';
import { PaginationInstance } from 'ngx-pagination';
import { SortEvent } from 'src/app/_domain/sortevent.interface';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})
export class DocumentComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('modalEditFileTpl') modalEditFileTpl: TemplateRef<any>;
  editDocFileFormModalRef: BsModalRef;
  currentDocTemp: DocTemplate;
  editDocFileForm: FormGroup;
  documentsList: DocTemplate[];
  myTempPaginationData = {
    page: 0,
    size: 10
  };
  public templatePageConfig: PaginationInstance;
  templListSort: SortEvent = {
    sortColumn: null,
    sortDirection: null
  };

  // from initialState
  context: string;
  docId: number;
  mode: string;
  env: string;
  saveDocumentOutsideComponent: boolean;
  inspectionId: number;
  versionId: number;
  preFillInputs;
  signedBy: number;
  signatureUuid: string;
  signed: Date;
  // END from initialState
  @Output() closeDocModal: EventEmitter<any> = new EventEmitter();
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() genDoc: EventEmitter<any> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<any> = new EventEmitter();
  @Output() removeFileEvent: EventEmitter<any> = new EventEmitter();
  @Output() restoreDoc: EventEmitter<any> = new EventEmitter();

  private subscription = new Subscription();
  isLoading: boolean;
  isGenerating: boolean;
  hasTemplate = false;
  lang: string;
  documentForm: FormGroup;
  file: File;
  doctypeGroups: Array<any>; // TODO tüüpida
  doctypesByGroup: any; // TODO tüüpida Map<number, any>
  doctypes: Array<any>;
  docForms: Array<any>; // TODO tüüpida
  issuers: Array<any>; // TODO tüüpida
  showVAIssuer: boolean;
  vtaMode = false;
  currentDoc: DocDocument;
  public localDateFormat = DateConstant.DateFormat;
  showArchiveBtn = false;
  currentUser: UserInfo;
  allowedExtentions = [];

  fileLoading = false;

  // IDs of veeteede amet & transpordiamet
  VEETEEDE_AMET_CODE = 291;
  TRANSPORDIAMET_CODE = 292;

  constructor(
    private fb: FormBuilder,
    private documentService: DocumentService,
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit() {
    this.currentUser = this.userService.getUser();
    this.addSubscriptionToList(
      this.userService.getUserSubject.subscribe(cUser => {
        this.currentUser = cUser;
      })
    );

    this.addSubscriptionToList(
      this.documentService.getAllowedExtentions().subscribe((res: any) => {
        this.allowedExtentions = res;
      })
    );

    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );

    if (this.docId && this.mode === 'ro') {
      this.addSubscriptionToList(
        forkJoin(
          this.documentService
            .getDocTypes(this.context)
            .pipe(catchError(error => of({ isError: true, error }))),
          this.documentService.getDocument(this.docId).pipe(
            switchMap(response => {
              this.currentDoc = response.data;
              return this.documentService
                .getDocForms(response.data.docType)
                .pipe(catchError(error => of({ isError: true, error })));
            })
          )
        ).subscribe(([typeResponse, docResponse]) => {
          this.currentDoc.docTypeDescr = typeResponse.data.docTypes.find(
            t => +t.code === +this.currentDoc.docType
          ).descr;
          if (this.currentDoc.docForm) {
            this.currentDoc.docFormDescr = docResponse.data.find(
              t => +t.code === +this.currentDoc.docForm
            ).descr;
          }
          this.isLoading = false;
        })
      );
    } else {
      this.buildForm();
      if (this.docId) {
        this.loadDoc(this.docId);
      } else if (this.preFillInputs) {
        this.showVAIssuer = this.preFillInputs.showVAIssuer;
        this.documentForm.patchValue(this.preFillInputs);
      }
      this.getIssuers();
      this.getDoctypesByContext();
      this.listenToDocType();
      this.switchIssuerMode();
      this.followDocNumber();
      this.listenToDocForm();
    }
    this.setShowArchiveBtn();

    this.templatePageConfig = {
      id: 'templateSearchId',
      itemsPerPage: this.myTempPaginationData.size,
      currentPage: this.myTempPaginationData.page + 1,
      totalItems: 0
    };
  }

  hasVTAorTAid(id: number): boolean {
    return id === this.TRANSPORDIAMET_CODE || id === this.VEETEEDE_AMET_CODE;
  }

  setShowArchiveBtn() {
    this.showArchiveBtn = false;
    if (this.docId && this.context === 'LAEV' && this.currentUser.privileges.includes('DOK_HALDUR')) {
      this.showArchiveBtn = true;
    }
  }

  close() {
    this.closeDocModal.emit({ success: true });
    this.bsModalRef.hide();
  }

  loadDoc(docId: number) {
    this.addSubscriptionToList(
      this.documentService.getDocument(docId).subscribe(
        response => {
          const doc = response.data;
          this.setFormvalues(doc);
          this.currentDoc = doc;
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  setFormvalues(doc: any) {
    this.documentForm.setValue({
      id: doc.id,
      docType: doc.docType,
      docForm: doc.docForm,
      docTitle: doc.docTitle,
      issuerId: doc.issuerId,
      issuer: doc.issuer,
      docNumber: doc.docNumber,
      issueDate: this.toDate(doc.issueDate),
      validFrom: this.toDate(doc.validFrom),
      validTo: this.toDate(doc.validTo),
      verifiedTo: this.toDate(doc.verifiedTo),
      validationTerminated: this.toDate(doc.validationTerminated),
      blankNr: doc.blankNr,
      officialOnly: doc.officialOnly,
      fileExtension: doc.fileExtension,
      fileOriginName: doc.fileOriginName,
      archived: doc.archived
    });
    this.signedBy = doc.signedBy;
    this.signatureUuid = doc.signatureUuid;
    this.signed = doc.signed;
    if (doc.issuerId) {
      this.documentForm.get('issuer').disable();
    }
    this.documentForm.get('docType').disable();
  }

  toDate(datestr: string): Date {
    if (!datestr) {
      return null;
    }
    const dmy = datestr.match(/(\d+)/g);
    // return new Date(+dmy[2], +dmy[1] - 1, +dmy[0]);
    return new Date(+dmy[0], +dmy[1] - 1, +dmy[2]);
    // LAB date string from backend keeps changing
    // issueDate: "2020-03-03"
    // validationTerminated: {year: 2020, month: "MARCH", day: 10 ...
  }

  followDocNumber() {
    this.addSubscriptionToList(
      this.documentForm.get('docNumber').valueChanges.subscribe(docNumber => {
        if (docNumber) {
          this.vtaMode = false;
        } else if (this.env === 'mk' && this.hasVTAorTAid(+this.documentForm.value.issuerId)) {
          this.vtaMode = true;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribeAllFromList();
  }

  /*
    Confluence Ref(context): https://tarkvara.datel.ee/confluence/pages
    /viewpage.action?spaceKey=LAEV&title=Dokumendi+vormid#Dokumendivormid-Dokumentidelisamisejamuutmisekontekstid
  */
  getDoctypesByContext() {
    this.addSubscriptionToList(
      this.documentService.getDocTypes(this.context).subscribe(
        response => {
          this.doctypes = response.data.docTypes;
          const groups = response.data.docTypes.map(d => d.docDoctypeGroupByGroupCode);
          this.doctypeGroups = groups.reduce((acc, current) => {
            const result = acc.find(({ code }) => code === current.code);
            if (!result) {
              acc.push(current);
            }
            return acc;
          }, []);
          this.doctypesByGroup = response.data.docTypes.reduce((acc, current) => {
            const key = current.groupCode;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(current);
            return acc;
          }, {});
          if (response.data.limited) {
            this.documentForm.get('docType').setValue(this.doctypes[0].code);
          }
          if (this.documentForm.get('docType').value) {
            this.getDocForms(this.documentForm.get('docType').value);
          }
          this.uiHelper.refreshSelectPickerById('#docTypePicker');
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  switchIssuerMode() {
    this.addSubscriptionToList(
      this.documentForm.get('issuerId').valueChanges.subscribe(issuerId => {
        this.documentForm.get('issuer').setValue(this.getIssuerById(issuerId));
        if (this.issuers) {
          if (issuerId) {
            this.documentForm.get('issuer').disable();
            if (this.env === 'mk' && this.hasVTAorTAid(+issuerId) && !this.documentForm.value.docNumber) {
              this.vtaMode = true;
            } else {
              this.vtaMode = false;
            }
          } else {
            this.vtaMode = false;
            this.documentForm.get('issuer').setValue(null);
            this.documentForm.get('issuer').enable();
          }
        }
        this.uiHelper.setSelectValue('#issuerPicker', issuerId);
      })
    );
  }

  getIssuerById(issuerId: number): string {
    if (this.issuers) {
      const issuer = this.issuers.find(i => +i.id === +issuerId);
      if (issuer) {
        return issuer.description;
      }
    }
  }

  getFile(event: any) {
    this.file = event.target.files[0];

    let fileName = this.file.name;
    /* if (this.file && this.file.name) {
      let fileNameList = this.file.name.split('.');
      fileNameList[fileNameList.length - 1] = fileNameList[fileNameList.length - 1].toLowerCase();
      fileName = fileNameList.join('.');
    } */

    this.documentForm.get('fileOriginName').setValue(fileName);
  }

  showCreatePdfBtn() {
    return (
      this.documentForm.valid &&
      this.mode === 'rw' &&
      this.documentForm.value['id'] &&
      this.getFileExtension(this.documentForm.value.fileOriginName) === 'docx'
    );
  }

  onSubmit() {
    const ext = this.file ? this.file.name.split('.').pop() : '';
    if (this.file && ext && !this.allowedExtentions.includes(ext.toLocaleLowerCase())) {
      this.getLongMsgError('Lubatud on ainult järgmised failid: ' + this.allowedExtentions.join(', '));
      return;
    }

    if (this.documentForm.invalid) {
      this.getLongMsgError('GLOBAL.INVALID_FORM');
      return;
    }

    const docDocument = this.documentForm.getRawValue() as DocDocument;
    docDocument.signatureUuid = this.signatureUuid;
    docDocument.signedBy = this.signedBy;
    docDocument.signed = this.signed;

    if (!this.saveDocumentOutsideComponent) {
      const defaultErrorMsgLabel = 'DOC.ADD_FAILED';
      this.addSubscriptionToList(
        this.documentService.saveDocument(docDocument, this.file).subscribe(
          response => {
            this.save.emit(response.data);
            this.close();
          },
          error => {
            this.showDbErrorMessage(defaultErrorMsgLabel, error, true);
            this.close();
          }
        )
      );
    } else {
      // save document outside Component
      this.save.emit({ success: true, doumentData: docDocument, fileData: this.file });
    }
  }

  buildForm() {
    this.documentForm = this.fb.group(
      {
        id: [null],
        docType: [null, Validators.required],
        docForm: [null],
        docTitle: [null],
        issuerId: [null],
        issuer: [null],
        docNumber: [null],
        issueDate: [null, [isDateValidator(), dateLessThanOrEqualToDoday()]],
        validFrom: [null, isDateValidator()],
        validTo: [null, isDateValidator()],
        verifiedTo: [null, isDateValidator()],
        validationTerminated: [null, isDateValidator()],
        blankNr: [null],
        officialOnly: [false],
        fileExtension: [null],
        fileOriginName: [null],
        archived: [null]
      },
      {
        validator: dateIssuedStartEndValidation([{ issued: 'issueDate', start: 'validFrom', end: 'validTo' }])
      }
    );
    this.uiHelper.initSelectPickerById('#docTypePicker');
    this.uiHelper.initSelectPickerById('#docFormPicker');
    this.uiHelper.initSelectPickerById('#issuerPicker');
    this.documentForm.get('docForm').disable();

    this.editDocFileForm = this.fb.group({
      fileOriginName: [null, Validators.required]
    });
  }

  listenToDocType() {
    this.addSubscriptionToList(
      this.documentForm.get('docType').valueChanges.subscribe(docType => {
        this.getDocForms(docType);
        this.uiHelper.setSelectValue('#docTypePicker', docType);
      })
    );
  }

  getDocForms(doctype: number) {
    this.addSubscriptionToList(
      this.documentService.getDocForms(doctype).subscribe(
        response => {
          this.docForms = response.data;
          this.uiHelper.refreshSelectPickerById('#docFormPicker');
          if (this.docForms.length === 0) {
            this.documentForm.get('docForm').disable();
          } else {
            this.documentForm.get('docForm').enable();
          }
          if (this.documentForm.get('docForm').value) {
            this.setHasTemplate(this.documentForm.get('docForm').value);
          }
          this.uiHelper.refreshSelectPickerById('#docFormPicker');
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  listenToDocForm() {
    this.addSubscriptionToList(
      this.documentForm.get('docForm').valueChanges.subscribe(docform => {
        this.setHasTemplate(docform);
        this.uiHelper.setSelectValue('#docFormPicker', docform);
      })
    );
  }

  setHasTemplate(code: number) {
    if (this.docForms) {
      const df = this.docForms.find(f => +f.code === +this.documentForm.get('docForm').value);
      if (df && df.formUuid) {
        this.hasTemplate = true;
      } else {
        this.hasTemplate = false;
      }
    }
  }

  getIssuers() {
    this.addSubscriptionToList(
      this.documentService.getIssuers().subscribe(
        response => {
          this.issuers = response.data;
          this.uiHelper.refreshSelectPickerById('#issuerPicker');
          if (this.documentForm.get('issuerId').value) {
            const issuerId = this.documentForm.get('issuerId').value;
            this.documentForm.get('issuer').setValue(this.getIssuerById(issuerId));
            this.documentForm.get('issuer').disable();
            if (this.env === 'mk' && this.hasVTAorTAid(+issuerId) && !this.documentForm.value.docNumber) {
              this.vtaMode = true;
            } else {
              this.vtaMode = false;
            }
          } else if (this.showVAIssuer) {
            const issuerId = this.TRANSPORDIAMET_CODE;
            this.documentForm.get('issuerId').patchValue(issuerId);
            this.documentForm.get('issuer').setValue(this.getIssuerById(issuerId));
            this.documentForm.get('issuer').disable();
            if (this.env === 'mk' && !this.documentForm.value.docNumber) {
              this.vtaMode = true;
            } else {
              this.vtaMode = false;
            }
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  getDocNumber() {
    if (this.documentForm.get('docType').value) {
      this.addSubscriptionToList(
        this.documentService.getDocumentNumber(this.documentForm.get('docType').value).subscribe(
          response => {
            if (response.success) {
              this.documentForm.get('docNumber').setValue(response.data);
            }
          },
          error => {
            this.getLongMsgError(error.message);
          }
        )
      );
    } else {
      this.getLongMsgWarning('DOC.DOCTYPE_ERROR');
    }
  }

  createPdf() {
    if (this.documentForm.invalid) {
      this.getLongMsgError('GLOBAL.INVALID_FORM');
      return;
    }
    const docDocument = this.documentForm.getRawValue() as DocDocument;
    docDocument.signatureUuid = this.signatureUuid;
    docDocument.signedBy = this.signedBy;
    docDocument.signed = this.signed;

    this.addSubscriptionToList(
      this.documentService.createPdf(docDocument, this.file).subscribe(
        response => {
          const doc = response.data;
          this.setFormvalues(doc);
          this.file = null;
          this.save.emit(doc.id);
        },
        error => {
          this.getLongMsgError('DOC.CREATE_PDF_ERROR');
        }
      )
    );
  }

  removeFile() {
    this.file = null;
    this.documentForm.get('fileOriginName').setValue(null);
    this.documentForm.get('fileExtension').setValue(null);
    this.removeFileEvent.emit({ success: true });
  }

  getDoctype(code: number): any {
    return this.doctypes.filter(t => +t.code === +code);
  }

  onCancel() {
    this.cancelEvent.emit(true);
    this.close();
  }

  saveAndPreviewDoc() {
    if (this.documentForm.invalid) {
      this.getLongMsgError('GLOBAL.INVALID_FORM');
      return;
    }
    const docDocument = this.documentForm.getRawValue() as DocDocument;
    docDocument.signatureUuid = this.signatureUuid;
    docDocument.signedBy = this.signedBy;
    docDocument.signed = this.signed;

    const defaultErrorMsgLabel = 'DOC.ADD_FAILED';
    this.addSubscriptionToList(
      this.documentService.saveDocument(docDocument, this.file).subscribe(
        response => {
          this.documentForm.get('id').setValue(response.data);
          this.save.emit(response.data);
          this.previewDoc();
        },
        error => {
          this.showDbErrorMessage(defaultErrorMsgLabel, error, true);
        }
      )
    );
  }

  previewDoc() {
    const docDocument = this.documentForm.getRawValue() as DocDocument;
    docDocument.docForm = +docDocument.docForm;

    let observable;
    if (this.inspectionId && (this.context === 'YV_AKT' || this.context === 'TAOTLUS')) {
      docDocument.contextObjectId = this.inspectionId;
      observable = this.documentService.generatePreview(docDocument, 'inspection');
    }
    if (this.inspectionId && this.context === 'VLAEV_AKT') {
      docDocument.contextObjectId = this.inspectionId;
      observable = this.documentService.generatePreview(docDocument, 'small-vessel');
    }
    if (this.versionId) {
      docDocument.contextObjectId = this.versionId;
      observable = this.documentService.generatePreview(docDocument, 'app-version');
    }

    this.addSubscriptionToList(
      observable.subscribe(
        response => {
          const date = new Date();
          // TODO read filename and type from response Content-disposition headers?
          const filename =
            docDocument.docType +
            '_preview' +
            new DatePipe('et_EE').transform(date, 'ddMMyyyy_HHmm') +
            '.docx';
          const mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          const blob = new Blob([response], { type: mimetype });
          saveAs(blob, filename);
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  generateDoc() {
    this.isGenerating = true;
    const docDocument = this.documentForm.getRawValue() as DocDocument;
    docDocument.docForm = +docDocument.docForm;
    let observable;
    if (this.inspectionId && (this.context === 'YV_AKT' || this.context === 'TAOTLUS')) {
      docDocument.contextObjectId = this.inspectionId;
      observable = this.documentService.generateDocument(docDocument, 'inspection');
    }
    if (this.inspectionId && this.context === 'VLAEV_AKT') {
      docDocument.contextObjectId = this.inspectionId;
      observable = this.documentService.generateDocument(docDocument, 'small-vessel');
    }
    if (this.versionId) {
      docDocument.contextObjectId = this.versionId;
      observable = this.documentService.generateDocument(docDocument, 'app-version');
    }

    if (!this.saveDocumentOutsideComponent) {
      this.addSubscriptionToList(
        observable.subscribe(
          response => {
            if (response.success && response.data) {
              this.save.emit(response.data);
              this.close();
            } else {
              this.getLongMsgError('MESSAGE.SAVE.FAIL');
              this.isGenerating = false;
            }
          },
          error => {
            this.getLongMsgError(error.message);
            this.isGenerating = false;
          }
        )
      );
    } else {
      // save document outside Component
      this.genDoc.emit({ success: true, doumentData: docDocument });
    }
  }

  archive() {
    if (this.documentForm.invalid) {
      this.getLongMsgError('GLOBAL.INVALID_FORM');
      return;
    }
    const docDocument = this.documentForm.getRawValue() as DocDocument;

    this.addSubscriptionToList(
      this.documentService.archive(docDocument, this.file).subscribe(
        response => {
          if (response.success) {
            this.save.emit(response.data);
          } else {
            this.getLongMsgError('MESSAGE.SAVE.FAIL');
          }
          this.close();
        },
        error => {
          this.getLongMsgError(error.message);
          this.close();
        }
      )
    );
  }

  restore() {
    this.addSubscriptionToList(
      this.documentService.restoreArchivedDocument(this.docId).subscribe(
        response => {
          if (response.success) {
            this.restoreDoc.emit(this.docId);
          }
          this.close();
        },
        error => {
          this.getLongMsgError(error.message);
          this.close();
        }
      )
    );
  }

  openFileEditModal(docTemp: any) {
    this.currentDocTemp = docTemp;
    this.editDocFileFormModalRef = this.modalService.show(
      this.modalEditFileTpl,
      Object.assign({}, { class: 'modal-lg' }, this.modalNoBackdropConfig)
    );
  }

  downloadDocxTemplate() {
    const formCode =
      this.currentDoc && this.currentDoc.docForm
        ? this.currentDoc.docForm
        : this.documentForm.controls.docForm.value;
    if (formCode) {
      window.location.href = API_ENDPOINT + 'api/document/template/download/' + formCode;
    }
  }

  closeFileEditModal(): void {
    this.removeFile();
    this.editDocFileFormModalRef.hide();
  }

  getTemplateFile(event: any) {
    this.file = event.target.files[0];
    this.editDocFileForm.get('fileOriginName').setValue(this.file.name);
  }

  removeTemplateFile() {
    this.file = null;
    this.editDocFileForm.get('fileOriginName').setValue(null);
  }

  saveNewDocFile() {
    if (!this.fileLoading) {
      const fileExten = this.file ? this.getFileExtension(this.file.name) : undefined;
      if (fileExten === 'docx') {
        this.fileLoading = true;
        if (!this.currentDocTemp) {
          this.currentDocTemp = {
            formCode: null,
            hasTemplate: true,
            form: null,
            type: null,
            lastModifiedAt: null
          };
        }
        this.currentDocTemp.formCode =
          this.currentDoc && this.currentDoc.docForm
            ? this.currentDoc.docForm
            : this.documentForm.controls.docForm.value;
        this.addSubscriptionToList(
          this.documentService.saveDocTemplate(this.currentDocTemp, this.file).subscribe(
            res => {
              if (res.success) {
                this.getLongMsgSuccess('MESSAGE.EDIT.SUCCESS');
                // this.getTemplateList(this.myTempPaginationData, this.templListSort);
                this.fileLoading = false;
                this.closeFileEditModal();
              }
            },
            error => {
              this.showDbErrorMessage('MESSAGE.EDIT.FAIL', error);
              this.fileLoading = false;
              this.closeFileEditModal();
            }
          )
        );
      } else {
        this.getLongMsgWarning('DOC.WRONG_FILE_FORMAT_UPLOAD');
        this.closeFileEditModal();
      }
    }
  }

  getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  /* getTemplateList(myTempPaginationData, listSortConfig) {
    this.addSubscriptionToList(
      this.documentService
        .getDocTemplates(myTempPaginationData.page, myTempPaginationData.size, listSortConfig)
        .subscribe(
          res => {
            if (res.success) {
              // this.documentsList = this.getSortedByDateDec(res.data);
              this.documentsList = res.data;
              this.templatePageConfig.totalItems = res.total;
            }
          },
          error => {
            this.getLongMsgError(error);
            this.currentDocTemp = null;
          }
        )
    );
  } */
}
