import { Directive, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'a[gray], a[blue], a[orange], a[href-void]'
})
export class LinkDirective implements OnInit {
  @Input()
  public icon: string;
  @Input()
  @HostBinding('class')
  public class = '';
  @Input()
  @HostBinding('href')
  public href: SafeUrl = '';

  private hrefVoid = 'href-void';
  private colors: string[] = ['gray', 'blue', 'orange'];
  private sizes: string[] = ['small'];

  constructor(private elem: ElementRef, private domSanitizer: DomSanitizer) {
    //
  }

  public ngOnInit() {
    const color = this.searchAttributes(this.colors);
    if (color) {
      this.class += ' link-' + color;
    }

    const size = this.searchAttributes(this.sizes);
    if (size) {
      this.class += ' link-' + size;
    }

    if (this.icon) {
      this.class += ' icon-' + this.icon;
      this.class += ' no-decoration';
    }

    if (this.elem.nativeElement.attributes[this.hrefVoid]) {
      this.href = this.domSanitizer.bypassSecurityTrustUrl('javascript:void(0)');
    }
  }

  private searchAttributes(allowedKeys: string[]): string {
    for (const key in allowedKeys) {
      if (this.elem.nativeElement.attributes[allowedKeys[key]]) {
        return allowedKeys[key];
      }
    }
    return '';
  }
}
