<!-- <div class="modal-dialog">
    <div class="modal-content"> -->
<div class="modal-header pb-1">
  <h2> {{ 'INS.DETAILS.DOCS_EXT_SELECT' | translate }}</h2>
  <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}"
    (click)="bsModalRef.hide()"></button>
</div>
<div class="modal-body">
  <form [formGroup]="extSelForm" (ngSubmit)="onSubmit()">
    <table appSortableTable="'extSel'" formArrayName="docIds"
      class="table table-hover table-adaptive table-cells table-sm table-md w-100">
      <thead class="table-head bg-white">
        <th><a>{{ 'GLOBAL.SELECT' | translate }}</a></th>
        <th><a>{{ 'DOC.DOCTYPE' | translate }}</a></th>
        <th><a>{{ 'DOC.ISSUER' | translate }}</a></th>
        <th><a>{{ 'DOC.ISSUED' | translate }}</a></th>
        <th><a>{{ 'DOC.VALIDTO' | translate }}</a></th>
        <th><a>{{ 'DOC.VERIFIED' | translate }}</a></th>
      </thead>
      <tbody class="bg-white">
        <tr *ngFor="let doc of docList; let i = index">
          <td [attr.data-label]="('GLOBAL.SELECT' | translate) + ':'">
            <div class="custom-control custom-checkbox">
              <input type="checkbox" [id]="i" formControlName="{{i}}" class="custom-control-input" />
              <label class="custom-control-label" [for]="i"> &nbsp;</label>
            </div>
          </td>
          <td [attr.data-label]="( 'DOC.DOCTYPE' | translate) + ':'">
            {{ doc.descr }}
            <span class="label-comment">{{ doc.docNumber }}</span></td>
          <td [attr.data-label]="('DOC.ISSUER' | translate) + ':'">{{ doc.issuer }}</td>
          <td [attr.data-label]="('DOC.ISSUED' | translate) + ':'">{{ doc.issueDate | date: localDateFormat }}</td>
          <td [attr.data-label]="('DOC.VALIDTO' | translate) + ':'">{{ doc.validTo | date: localDateFormat }}</td>
          <td [attr.data-label]="('DOC.VERIFIED' | translate) + ':'">{{ doc.verifiedTo | date: localDateFormat }}</td>
        </tr>
      </tbody>
    </table>
    <div class="float-right py-3">
      <button success [style]="'square'" [icon]="'save'">
        <span>{{ 'GLOBAL.SAVE' | translate }}</span>
      </button>
      <button danger [style]="'square'" custom-class="ml-2" type="button" [icon]="'cancel'"
        (click)="bsModalRef.hide()"><span>
          {{ 'GLOBAL.LEAVE' | translate }}
        </span>
      </button>
    </div>
  </form>
</div>
<!--     </div>
</div> -->
