<!-- SPINNER -->
<div id="app-root-container" *ngIf="isAppRootVisible">
  <div class="spinner-layer-bg">
    <div class="spinner-layer">
      <div class="lds-spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>


  <div *ngIf="isTermsAcceped">
    <div *ngIf="!isRoleSelected" class="mt-5">
      <app-home></app-home>
    </div>
    <div *ngIf="isRoleSelected">
      <!-- Main page body -->
      <ng-container *ngTemplateOutlet="mainPageBodyTpl"></ng-container>
    </div>


  </div>
  <!-- !isTermsAcceped -->
  <div *ngIf="!isTermsAcceped">
    <ng-container *ngTemplateOutlet="confirmTermsTpl"></ng-container>
  </div>


</div>
<div *ngIf="!isAppRootVisible" class="w-100 h-100">
  <div style="padding:40px;text-align:center;">
    <!--  <div class="lds-ripple">
      <div></div>
      <div></div>
    </div> -->
    <img src="assets/assets/images/logos/liquid.svg" alt="" class="" />
    <h1 style="color:#003087;font-family: Roboto, Arial, Helvetica, sans-serif;padding:20px 0;font-weight:normal;">
      {{ 'GLOBAL.LOADING' | translate}}</h1>
  </div>

</div>




<!--
  TEMPLATES
-->

<ng-template #confirmTermsTpl>
  <div class="row bg-white mt-5 pt-2 ml-auto mr-auto col-12 representativeBox d-block">
    <div class="row w-100">
      <div class="col-20 ml-5  px-0 d-flex justify-content-end">
        <app-langbar class="align-middle"></app-langbar>
      </div>
    </div>
    <div class="row ">
      <div class="col-20">
        <div class="custom-control custom-checkbox">
          <input [value]="isApplicationTermsAccepted" [(ngModel)]="isApplicationTermsAccepted" type="checkbox"
            id="applicationTerms" class="custom-control-input">
          <label class="custom-control-label"
            for="applicationTerms">{{ ('TERMS.APPLICATION_TERMS_ACCEPT_MSG' | translate) }}
            {{ ('TERMS.APPLICATION_TERMS_DOWNLOAD_MSG' | translate) }}
            <a href="assets/data/pdf/{{lang}}_application_terms.pdf" target="_blank"
              download="{{ ('TERMS.APPLICATION_FILE_NAME' | translate) }}">{{ ('TERMS.APPLICATION_TERMS_LINK' | translate) }}</a></label>
        </div>
      </div>
    </div>
    <div class="row ">
      <div class="col-20">
        <div class="custom-control custom-checkbox">
          <input [value]="isUserTermsAccepted" [(ngModel)]="isUserTermsAccepted" type="checkbox" id="userTerms"
            class="custom-control-input">
          <label class="custom-control-label" for="userTerms">{{ ('TERMS.USER_TERMS_ACCEPT_MSG' | translate) }}
            {{ ('TERMS.USER_TERMS_DOWNLOAD_MSG' | translate) }}
            <a href="assets/data/pdf/{{lang}}_user_terms.pdf" target="_blank"
              download="{{ ('TERMS.USER_TERMS_FILE_NAME' | translate) }}">{{ ('TERMS.USER_TERMS_LINK' | translate) }}</a></label>
        </div>
      </div>
    </div>
    <div class="row w-100">
      <div class="col-20 ml-5 pt-3 mb-3 px-0 d-flex justify-content-end">
        <button success *ngIf="!(isApplicationTermsAccepted === false || isUserTermsAccepted === false)"
          [style]="'square'" type="button" custom-class="" (click)="confirmTerms()" [icon]="'save'">
          <span>{{ 'GLOBAL.CONFIRM' | translate }}</span> </button>
        <button *ngIf="isApplicationTermsAccepted === false || isUserTermsAccepted === false" success [disabled]="true"
                [style]="'square'" type="button" custom-class="" [icon]="'save'">
          <span>{{ 'GLOBAL.CONFIRM' | translate }}</span> </button>
        <button danger [style]="'square'" type="button" custom-class="ml-2" (click)="''" [icon]="'cancel'"
          [popover]="cancelPopover" [outsideClick]="true">
          <span>
            {{ 'TERMS.REFUSE' | translate }}</span> </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #mainPageBodyTpl>
  <app-header></app-header>
  <app-breadcrumbs></app-breadcrumbs>
  <!-- show timer -->
  <div id="mainRouterOutletId" class="mainRouterOutletCls container mb-5 px-0" style="min-height: 20vh;">
    <!-- [ngClass]="{'bg-gray-light': !isRoleSelected, 'bg-white': isRoleSelected}" -->
    <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
  </div>
  <!--div class="debugMode container pb-5 d-flex" *ngIf="debugMode === true">
    <div>
      <button class="btn btn-success" routerLink="/et/playground">
        <i class="icon-color mr-2"></i>LIS PLAYGROUND </button>
    </div>
    <div class="ml-3">
      <button info
        (click)="getLongMsgError('MESSAGE.SUCCSESS', 'bla');getLongMsgInfoSticky('\'Second Review\' validation has<br/> started. All SME\'s and Reviewers will get an email notification</br>');getLongMsgError('bla bla');getLongMsgWarningSticky('bla bla');getLongMsgSuccessSticky('<h2>Data shown successfully !!</h2>');getLongMsgInfo('\'Second Review\' validation has started. <br />All SME\'s and Reviewers will get an email notification</br>');">Sticky
        notification test</button>
    </div>
  </div-->
  <app-footer></app-footer>
</ng-template><!-- template END -->

<ng-template #cancelPopover>
  <div class="body p-2" style="min-width: 230px;">
    <h4>{{ 'TERMS.REFUSE_CONFIRM_MSG' | translate }}</h4>
    <div class="col-xs-20 pt-3 mb-1 d-flex justify-content-end">
      <button success [style]="'small'" type="button" custom-class="" (click)="logout()" [icon]="'ok'">
        <span>{{ 'GLOBAL.YES' | translate }}</span>
      </button>
      <!--  <button danger [style]="'small'" type="button" custom-class="ml-2" (click)="closePopover()" [icon]="'cancel'">
        <span>{{ 'GLOBAL.NO' | translate }}</span>
      </button> -->
    </div>
  </div>
</ng-template><!-- template END -->

<!-- Timer -->
<ng-template #countDownTmp>
  <!--  <div #timer class="" style="
    font-weight: 600;
    position: absolute;
    top: 5px;
    right: 30px;
    z-index: 3;
    font-size: large;" ></div> -->
</ng-template><!-- template END -->

<ng-template #confirmTimeoutTmp>
  <div class="modal-header pb-1 mb-0">
    <h2>{{'SESSIOON.ENDING_TITLE' | translate }}</h2>

    <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}"
      (click)="closeModal()"></button>
  </div>
  <div class="modal-body  mb-0">
    <div class="row mb-0">
      <div class="col-20 mb-0">
        <h4>{{'SESSIOON.QUESTION' | translate }}</h4>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-20 pt-3 mb-3 d-flex justify-content-between">
        <div id="timerId" #timer style="font-weight: 600;font-size: 18px;"></div>
        <div>
          <button success [style]="'small'" type="button" custom-class="" (click)="resetIdle(); closeModal()"
            [icon]="'ok'">
            <span>{{'GLOBAL.YES' | translate }}</span>
          </button>
          <button danger [style]="'small'" type="button" custom-class="ml-2" (click)="logout();" [icon]="'cancel'">
            <span>{{'GLOBAL.NO' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template><!-- template END -->

<ng-template #timeoutMessageTmp>
  <div class="modal-header pb-1 mb-0">
    <h2>{{'SESSIOON.END_TITLE' | translate }}</h2>
    <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}"
      (click)="closeModal(); logout()"></button>
  </div>
  <div class="modal-body  mb-0">
    <div class="row mb-0">
      <div class="col-20 mb-0">
        <h4>{{'SESSIOON.END_MESSAGE' | translate }}</h4>
      </div>
    </div>
    <div class="row mb-0">
      <div class="col-20 pt-3 mb-3 d-flex justify-content-end">
        <div>
          <button success [style]="'small'" type="button" custom-class="" (click)="closeModal(); logout();"
            [icon]="'ok'">
            <span>{{'GLOBAL.CONFIRM' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template><!-- template END -->

<ng-template #emptyTmp></ng-template>
