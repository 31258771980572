<div class="row m-0">
  <div class="col-20 bg-gray-upper py-5">
    <div class="d-flex justify-content-between">
<h1 class="mb-5">{{ 'AUTH.AUTHSEARCH' | translate }}</h1>
      <!-- <button info type="button" custom-class="mt-2" [style]="'square'" [icon]="'with-plus'"
        [routerLink]="['../volitus/']" ><span>{{ 'AUTH.ADD' | translate }}</span></button> -->
    </div>

    <form [formGroup]="authSearchForm" (ngSubmit)="onSubmit()">
      <div class="form-group row align-items-end">
        <div class="col-xs-20 col-md-7 pr-4">
          <label class="col-form-label has-comment text-left">
            {{ 'AUTH.AUTH1' | translate }}
            <span class="label-comment">{{ 'AUTH.AUTH2' | translate }}</span>
          </label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="authorizer" />
          </div>
        </div>
        <div class="col-xs-20 col-md-6 pr-4">
          <label class="col-form-label has-comment text-left">
            {{ 'AUTH.AUTH3' | translate }}
            <span class="label-comment">{{ 'AUTH.AUTH4' | translate }}</span>
          </label>
          <div class="input-group">
            <input type="text" class="form-control" formControlName="authorized" />
          </div>
        </div>
        <div class="col-xs-20 col-md-6 pr-4">
          <!-- kuupäev -->
          <label class="col-form-label">{{ 'AUTH.PERIOD' | translate }}</label>
          <div
            class="row range-group mx-0 form-group pb-0"
            [ngClass]="{ 'has-danger': submitted && !authSearchForm?.valid }"
          >
            <div class="col-10 pl-0 pr-3">
              <app-datepicker
                id="dateValue"
                formControlName="validDate"
                placeholder="{{ 'GLOBAL.KUUPAEV' | translate }}"
              ></app-datepicker>
            </div>
            <div *ngIf="submitted && !authSearchForm?.valid" class=" invalid-feedback">
              <div *ngIf="authSearchForm.errors?.dates">
                {{ authSearchForm.errors?.dates | translate }}
              </div>
            </div>
          </div>
          <!-- /kuupäeva vahemik-->
        </div>
      </div>
      <!-- row -->

      <div class="form-group row align-items-end">
        <div class="col-xs-20 col-md-6 pxr4">
          <label class="col-form-label">{{ 'AUTH.ASTATUS' | translate }}</label>
          <div class="d-flex">
            <div class="custom-control custom-radio">
              <input
                id="radioStacked1Id"
                type="radio"
                class="custom-control-input"
                formControlName="state"
                value="0"
              />
              <label class="custom-control-label" for="radioStacked1Id">{{
                'AUTH.TAOTLETAV' | translate
              }}</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                id="radioStacked2Id"
                type="radio"
                class="custom-control-input"
                formControlName="state"
                value="1"
              />
              <label class="custom-control-label" for="radioStacked2Id">{{
                'AUTH.KEHTIV' | translate
              }}</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                id="radioStacked3Id"
                type="radio"
                class="custom-control-input"
                formControlName="state"
                value="2"
              />
              <label class="custom-control-label" for="radioStacked3Id">{{
                'AUTH.LOPPENUD' | translate
              }}</label>
            </div>
            <div class="custom-control custom-radio">
              <input
                id="radioStacked4Id"
                type="radio"
                class="custom-control-input"
                formControlName="state"
                value="3"
              />
              <label class="custom-control-label" for="radioStacked4Id">{{ 'AUTH.KOIK' | translate }}</label>
            </div>
          </div>
        </div>
        <div class="col-xs-20 col-md-13  pl-0 pr-5 d-flex justify-content-end">
          <button
            id="paySumbmitBtnId"
            info
            type="submit"
            custom-class="mt-2"
            [style]="'square'"
            [icon]="'magnifier'"
            (click)="submitted = true"
          >
            <span>{{ 'GLOBAL.EXSEARCH' | translate }}</span>
          </button>
          <button
            id="payClearBtnId"
            clear
            type="button"
            custom-class="ml-3 mt-2"
            (click)="clear()"
            [style]="'square'"
            [icon]="'trashcan'"
          >
            <span>{{ 'GLOBAL.CLEAR' | translate }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="bg-white mt-0 w-100">
    <div class="m-3 d-flex justify-content-end" *ngIf="user?.privileges?.includes('VOLITUSTE_HALDUR')">
      <button info type="button" custom-class="mt-2" [style]="'square'" [icon]="'with-plus'"
        [routerLink]="['../volitus/']"><span>{{ 'AUTH.ADD' | translate }}</span></button>
    </div>
    <div *ngIf="isSearching" class="col-20 py-5">
      <app-loading-spinner [msg]="'APL.SEARCH_APL_PROCEEDER_DETAILS'"></app-loading-spinner>
    </div>
    <div *ngIf="!isSearching && searchResults === null" class="col-20 py-5">
      <app-no-results [showMsg]="false" [mainText]="'APL.SEARCH_APL_SEARCHAREA'"></app-no-results>
    </div>
    <div *ngIf="!isSearching && searchResults && searchResults.length == 0" class="col-20 py-5">
      <app-no-results [showMsg]="true" [mainText]="'APL.SEARCH_APL_NORESULTS'"></app-no-results>
    </div>
    <div *ngIf="!isSearching && searchResults && searchResults.length > 0" class="col-20 py-5">
      <app-paginator showBoundaryLinks="true" [(config)]="pagConf" (pageChange)="getPage()"></app-paginator>
      <table
        id="authTblId"
        appSortableTable="'auth-search'"
        (sorted)="onSorted($event)"
        class="table table-hover table-adaptive table-cells table-sm table-md w-100 white-space-normal">
        <thead class="table-head bg-white">
          <th sortable-column="mandatorName">
            <a>{{ 'AUTH.AUTH1' | translate }}</a>
          </th>
          <th sortable-column="representorName">
            <a>{{ 'AUTH.AUTH3' | translate }}</a>
          </th>
          <th sortable-column="validFrom">
            <a>{{ 'AUTH.PERIOD' | translate }}</a>
          </th>
          <th sortable-column="status">
            <a>{{ 'GLOBAL.STATUS' | translate }}</a>
          </th>
          <th>
            <a>{{ 'GLOBAL.ACTIONS' | translate }}</a>
          </th>
        </thead>
        <tbody class="bg-white">
          <tr *ngFor="let result of searchResults | paginate: pagConf; let i = index">
            <td [attr.data-label]="('AUTH.AUTH1' | translate) + ':'">
              {{ result?.mandatorName }}<span class="label-comment">{{ result?.mandatorRegcode }}</span>
            </td>
            <td [attr.data-label]="('AUTH.AUTH3' | translate) + ':'">
              {{ result?.representorName }}<span class="label-comment">{{ result?.representorRegcode }}</span>
            </td>
            <td [attr.data-label]="('AUTH.PERIOD' | translate) + ':'">
              {{ result?.validFrom | date: localDateFormat }}..{{ result?.validTo | date: localDateFormat }}
            </td>
            <td [attr.data-label]="('GLOBAL.STATUS' | translate) + ':'">
              <span *ngIf="result?.status === 'REQUEST'" class="badge badge-warning">
                <span class="badge-text">
                  {{ 'AUTH.TAOTLETAV' | translate }}
                </span>
              </span>
              <span *ngIf="result?.status === 'VALID'" class="badge badge-success">
                <span class="badge-text">
                  {{ 'AUTH.KEHTIV' | translate }}
                </span>
              </span>
              <span *ngIf="result?.status === 'EXPIRED'" class="badge badge-danger">
                <span class="badge-text">
                  {{ 'AUTH.LOPPENUD' | translate }}
                </span>
              </span>
            </td>
            <td class="d-flex justify-content-left">
              <button
                id="{{ 'authEditBtnId' + i }}"
                [style]="'small'"
                light
                [icon]="'eye'"
                title="{{ 'GLOBAL.LOOK' | translate }}"
                [routerLink]="['../volitus/' + result.id]"
              ></button>
            </td>
          </tr>
        </tbody>
      </table>
      <app-paginator showBoundaryLinks="true" [(config)]="pagConf" (pageChange)="getPage()"></app-paginator>
    </div>
  </div>
</div>
