import { NgControl } from '@angular/forms';
import { Directive, Input, ElementRef, OnInit, OnChanges, SimpleChanges, HostBinding } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'app-datepicker[disableControl]'
})
export class DisableControlDirective {
  @Input() set disableControl(condition: boolean) {
    setTimeout(() => { //timeout defers execution to the next tick to avoid undefined control error
      if (this.ngControl && this.ngControl.control) {
        const action = condition ? 'disable' : 'enable';
        this.ngControl.control[action]();
      }
    }, 0);
  }

  constructor(private ngControl: NgControl, private elem: ElementRef) {}
}
