import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaginationInstance } from 'ngx-pagination';
import { Subscription } from 'rxjs';
import { UserService } from '../../shared/_services';
import { BaseComponent } from 'src/app/shared/_common/base/base.component';
import { UserInfo } from 'src/app/_domain';
import { AuthorityService } from 'src/app/shared/_services/authority.service';
import { AuthorityTableElement } from 'src/app/_domain/authority.interface';
import { DatePipe } from '@angular/common';
import { TableSessionService } from 'src/app/shared/_services/table-session.service';
import { idLocale } from 'ngx-bootstrap/locale';

@Component({
  selector: 'app-authority-search',
  templateUrl: './authority-search.component.html',
  styleUrls: ['./authority-search.component.scss']
})
export class AuthoritySearchComponent extends BaseComponent implements OnInit, OnDestroy {
  tableSessionName = 'AuthSearchComponent';
  authSearchForm: FormGroup;
  submitted = false;
  searchResults = null;
  total = 0;
  size: number;
  public pagConf: PaginationInstance;
  authTablePaginationData = {
    page: 0,
    size: 10
  };
  search = {
    page: 0,
    sortBy: 'validFrom',
    sortDir: 'desc',
    size: 10
  };
  authSearch = this.search;
  isSearching = false;
  public user: UserInfo;
  public subscriptions: Subscription[] = [];

  constructor(
    private fb: FormBuilder,
    private tableSessionService: TableSessionService,
    private authorityService: AuthorityService
  ) {
    super();
  }

  ngOnInit() {
    this.user = this.userService.getUser();
    this.uiHelper.initSelectPicker();
    this.subscriptions.push(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
        this.uiHelper.refreshSelectPicker();
      })
    );
    this.buildForm();
    this.size = this.authTablePaginationData.size; // TODO võtta konfist - tulemuste arv leheküljel */
    this.pagConf = {
      id: 'authShearchId',
      itemsPerPage: this.size,
      currentPage: 1,
      totalItems: this.total
    };
    this.initTableParamsFromSession();
  }

  buildForm() {
    this.authSearchForm = this.fb.group({
      authorizer: [null],
      authorized: [null],
      validDate: [null],
      state: [null],
      page: [0],
      size: [10],
      sortBy: [null],
      sortDir: [null]
    });
  }

  onSorted(event: any) {
    this.authSearch.sortBy = event.sortColumn;
    this.authSearch.sortDir = event.sortDirection;
    this.authSearch.page = 0;
    this.pagConf.currentPage = 1;
    this.getData();
  }

  getPage() {
    this.authSearch.page = this.pagConf.currentPage - 1;
    this.authSearch.size = this.pagConf.itemsPerPage;
    this.getData();
  }

  getData(saveToSession = true) {
    if (saveToSession) {
      this.saveTableParamsToSession();
    }
    this.isSearching = true;
    let parm =
      'page=' +
      this.authSearch.page +
      '&size=' +
      this.authSearch.size +
      '&sort=' +
      this.authSearch.sortBy +
      ',' +
      this.authSearch.sortDir;
    if (this.authSearchForm.value.authorizer) {
      parm += '&mandator=' + this.authSearchForm.value.authorizer;
    }
    if (this.authSearchForm.value.authorized) {
      parm += '&representor=' + this.authSearchForm.value.authorized;
    }
    if (this.authSearchForm.value.state === '0') {
      parm += '&status=REQUEST';
    } else if (this.authSearchForm.value.state === '1') {
      parm += '&status=VALID';
    } else if (this.authSearchForm.value.state === '2') {
      parm += '&status=EXPIRED';
    }
    if (this.authSearchForm.value.validDate) {
      parm +=
        '&validDate=' +
        new DatePipe('et_EE').transform(new Date(this.authSearchForm.value.validDate), 'yyyy-MM-dd');
    }

    this.subscriptions.push(
      this.authorityService.searchAuthorities(parm).subscribe(
        (res: AuthorityTableElement) => {
          this.searchResults = res.data;
          this.total = res.total;
          this.pagConf.totalItems = res.total;
          this.isSearching = false;
        },
        error => {
          this.getLongMsgError(error.error.detail);
          this.isSearching = false;
          this.searchResults = [];
        }
      )
    );
  }

  onSubmit(): void {
    this.getData();
  }

  clear(): void {
    this.authSearchForm.reset();
    this.uiHelper.refreshSelectPicker();
    this.authSearchForm.get('state').setValue('3');
    this.clearTableParamsToSession();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    });
  }

  /*
    TABLE session
  */
  saveTableParamsToSession() {
    this.tableSessionService.saveTableSessionParams(
      this.tableSessionName,
      this.authSearch,
      this.authSearchForm.value,
      this.pagConf
    );
  }

  clearTableParamsToSession() {
    this.tableSessionService.clearTableSessionFor(this.tableSessionName);
  }

  initTableParamsFromSession() {
    const params = this.tableSessionService.getTableSessionParams(this.tableSessionName);
    if (params !== null && params !== undefined) {
      if (params.form !== undefined) {
        this.authSearchForm.setValue(params.form);
      }
      if (params.pageConf !== undefined) {
        this.pagConf = params.pageConf;
      }
      if (params.table !== undefined) {
        this.authSearch = params.table;
      }
      this.getData(false);
    } else {
      this.authSearchForm.get('state').setValue('3');
    }
  }
}
