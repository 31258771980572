import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { LangswitchService } from '../../_services';
import { Subscription } from 'rxjs';
import { DateConstant } from '../../_utils';

@Component({
  selector: 'app-doc-view',
  templateUrl: './doc-view.component.html',
  styleUrls: ['./doc-view.component.scss']
})
export class DocViewComponent implements OnInit, OnDestroy {
  @Input() doc;
  @Output() openDoc: EventEmitter<any> = new EventEmitter();


  localDateFormat = DateConstant.DateFormat;
  lang: string;
  subscription = new Subscription();

  constructor(
    private langswitchService: LangswitchService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
