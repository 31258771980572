import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { API_ENDPOINT } from '../../../app.constants';
import { UserInfo } from 'src/app/_domain';
import { BaseComponent } from '../../_common/base/base.component';

declare var $: any; // jQuery gets imported at angular.json

@Component({
  selector: 'app-mainnav',
  templateUrl: './mainnav.component.html',
  styleUrls: ['./mainnav.component.scss']
})
export class MainnavComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('menu') menuView: ElementRef;

  public lang: string;
  public currentEnv: string;

  public user: UserInfo;
  selectedRoleEnvinronment: string;
  isCollapsed = true;

  privileges: string[] = [];
  currentUser: UserInfo;
  hasPrivilegeToTempEdit: boolean;
  hasPrivilegeToInspectionRequirements: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private el: ElementRef,
  ) {
    super();
  }

  /*
    Closes and opens menu dropdowns
  */
  @HostListener('document:click', ['$event'])
  clickListener(event): void {
    if (event.target.classList.contains('dropdown-toggle')) {
      // is dropdown menu
      if (event.target.nextSibling && !event.target.nextSibling.classList.contains('show')) {
        if (this.el && this.renderer) {
          const dropdownMenuShowList = this.el.nativeElement.querySelectorAll('.dropdown-menu.show');
          // remove all show classes
          dropdownMenuShowList.forEach(dropdown => {
            this.renderer.removeClass(dropdown, 'show');
          });
          // remove current show classes
          if (this.el.nativeElement.classList.contains('show')) {
            this.renderer.removeClass(this.el, 'show');
          } else {
            // add to current show classes
            this.renderer.addClass(event.target.nextSibling, 'show');
          }
        }
      }
    } else {
      // is menu item
      const dropdownMenuShowList = this.el.nativeElement.querySelectorAll('.dropdown-menu.show');
      // remove all show classes
      dropdownMenuShowList.forEach(dropdown => {
        this.renderer.removeClass(dropdown, 'show');
      });
    }
    if (event.target.classList.contains('nav-link')) {
      // console.log('HostListener ', event.target.href, event.target);
      // this.router.navigateByUrl(event.target.href);
    }
  }

  ngOnInit() {
    this.currentEnv = sessionStorage.getItem('currentActiveEnvinronment');
    /*
        Get router data from child component
    */
    this.addSubscriptionToList(this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.router.routerState.root),
        map( route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .subscribe((route: ActivatedRoute) => {
        const env = route.snapshot && route.snapshot.data && route.snapshot.data.env;
        if (env) {
          this.currentEnv = env;
        }
      }));

    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );
    // get user
    this.user = this.userService.getUser();
    if (!this.user) {
      this.getUser();
    }
    if (this.user) {
      this.selectedRoleEnvinronment = this.user.activeEnvinronment;
    }

    this.checkUserPrivileges(this.user);
    this.selectedRoleEnvinronment = this.userService.getUserSelectedRoleEnvinronment();
    this.addSubscriptionToList(
      this.userService.getUserSubject.subscribe(userInfo => {
        this.user = userInfo;
        if (this.user) {
          this.selectedRoleEnvinronment = this.user.activeEnvinronment;
        }
        if (!this.selectedRoleEnvinronment) {
          this.selectedRoleEnvinronment = this.userService.getUserSelectedRoleEnvinronment();
        }
        this.checkUserPrivileges(this.user);
      })
    );
  }

  checkUserPrivileges(user: UserInfo) {
    this.currentUser = user;
    if (this.currentUser && this.currentUser.privileges && this.currentUser.privileges.length) {
      this.privileges = this.currentUser.privileges;
      this.hasPrivilegeToInspectionRequirements = this.privileges.indexOf('YLEV_NOUDED') !== -1;
      this.hasPrivilegeToTempEdit = this.privileges.indexOf('KLASSIF_HALDUR') !== -1;
    }
  }

  /*
    Refresh backend session
  */
  async getUser() {
    try {
      const userInfo = await this.userService.loadUser().toPromise();

      if (userInfo === undefined || userInfo === null) {
        this.logout();
      } else {
        userInfo.rolesSize = Object.keys(userInfo.roles).length;
        userInfo.entitiesSize = Object.keys(userInfo.entities).length;
        userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);
        this.currentUser = userInfo;
        this.userService.setUser(userInfo);
        if (this.currentUser) {
          this.userService.userSubject.next();
        }
        this.user = this.currentUser;
      }
    } catch (error) {
      // "Unauthorized"
      if (error.status === 401) {
        this.logout();
      } else {
        this.getLongMsgError(error.message);
      }
    }
  }

  logout(): void {
    this.addSubscriptionToList(this.userService.logoutUser().subscribe(
      response => {
        this.userService.cleanSession();
        if (API_ENDPOINT.charAt(API_ENDPOINT.length - 1) === '/') {
          window.location.href = API_ENDPOINT;
        } else {
          window.location.href = API_ENDPOINT + '/';
        }
      },
      error => {
        this.getLongMsgError(error.message);
      }));
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.unsubscribeAllFromList();
  }

  consoleLog(text: string) {
    console.log(text);
  }

  routeTo(link: any[]) {
    // console.log('routeTo', link);
    this.router.navigate(link);
  }
}
