<form [formGroup]="recreationShipDetailForm" (ngSubmit)="onSubmit()">
  <div class="modal-header pb-1">
    <h2>{{ 'INS.H1MUUT' | translate }}</h2>

    <button
      light
      [style]="'round'"
      [icon]="'exit'"
      title="{{ 'GLOBAL.CLOSE' | translate }}"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group mx-0 pb-0">
      <div class="mb-2">
        <label class="col-form-label requiredField" for="type2">{{ 'INS.LIIK' | translate }}</label>
        <select
          formControlName="subtypeCode"
          value="{{ recreationShipDetailForm?.value?.subtypeCode }}"
          class="form-control selectpicker"
          id="type"
          title="{{ 'GLOBAL.SELECT' | translate }}"
          required
        >
          <optgroup class="" *ngFor="let t of typeList" [label]="t.descr[lang]">
            <option [value]="tt.code" *ngFor="let tt of t.claInspectionSubtypeEntityByCode">
              {{ tt.descr[lang] }}
            </option>
          </optgroup>
        </select>

        <!-- error -->
        <div *ngIf="submitted && !recreationShipDetailForm?.valid" class="invalid-feedback">
          <div *ngIf="recreationShipDetailForm.controls?.subtypeCode?.errors?.required">
            {{ 'ERROR.REQUIRED' | translate }}
          </div>
        </div>
      </div>

      <!-- kuupäeva vahemik-->
      <div class="mb-2">
        <div class="mb-2 pt-2">
          <label class="col-form-label requiredField">{{ 'INS.AEG' | translate }}</label>
          <div class="row range-group mx-0">
            <div class="col-10 range-from pl-0 pr-3">
              <app-date-time-picker
                [minDate]="nowDate"
                (ngModelChange)="dateChange()"
                formControlName="startDate"
                placeholder="{{ 'APL.SEARCH_APL_PROC_DATE_FROM' | translate }}"
                required
              >
              </app-date-time-picker>
              <!-- error -->
              <div *ngIf="submitted && !recreationShipDetailForm?.valid" class="invalid-feedback">
                <div *ngIf="recreationShipDetailForm.controls?.startDate?.errors?.required">
                  {{ 'ERROR.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-10 pl-3 pr-0">
              <app-date-time-picker
                [minDate]="nowDate"
                (ngModelChange)="dateChange()"
                formControlName="endDate"
                placeholder="{{ 'APL.SEARCH_APL_PROC_DATE_TO' | translate }}"
              >
              </app-date-time-picker>
              <!-- error -->
              <div *ngIf="submitted && !recreationShipDetailForm?.valid" class="invalid-feedback">
                <div *ngIf="recreationShipDetailForm.controls?.endDate?.errors?.required">
                  {{ 'ERROR.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <!-- errors -->
            <div *ngIf="submitted" class="invalid-feedback">
              <div *ngIf="recreationShipDetailForm.errors?.dates">
                {{ recreationShipDetailForm.errors?.dates | translate }}
              </div>
              <div *ngFor="let error of dateErrors">{{ error }}</div>
            </div>
          </div>
        </div>
        <!-- kuupäeva vahemik-->
        <!-- <div class="col-xs-20 col-md-8 border-right px-4">
              </div> -->
      </div>
      <div class="mb-2">
        <label class="col-form-label" for="port">{{ 'INS.SADAM' | translate }}</label>
        <select
          formControlName="portId"
          value="{{ recreationShipDetailForm?.value?.portId }}"
          class="form-control selectpicker"
          id="port"
          title="{{ 'GLOBAL.SELECT' | translate }}"
        >
          <option [value]="r.id" *ngFor="let r of inspectionPortList"
            >{{ r.description }} {{ r.knrObjId ? '(Kohanimeregister)' : '' }}</option
          >
        </select>
        <!-- errors -->
        <!-- <div *ngIf="submitted" class="invalid-feedback">
                    <div *ngFor="let portError of portErrors">{{ portError }}</div>
                </div> -->
      </div>
      <div class="mb-2">
        <label class="col-form-label" for="inputLocation">{{ 'INS.KOHT' | translate }}</label>
        <input type="text" class="form-control" id="inputLocation" formControlName="location" />
      </div>

      <!-- Juht -->
      <div class="mb-2">
        <label class="col-form-label requiredField" for="teamLeader">{{ 'INS.INSPECTOR' | translate }}</label>
        <select
          formControlName="teamLeader"
          data-live-search="true"
          class="form-control selectpicker searchable"
          id="teamLeader"
          title="{{ 'GLOBAL.SELECT' | translate }}"
        >
          <option [value]="t.id" *ngFor="let t of generalTeamList" [label]=""
            >{{ t.firstName + ' ' + t.name }}
          </option>
        </select>

        <div *ngIf="submitted && !recreationShipDetailForm?.valid" class="invalid-feedback">
          <div *ngIf="recreationShipDetailForm.controls?.teamLeader?.errors?.required">
            {{ 'ERROR.REQUIRED' | translate }}
          </div>
        </div>
        <div *ngIf="teamMembersErrorsMap?.teamLeader" class="invalid-feedback">
          {{ teamMembersErrorsMap.teamLeader }}
        </div>
      </div>
    </div>
    <div
      *ngIf="
        recreationShipDetailForm.value.status === 30001 ||
        this.currentUser.privileges.includes('VLAEV_INSPEKTOR')
      "
      class="form-group row"
    >
      <div class="col-12">
        <div class="custom-control custom-checkbox">
          <input
            [value]="this.recreationShipDetailForm.value.approv"
            formControlName="approv"
            type="checkbox"
            id="approv"
            class="custom-control-input"
          />
          <label class="custom-control-label" for="approv">{{ 'INS.APPROV' | translate }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mb-2 mx-2 d-flex">
          <div>
            <button  success [style]="'success square'"
              [icon]="'with-plus'">{{'INS.SALVESTA' | translate}}
            </button>
          </div>
          <div class="ml-2">
            <button type="button" danger (click)="closeModal()">{{'INS.KATKESTA' | translate}}</button>
          </div>

        </div> -->
  <div class="row mx-0 px-0 w-100">
    <div class="col-20 px-0">
      <hr class="mb-0" />
      <div class="p-3 w-100 d-flex justify-content-end">
        <div class="mb-0">
          <!-- will submit form -->
          <button success [style]="'square'" (click)="submitted = true" [icon]="'save'">
            <span>{{ 'GLOBAL.SAVE' | translate }}</span>
          </button>
        </div>
        <div class="ml-2 mb-0">
          <button danger [style]="'square'" type="button" (click)="closeModal()" [icon]="'cancel'">
            <span>{{ 'GLOBAL.LEAVE' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- row -->
</form>

<ng-template #checkTeamTimesModalTpl>
  <div class="mb-2">
    <div class="modal-header">
      <h3 class="font-weight-bold">
        <b>{{ 'INS.WARNTEXT' | translate }}</b> <br />
        <ul>
          <li *ngFor="let f of foundTeamDates">
            {{ f.vesVesselByVesselId?.vesselName }} <br />
            {{ (f.type?.claInspectionTypeByTypeCode?.descr)[lang] }}
            {{ (f.type?.descr)[lang] }} <br />
            {{ f.startEndDateString }} <br />
            <!-- {{f.teamMembers}} <br /> -->
            <br />
          </li>
        </ul>
      </h3>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-20">
          <div class="form-group pb-0">
            <h3 class="font-weight-bold">{{ 'INS.CONFIRMTXT' | translate }}</h3>
            <div class="w-100 d-flex justify-content-end">
              <div class="mb-0">
                <button
                  success
                  [style]="'square'"
                  type="button"
                  (click)="saveAndCloseTeamModal()"
                  [icon]="'save'"
                >
                  <span>{{ 'GLOBAL.YES' | translate }}</span>
                </button>
              </div>
              <div class="ml-2 mb-0">
                <button danger [style]="'square'" type="button" (click)="closeTeamModal()" [icon]="'cancel'">
                  <span>{{ 'GLOBAL.NO' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.modal-body -->
  </div>
</ng-template>
