import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BaseComponent } from 'src/app/shared/_common/base/base.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InspectionSubmit } from 'src/app/_domain/inspection.submit';
import { InspectionService } from 'src/app/shared/_services/inspection.service';
import { InspectionInterface, InspectionDate, PortListElement } from 'src/app/_domain/inspection.interface';
import { DatePipe } from '@angular/common';
import { LangswitchService, ClassifierService } from 'src/app/shared/_services';
import { UiHelper } from 'src/app/shared/_utils/ui';
import { ClassificatorObjects } from 'src/app/_domain';
import * as cloneDeep from 'lodash/cloneDeep';
import { distinctUntilChanged } from 'rxjs/operators';


export interface GeneralTeam {
  id: number;
  firstName: string;
  name: string;
}

@Component({
  selector: 'app-add-edit-inspection',
  templateUrl: './add-edit-inspection.component.html',
  styleUrls: ['./add-edit-inspection.component.scss']
})
export class AddEditInspectionComponent extends BaseComponent implements OnInit {
  @Input()
  id: number; // inspection id
  @Input()
  applicationId: number;
  @Input()
  vesselId: number;
  @Input()
  modalRef: BsModalRef;
  edit = false;
  @Output()
  refreshInspections: EventEmitter<any> = new EventEmitter();

  currentSubtypeCode: string;
  currentTypeCode: number;
  currentType: {
    code: number;
    descr: Record<string, string>;
  };

  @Input() isAudit = false;

  lang: string;
  @ViewChild('checkTeamTimesModalTpl') checkTeamTimesModalTpl: TemplateRef<any>;
  modalRef2: BsModalRef;

  submitted = false;

  inspectionDetailForm: FormGroup;
  inspectionSubmit: InspectionSubmit;
  isForeign = false;
  nowDate = new Date();
  isValid: boolean;
  foundTeamDates: InspectionInterface[];

  inspectionPortList: PortListElement[];
  countryClassifier: ClassificatorObjects;

  generalTeamList: GeneralTeam[];

  teamMemberList = [];

  typeList: [
    {
      code: number;
      descr: Record<string, string>;
      claInspectionSubtypeEntityByCode: [
        {
          code: number;
          descr: Record<string, string>;
        }
      ];
    }
  ];

  preTranslatedLabelList = ['GLOBAL.CLEAR', 'GLOBAL.CHOOSE_ALL'];
  preTranslatedLabels = {};

  // errors lists
  dateErrors: string[];
  portErrors: string[];
  countryErrors: string[];
  teamMembersErrorsMap = { teamMembers: undefined, teamLeader: undefined };

  constructor(
    private fb: FormBuilder,
    private classifierService: ClassifierService,
    private inspectionService: InspectionService
  ) {
    super();
  }

  ngOnInit() {
    this.submitted = false;
    this.addSubscriptionToList(this.translateServ.get(this.preTranslatedLabelList).subscribe(labels => {
      this.preTranslatedLabels = labels;
    }));

    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(
        clang => {
          this.lang = clang;
          // this.langDescr = clang === 'et' ? 'descrEst' : 'descrEng'; // TODO backist õiged väljad vaja
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );

    // sadam
    this.addSubscriptionToList(this.inspectionService.getPortList().subscribe(
      (res: any) => {
        if (res.data) {
          this.inspectionPortList = [
            this.getSelectDefaultFirstElement(),
            ...res.data.sort((a, b) => a.description.localeCompare(b.description))
          ] as PortListElement[];
        }
      },
      error => {
        this.getLongMsgError(error.message);
      }
    ));

    // riik
    this.addSubscriptionToList(this.classifierService.getCountry().subscribe(
      (data: any) => {
        if (data && data.length > 0) {
          this.countryClassifier = [
            this.getSelectDefaultFirstElement(),
            ...data.sort((n1, n2) => {
              if (n1.name[this.lang] > n2.name[this.lang]) {
                return 1;
              }
              if (n1.name[this.lang] < n2.name[this.lang]) {
                return -1;
              }
              return 0;
            })
          ];
        }
      },
      error => {
        this.getLongMsgError(error.message);
      }
    ));

    // komisjoni liikmed
    this.addSubscriptionToList(this.inspectionService.getInspectionTeamList()
      .subscribe(
        response => {
          this.uiHelper.refreshSelectPicker();
          this.generalTeamList = cloneDeep(response.data);
          const distinctThings = this.generalTeamList.filter(
            (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i
          );
          this.generalTeamList = distinctThings as GeneralTeam[];
          this.teamMemberList = distinctThings;
          this.uiHelper.refreshSelectPicker();
        },
        error => {
          this.getLongMsgError(error.message);
        }
      ));

    this.uiHelper.initSelectPicker();

    this.loadInspectionSelectForm();
    this.uiHelper.refreshSelectPicker();
    // this.uiHelper.translateSelectPicker('select3');

    this.addSubscriptionToList(this.inspectionDetailForm.get('subtypeCode').valueChanges.subscribe(subtypeCode => {
      this.currentType = this.typeList.find(
        x => x.claInspectionSubtypeEntityByCode.findIndex(y => y.code === +subtypeCode) !== -1
      );
      if (this.isAudit && this.currentType) {
        this.getAuditTeam(this.currentType.code);
      }
    }));
  }

  createSearchForm(): void {
    this.inspectionDetailForm = this.fb.group(
      {
        // TODO: definitely add proper validators based on input types!
        subtypeCode: [null, Validators.required],
        startDate: [null, Validators.required],
        endDate: [null, Validators.required],
        // shipCategory: [null],
        teamLeader: [null, Validators.required],
        teamMembers: [[]],
        location: [null],
        portId: [null],
        // city: [null],
        // isForeign: [false],
        countryId: [null],
        id: [null],
        approv: [false],
        status: [null]
      },
      {
        validator: this.dateLessThanWithStatus('startDate', 'endDate', 'status', '30001')
      }
    );
  }

  async loadInspectionSelectForm() {
    if (this.isAudit) {
      this.addSubscriptionToList(
        this.inspectionService.getAuditingTypesList().subscribe(
          response => {
            this.typeList = response.data;
            if (this.id) {
              this.edit = true;
              this.loadInspectionData(this.id);
            }
          },
          error => {
            this.getLongMsgError(error.message);
          }
        )
      );
    } else {
      this.addSubscriptionToList(
        this.inspectionService.getInspectionTypesList().subscribe(
          response => {
            this.typeList = response.data;
            if (this.id) {
              this.edit = true;
              this.loadInspectionData(this.id);
            }
          },
          error => {
            this.getLongMsgError(error.message);
          }
        )
      );
    }

    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(
        clang => {
          this.lang = clang;
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
    this.createSearchForm();

    await this.delay(1500);
    this.uiHelper.refreshSelectPicker();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  foreignChange(isForeign: boolean) {
    this.isForeign = isForeign;
    /* if (isForeign) {
      this.inspectionDetailForm.patchValue({ portId: null });
    } else {
      this.inspectionDetailForm.patchValue({ countryId: null, city: null });
    } */
    this.uiHelper.refreshSelectPicker();
  }

  onSubmit(): void {
    if (this.submitted && this.isSubmitValid() && this.inspectionDetailForm.valid) {
      this.addSpinnerLayer();
      this.inspectionSubmit = this.inspectionDetailForm.value;
      this.inspectionSubmit.subtypeCode = +this.inspectionDetailForm.value.subtypeCode;
      this.inspectionSubmit.portId = +this.inspectionDetailForm.value.portId;
      if (!this.inspectionDetailForm.value.teamMembers) {
        this.inspectionSubmit.teamMembers = [];
      }

      this.inspectionSubmit.applicationId = this.applicationId;
      this.inspectionSubmit.vesselId = this.vesselId;
      // this.inspectionSubmit.status = 30002; // Backend lisab ise

      this.checkTeamDates();
      this.submitted = false;
      this.removeSpinnerLayer();
    } else {
      // console.log()
      // this.notifierServ.notify('error', this.findInvalidControls().toString());
    }
  }

  saveAndCloseTeamModal() {
    this.submitInspectionToDb();
    this.closeTeamModal();
  }

  submitInspectionToDb() {
    if (this.isForeign) {
      this.inspectionDetailForm.patchValue({ portId: null });
      this.inspectionSubmit.portId = null;
    } else {
      this.inspectionDetailForm.patchValue({ countryId: null, city: null });

      this.inspectionSubmit.countryId = null;
      this.inspectionSubmit.location = null;
    }
    if (isNaN(this.inspectionSubmit.subtypeCode)) {
      this.inspectionSubmit.subtypeCode = +this.currentSubtypeCode;
    }
    // console.log('submitInspectionToDb', this.inspectionSubmit);
    if (!this.isAudit) {
      this.addSubscriptionToList(
        this.inspectionService.submitInspection(this.inspectionSubmit).subscribe(
          response => {
            const defaultErrorMsgLabel = 'ERROR.CREATE_DATA';
            if (response.success === true) {
              this.getLongMsgSuccess('MESSAGE.SAVE.SUCCESS');
            } else {
              this.showDbErrorMessage(defaultErrorMsgLabel, response);
            }
            this.refreshInspections.emit(response.data);
            this.closeModal();
            this.closeTeamModal();
          },
          error => {
            this.getLongMsgError(error.message);
          }
        )
      );
    }
    if (this.isAudit) {
      this.addSubscriptionToList(
        this.inspectionService.saveAudit(this.inspectionSubmit).subscribe(
          response => {
            const defaultErrorMsgLabel = 'ERROR.CREATE_DATA';
            if (response.success === true) {
              this.getLongMsgSuccess('MESSAGE.SAVE.SUCCESS');
            } else {
              this.showDbErrorMessage(defaultErrorMsgLabel, response);
            }
            this.refreshInspections.emit(response.data);
            this.closeModal();
          },
          error => {
            this.getLongMsgError(error.message);
          }
        )
      );
    }
  }

  /*
    Planeerimisel 30001
    Ettevalmistamisel 30002
    Vormistamisel 30003,
  */
  isSubmitValid(): boolean {
    const submitVals = this.inspectionDetailForm.value;
    let valid = true;
    this.dateErrors = [];
    this.portErrors = [];
    this.countryErrors = [];
    this.teamMembersErrorsMap = { teamMembers: undefined, teamLeader: undefined };

    if (!this.isForeign && (!submitVals.portId || submitVals.portId === 0)) {
      this.addSubscriptionToList(this.translateServ.get(['ERROR.REQUIRED']).subscribe(translations => {
        // this.notifierServ.notify('error', translations['INS.PORTERROR']);
        this.portErrors.push(translations['ERROR.REQUIRED']);
        valid = false;
      }));
    }
    if (this.isForeign && submitVals.countryId === null) {
      this.addSubscriptionToList(this.translateServ.get(['INS.COUNTRYERROR']).subscribe(translations => {
        // this.notifierServ.notify('error', translations['INS.COUNTRYERROR']);
        this.countryErrors.push(translations['ERROR.REQUIRED']);
        valid = false;
      }));
    }
    /*
      DATE ERRORS
    */
    if (submitVals.startDate !== null && submitVals.endDate && submitVals.startDate > submitVals.endDate) {
      this.addSubscriptionToList(this.translateServ.get(['INS.DATEERROR1']).subscribe(translations => {
        // this.notifierServ.notify('error', translations['INS.DATEERROR1']);
        this.dateErrors.push(translations['INS.DATEERROR1']);
        valid = false;
      }));
    }
    // Planeerimisel (30001) seisundis ülevaatusel alguse ja lõpu ajad peavad olema tulevikus
    if (
      submitVals.status === 30001 &&
      ((submitVals.startDate !== null &&
        submitVals.endDate !== null &&
        submitVals.startDate < this.nowDate) ||
        submitVals.endDate < this.nowDate)
    ) {
      this.addSubscriptionToList(this.translateServ.get(['INS.DATEERROR2']).subscribe(translations => {
        // this.notifierServ.notify('error', translations['INS.DATEERROR2']);
        this.dateErrors.push(translations['INS.DATEERROR2']);
        valid = false;
      }));
    }
    /*
     TEAMMEMBER ERRORS
   */
    /* if (!submitVals.teamMembers || submitVals.teamMembers.length < 1) {
      this.translateServ.get(['INS.TEAMERROR']).subscribe(translations => {
        // this.notifierServ.notify('error', translations['INS.TEAMERROR']);
        this.teamMembersErrorsMap.teamMembers = translations['INS.TEAMERROR'];
        valid = false;
      });
    } */
    if (submitVals.teamMembers && submitVals.teamMembers.indexOf(+submitVals.teamLeader) !== -1) {
      const teamLeader = this.generalTeamList.find(i => i.id === +submitVals.teamLeader);
      this.addSubscriptionToList(this.translateServ.get(['INS.LEADERERROR']).subscribe(translations => {
        // this.notifierServ.notify('error', teamLeader.firstName + ' ' + teamLeader.name + ' ' + translations['INS.LEADERERROR']);
        this.teamMembersErrorsMap.teamLeader =
          teamLeader.firstName + ' ' + teamLeader.name + ' ' + translations['INS.LEADERERROR'];
        valid = false;
      }));
    }

    return valid;
  }

  checkTeamDates() {
    this.addSpinnerLayer();
    this.addSubscriptionToList(
      this.inspectionService.checkTeamTimes(this.inspectionSubmit).subscribe(
        response => {
          if (response.success === true) {
            if (response.data.length > 0) {
              this.foundTeamDates = response.data;
              this.foundTeamDates.forEach(item => {
                item.startEndDateString = this.getDateTimeString(item);
              });

              // eemaldeab hetkel lahti oleva ülevaatluse listist
              this.foundTeamDates = this.foundTeamDates.filter(teamDates => {
                return teamDates.id !== this.inspectionSubmit.id;
              });

              if (this.foundTeamDates && this.foundTeamDates.length === 0) {
                this.submitInspectionToDb();
              } else {
                this.modalRef2 = this.modalService.show(
                  this.checkTeamTimesModalTpl,
                  Object.assign({}, { class: 'modal-lg' })
                );
              }
              this.removeSpinnerLayer();
            } else {
              this.submitInspectionToDb();
              this.removeSpinnerLayer();
            }
          } else {
            this.showDbErrorMessage('Error', response);
            this.removeSpinnerLayer();
          }
        },
        error => {
          this.getLongMsgError(error.message);
          this.removeSpinnerLayer();
        }
      )
    );
  }

  // JUHT
  teamMemberChange(val: number) {
    // this.inspectionDetailForm.patchValue({ teamMembers: null });

    const vals = this.inspectionDetailForm.value;
    if (vals.teamMembers !== null && vals.teamMembers.includes(+val)) {
      this.inspectionDetailForm.patchValue({ teamLeader: null });
      const teamLeader = this.generalTeamList.find(i => i.id === +val);
      this.addSubscriptionToList(this.translateServ.get(['INS.LEADERERROR']).subscribe(translations => {
        this.teamMembersErrorsMap.teamLeader =
          teamLeader.firstName + ' ' + teamLeader.name + ' ' + translations['INS.LEADERERROR'];
      }));
      // this.teamMemberList = this.generalTeamList.filter(obj => obj.id !== +val);
      this.uiHelper.refreshSelectPicker();
    } else {
      this.teamMemberList = this.generalTeamList.filter(obj => obj.id !== +val);
      this.uiHelper.refreshSelectPicker();
      this.teamMembersErrorsMap.teamLeader = null;
    }
  }

  // LIIKMED
  teamMemberChange2() {
    const vals = this.inspectionDetailForm.value;
    let teamMembers = vals.teamMembers;
    teamMembers = teamMembers.filter(obj => obj !== +vals.teamLeader);
    this.inspectionDetailForm.patchValue({ teamMembers });
  }

  getDateTimeString(date: InspectionInterface) {
    // const startDateString = this.extractDate(date.startDate);
    // const endDateString = this.extractDate(date.endDate);
    const startDate = this.datePipe.transform(date.startDate, this.DATE_TIME_FORMAT_FOR_PIPE);
    const endDate = this.datePipe.transform(date.endDate, this.DATE_TIME_FORMAT_FOR_PIPE);
    return startDate + ' - ' + endDate;
  }

  /*  extractDate(date: InspectionDate) {
     return date.dateTime.date + ' ' + date.dateTime.time.hour + ':' + date.dateTime.time.minute;
   } */

  public findInvalidControls() {
    const invalid = [];
    const controls = this.inspectionDetailForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  dateChange() {
    const INSVal = this.inspectionDetailForm.value;
    if (
      (INSVal.startDate !== null && INSVal.endDate !== null && INSVal.startDate > INSVal.endDate) ||
      INSVal.startDate < this.nowDate
    ) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  closeModal() {
    this.modalRef.hide();
    // this.getSearchResults(this.inspectionSearch);
  }
  closeTeamModal() {
    this.modalRef2.hide();
  }

  loadInspectionData(id: number) {
    this.addSubscriptionToList(
      this.inspectionService.getInspection(id).subscribe(
        response => {
          this.uiHelper.refreshSelectPicker();
          const data = response.data;
          this.vesselId = data.vesselId;
          this.applicationId = data.applicationId;
          this.currentSubtypeCode = data.subtypeCode; // for disabled select
          this.currentTypeCode = data.claInspectionSubtypeBySubtypeCode.typeCode;
          if ([200, 300, 400].some(x => x === this.currentTypeCode)) {
            this.isAudit = true;
          }

          // const startDateString = this.extractDate(data.startDate).replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3');
          // const endDateString = this.extractDate(data.endDate).replace(/(\d{2}).(\d{2}).(\d{4})/, '$2/$1/$3');
          // const startDate = this.datePipe.transform(data.startDate, this.DATE_TIME_FORMAT);
          // const endDate = this.datePipe.transform(data.endDate, this.DATE_TIME_FORMAT);

          // LAB kui pipe, siis DATE_TIME_FORMAT_FOR_PIPE (DATE_TIME_FORMAT pipe annab vigase stringi)
          // aga siin patchValue sees
          // new Date(string) aktsepteerib '2020-04-22T11:04:00+03:00' kuju nagu BE-st tuleb
          // ja ei aktsepteeri '22.04.2020 11:04' stringi, mis tuleb DATE_TIME_FORMAT_FOR_PIPE-ist
          // new Date('22.04.2020 11:04') tulemus on Invalid Date
          // ehk siis jätta nii nagu on - new Date(data.startDate)
          // END LAB
          const startDate = data.startDate;
          const endDate = data.endDate;

          // TODO: panna parem lahendus eesti countryId leidmiseks
          if (data.countryId !== null && +data.countryId !== 250) {
            this.isForeign = true;
          } else {
            this.isForeign = false;
          }

          this.inspectionDetailForm.patchValue({
            subtypeCode: +data.subtypeCode,
            startDate: startDate ? new Date(startDate) : null, // LAB acceptable string is '2020-04-22T11:04:00+03:00'
            endDate: endDate ? new Date(endDate) : null, // LAB '22.04.2020 11:04' string is not acceptable
            teamLeader: data.teamLeader,
            teamMembers: data.teamMembers,
            portId: data.portId,
            countryId: data.countryId,
            city: data.city,
            id: +data.id,
            status: data.status,
            location: data.location
          });
          this.uiHelper.refreshSelectPicker();
          /* disabled select */
          if (data.id && +data.status !== 30001) {
            this.inspectionDetailForm.controls.subtypeCode.disable();
          }

          this.dateChange();
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  // LAB auditi komisjoni liikmed
  getAuditTeam(typeCode: number) {
    this.addSubscriptionToList(this.inspectionService.getAuditionTeamList(typeCode).subscribe(
      response => {
        this.generalTeamList = cloneDeep(response.data);
        const distinctThings = this.generalTeamList.filter(
          (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i
        );
        this.generalTeamList = distinctThings;
        this.teamMemberList = distinctThings;
        this.uiHelper.refreshSelectPicker();
      },
      error => {
        this.getLongMsgError(error.message);
      }
    ));
  }
}
