import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'skaCurrency'
})
export class SkaCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(
    value: number,
    currencyCode: string = 'EUR',
    display: 'code' | 'symbol' | 'symbol-narrow' | string | boolean = 'symbol',
    digitsInfo: string = '1.0-2',
    locale: string = 'et'
  ): string | null {
    return super.transform(value, currencyCode, display, digitsInfo, locale);
  }
}
