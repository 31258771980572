import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[integer-value]'
})
export class IntegerInputDirective  {
  REGEX_INT_NUMBER = '^[0-9]*$';

  constructor(el: ElementRef) {}

  @HostListener('keypress', ['$event']) onMouseEnter(event) {
    // console.log('keypress', event);
    if (event.key.match(this.REGEX_INT_NUMBER)) {
      return true;
    } else {
      return false;
    }
  }
}
