import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangswitchService } from '../../_services/langswitch.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-langbar',
  templateUrl: './langbar.component.html',
  styleUrls: ['./langbar.component.scss']
})
export class LangbarComponent implements OnInit, OnDestroy {
  lang: string;
  langServSubs: Subscription;
  langList = [];

  constructor(
    private router: Router,
    private translateServ: TranslateService,
    private langswitchService: LangswitchService
  ) {}

  ngOnInit() {
    this.langList = this.langswitchService.getLangList();
    this.langServSubs = this.langswitchService.routeLanguage.subscribe(lang => {
      this.lang = lang;
    });
  }

  switchLanguage(lang: string): void {
    this.translateServ.use(lang);
    this.lang = lang;
    let urlList = window.location.href.split('/');

    let index = 5;
    this.langswitchService.getLangList().forEach(langObject => {
      if (urlList.indexOf(langObject.lang) !== -1) {
        index = urlList.indexOf(langObject.lang) + 1;
      }
    });
    // console.log('urlList', urlList, index);
    urlList = urlList.slice(index);

    //this.router.navigate([this.lang + '/' + urlList.join('/')]);
    window.location.href= '/#/'+[this.lang + '/' + urlList.join('/')].toString();
    this.langswitchService.updateCurrentLang(lang);
    window.location.reload();
  }

  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    this.langServSubs.unsubscribe();
  }
}
