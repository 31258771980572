import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { BaseComponent } from 'src/app/shared/_common/base/base.component';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InspectionSubmit } from 'src/app/_domain/inspection.submit';
import { InspectionService } from 'src/app/shared/_services/inspection.service';
import { InspectionInterface, InspectionDate, PortListElement } from 'src/app/_domain/inspection.interface';
import { DatePipe } from '@angular/common';
import { LangswitchService, ClassifierService, UserService } from 'src/app/shared/_services';
import { UiHelper } from 'src/app/shared/_utils/ui';
import { ClassificatorObjects, UserInfo } from 'src/app/_domain';
import * as cloneDeep from 'lodash/cloneDeep';

export interface GeneralTeam {
  id: number;
  firstName: string;
  name: string;
}

@Component({
  selector: 'app-edit-recreat-inspection',
  templateUrl: './edit-recreat-inspection.component.html',
  styleUrls: ['./edit-recreat-inspection.component.scss']
})
export class EditRecreatInspectionComponent extends BaseComponent implements OnInit {
  @Input()
  id: number; // inspection id
  @Input()
  applicationId: number;
  @Input()
  vesselId: number;
  @Input()
  modalRecRef: BsModalRef;
  edit = false;
  @Output()
  refreshRecInspections: EventEmitter<any> = new EventEmitter();
  currentSubtypeCode: string;
  currentTypeCode: number;
  currentUser: UserInfo;
  currentType: {
    code: number;
    descr: Record<string, string>;
  };

  lang: string;
  @ViewChild('checkTeamTimesModalTpl') checkTeamTimesModalTpl: TemplateRef<any>;
  modalRef2: BsModalRef;

  submitted = false;

  recreationShipDetailForm: FormGroup;
  inspectionSubmit: InspectionSubmit;
  isForeign = false;
  nowDate = new Date();
  isValid: boolean;
  foundTeamDates: InspectionInterface[];

  inspectionPortList: PortListElement[];
  countryClassifier: ClassificatorObjects;

  generalTeamList: GeneralTeam[];

  teamMemberList = [];

  typeList: [
    {
      code: number;
      descr: Record<string, string>;
      claInspectionSubtypeEntityByCode: [
        {
          code: number;
          descr: Record<string, string>;
        }
      ];
    }
  ];

  preTranslatedLabelList = ['GLOBAL.CLEAR', 'GLOBAL.CHOOSE_ALL'];
  preTranslatedLabels = {};

  // errors lists
  dateErrors: string[];
  portErrors: string[];
  countryErrors: string[];
  teamMembersErrorsMap = { teamLeader: undefined };

  defaultErrorMsgLabel = 'ERROR.CREATE_DATA';
  constructor(
    private fb: FormBuilder,
    private classifierService: ClassifierService,
    private inspectionService: InspectionService
  ) {
    super();
  }

  ngOnInit() {
    this.submitted = false;
    this.addSubscriptionToList(
      this.translateServ.get(this.preTranslatedLabelList).subscribe(labels => {
        this.preTranslatedLabels = labels;
      })
    );

    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(
        clang => {
          this.lang = clang;
          // this.langDescr = clang === 'et' ? 'descrEst' : 'descrEng'; // TODO backist õiged väljad vaja
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );


    // sadam
    this.addSubscriptionToList(
      this.inspectionService.getPortList().subscribe(
        (res: any) => {
          if (res.data) {
            this.inspectionPortList = [
              this.getSelectDefaultFirstElement(),
              ...res.data.sort((a, b) => a.description.localeCompare(b.description))
            ] as PortListElement[];
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );

    // riik
    this.addSubscriptionToList(
      this.classifierService.getCountry().subscribe(
        (data: any) => {
          if (data && data.length > 0) {
            this.countryClassifier = [
              this.getSelectDefaultFirstElement(),
              ...data.sort((n1, n2) => {
                if (n1.name[this.lang] > n2.name[this.lang]) {
                  return 1;
                }
                if (n1.name[this.lang] < n2.name[this.lang]) {
                  return -1;
                }
                return 0;
              })
            ];
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );

    this.currentUser = this.userService.getUser();

    // komisjoni liikmed
    this.addSubscriptionToList(
      this.inspectionService.getInspectionSVTeamList().subscribe(
        response => {
          this.uiHelper.refreshSelectPicker();
          this.generalTeamList = cloneDeep(response.data);
          const distinctThings = this.generalTeamList.filter(
            (thing, i, arr) => arr.findIndex(t => t.id === thing.id) === i
          );
          this.generalTeamList = distinctThings;
          this.teamMemberList = distinctThings;
          this.uiHelper.refreshSelectPicker();
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );

    this.uiHelper.initSelectPicker();
    this.loadInspectionSelectForm();
    this.uiHelper.refreshSelectPicker();
    this.addSubscriptionToList(
      this.recreationShipDetailForm.get('subtypeCode').valueChanges.subscribe(subtypeCode => {
        this.currentType = this.typeList.find(
          x => x.claInspectionSubtypeEntityByCode.findIndex(y => y.code === +subtypeCode) !== -1
        );
      })
    );
  }

  createSmBoatinfoForm(): void {
    this.recreationShipDetailForm = this.fb.group(
      {
        subtypeCode: [null, Validators.required],
        startDate: [null, Validators.required],
        endDate: [null],
        // shipCategory: [null],
        teamLeader: [null, Validators.required],
        // teamMembers: [null],
        location: [null],
        portId: [null],
        // city: [null],
        // isForeign: [false],
        // countryId: [null],
        id: [null],
        approv: [false]
        // status: [null]
      }
      // {
      //   validator: this.dateLessThanWithStatus('startDate',  'status', '30001')
      // }
    );
  }

  async loadInspectionSelectForm() {
    this.addSubscriptionToList(
      this.inspectionService.getInspectionSVTypesList().subscribe(
        response => {
          this.typeList = response.data;
          if (this.id) {
            this.edit = true;
            this.loadInspectionData(this.id);
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );

    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(
        clang => {
          this.lang = clang;
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
    this.createSmBoatinfoForm();

    await this.delay(1500);
    this.uiHelper.refreshSelectPicker();
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  foreignChange(isForeign: boolean) {
    this.isForeign = isForeign;
    /* if (isForeign) {
      this.inspectionDetailForm.patchValue({ portId: null });
    } else {
      this.inspectionDetailForm.patchValue({ countryId: null, city: null });
    } */
    this.uiHelper.refreshSelectPicker();
  }

  onSubmit(): void {
    if (this.submitted && this.isSubmitValid() && this.recreationShipDetailForm.valid) {
      this.inspectionSubmit = this.recreationShipDetailForm.value;
      this.inspectionSubmit.subtypeCode = +this.recreationShipDetailForm.value.subtypeCode;
      this.inspectionSubmit.portId = +this.recreationShipDetailForm.value.portId;
      this.inspectionSubmit.applicationId = this.applicationId;
      // this.inspectionSubmit.vesselId = this.vesselId;
      // this.inspectionSubmit.status = 30002; // Backend lisab ise
      // if (this.bothDatePresent()) {
      //   this.checkTeamDates();
      // } else {
      //   this.saveAndCloseModal();
      // }
      this.saveAndCloseModal();
      this.submitted = false;
    } else {
      // this.notifierServ.notify('error', this.findInvalidControls().toString());
    }
  }

  saveAndCloseModal() {
    this.submitInspectionToDb();
    // this.closeModal();
  }
  saveAndCloseTeamModal() {
    this.submitInspectionToDb();
    // this.closeTeamModal();
  }

  submitInspectionToDb() {
    if (isNaN(this.inspectionSubmit.subtypeCode)) {
      this.inspectionSubmit.subtypeCode = +this.currentSubtypeCode;
    }
    this.addSubscriptionToList(
      this.inspectionService.submitSmBoatInspection(this.inspectionSubmit).subscribe(
        response => {
          if (response.success === true) {
            this.getLongMsgSuccess('MESSAGE.SAVE.SUCCESS');
            this.refreshRecInspections.emit(response.data);
            this.closeModal();
          } else {
            this.showDbErrorMessage(this.defaultErrorMsgLabel, response);
            this.closeModal();
          }

          // this.closeModal();
        },
        error => {
          this.getLongMsgError(error.message);
          this.closeModal();
        }
      )
    );
  }

  bothDatePresent(): boolean {
    const submitVals = this.recreationShipDetailForm.value;
    return submitVals.startDate && submitVals.endDate ? true : false;
  }
  /*
    Planeerimisel 30001
    Ettevalmistamisel 30002
    Vormistamisel 30003,
  */
  isSubmitValid(): boolean {
    const submitVals = this.recreationShipDetailForm.value;
    let valid = true;
    if (submitVals.subtypeCode && submitVals.startDate && submitVals.teamLeader) {
      valid = true;
    } else {
      valid = false;
    }
    return valid;
  }

  checkTeamDates() {
    this.addSubscriptionToList(
      this.inspectionService.checkTeamTimes(this.inspectionSubmit).subscribe(
        response => {
          if (response.success === true) {
            if (response.data.length > 0) {
              this.foundTeamDates = response.data;
              this.foundTeamDates.forEach(item => {
                item.startEndDateString = this.getDateTimeString(item);
              });
              this.modalRef2 = this.modalService.show(
                this.checkTeamTimesModalTpl,
                Object.assign({}, { class: 'modal-lg' })
              );
            } else {
              this.submitInspectionToDb();
            }
          } else {
            this.showDbErrorMessage(this.defaultErrorMsgLabel, response);
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  getDateTimeString(date: InspectionInterface) {
    // const startDateString = this.extractDate(date.startDate);
    // const endDateString = this.extractDate(date.endDate);
    const startDate = this.datePipe.transform(date.startDate, this.DATE_TIME_FORMAT_FOR_PIPE);
    const endDate = this.datePipe.transform(date.endDate, this.DATE_TIME_FORMAT_FOR_PIPE);
    return startDate + ' - ' + endDate;
  }

  /*  extractDate(date: InspectionDate) {
     return date.dateTime.date + ' ' + date.dateTime.time.hour + ':' + date.dateTime.time.minute;
   } */

  public findInvalidControls() {
    const invalid = [];
    const controls = this.recreationShipDetailForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  dateChange() {
    const INSVal = this.recreationShipDetailForm.value;
    if (
      (INSVal.startDate !== null && INSVal.endDate !== null && INSVal.startDate > INSVal.endDate) ||
      INSVal.startDate < this.nowDate
    ) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  closeModal() {
    this.modalRecRef.hide();
    // this.getSearchResults(this.inspectionSearch);
  }
  closeTeamModal() {
    this.modalRef2.hide();
  }

  loadInspectionData(id: number) {
    this.addSubscriptionToList(
      this.inspectionService.getInspection(id).subscribe(
        response => {
          this.uiHelper.refreshSelectPicker();
          const data = response.data;
          this.vesselId = data.vesselId;
          this.applicationId = data.applicationId;
          this.currentSubtypeCode = data.subtypeCode; // for disabled select
          this.currentTypeCode = data.claInspectionSubtypeBySubtypeCode.typeCode;
          const startDate = data.startDate;
          const endDate = data.endDate;
          this.recreationShipDetailForm.patchValue({
            subtypeCode: +data.subtypeCode,
            startDate: startDate ? new Date(startDate) : null, // LAB acceptable string is '2020-04-22T11:04:00+03:00'
            endDate: endDate ? new Date(endDate) : null, // LAB '22.04.2020 11:04' string is not acceptable
            teamLeader: data.teamLeader,
            // teamMembers: data.teamMembers,
            portId: data.portId,
            // countryId: data.countryId,
            city: data.city,
            id: +data.id,
            // status: data.status,
            location: data.location
          });
          this.uiHelper.refreshSelectPicker();
          /* disabled select */
          // if (data.id && +data.status !== 30001) {
          //   this.recreationShipDetailForm.controls.subtypeCode.disable();
          // }

          this.dateChange();
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }
}
