<!-- <div>
    <h2>{{ 'DOC.ADD' | translate }}</h2>
</div> -->
<div>
    <form [formGroup]="simpleDocForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <!-- doctype -->
            <div class="row align-items-center mt-1">
                <label class="col-xs-20 col-md-5 col-form-label requiredField">{{ 'DOC.DOCTYPE' | translate }}:</label>
                <div class="col-xs-20 col-md-15 pl-0">
                    <div class="input-group">
                        <select formControlName="docType" id="docTypePicker" class="form-control selectpicker clearable"
                            title="{{ 'DOC.DOCTYPE' | translate }}">
                            <optgroup *ngFor="let group of doctypeGroups; let i = index"
                                label="{{ group.descr[lang] }}">
                                <option *ngFor="let docType of doctypesByGroup[group.code]" [value]="docType.code">
                                    {{ docType.descr[lang] }}</option>
                            </optgroup>
                        </select>
                    </div>
                </div>
            </div>
            <!-- title -->
            <div *ngIf="selectedTypeContext==='MUU'" class="row align-items-center mt-2">
                <label class="col-xs-20 col-md-5 col-form-label">{{ 'DOC.TITLE' | translate }}:</label>
                <div class="col-xs-20 col-md-15 pl-0">
                    <div class="input-group">
                        <input formControlName="docTitle" id="titleTxt" class="form-control" type="text" />
                    </div>
                </div>
            </div>
            <!-- file -->
            <div class="row align-items-center mt-2">
                <label class="col-xs-20 col-md-5 col-form-label requiredField">{{ 'DOC.FILE' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0">
                    <div *ngIf="!simpleDocForm.value['fileOriginName']" class="file-upload-inline">
                        <div class="file-upload-frame">
                            <label class="text-center" for="fileInput" [innerHTML]="'DOC.ADDFILE' | translate"></label>
                            <input class="file-input" id="fileInput" type="file" (change)="getFile($event)" />
                        </div>
                    </div>

                    <!-- fileOriginName and DELETE BUTTON -->
                    <div *ngIf="simpleDocForm.value['fileOriginName']">
                        {{ simpleDocForm.value['fileOriginName'] }}
                        <button danger [style]="'small'" [icon]="'trashcan'" title="{{'GLOBAL.REMOVE'|translate}}"
                            (click)="removeFile()">
                        </button>
                    </div>
                    <div class=" invalid-feedback" *ngIf="showAllowedFileTypesWarning">{{ 'ERROR.ALLOWED_EXTENTIONS' | translate }} {{allowedExtentions.join(',')}}</div>
                    <div class=" invalid-feedback" *ngIf="showWarning">{{ 'ERROR.REQUIRED' | translate }}</div>
                </div>
                <!-- upload -->
                <div class="col-xs-20 col-md-5 pl-0 d-flex align-items-baseline">
                    <div class="mb-0">
                        <button success [style]="'small'" title="{{'GLOBAL.YES' | translate}}" [icon]="'save'">
                        </button>
                    </div>
                    <div class="ml-2 mb-0">
                        <button danger [style]="'small'" type="button" (click)="onCancel()"
                            title="{{'GLOBAL.NO'| translate}}" [icon]="'cancel'">
                        </button>
                    </div>
                </div>
            </div>
            <!-- upload button -->
            <!-- <div class="row align-items-center">
                <label class="col-xs-20 col-md-5 col-form-label">&nbsp;</label>
                <div class="col-xs-20 col-md-5 col-form-label">
                    <div class="mb-0">
                        <button success [style]="'square'" title="{{'GLOBAL.YES' | translate}}" [icon]="'save'">
                        </button>
                    </div>
                    <div class="ml-2 mb-0">
                        <button danger [style]="'square'" type="button" (click)="onCancel()"
                            title="{{'GLOBAL.NO'| translate}}" [icon]="'cancel'">
                        </button>
                    </div>
                </div>
            </div> -->
        </div>
    </form>
</div>
