import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Global shared module
import { SharedModule } from '../shared/shared.module';

import { AuthorityRoutingModule } from './authority-routing.module';
import { AuthorityComponent } from './authority.component';
import { AuthoritySearchComponent } from './authority-search/authority-search.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorityDetailComponent } from './authority-detail/authority-detail.component';

@NgModule({
  declarations: [AuthorityComponent, AuthoritySearchComponent, AuthorityDetailComponent],
  imports: [SharedModule, CommonModule, AuthorityRoutingModule, TranslateModule]
})
export class AuthorityModule {}
