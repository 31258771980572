import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormArray, FormGroup, FormControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { NotificationComponent } from 'src/app/shared/_common/notification/notification.component';
import { InspectionService } from 'src/app/shared/_services/inspection.service';
import { DateConstant } from 'src/app/shared/_utils';

@Component({
  selector: 'app-inspection-docs-ext-select',
  templateUrl: './inspection-docs-ext-select.component.html',
  styleUrls: ['./inspection-docs-ext-select.component.scss']
})
export class InspectionDocsExtSelectComponent extends NotificationComponent implements OnInit, OnDestroy {
  @Output() docsAdded: EventEmitter<any> = new EventEmitter();
  inspectionId: number; // from initialState
  docList = []; // from initialState
  extSelForm: FormGroup;
  docIds = new FormArray([]);
  isLoading = true;
  localDateFormat = DateConstant.DateFormat;

  private subscription = new Subscription();
  constructor(
    public bsModalRef: BsModalRef,
    private inspectionService: InspectionService
  ) { super(); }

  ngOnInit() {
    this.extSelForm = new FormGroup({
      docIds: this.docIds
    });
    this.docList.forEach(el =>
      (this.extSelForm.controls.docIds as FormArray).push(new FormControl(false)));
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSubmit() {
    const addDocIds = [];
    this.extSelForm.value.docIds.forEach((el, index) => {
      if (el === true) {
        addDocIds.push(this.docList[index].id);
      }
    });
    this.subscription.add(this.inspectionService.addExtendableDocument(this.inspectionId, addDocIds)
      .subscribe(
        response => {
          if (response.success) {
            this.docsAdded.emit(response.data); // response.data = count of extended docs
          }
          this.bsModalRef.hide();
        },
        error => {
          this.getLongMsgError(error.message);
          this.bsModalRef.hide();
        }
      ));
  }
}
