<div id="popover-notifications" class="pb-2 min-width-200">
  <div class="body p-2 ">
    <div class="popoverHeader position-relative">
      <h2 class="mb-2 font-weight-normal">{{ 'NOTIFICATIONS.LATEST_NOTIFICATIONS' | translate}}</h2>
      <span (click)="setAllNotificationsToRead()" class="link-orange position-absolute right-0 top-0 bottom-0">{{ 'NOTIFICATIONS.SET_ALL_TO_READ' | translate}}</span>
    </div>
    <hr class="mb-0">
    <ul class="headerNotificationsList pr-2 min-width-300">
      <!-- Could not use a table, bootstrap removes it  -->
      <li *ngIf="!latestNotifications?.data">
        <h3>{{ 'NOTIFICATIONS.NO_NOTIFICATIONS' | translate}}</h3>
      </li>
      <li *ngFor="let item of latestNotifications?.data" [ngClass]="item?.markedAsRead === null ? 'unread': ''">
        <a [routerLink]="[ lang, env, 'teavitused', 'detail', item.id]">
          <div class="w-100 m-2 position-relative">
            <p class="from mb-0"> <strong>{{ item?.senderName }}</strong> </p>
            <p class="subject mb-0"> {{ item?.subject }} </p>
            <p class="content mb-0 mt-2 max-height text-truncate">{{ item?.notificBody }} </p>
            <p class="date mb-0 position-absolute top-0 right-0"> {{ item?.stampCre | date}}</p>
          </div>
        </a>
        <hr class="mb-0">
      </li>
    </ul>
    <div *ngIf="latestNotifications?.data?.length === 0" class="w-100 m-2 position-relative">
      <p class="from mb-0 text-center"> <strong class="">{{ 'NOTIFICATIONS.NO_RESULTS' | translate }}</strong> </p>
    </div>
    <hr *ngIf="latestNotifications?.data?.length === 0" class="mb-0">
    <div class="w-100 mt-3">
      <a (click)="closePopover()" [routerLink]="['/', lang, env, 'teavitused']" class="link-orange pl-2">{{ 'NOTIFICATIONS.ALL_NOTIFICATIONS' | translate}}</a>
    </div>
  </div>
</div>
