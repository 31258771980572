import { Pipe, PipeTransform } from '@angular/core';

/*
  tagastab Map-i elemendi ilma listi ümber järjestamata
*/
@Pipe({ name: 'mapItems' })
export class ValuesPipe implements PipeTransform {

  transform(value: any): any {
    if (value !== null && value !== undefined) {
      return Object.keys(value).map(key => {
        return { key, value: value[key] };
      });
    }
    return { key: '', value: ''};
  }
}
