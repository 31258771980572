import { DatePipe } from '@angular/common';
import { PipeTransform, Pipe } from '@angular/core';
import { DateConstant } from '../_utils/date/date.constant';

@Pipe({
  name: 'skaDate'
})
export class SkaDatePipe extends DatePipe implements PipeTransform {
  transform(
    value: any,
    format: string = DateConstant.DateFormat,
    timezone: string = DateConstant.Timezone,
    locale: string = 'et'
  ): string | null {
    return super.transform(value, format, timezone, locale);
  }
}
