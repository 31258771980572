import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SortService {
  tableIds: string[];
  tableId: string;
  private columnSortedSource;
  columnSorted$: any;
  sortEventMap: any;

  constructor() {
    this.sortEventMap = {};
    this.columnSortedSource = new Subject<ColumnSortedEvent>();
    this.columnSorted$ = this.columnSortedSource.asObservable();
  }

  columnSorted(event: ColumnSortedEvent) {
    // event.tableId = this.tableId;
    // this.sortEventMap[this.tableId] = event;
    this.sortEventMap[event.tableId] = event;
    this.columnSortedSource.next(event);
  }

  setTableId(value: string) {
    this.tableId = value;
    /* if (!this.tableIds) {
      this.tableIds = [];
    }
    this.tableIds.push(value); */
  }
}

export interface ColumnSortedEvent {
  tableId: string;
  sortColumn: string;
  sortDirection: string;
}
