import {
  Component,
  OnInit,
  Input, Output, EventEmitter, OnDestroy, TemplateRef, ViewChild, IterableDiffers, IterableDiffer, DoCheck
} from '@angular/core';
import { Subscription } from 'rxjs';
import { LangswitchService, UserService, DocumentService } from '../_services';
import { SortEvent } from 'src/app/_domain/sortevent.interface';
import { DateConstant } from '../_utils/date';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute } from '@angular/router';
import { DocDocument, DocSignDTO } from 'src/app/_domain/docdocument.interface';
import { DocDocumentTableRowDTO } from 'src/app/_domain/dto.interface';
import { AplStatusChangeDTO, UserInfo } from 'src/app/_domain';
import { DatePipe } from '@angular/common';
import { subscribeOn } from 'rxjs/operators';
import { BaseComponent } from '../_common/base/base.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-document-table',
  templateUrl: './document-table.component.html',
  styleUrls: ['./document-table.component.scss']
})
export class DocumentTableComponent extends BaseComponent implements OnInit, OnDestroy, DoCheck {
  @Input() docList = [];
  @Input() mode = 'ro';
  @Input() type: string;
  @Input() canReturnType = false;
  @Input() noDel = false;
  @Input() isOmandiDokud = false;
  @Output() editDoc: EventEmitter<any> = new EventEmitter();
  @Output() viewDoc: EventEmitter<number> = new EventEmitter();
  @Output() deleteDoc: EventEmitter<number> = new EventEmitter();
  @Output() deleteDocContext: EventEmitter<DocDocumentTableRowDTO> = new EventEmitter();
  @Output() restoreDoc: EventEmitter<number> = new EventEmitter();
  @Output() signDoc: EventEmitter<number> = new EventEmitter();
  @Output() signedDoc: EventEmitter<number> = new EventEmitter();
  @Output() sortEvent: EventEmitter<any> = new EventEmitter();

  lang: string;
  env: string;
  currentDocId: number;
  parentForm: FormGroup;
  currentDoc: DocDocumentTableRowDTO;
  currentUser: UserInfo;
  localDateFormat = DateConstant.DateFormat;
  bsConfirmRef: BsModalRef;
  signModalRef: BsModalRef;
  @ViewChild('deleteDocModal') deleteDocModal: TemplateRef<any>;
  @ViewChild('signDocModal') signDocModal: TemplateRef<any>;

  private subscription = new Subscription();
  currentDateStr: string;
  iterableDiffer: IterableDiffer<any>;

  public docSignDTO: DocSignDTO = {
    docDocumentId: null,
    registerCode: null,
    phone: null,
    docSignMethod: null
  } as DocSignDTO;

  constructor(
    // private langswitchService: LangswitchService,
    // private modalService: BsModalService,
    private route: ActivatedRoute,
    private documentService: DocumentService,
    private iterableDiffers: IterableDiffers,
    private fb: FormBuilder
  ) {
    super();
    this.iterableDiffer = iterableDiffers.find([]).create(null);
  }

  buildForm() {
    this.parentForm = this.fb.group({
      code: this.currentUser.registerCode,
      phone: [null]
    });
  }
  ngOnInit() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    this.currentDateStr = new DatePipe('et_EE').transform(currentDate, 'yyyy-MM-dd');
    this.subscription.add(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );
    this.subscription.add(this.route.data.subscribe(data => {
      this.env = data.env;
    }));
    this.currentUser = this.userService.getUser();
    this.buildForm();
  }

  ngDoCheck() {
    const changes = this.iterableDiffer.diff(this.docList);
    if (changes) {
      this.docList.map(d => {
        d.signed = this.toDateStr(d.signed);
      });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribeAllFromList();
  }

  toDateStr(datestr: string): string {
    if (!datestr) {
      return null;
    }
    const dmy = datestr.match(/(\d+)/g);
    const signed = new Date(+dmy[0], +dmy[1] - 1, +dmy[2]);
    return new DatePipe('et_EE').transform(signed, 'yyyy-MM-dd');
  }

  onSorted(event: SortEvent) {
    this.sortEvent.emit(event);
  }

  signDocument(doc) {
    this.currentDocId = doc.id;
    this.currentDoc = doc;
    this.signModalRef = this.modalService.show(this.signDocModal, { class: 'modal-dialog-centered' });
  }

  confirmDelete(doc) {
    this.currentDocId = doc.id;
    this.currentDoc = doc;
    this.bsConfirmRef = this.modalService.show(this.deleteDocModal, { class: 'modal-dialog-centered' });
  }

  onDelete() {
    this.deleteDoc.emit(this.currentDocId);
    this.deleteDocContext.emit(this.currentDoc);
    this.bsConfirmRef.hide();
  }

  onSignDoc() {
    this.signModalRef.hide();

    this.docSignDTO.docDocumentId = this.currentDocId;
    this.docSignDTO.registerCode = this.parentForm.value.code;
    this.docSignDTO.phone = this.parentForm.value.phone;
    this.docSignDTO.docSignMethod = 'MOBIILID';

    if (this.docSignDTO.registerCode && this.docSignDTO.phone) {
      this.addSpinnerLayer();

      this.addSubscriptionToList(this.documentService.signDocument(this.docSignDTO).subscribe(
        response => {
          if (response.success) {
            this.getLongMsgSuccess('GLOBAL.SUCCESS.SIGN');
            this.signDoc.emit(this.currentDocId);
          } else {
            this.getLongMsgError('ERROR.SIGA');
          }
          this.removeSpinnerLayer();
        },
        error => {
          this.getLongMsgError(error.message);
          this.removeSpinnerLayer();
        }
      ));
    } else {
      this.getLongMsgError('ERROR.ALL_FIELDS_MANDATORY');
    }

  }

  onViewDoc(docId: number) {
    this.viewDoc.emit(docId);
  }

  onEditDoc(doc: any) {
    if (!this.canReturnType) {
      this.editDoc.emit(doc.id);
    } else {
      this.editDoc.emit({ docId: doc.id, docType: doc.docType });
    }
  }

  onRestoreDoc(docId: number) {
    this.restoreDoc.emit(docId);
  }

  closeModal(): void {
    this.bsConfirmRef.hide();
  }

  closeSignModal(): void {
    this.signModalRef.hide();
  }
}
