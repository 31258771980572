import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

const MAX_COLS = 20;

@Component({
  selector: 'app-formgroup-row',
  templateUrl: './formgroup-row.component.html',
  styleUrls: ['./formgroup-row.component.scss']
})
export class FormgroupRowComponent extends BaseComponent implements OnInit {

  @Input() label: string;
  @Input() labelWidth: number;
  @Input() requiredField: string;
  colWidth: number;
  // Must be same in BACKEND
  @Input() fieldName: string;
  @Input() formRef: NgForm;

  objectKeys = Object.keys;
  errorList = [];

  constructor() {
    super();
    if (!this.labelWidth) {
      this.labelWidth = 5;
    }
  }

  ngOnInit() {
    if (this.requiredField) {
      this.requiredField = 'requiredField';
    }
    if (this.labelWidth >= MAX_COLS - 1) {
      console.error('Maximum grid size is ' + MAX_COLS);
    } else {
      this.colWidth = MAX_COLS - this.labelWidth;
    }

    if (this.formRef && this.formRef.form) {
      this.addSubscriptionToList(
        this.formRef.statusChanges.subscribe(formV => {
        // Get current field errors
        if (formV === 'INVALID' && this.formRef.form.controls[this.fieldName].errors) {
          const list = this.formRef.form.controls[this.fieldName].errors;
          this.formRef.form.controls[this.fieldName].errors.forEach(errorLabel => {
            if (this.errorList.indexOf(errorLabel) === -1) {
              this.errorList.push(errorLabel);
            }
          });
        } else {
          this.errorList = [];
        }
      }));
    }
  }
}
