import { Injectable, OnDestroy, Component } from '@angular/core';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError, Subscription } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { BaseComponent } from '../shared/_common/base/base.component';
import { NotificationComponent } from '../shared/_common/notification/notification.component';
import { Location } from '@angular/common';
import { API_ENDPOINT } from '../app.constants';
import { UserService } from '../shared/_services';



/**
 * code url: https://github.com/vigneshsithirai/Angular-Interceptor/blob/master/src/app/interceptor/httpconfig.interceptor.ts
 * example url: https://www.digitalocean.com/community/tutorials/how-to-use-angular-interceptors-to-manage-http-requests-and-error-handling
 */
// @Component({
//     selector: 'app-notification',
//     template: ''
// })
@Injectable()
export class HttpConfigInterceptor extends NotificationComponent implements HttpInterceptor, OnDestroy {
  public subscriptions: Subscription[] = [];

  constructor(private location: Location, private userService: UserService) {
    super();
  }

  /*
    http codes: https://en.wikipedia.org/wiki/List_of_HTTP_status_codes

    401 Unauthorized
    403 Forbidden
    500 Internal Server Error
  */
  intercept(request: HttpRequest<any>, next: HttpHandler): any { // Observable<HttpEvent<any>>
    return next.handle(request)
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            /*
             Do not intercept
            */
            // console.log('intercept event--->>>', event);
          }
          return event;
        }),
        catchError((error: any) => { // HttpErrorResponse
          this.removeSpinnerLayer();
          if (error.status !== 401) {
            console.error('Intercept Error', error, ' Error url: ', window.location.href);
          }

          if (error.status === 500) { // Internal Server Error
            this.showInterceptorError(error, ['ERROR.URL'], ' ' + window.location.href); // <br/><br/>
          } else if (error.status === 401) { // "Unauthorized"
            const currentUser = this.userService.getUser();
            // this.location.back();
            // this.logout();
            if (!currentUser) {
              window.location.href = API_ENDPOINT + 'app/login';
            } else {
              error.error = { parameters: { message: 'ERROR.HTTP.401' } };
              this.showInterceptorError(error, ['ERROR.URL'], ' ' + window.location.href);
            }
            // this.showInterceptorError(error, ['ERROR.URL'], ': ' + window.location.href); // <br/><br/>
          } else if (error.status === 403) { // Forbidden
            // this.location.back();
            this.showInterceptorError(error, ['ERROR.URL'], ' ' + window.location.href); // <br/><br/>
          } else if (error.status === 404) { // Not Found
            error.error = { parameters: { message: 'ERROR.HTTP.404' } };

            this.showInterceptorError(error, ['ERROR.URL'], ' ' + window.location.href); // <br/><br/>
          } else {
            // this.showInterceptorError([upperCaseMsg, 'ERROR.URL'], ': ' + window.location.href); // <br/><br/>
            return throwError(error);
          }
          // return throwError(error);
          return new Observable();
        }));
  }

  removeSpinnerLayer() {
    window.document.body.classList.remove('show-spinner-layer');
  }

  // Error
  async showInterceptorError(error: HttpErrorResponse, labelTextList: string[], text = '') {
    if (error.error.parameters && error.error.parameters.message) {
      const msg = error.error.parameters.message;
      // Label to Upper Case
      const upperCaseMsg = msg.split('.').map(item => item.toUpperCase()).join('.');
      labelTextList = [upperCaseMsg, ...labelTextList, 'MESSAGE.DETAILS'];
    }
    // console.error('interceptor Label error: ', error);
    const detailMsg = error.error.detail ?  await this.translate(error.error.detail) : null;

    this.addSubscriptionToList(this.translateServ.get(labelTextList).subscribe(labels => {
      const keys = Object.keys(labels);
       // console.log('labels ', labels, keys, labelTextList, error);

      let notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
        (text !== '' ? ' ' + text : text);

      notifyText += error.error.localizedMessage ? ''
        + '<div class="collapseWrapper">'
        + ' <a class="collapsible"> <span>' + labels['MESSAGE.DETAILS'] + '</span></a>:<div class="content" > '
        + '<p> ' + (detailMsg === error.error.detail ? error.error.localizedMessage : detailMsg) + '</p>'
        + '</div></div>' : '';

      console.error( '* ' + labels['MESSAGE.DETAILS'] + ': ', error.error.localizedMessage);
      
      if(error.status === 500) {
        this.toastr.error(notifyText, labels[keys[0]], this.toasterOptions);
      } else {
        this.toastr.error(notifyText, labels[keys[0]], this.stikyToasterOptions);
      }
    }));
  }



  logout(): void {
    this.addSubscriptionToList(this.userService.logoutUser().subscribe(
      response => {
        if (API_ENDPOINT.charAt(API_ENDPOINT.length - 1) === '/') {
          window.location.href = API_ENDPOINT;
        } else {
          window.location.href = API_ENDPOINT + '/';
        }
      },
      error => {
        this.getLongMsgError(error.message);
      }));
  }

  addSubscriptionToList(subscription: any) {
    this.subscriptions.push(subscription);
  }

  unsubscribeAllFromList() {
    this.subscriptions.forEach(subscription => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAllFromList();
  }
}
