import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Global shared module
import { SharedModule } from '../shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [DashboardComponent],
  imports: [CommonModule, SharedModule, DashboardRoutingModule, TranslateModule]
})
export class DashboardModule {}
