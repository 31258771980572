
<div *ngIf="currentUser" id="popover-client-select" class="chooseRepresentative pb-2">
  <div class="body p-2">
    <h3 *ngIf="currentUser.rolesSize > 0" class="representativeHeader">{{ 'USERBAR.PROCEDUAL_ENV' | translate }}</h3>
    <ul class="representativeUl">{{currentUser.roles.size}}
      <li *ngFor="let role of currentUser.roles | keyvalue">
        <a (click)="changeRole(role.key)">
          <!-- NB! TODO: handle translations! -->
          <span class="representativeList">{{ role.value.rolenameEst}}</span>
        </a>
      </li>
    </ul>
    <h3 class="representativeHeader">{{ "USERBAR.SELF_SERVICE" | translate }}</h3>
    <ul class="representativeUl">
      <li *ngFor="let role of currentUser.entities | mapItems">
         <a (click)="changeRole(role.key)">
          <span class="representativeList"> {{ role.value.rolenameEst}} </span>
        </a>
      </li>
    </ul>
  </div>
</div>
