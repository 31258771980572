import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '../shared/shared.module';

import { MyStuffRoutingModule } from './my-stuff-routing.module';
import { MyStuffComponent } from './my-stuff.component';



@NgModule({
  declarations: [MyStuffComponent],
  imports: [
    TranslateModule,
    CommonModule,
    SharedModule,
    MyStuffRoutingModule
  ]
})
export class MyStuffModule { }
