<div class="breadcrumbs container p-0 my-0">
  <!-- <ul class="d-flex flex-row justify-content-start py-3">
    <li><a href="#">Esileht ></a></li>
    <li><a href="#" class="ml-2"> Teine vaade ></a></li>
    <li><a href="#" class="ml-2"> Kolmas vaade</a></li>
  </ul> -->

  <breadcrumb #parent>
    <ol class="breadcrumb">
      <!-- <li class="first"><a href="#" class="icon-home"></a>HOME</li> -->
      <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
        <li *ngIf="!route.terminal && (route?.route?.data?.isBreadcrumbVisible === undefined
         || route?.route?.data?.isBreadcrumbVisible !== false)" class="breadcrumb-item-lis align-self-center">
          <a href="" [routerLink]="[route.url]">{{ route.displayName | translate }}</a>
        </li>
        <li *ngIf="route.terminal" class="breadcrumb-item-lis active align-self-center" aria-current="page">
          {{ route.displayName | translate }}
        </li>
      </ng-template>
    </ol>
  </breadcrumb>
</div>
