import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isothree'
})
export class IsothreePipe implements PipeTransform {

  transform(value: any, ...args: any[]): any {
    if (value === 'et') { return 'EST'; }
    if (value === 'en') { return 'ENG'; }
    if (value === 'ru') { return 'RUS'; }
    return 'est';
  }

}
