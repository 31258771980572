<div class="row align-items-center">
  <label class="{{ this.requiredField }} col-xs-20 col-md-{{ this.labelWidth }} col-form-label">{{ this.label | translate }}:</label>
  <div class="col-xs-20 col-md-{{ this.colWidth }} pl-0">
    <div class="input-group">
      <ng-content></ng-content>
    </div>
    <div *ngIf="fieldName && !formRef?.form?.valid && formRef?.form?.controls[fieldName]?.errors">
      <div *ngFor="let errorLabel of this.errorList" class="invalid-feedback">
        <div *ngIf="errorLabel">
          {{ errorLabel | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
