import { Component, OnInit, HostListener, Input, OnDestroy, ElementRef } from '@angular/core';
import { SortService } from '../../_services/sort.service';
import { Subscription } from 'rxjs';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '[sortable-column]',
  templateUrl: './sortable-column.component.html'
})
export class SortableColumnComponent implements OnInit, OnDestroy {

  constructor(private sortService: SortService, private el: ElementRef) { }
  @Input() tableId: string;

  // tslint:disable-next-line: no-input-rename
  @Input('sortable-column')
  sortableColumn: string;

  // tslint:disable-next-line: no-input-rename
  @Input('sort-direction')
  sortDirection = '';

  private columnSortedSubscription: Subscription;

  @HostListener('click')
  sort() {
    // leiab tabeli unikaalse nime html-ist
    const tableEL = this.el.nativeElement.closest('table');
    let tableName = tableEL.getAttribute('appsortabletable');
    // LAB if appsortabletable is a binded property it's value cannot be retrieved from DOM
    // ng-reflect- attributes are all debug helpers to let devtools know what bindings are,
    // which never exist in non-debug (production) mode.
    // solution for now: set tableId on each sortable-column
    if (!tableName) {
      tableName = this.tableId;
    }
    this.sortDirection = (this.sortService.sortEventMap[tableName] === undefined ||
      this.sortService.sortEventMap[tableName].sortDirection) === 'asc' ?
      'desc' : 'asc';

    /*
      Remove classes
    */
    const matchesAsc = tableEL.querySelectorAll('th.sorted-ascending');
    const matchesDesc = tableEL.querySelectorAll('th.sorted-descending');
    if (matchesAsc) {
      matchesAsc.forEach(ascItem => {
        ascItem.classList.remove('sorted-ascending');
      });
    }
    if (matchesDesc) {
      matchesDesc.forEach(ascItem => {
        ascItem.classList.remove('sorted-descending');
      });
    }
    /*
      Lisab sobiva classi th elemendile
    */
    if (this.sortDirection !== undefined && this.sortDirection === 'asc') {
      this.el.nativeElement.classList.add('sorted-ascending');
      this.el.nativeElement.classList.remove('sorted-descending');
    } else if (this.sortDirection === 'desc') {
      this.el.nativeElement.classList.remove('sorted-ascending');
      this.el.nativeElement.classList.add('sorted-descending');
    }

    this.sortService.columnSorted({ tableId: tableName, sortColumn: this.sortableColumn, sortDirection: this.sortDirection });
  }

  ngOnInit() {
    // leiab tabeli unikaalse nime html-ist
    let tableName = this.el.nativeElement.closest('table').getAttribute('appsortabletable');
    if (!tableName) {
      tableName = this.tableId;
    }
    if (this.sortService.sortEventMap[tableName] !== undefined &&
      this.sortService.sortEventMap[tableName].sortColumn === this.sortableColumn) {
      // võtab sort direction-i mapist
      this.sortDirection = this.sortService.sortEventMap[tableName].sortDirection;
    }
    /*
      Lisab sobiva classi th elemendile
    */
    if (this.sortDirection !== undefined && this.sortDirection === 'asc') {
      this.el.nativeElement.classList.add('sorted-ascending');
      this.el.nativeElement.classList.remove('sorted-descending');
    } else if (this.sortDirection === 'desc') {
      this.el.nativeElement.classList.remove('sorted-ascending');
      this.el.nativeElement.classList.add('sorted-descending');
    }
    // subscribe to sort changes so we can react when other columns are sorted
    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
      // reset this column's sort direction to hide the sort icons
      if (this.sortableColumn !== event.sortColumn) {
        this.sortDirection = '';
      }
    });
  }

  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }
  // Allikas: https://www.carbonatethis.com/sort-table-columns-with-angular-and-typescript/

}
