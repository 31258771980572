import { Directive, ElementRef, HostBinding, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
/*
  icon code:  src\assets\scss\abstracts\_variables-icons.scss
  add css to: src\assets\scss\components\buttons\_extra.scss
*/
@Directive({
  selector:
    // tslint:disable-next-line: directive-selector
    'button[success],button[danger],button[info],button[dark],button[clear],button[light],button[ska-type]'
})
export class ButtonDirective implements OnInit, OnChanges {
  @HostBinding('class')
  public class = 'btn';
  // tslint:disable-next-line: no-input-rename
  @Input('ska-type')
  public skaType: string;
  @Input()
  public disabled: string;
  @Input()
  public style: string;
  @Input()
  public icon: string;

  private types: string[] = ['success', 'danger', 'info', 'dark', 'light', 'clear'];
  private icons: string[] = ['with-plus', 'multiple-options', 'save', 'cancel', 'logout',
    'eye', 'anchor', 'user', 'print-alt', 'login-alt', 'logout-alt', 'pencil', 'square-pencil',
    'square-pencil1', 'square-pencil2', 'trashcan', 'trashcan2', 'trashcan3', 'magnifier',
    'print', 'letter', 'letter2', 'letter3', 'file-download', 'file-upload', 'ok', 'question', 'close', 'exit', 'contacts'
    , 'shield', 'refresh-1-arrow', 'refresh-2-arrow', 'arrow-lg-left', 'arrow-lg-right', 'arrow-sm-left', 'arrow-sm-right',
    'arrow-sm-filled-left', 'arrow-sm-filled-right', 'menu', 'c-user', 'file-pdf', 'file-excel'];
  private styles: string[] = ['round', 'square', 'small', 'semi-square'];

  constructor(private elem: ElementRef) {
    //
  }

  public ngOnInit() {
    if (this.elem.nativeElement.children && this.elem.nativeElement.children.length === 0) {
      this.class += ' center';
    }
    if (!this.skaType) {
      this.skaType = this.searchAttributes(this.types);
    }
    if (this.elem.nativeElement.attributes['custom-class']) {
      this.class += ' ' + this.elem.nativeElement.attributes['custom-class'].value;
    }

    this.class += ' btn-' + this.skaType;

    if (!this.style) {
      this.style = this.searchAttributes(this.styles);
    }
    if (this.style === 'square') {
      this.class += ' btn-square';
    }
    if (this.style === 'semi-square') {
      this.class += ' btn-semi-square';
    }
    if (this.style === 'small') {
      this.class += ' btn-sm';
    }

    if (this.icon && this.icons.indexOf(this.icon) >= 0) {
      this.class += ' btn-' + this.icon;
    }

    if (this.disabled === 'disabled' || (typeof this.disabled !== 'undefined' && this.disabled)) {
      if (this.class && this.class.indexOf('disabled') === -1) {
        this.class += ' disabled';
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      if (this.disabled === 'disabled' || (typeof this.disabled !== 'undefined' && this.disabled)) {
        if (this.class && this.class.indexOf('disabled') === -1) {
          this.class += ' disabled';
        }
      } else {
        this.class = this.class.split('disabled').join('');
      }
    }
  }

  private searchAttributes(allowedKeys: string[]): string {
    for (const key in allowedKeys) {
      if (this.elem.nativeElement.attributes[allowedKeys[key]]) {
        return allowedKeys[key];
      }
    }
    return '';
  }
}
