import { NgModule, Injectable } from '@angular/core';
import {
  Routes,
  RouterModule,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  Router
} from '@angular/router';
// Guard(s)
import { AuthGuard, HomePageGuard /* PendingChangesGuard */ } from './_guards';
import { Route } from '@angular/compiler/src/core';
import { MyStuffComponent } from './my-stuff/my-stuff.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AuthorityDetailComponent } from './authority/authority-detail/authority-detail.component';

/* @Injectable()
export class HomePageGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(): any {
    if (sessionStorage.getItem('currentActiveRoleEnvinronment') === 'ROLE_ADMIN') {
      this.router.navigate([':lang/mk/toolaud']);
    } else if (sessionStorage.getItem('currentActiveRoleEnvinronment') !== 'ROLE_ADMIN') {
      this.router.navigate([':lang/ik/minu-asjad']);
    } else {
      return true;
    }
  }
} */

export const ikRoutes: Routes = [
  {
    path: ':lang/ik',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'MAINNAV.MAIN_PAGE_IK', env: 'ik' },
    children: [
      {
        path: 'minu-asjad',
        canActivate: [AuthGuard],
        loadChildren: () => import('./my-stuff/my-stuff.module').then(m => m.MyStuffModule),
        data: { breadcrumb: 'MAINNAV.MY_STUFF', env: 'ik', isMainPage: false }
      },
      {
        path: 'laevad',
        canActivate: [AuthGuard],
        loadChildren: () => import('./ships/ships.module').then(m => m.ShipsModule),
        data: { breadcrumb: 'MAINNAV.SHIPS', env: 'ik' }
      },
      {
        path: 'taotlused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
        data: { breadcrumb: 'MAINNAV.APPLICATION', isBreadcrumbVisible: false, env: 'ik' }
      },
      {
        path: 'ylevaatused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule),
        data: { breadcrumb: 'MAINNAV.INSPECTIONS', env: 'ik' }
      },
      {
        path: 'kontrollleht',
        canActivate: [AuthGuard],
        loadChildren: () => import('./checkform/checkform.module').then(m => m.CheckformModule),
        data: { breadcrumb: 'MAINNAV.CHECKFORM', env: 'ik' }
      },
      {
        path: 'mittevastavused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./contradiction/contradiction.module').then(m => m.ContradictionModule),
        data: { breadcrumb: 'MAINNAV.CONTRADICTION', env: 'ik' }
      },
      {
        path: 'minuandmed',
        canActivate: [AuthGuard],
        loadChildren: () => import('./userdata/userdata.module').then(m => m.UserdataModule),
        data: { breadcrumb: 'MAINNAV.MY_DATA', env: 'ik' }
      },
      {
        path: 'toolaud',
        redirectTo: 'minu-asjad'
      },
      {
        path: 'teavitused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        data: { breadcrumb: 'MAINNAV.MY_NOTIFICATIONS', env: 'ik' }
      },
      {
        path: 'minu/volitused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./authority/authority.module').then(m => m.AuthorityModule),
        data: { breadcrumb: 'AUTH.DETAILS', env: 'ik' }
      },
      {
        path: '',
        canActivate: [AuthGuard],
        component: MyStuffComponent,
        data: { breadcrumb: 'MAINNAV.MY_STUFF', env: 'ik' }
      }
    ]
  }
];

export const mkRoutes: Routes = [
  {
    path: ':lang/mk',
    canActivate: [AuthGuard],
    data: { breadcrumb: 'MAINNAV.MAIN_PAGE_MK', env: 'mk' },
    children: [
      {
        path: 'toolaud',
        canActivate: [AuthGuard],
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { breadcrumb: 'MAINNAV.DASHBOARD', env: 'mk', isMainPage: false }
      },
      {
        path: 'laevad',
        canActivate: [AuthGuard],
        loadChildren: () => import('./ships/ships.module').then(m => m.ShipsModule),
        data: { breadcrumb: 'MAINNAV.SHIPS', env: 'mk' }
      },
      {
        path: 'taotlused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
        data: { breadcrumb: 'MAINNAV.APPLICATION', env: 'mk' }
      },
      {
        path: 'ylevaatused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule),
        data: { breadcrumb: 'MAINNAV.INSPECTIONS', env: 'mk' }
      },
      {
        path: 'volitused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./authority/authority.module').then(m => m.AuthorityModule),
        data: { breadcrumb: 'MAINNAV.AUTHORITIES', env: 'mk' }
      },
      {
        path: 'kohustised',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./finobligations/finobligations.module').then(m => m.FinobligationsModule),
        data: { breadcrumb: 'MAINNAV.OBLIGATIONS', env: 'mk' }
      },
      {
        path: 'kontrollleht',
        canActivate: [AuthGuard],
        loadChildren: () => import('./checkform/checkform.module').then(m => m.CheckformModule),
        data: { breadcrumb: 'MAINNAV.CHECKFORM', env: 'mk' }
      },
      {
        path: 'mittevastavused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./contradiction/contradiction.module').then(m => m.ContradictionModule),
        data: { breadcrumb: 'MAINNAV.CONTRADICTION', env: 'mk' }
      },
      {
        path: 'vaikelaev',
        canActivate: [AuthGuard],
        loadChildren: () => import('./smallship/smallship.module').then(m => m.SmallshipModule),
        data: { breadcrumb: 'MAINNAV.SMALLSHIPS', env: 'mk' }
      },
      {
        path: 'mallid',
        canActivate: [AuthGuard],
        loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
        data: { breadcrumb: 'MAINNAV.TEMPLATE_MANAGEMENT', env: 'mk' }
      },
      {
        path: 'minuandmed',
        canActivate: [AuthGuard],
        loadChildren: () => import('./userdata/userdata.module').then(m => m.UserdataModule),
        data: { breadcrumb: 'MAINNAV.MY_DATA', env: 'mk' }
      },
      {
        path: 'nouded',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./inspection-requirements/inspection-requirements.module').then(
            m => m.InspectionRequirementsModule
          ),
        data: { breadcrumb: 'MAINNAV.INSPECTION_REQUIREMENTS', env: 'mk' }
      },
      {
        path: 'klassifikaatorid',
        canActivate: [AuthGuard],
        loadChildren: () => import('./classifiers/classifiers.module').then(m => m.ClassificatorsModule),
        data: { breadcrumb: 'MAINNAV.CLASSIFCATORS', env: 'mk' }
      },
      {
        path: 'kasutajad',
        canActivate: [AuthGuard],
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        data: { breadcrumb: 'MAINNAV.KASUTAJAD', env: 'mk' }
      },
      {
        path: '',
        canActivate: [AuthGuard],
        component: DashboardComponent,
        data: { breadcrumb: 'MAINNAV.DASHBOARD', env: 'mk', isMainPage: true }
      },
      {
        path: 'teavitused',
        canActivate: [AuthGuard],
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
        data: { breadcrumb: 'MAINNAV.MY_NOTIFICATIONS', env: 'mk' }
      }
    ]
  }
];

export const defaultRoutes: Routes = [
  // DOTO: hiljem võib eemaldada
  {
    path: ':lang/playground',
    canActivate: [AuthGuard],
    loadChildren: () => import('./playground/playground.module').then(m => m.PlaygroundModule),
    data: { breadcrumb: 'MAINNAV.PLAYGROUND' }
  },
  {
    path: ':lang',
    canActivate: [HomePageGuard],
    // redirectTo: 'ik',
    // redirectTo: sessionStorage.getItem('currentActiveRoleEnvinronment') === 'ROLE_ADMIN' ? ':lang/mk/toolaud' : ':lang/ik/minu-asjad',
    pathMatch: 'full',
    loadChildren: () => {
      const env = sessionStorage.getItem('currentActiveEnvinronment');
      if (env === 'mk') {
        return import('./dashboard/dashboard.module').then(m => m.DashboardModule);
      } else if (env === 'ik') {
        return import('./my-stuff/my-stuff.module').then(m => m.MyStuffModule);
      } else {
        return import('./home/home.module').then(m => m.HomeModule);
      }
      // return import('./home/home.module').then(m => m.HomeModule);
    }
    /* redirectTo: () => {
      const env = sessionStorage.getItem('currentActiveEnvinronment');
      if (env === 'mk') {
        return 'toolaud';
      } else {
        return 'minu-asjad';
      }
    } */
    // data: { breadcrumb: 'MAINNAV.REQUESTS' }
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/et'
  }
];

// OLD ROUTES
/* const OLD_ROUTES: Routes = [
  {
    path: ':lang/minu-asjad',
    canActivate: [AuthGuard],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    data: { breadcrumb: 'MAINNAV.MY_STUFF' }
  },
  {
    path: ':lang/laevad',
    canActivate: [AuthGuard],
    loadChildren: () => import('./ships/ships.module').then(m => m.ShipsModule),
    data: { breadcrumb: 'MAINNAV.SHIPS' }
  },
  {
    path: ':lang/taotlused',
    canActivate: [AuthGuard],
    loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule),
    data: { breadcrumb: 'MAINNAV.APPLICATION' }
  },
  {
    path: ':lang/volitused',
    canActivate: [AuthGuard],
    loadChildren: () => import('./authority/authority.module').then(m => m.AuthorityModule),
    data: { breadcrumb: 'MAINNAV.AUTHORITY' }
  },
  {
    path: ':lang/ylevaatused',
    canActivate: [AuthGuard],
    loadChildren: () => import('./inspections/inspections.module').then(m => m.InspectionsModule),
    data: { breadcrumb: 'MAINNAV.INSPECTIONS' }
  },
  {
    path: ':lang/rahalised-kohustused',
    canActivate: [AuthGuard],
    loadChildren: () => import('./finobligations/finobligations.module').then(m => m.FinobligationsModule),
    data: { breadcrumb: 'MAINNAV.FINANCIAL_OBLIGATIONS' }
  },
  {
    path: ':lang/playground',
    canActivate: [AuthGuard],
    loadChildren: () => import('./playground/playground.module').then(m => m.PlaygroundModule),
    data: { breadcrumb: 'MAINNAV.PLAYGROUND' }
  }
]; */

// ikRoutes.concat(mkRoutes).concat(OLD_ROUTES).concat(defultRoutes);
const ALL_ROUTES = [...ikRoutes, ...mkRoutes, ...defaultRoutes]; // ...OLD_ROUTES,

@NgModule({
  imports: [
    RouterModule.forRoot(ALL_ROUTES, {
      relativeLinkResolution: 'corrected',
      useHash: true,
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
      // Enable scrolling to anchors
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule],
  providers: [AuthGuard /* PendingChangesGuard */, HomePageGuard]
})
export class AppRoutingModule {}
