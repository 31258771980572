import { Directive, ElementRef, HostListener, Input, forwardRef } from '@angular/core';
import { NotificationComponent } from '../_common/notification/notification.component';

@Directive({
  selector: '[appInputRegexValidator]'
})
export class InputRegexValidatorDirective extends NotificationComponent {
  @Input()
  appInputRegexValidator: string;

  constructor(el: ElementRef) {
    super();
   }

  @HostListener('keypress', ['$event']) onMouseEnter(event) {
    // console.log('keypress', event);
    if (this.appInputRegexValidator) {
      if (event.key.match(this.appInputRegexValidator)) {
        return true;
      } else {
        return false;
      }
    } else {
      this.getLongMsgWarningSticky('Regex formula is missing!');
    }
  }
}
