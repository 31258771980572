import { Component, OnInit, Input, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { etLocale, enGbLocale } from 'ngx-bootstrap/locale';

import { LangswitchService } from '../../_services';
import { DatePipe } from '@angular/common';

defineLocale('et', etLocale);
defineLocale('en', enGbLocale);

@Component({
  selector: 'app-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateTimePickerComponent,
      multi: true
    },
    DatePipe
  ]
})
export class DateTimePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {
  DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm';
  DATE_TIME_FORMAT_FOR_PIPE = 'dd.MM.yyyy HH:mm';
  // DATE_TIME_FORMAT_FOR_PIPE_TO_TRANSFORM_DATE_OBJ = 'dd.MM.yyyy HH:m';

  config = {
    dateInputFormat: this.DATE_TIME_FORMAT,
    containerClass: 'theme-dark-blue',
    customTodayClass: 'today'
  };
  @Input()
  placeholder = '';
  @Input()
  minDate = '';
  @Input()
  inputValue = null;

  @ViewChild('dateInput', { static: true, read: ElementRef })
  inputElementRef: ElementRef;

  private langServSubs: Subscription;

  public value: Date;

  constructor(
    private langswitchService: LangswitchService,
    private localeService: BsLocaleService,
    private renderer: Renderer2,
    public datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.langServSubs = this.langswitchService.routeLanguage.subscribe(
      clang => {
        this.localeService.use(clang);
      }
    );
  }

  ngOnDestroy() {
    this.langServSubs.unsubscribe();
  }



  onChange(event: Date) {
    // console.log('datepicker onchange', event);
    this.value = event;
    if (this.value) {
      // this.value.setHours(0, 0, 0, 0);
    }
    this.onChanged(this.value);
    this.onTouched();
  }
  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    this.value = value;
    if (value) {
      const valueString = this.datepipe.transform(this.value, this.DATE_TIME_FORMAT_FOR_PIPE);
      // console.log('datepicker writeval', value, valueString);
      this.renderer.setProperty(this.inputElementRef.nativeElement, 'value', valueString);
    } else {
      this.inputValue = null;
    }
  }

  registerOnChange(fn: any) {
    // console.log('datepicker registeronChange', fn);
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    // console.log('datepicker registeronTouched', fn);
    this.onTouched = fn;
  }

}
