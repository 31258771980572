<div >
  <div *ngIf="isBig" class="w-100 d-flex justify-content-center py-5">
    <img src="assets/assets/images/logos/liquid.svg" alt="" class="" style="height: 100px;" />
  </div>
  <div *ngIf="!isBig" class="progress-simple">
    <div>
      <span *ngIf="this.msg; else defaultMsg">{{ this.msg | translate }}</span>&nbsp;
    </div>
    <div *ngIf="this.smallMsg" class="text-small">{{ this.smallMsg | translate }}</div>
  </div>
</div>
<ng-template #defaultMsg>
  <span>{{ 'GLOBAL.LOADING' | translate }}</span>
</ng-template>
