import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  ViewChild,
  Pipe,
  PipeTransform
} from '@angular/core';
import { UserInfo } from 'src/app/_domain';
import { UserService } from 'src/app/shared/_services';
import { BaseComponent } from 'src/app/shared/_common/base/base.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-change-role',
  templateUrl: './user-change-role.component.html',
  styleUrls: ['./user-change-role.component.scss']
})
export class UserChangeRoleComponent extends BaseComponent implements OnInit, OnDestroy {
  @Output() clickOnLink = new EventEmitter<string>();
  public currentUser: UserInfo;
  public activeRoleTitle: string;
  constructor(private router: Router) {
    super();
  }

  async ngOnInit() {
    this.currentUser = this.userService.getUser();
    if (!this.currentUser) {
      this.addSubscriptionToList(this.userService.userSubject.subscribe(user => {
        this.currentUser = user;
      }));
    }
  }

  ngOnDestroy() {
    this.unsubscribeAllFromList();
  }

  /*
   * Changes the role/profile of the user
   *
   */
  changeRole(roleId: string): void {
    this.addSubscriptionToList(
      this.userService.changeUserRole(roleId).subscribe(
        userInfo => {
          this.handleAuthChange(userInfo);
          this.closePopover();
          this.userService.sendNewRepresentativeEvent();

        },
        error => {
          if (error.message) {
            this.getLongMsgError(error.message);
          }
        }
      )
    );
  }

  /*
   * Handles login or role changes
   *
   */
  handleAuthChange(userInfo: UserInfo): void {
    if (userInfo.autPersonId) {
      this.userService.setUser(userInfo);
      this.clickOnLink.emit('');

      const urlList = window.location.href.split('/');
      let index = 5;
      this.langswitchService.getLangList().forEach(langObject => {
        if (urlList.indexOf(langObject.lang) !== -1) {
          index = urlList.indexOf(langObject.lang) + 1;
        }
      });


      const urlListStart = urlList.slice(index - 1, index);
      const urlListEnd = urlList.slice(index + 1);
      /* console.log('urlList', urlList, urlListStart, urlListEnd, index); */

      // this.userService.userSubject.next(userInfo);
      // sisestab aktiivse rolli keskkonna sesiooni
      this.userService.setUserSelectedRoleEnvinronment(userInfo.activeEnvinronment, true);

      const langs = this.translateServ.getLangs();
      let currentLang;
      if (langs.indexOf(urlListStart[0]) !== -1) {
        // form url
        currentLang = urlListStart[0];
      } else {
        // default
        currentLang = this.translateServ.getDefaultLang();
      }

      if (urlListEnd.indexOf('minuandmed') > -1) {
        if (userInfo.activeEnvinronment === 'ROLE_USER') {
          const urlOut = '/' + currentLang + '/ik/minuandmed';
          this.router.navigate([urlOut]);
        } else if (userInfo.activeEnvinronment === 'ROLE_ADMIN') {
          const urlOut = '/' + currentLang + '/mk/minuandmed';
          this.router.navigate([urlOut]);
        }
      } else {
        if (userInfo.activeEnvinronment === 'ROLE_USER') {
          const urlOut = '/' + currentLang + '/ik/minu-asjad';
          this.router.navigate([urlOut]);
        } else if (userInfo.activeEnvinronment === 'ROLE_ADMIN') {
          const urlOut = '/' + currentLang + '/mk/toolaud';
          this.router.navigate([urlOut]);
        }
      }

    } else {
      this.getLongMsgError('TODO: lisa tõlge kuid tagastatud kasutaja objekt pole korrektne!');
    }
  }
}
