<div id="mainLangbarId" style="position: inherit;" class="d-flex" placement="bottom"
 tooltip="{{ 'GLOBAL.CHANGE_LANGUAGE' | translate }}"
>
  <!-- min-width: 130px; -->
  <div *ngFor="let langObject of langList" class="pr-0">
        <a (click)="switchLanguage(langObject.lang)" [class.font-weight-bold]="lang==langObject.lang" class="nav-link nav-language px-2"
            href="javascript:void(0);">{{ langObject.descr }}</a>
  </div>
    <!-- <li class="nav-item">
        <a (click)="switchLanguage('en')" [class.font-weight-bold]="lang=='en'" class="nav-link nav-language"
            href="javascript:void(0);">ENG</a>
    </li> -->
  </div>
