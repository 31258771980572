import { Directive, Output, EventEmitter, OnInit, OnDestroy, Input } from '@angular/core';

import { Subscription } from 'rxjs';

import { SortService } from '../_services/sort.service';
/*
  Et töötaks on vaja lisada:
  <table appSortableTable="'unique-table-id'"

  <th sortable-column="propsName">

  Kui soovid default sort siis:
  <th sortable-column="propsName" sort-direction="asc">
*/
@Directive({
  selector: '[appSortableTable]'
})
export class SortableTableDirective implements OnInit, OnDestroy {
  @Input()
  appSortableTable: string;

  constructor(private sortService: SortService) { }

  @Output()
  sorted = new EventEmitter();

  private columnSortedSubscription: Subscription;

  ngOnInit() {
    this.sortService.setTableId(this.appSortableTable);
    this.columnSortedSubscription = this.sortService.columnSorted$.subscribe(event => {
      if ( this.appSortableTable === event.tableId ) {
        this.sorted.emit(event);
      }
    });
  }

  ngOnDestroy() {
    this.columnSortedSubscription.unsubscribe();
  }
}
