import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';

import { Observable, Subject, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_ENDPOINT } from '../../app.constants';

import { Ship, ShipSearch, SmallShipSearch } from '../../_domain';
import { InspectionSearch } from '../../_domain';
import { NotNotification } from 'src/app/_domain/notnotification.interface';
import { timeout, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ShipService {
  constructor(private http: HttpClient) { }

  lastTabSelected: string;

  // Subject for tab changes
  shipTabSelectedSource = new Subject<string>();
  shipTabSelected$ = this.shipTabSelectedSource.asObservable();

  onShipTabSelect(tab: string) {
    this.shipTabSelectedSource.next(tab);
    this.setSelectedTab(tab);
  }

  getSelectedTab() {
    return this.lastTabSelected;
  }

  setSelectedTab(tab: string) {
    this.lastTabSelected = tab;
  }

  getShip(shipId: number, filter: boolean): Observable<Ship> {
    // NB! Here are 2 examples how to pass params to back-end
    // use http params if regular GET params are required by the back-end
    // use just the ID in the URL when needed
    // Current sample uses both just for demo purposes (and because the Fake JSON provider is a REST api);
    let httpParams = new HttpParams();
    httpParams = httpParams.append('filterRelatedPersons', String(filter));
    return this.http
      .get<any>(API_ENDPOINT + 'api/vessel/' + shipId, { params: httpParams })
      .pipe(map((response: any) => response));
  }

  searchShips(formData: ShipSearch) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/vesselsearch', formData) // Õige otsing
      .pipe(map((response: any) => response));
  }

  searchInspection(search: InspectionSearch, env: string) {
    return this.http
      .post(API_ENDPOINT + 'api/' + env + '/inspection/list/', search)
      .pipe(map((response: any) => response));
  }

  /*
    Ship registration
  */

  createDraftIk(typeId: number) {
    let params = new HttpParams();
    params = params.append('appType', typeId.toString());

    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/create/ik', params)
      .pipe(map((response: HttpResponse<any>) => response));
  }

  vesselActions(vesselId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/vessel/action/' + vesselId)
      .pipe(map((response: any) => response));
  }

  createDraftMk(typeId: number) {
    let params = new HttpParams();
    params = params.append('appType', typeId.toString());

    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/create/mk', params)
      .pipe(map((response: HttpResponse<any>) => response));
  }

  getShipWithOwner(page: number, size: number): Observable<Ship> {
    let params = new HttpParams();
    params = params.append('page', page.toString()).append('size', size.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/getvesselswithowners', params)
      .pipe(map((response: any) => response));
  }

  getShipWithOwnerRegCode(page: number, size: number): Observable<Ship> {
    let params = new HttpParams();
    params = params.append('page', page.toString()).append('size', size.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/getvesselswithownersreg', params)
      .pipe(map((response: any) => response));
  }

  getSmallShipXTee(formData: SmallShipSearch, page: number, size: number): Observable<Ship> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/small-vessel-search/xtee?page=' + page + '&size=' + size, formData)
      .pipe(
        timeout(132000), // 2,2 min
        catchError(e => {
          console.error('XTEE_VLAEV catchError', e);
          return of({ success: false, data: [{ field: 'xtee', message: 'ERROR.XTEE_VLAEV' }] });
        })
      );
  }

  getSmallShipCache(formData: SmallShipSearch, page: number, size: number): Observable<Ship> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/small-vessel-search/cache?page=' + page + '&size=' + size, formData)
      .pipe(
        map((response: any) => response)
      );
  }

  getVesselAllowed(vesselId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/ik/vessel-allowed/' + vesselId)
      .pipe(map((response: any) => response));
  }

  getMkVesselAllowed(shipId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-data/' + shipId)
      .pipe(map((response: any) => response));
  }

  smallVesselSearchInspections(regNr: string) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/small-vessel-inspections', regNr)
      .pipe(map((response: any) => response));
  }

  getVesselApplications(shipId: number, page: number, size: number, sortBy: string, sortDir: string) {
    sortBy = sortBy ? sortBy : '';
    sortDir = sortDir ? sortDir.toString() : '';

    const params = new HttpParams()
      .set('arcived', 'false')
      .set('page', page.toString())
      .set('size', size.toString())
      .set('sort', sortBy + ',' + sortDir);

    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-data/applications/' + shipId, { params })
      .pipe(map((response: any) => response));
  }

  sendNotification(notification: NotNotification) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/notification/write', notification)
      .pipe(map((response: any) => response));
  }
}
