import { Component, OnInit, OnDestroy, Directive } from '@angular/core';
import { AppInjector } from 'src/app/app.injector.service';
import { ToastrService } from 'ngx-toastr';
import { NotifierService } from 'angular-notifier';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

// @Component({
//   selector: 'app-notification',
//   template: ''
// })
@Directive({
  selector: 'app-notification'
})
export class NotificationComponent implements OnInit, OnDestroy {
  protected toastr: ToastrService;
  protected translateServ: TranslateService;
  public subscriptions: Subscription[] = [];

  // Default
  toasterOptions = {
    timeOut: 5000,
    extendedTimeOut: 10000,
    progressBar: true,
    closeButton: true,
    preventDuplicates: true,
    enableHtml: true
  };
  // Sticky
  stikyToasterOptions = {
    timeOut: 0,
    extendedTimeOut: 0,
    closeButton: true,
    preventDuplicates: true,
    enableHtml: true,
    tapToDismiss: false
  };

  constructor() {

    try {
      const injector = AppInjector.getInjector();

      this.toastr = injector.get(ToastrService);
      this.translateServ = injector.get(TranslateService);
    } catch (e) {
      console.error('Failed initializing dependencies', e);
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    // console.log('Base ngOnDestroy');
    this.unsubscribeAllFromList();
  }

  addSubscriptionToList(subscription: any) {
    this.subscriptions.push(subscription);
  }

  unsubscribeAllFromList() {
    this.subscriptions.forEach(subscription => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    });
  }

  /*
    SUURED teated
  */
  // Success
  getLongMsgSuccess(labelText: string, text = '') {
    this.addSubscriptionToList(
      this.translateServ.get(['MESSAGE.SUCCSESS', labelText]).subscribe(labels => {
        const keys = Object.keys(labels);
        const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
          (text !== '' ? ' ' + text : text);
        this.toastr.success(notifyText, labels[keys[0]], this.toasterOptions);
      }));
  }
  // Error
  async getLongMsgError(labelText: any, text = '') {
     this.translateServ.get(['MESSAGE.ERROR', labelText]).subscribe(labels => {
       const keys = Object.keys(labels);
       const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
         (text !== '' ? ' ' + text : text);
      let options = this.toasterOptions
      options.timeOut = 10000
      options.extendedTimeOut = 20000
      this.toastr.error(notifyText, labels[keys[0]], options);
     });
   }

  // Info
  getLongMsgInfo(labelText: string, text = '') {
    this.addSubscriptionToList(
      this.translateServ.get(['MESSAGE.INFO', labelText]).subscribe(labels => {
        const keys = Object.keys(labels);
        const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
          (text !== '' ? ' ' + text : text);
        this.toastr.info(notifyText, labels[keys[0]], this.toasterOptions);
      }));
  }
  // Warning
  getLongMsgWarning(labelText: string, text = '') {
    this.addSubscriptionToList(
      this.translateServ.get(['MESSAGE.WARNING', labelText]).subscribe(labels => {
        const keys = Object.keys(labels);
        const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
          (text !== '' ? ' ' + text : text);
        this.toastr.warning(notifyText, labels[keys[0]], this.toasterOptions);
      }));
  }
  /*
    STICKY MESSAGE
  */
  // Success
  getLongMsgSuccessSticky(labelText: string, text = '') {
    this.addSubscriptionToList(
      this.translateServ.get(['MESSAGE.SUCCSESS', labelText]).subscribe(labels => {
        const keys = Object.keys(labels);
        const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
          (text !== '' ? ' ' + text : text);
        this.toastr.success(notifyText, labels[keys[0]], this.stikyToasterOptions);
      }));
  }
  // Error
  async getLongMsgErrorSticky(labelText: any, text = '') {
    if (labelText !== null && labelText !== undefined) {
      if (labelText.message) {
        labelText = labelText.message;
      }
      const inputLabels = Array.isArray(labelText) ? ['MESSAGE.ERROR', ...labelText] : ['MESSAGE.ERROR', labelText];
      this.addSubscriptionToList(
        this.translateServ.get(inputLabels).subscribe(labels => {
          const keys = Object.keys(labels);
          let labelsText = '';
          keys.forEach((key, i) => {
            if (i !== 0) {
              labelsText += labels[key] + '</br> ';
            }
          });

          const notifyText = labelsText + // (keys.length > 1 ? ' ' + labels[keys[1]] : '') +
            (text !== '' ? ' ' + text : text);
          this.toastr.error(notifyText, labels[keys[0]], this.stikyToasterOptions);
        }));
    } else {
      console.error('Vea label:', labelText);
      const errorLabel1 = await this.translate('MESSAGE.ERROR');
      const errorLabel2 = await this.translate('ERROR.SHOW_ERROR');
      this.toastr.error(errorLabel2, errorLabel1, this.stikyToasterOptions);
    }
  }
  // Info
  getLongMsgInfoSticky(labelText: string, text = '') {
    this.addSubscriptionToList(
      this.translateServ.get(['MESSAGE.INFO', labelText]).subscribe(labels => {
        const keys = Object.keys(labels);
        const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
          (text !== '' ? ' ' + text : text);
        this.toastr.info(notifyText, labels[keys[0]], this.stikyToasterOptions);
      }));
  }
  // Warning
  getLongMsgWarningSticky(labelText: string, text = '') {
    this.addSubscriptionToList(
      this.translateServ.get(['MESSAGE.WARNING', labelText]).subscribe(labels => {
        const keys = Object.keys(labels);
        const notifyText = (keys.length === 2 ? ' ' + labels[keys[1]] : '') +
          (text !== '' ? ' ' + text : text);
        this.toastr.warning(notifyText, labels[keys[0]], this.stikyToasterOptions);
      }));
  }


  async translate(label: string) {
    try {
      const translation = await this.translateServ.get([label]).toPromise();
      return translation[label];
    } catch (error) {
      // console.error('Label error: ', label);
      this.getLongMsgErrorSticky('ERROR.TRANSLATE_ERROR');
    }
  }
}
