<div id="userbarConteinerId" class="row d-block">
  <!--  -->
  <div *ngIf="!this.currentUser" class="navbar-right justify-content-end">
    <div class="nav-item nav-login pr-0" placement="bottom" tooltip="{{ 'GLOBAL.LOGIN' | translate }}">
      <button success (click)="goToLogin()">
        {{ 'GLOBAL.LOGIN' | translate }}
      </button>
    </div>
  </div>
  <div id="userbarMenuId" *ngIf="currentUser" class="h-100 pt-2 col row mx-0 px-0 d-flex justify-content-end">
    <div class="col-12 row mx-0 px-0 d-flex justify-content-end">
      <!-- LANG -->
      <div style="min-width: 80px;" class="v-align  px-0">
        <!-- nav-item -->
        <app-langbar class="align-middle"></app-langbar>
      </div>
      <!-- USER DETAILS -->
      <div  style="min-width: 42px;" class="d-inline-block v-align px-1"><!-- *ngIf="showPersonalDataHeader" -->
        <!-- nav-item -->
        <span>
          <button info [style]="'square'" type="button" custom-class="align-middle" [routerLink]="['/', lang, env, 'minuandmed']" [icon]="'c-user'" title="" placement="bottom" tooltip="{{ 'MAINNAV.MY_DATA' | translate }}"></button>
        </span>
      </div>
      <!-- NOTIFICATIONS AREA -->
      <div  class="d-inline-flex align-items-center ml-1 mr-2"><!-- *ngIf="showPersonalDataHeader" -->
        <div style="cursor: pointer;" placement="bottom" tooltip="{{ 'NOTIFICATION.BUTTON_TITLE' | translate }}" class="text-center position-relative hasPopover" [popover]="notificationsPopOver" [outsideClick]="true">
          <span class="totalNotificationsCount"> {{ unreadNotificationsCount }} </span>
          <img src="assets/assets/images/notificationsIcon.svg">
        </div>
      </div>
      <!-- NOTIFICATIONS AREA -->
      <!-- LOGOUT -->
      <div class="d-inline v-align align-middle pl-2" placement="bottom" tooltip="{{ 'GLOBAL.LOGOUT' | translate }}" title="{{ 'GLOBAL.LOGOUT' | translate }}">
        <!-- nav-item nav-logout -->
        <a id="logOutId" class="p-0 align-middle" (click)="logout()">
          <img src="assets/assets/images/logOut.svg" alt="" />
        </a>
      </div>
    </div>
    <!-- USER ROLE -->
    <div id="curUserId" class="col curUser  px-0 py-2 v-align hasPopover align-middle " title="" data-toggle="popover" container-class="userModePopover raised" data-placement="bottom" data-html="#popover-client-select">
      <div class="inner d-flex flex-row justify-content-end">
        <!-- popover -->
        <div id="showChangeRoleDivId" placement="bottom" tooltip="{{ 'USERBAR.CHANGE_ROLE_TOOLTIP' | translate }}" style="width: fit-content;white-space: -moz-pre-space; width: -moz-min-content;" class="flex-row justify-content-end mr-4"
          [popover]="roleChangeTpl" [outsideClick]="true">
          <div class="representer">
            <div *ngIf="!isRoleUser" class="nav-user-name text-nowrap">{{ currentUser.firstName }}
              {{ currentUser.name }}
            </div>
          </div>
          <img *ngIf="!isRoleUser" src="assets/assets/images/arrowRightBlue.svg" alt="" class="userRepSeparator ml-2 mr-3" />
          <div *ngIf="activeRoleTitle" class="represented">
            <div class="font-weight-bold">{{ activeRoleTitle }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #roleChangeTpl>
  <app-user-change-role></app-user-change-role>
</ng-template>
<ng-template #notificationsPopOver>
  <app-user-notifications (notificationsToRead)="setNotificationsToRead($event)" [latestNotifications]="latestNotifications" [lang]="lang" [env]="env">
  </app-user-notifications>
</ng-template>
