<div class="form-group row pb-0">
  <label class="col-md-7 col-form-label">{{ 'SMALLSHIPDATA.' + label1 | translate }}:</label>
  <div class="col-sm-3 col-form-label">
    {{ val1 }}
  </div>
  <label *ngIf="label2 !== ''" class="col-md-7 col-form-label">{{ 'SMALLSHIPDATA.' + label2 | translate }}:</label>
  <div *ngIf="label2 !== ''" class="col-md-3 col-form-label">
    {{ val2 }}
  </div>
</div>


