import { environment } from '../environments/environment';

/**
 * GLOBAL APPLICATION CONSTANTS
 */
export const API_ENDPOINT = environment.apiEndpoint;
export const DEBUG_MODE = environment.debugMode;

/**
 * FAKE login logic
 */
// export const API_USERINFO_PATH = '';

/**
 * UI constants
 * TODO: needs to be implemented inside templates
 */
export const LIS_IMG_PATH = 'assets/assets/images';

/**
 * LANGUAGE SETTING constants
 * et = Estonian, en = English
 */

export const LANGUAGE_OPTIONS = ['et', 'en'];
export const LANGUAGE_OPTIONS_DEFAULT = 'et';
