export enum PersonType {
  F = 'F',
  J = 'J'
}

export enum Language {
  ET = 'ET',
  EN = 'EN'
}

export enum Citizenship {
  EST = 'EST',
  FGN = 'FGN'
}

export enum AutRole {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER'
}

export enum OwnershipType {
  OWNER = '24001',
  AGENT = '24005',
  OPERATOR = '24003',
  APPLICANT = '24011',
  SHIPHUSBAND = '24004',
  STATE_PROPERTY_MANAGER = '24007',
  AUTHORIZED_BODY = '24008',
  USER = '24006'
}

export enum HusbandDocs {
  PRAHTLUBA = 'PRAHTLUBA',
  PRAHTLEPING = 'PRAHTLEPING'
}

export enum ContactType {
  AADRESS = 'AADRESS',
  EMAIL = 'EMAIL'
}

export enum DocTypes {
  KLASSITUNNISTUS = 'KLASSITUNNISTUS',
  RAADIOLUBA = 'RAADIOLUBA',
  MOODUKIRI = 'MOODUKIRI',
  PRAHTLUBA = 'PRAHTLUBA',
  PRAHTLEPING = 'PRAHTLEPING'
}
