import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appAlphabetOnly]'
})
export class AlphabetOnlyDirective {
  key: string;
  //   take only alphabet as input
  @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
    this.key = event.key;
    if (this.key && !this.key.match('^[a-zA-Z()]+$')) {
      event.preventDefault();
    }
  }
}
