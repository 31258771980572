import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { IsothreePipe } from '../_pipes/isothree.pipe';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_ENDPOINT } from '../../app.constants';
import {
  DocDocument,
  DocEditTemplate,
  DocSignDTO,
  DocTemplate,
  NewDocTemplate
} from 'src/app/_domain/docdocument.interface';
import { DatePipe } from '@angular/common';

import { CompactDocument } from 'src/app/_domain/docdocument.interface';
import { SortEvent } from 'src/app/_domain/sortevent.interface';
import { DocTypes } from '../../inspection-requirements/inspection-requirements.model';
import { Ship } from 'src/app/_domain';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private $docTypes = new BehaviorSubject<any>([]);
  readonly docTypes = this.$docTypes.asObservable();

  constructor(private http: HttpClient, private isothree: IsothreePipe) {}

  saveDocument(document: DocDocument, file: File): Observable<any> {
    const formData = new FormData();
    Object.keys(document).forEach(key => {
      if (document[key]) {
        // LAB TODO ei tundu eriti hea lahendus
        if (document[key] instanceof Date) {
          formData.set(key, new DatePipe('et_EE').transform(document[key], 'yyyy-MM-dd'));
        } else {
          formData.set(key, document[key]);
        }
      }
    });
    if (file) {
      formData.set('file', file);
    }
    return this.http
      .post<any>(API_ENDPOINT + 'api/document/save', formData)
      .pipe(map((response: any) => response));
  }

  createPdf(document: DocDocument, file: File): Observable<any> {
    const formData = new FormData();
    Object.keys(document).forEach(key => {
      if (document[key]) {
        // LAB TODO ei tundu eriti hea lahendus
        if (document[key] instanceof Date) {
          formData.set(key, new DatePipe('et_EE').transform(document[key], 'yyyy-MM-dd'));
        } else {
          formData.set(key, document[key]);
        }
      }
    });
    if (file) {
      formData.set('file', file);
    }
    return this.http
      .post<any>(API_ENDPOINT + 'api/document/create-pdf', formData)
      .pipe(map((response: any) => response));
  }

  archive(document: DocDocument, file: File): Observable<any> {
    const formData = new FormData();
    Object.keys(document).forEach(key => {
      if (document[key]) {
        if (document[key] instanceof Date) {
          formData.set(key, new DatePipe('et_EE').transform(document[key], 'yyyy-MM-dd'));
        } else {
          formData.set(key, document[key]);
        }
      }
    });
    if (file) {
      formData.set('file', file);
    }
    return this.http
      .post<any>(API_ENDPOINT + 'api/document/archive', formData)
      .pipe(map((response: any) => response));
  }

  saveCompact(document: { docType: number }) {
    const params = new HttpParams().set('docType', document.docType.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/document/save/compact', params)
      .pipe(map((response: any) => response));
  }

  getDocTypesByGroupCode(groupCode: number): Observable<any> {
    const params = new HttpParams().set('groupCode', groupCode.toString(10));
    return this.http.get<DocTypes>(API_ENDPOINT + 'api/document/types/list', { params });
  }

  getAllowedExtentions() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/allowed-extentions')
      .pipe(map((response: any) => response));
  }

  getDocTypes(context: string): Observable<any> {
    return this.http.get<any>(API_ENDPOINT + 'api/document/types/' + context).pipe(
      map((response: any) => {
        return response;
      })
    );
    /* const runFunction = () => {
      return this.http
        .get<any>(API_ENDPOINT + 'api/document/types/' + context)
        .pipe(map((response: any) => {
          // save in subject
          this.$docTypes.next(Object.assign({}, response));
          return response;
        }));
    }; */
    /*
      singelton
    */
    /* let obs = null;
    this.$docTypes.asObservable().subscribe(savedResponse => {
      if (savedResponse &&
        savedResponse.data &&
        savedResponse.data.docTypes &&
        savedResponse.data.docTypes.length > 0) {
        obs = this.$docTypes.asObservable();
      } else {
        obs = runFunction();
      }
    },
      error => {
        obs = runFunction();
      });
    return obs; */
  }

  getAppVesselDocuments(vesselId: number, lang: string) {
    const params = new HttpParams().set('language', this.isothree.transform(lang));
    return this.http
      .get<any>(API_ENDPOINT + 'api/application/ship-documents/' + vesselId, { params })
      .pipe(map((response: any) => response));
  }

  getInContext(versionId: number, context: string): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/find-in-context/' + versionId + '/' + context)
      .pipe(map((response: any) => response));
  }

  getDocForms(docType: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/forms/' + docType)
      .pipe(map((response: any) => response));
  }

  getIssuers(): Observable<any> {
    return this.http.get<any>(API_ENDPOINT + 'api/document/issuers').pipe(map((response: any) => response));
  }

  getDocuments(appId: number, appVersionId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/list/' + appId + '/' + appVersionId)
      .pipe(map((response: any) => response));
  }

  getUserDocuments(appVersionId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/ik/list-documents/' + appVersionId)
      .pipe(map((response: any) => response));
  }

  getAplDocuments(appVersionId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/mk/list-documents/' + appVersionId)
      .pipe(map((response: any) => response));
  }

  getAplDocument(versionId: number, docDocumentId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/apl-document/' + versionId + '/' + docDocumentId)
      .pipe(map((response: any) => response));
  }

  getDocument(docDocumentId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/load/' + docDocumentId)
      .pipe(map((response: any) => response));
  }

  getDocumentView(docDocumentId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/view/' + docDocumentId)
      .pipe(map((response: any) => response));
  }

  downloadDocument(docDocumentId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/download/' + docDocumentId)
      .pipe(map((response: any) => response));
  }

  saveCompactDocument(document: CompactDocument, file: File) {
    const formData = new FormData();
    Object.keys(document).forEach(key => {
      if (document[key]) {
        if (document[key] instanceof Date) {
          formData.set(key, new DatePipe('et_EE').transform(document[key], 'yyyy-MM-dd'));
        } else {
          formData.set(key, document[key]);
        }
      }
    });
    if (file) {
      formData.set('file', file);
    }
    return this.http
      .post(API_ENDPOINT + 'api/document/save/compact', formData)
      .pipe(map((response: any) => response));
  }

  deleteCompactDocument(docId: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/document/delete/' + docId)
      .pipe(map((response: any) => response));
  }

  unlinkDocument(versionId: number, docId: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/boat-application/unlink-document/' + versionId + '/' + docId)
      .pipe(map((response: any) => response));
  }

  linkMultipleAplDocs(versionId, documentIds): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/link-documents/' + versionId, documentIds)
      .pipe(map((response: any) => response));
  }

  restoreDocument(versionId: number, docId: number) {
    return this.http
      .put<any>(API_ENDPOINT + 'api/boat-application/restore-document/' + versionId + '/' + docId, docId)
      .pipe(map((response: any) => response));
  }

  linkVesselDocument(vesselId: number, documentId: number) {
    const params = new HttpParams()
      .set('vesselId', vesselId.toString())
      .set('documentId', documentId.toString());

    return this.http
      .post<any>(API_ENDPOINT + 'api/vessel-document/add', params)
      .pipe(map((response: any) => response));
  }

  getVesselDocumentsByGroup(
    env: string,
    vesselId: number,
    code: number,
    page: number,
    size: number,
    archived: boolean,
    sort: Array<SortEvent>
  ) {
    let params = new HttpParams()
      .set('archived', archived.toString())
      .set('page', page.toString())
      .set('size', size.toString());
    sort.forEach(s => {
      params = params.append('sort', s.sortColumn + ',' + s.sortDirection);
    });
    return this.http
      .get<any>(API_ENDPOINT + 'api/vessel-document/' + env + '/list/' + vesselId + '/' + code, { params })
      .pipe(map((response: any) => response));
  }

  getDoctypeGroups() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/doctypegroups')
      .pipe(map((response: any) => response));
  }

  getIsVesselOwner(vesselId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-data/is-owner/' + vesselId)
      .pipe(map((response: any) => response));
  }

  deleteVesselDocument(vesselId: number, docId: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/vessel-document/' + vesselId + '/' + docId)
      .pipe(map((response: any) => response));
  }

  getDocumentNumber(docType: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/number/' + docType)
      .pipe(map((response: any) => response));
  }

  generatePreview(document: DocDocument, type: string): Observable<any> {
    Object.keys(document).forEach(key => {
      if (document[key]) {
        if (document[key] instanceof Date) {
          document[key] = new DatePipe('et_EE').transform(document[key], 'yyyy-MM-dd');
        }
      }
    });
    return this.http.post<any>(API_ENDPOINT + 'api/document/generate/' + type + '/preview', document, {
      responseType: 'blob' as 'json'
    });
  }

  generateDocument(document: DocDocument, type: string) {
    Object.keys(document).forEach(key => {
      if (document[key]) {
        if (document[key] instanceof Date) {
          document[key] = new DatePipe('et_EE').transform(document[key], 'yyyy-MM-dd');
        }
      }
    });
    return this.http.post<any>(API_ENDPOINT + 'api/document/generate/' + type + '/save', document);
  }

  restoreArchivedDocument(docId: number) {
    return this.http
      .put<any>(API_ENDPOINT + 'api/document/restore/' + docId, docId)
      .pipe(map((response: any) => response));
  }

  getFlagDocuments(vesselId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/vessel-document/flag-docs/' + vesselId)
      .pipe(map((response: any) => response));
  }

  deleteCerts(versionId: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/boat-application/delete-certs/' + versionId)
      .pipe(map((response: any) => response));
  }

  getDocTemplates(page: any, size: any, sortConfig: SortEvent) {
    const params = new HttpParams()
      .set('page', page.toString())
      .set('size', size.toString())
      .set('sort', sortConfig.sortColumn + ',' + sortConfig.sortDirection);
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/template/table', { params })
      .pipe(map((response: any) => response));
  }

  saveDocTemplate(docTemp: DocTemplate, file: File) {
    const formData = new FormData();
    Object.keys(docTemp).forEach(key => {
      if (docTemp[key]) {
        formData.set(key, docTemp[key]);
      }
    });
    if (file) {
      formData.set('file', file);
    }

    return this.http
      .post(API_ENDPOINT + 'api/document/template/save', formData)
      .pipe(map((response: any) => response));
  }

  editDocTemplate(docEditTemp: DocEditTemplate) {
    return this.http
      .put(API_ENDPOINT + 'api/document/template/edit', docEditTemp)
      .pipe(map((response: any) => response));
  }
  saveNewDocTemplate(newDocTemplate: NewDocTemplate) {
    return this.http
      .post(API_ENDPOINT + 'api/document/template/new', newDocTemplate)
      .pipe(map((response: any) => response));
  }
  // for templace preview
  saveDocForTemp(docDetails: any) {
    const document = {
      contextObjectId: docDetails.contextObjectId,
      docForm: docDetails.docForm,
      docType: docDetails.docType
    };
    return this.http.post<any>(
      API_ENDPOINT + 'api/document/generate/' + docDetails.contextName + '/save',
      document
    );
  }

  downloadSignature(docDocumentId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/document/download-signature/' + docDocumentId)
      .pipe(map((response: any) => response));
  }

  signDocument(docSignDTO: DocSignDTO): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/document/sign/', docSignDTO)
      .pipe(map((response: any) => response));
  }

  exportVesselRegistryData(ship: Ship, type: string, language: string) {
    let docForm = 0;
    if (type == 'active' && language == 'et') {
      docForm = ship.registry == 1003 ? 600065 : 600061;
    }
    if (type == 'active' && language == 'en') {
      docForm = ship.registry == 1003 ? 600066 : 600062;
    }
    if (type == 'historical' && language == 'et') {
      docForm = ship.registry == 1003 ? 600067 : 600063;
    }
    if (type == 'historical' && language == 'en') {
      docForm = ship.registry == 1003 ? 600068 : 600064;
    }
    const document = {
      contextObjectId: ship.id,
      docForm: docForm,
      docType: 99980
    };

    return this.http.post<any>(API_ENDPOINT + `api/document/generate/report/${type}/${language}`, document, {
      responseType: 'blob' as 'json'
    });
  }
}
