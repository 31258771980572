<footer class="row py-5">
  <div class="row inner container px-0 mt-2 pr-0">
    <div class="col-md-auto col-sm-20 image">
      <img src="assets/assets/images/logos/emkf.jpg" class="px-0 left" alt="EMKF rakenduskava 2014-2020" />
    </div>
    <div class="col-md-auto col-sm-20 mt-md-0 mt-sm-2 pr-2">
      <p>
        <strong class="mb-2">{{ 'FOOTER.EMERGENCY_PHONE' | translate }}</strong
        >: (+372) 620 5665<br />
      </p>
      <p>
        <strong class="mb-2">{{ 'FOOTER.EMAIL' | translate }}</strong
        >:
        <a href="mailto:ljo@transpordiamet.ee">ljo@transpordiamet.ee</a>
      </p>
    </div>
    <div class="col-md-auto col-sm-20 mt-md-0 mt-sm-2 pr-2">
      <p><a href="https://www.transpordiamet.ee/" target="_blank">Transpordiamet</a><br /></p>
      <p>
        <a
          href="https://transpordiamet.ee/ohutus-ja-jarelevalve/jarelevalve/laevade-ning-vaike-ja-siseveelaevade-jarelevalve" target="_blank"
          >Laevade järelevalve</a
        ><br />
      </p>
    </div>
    <div class="col-md-auto col-sm-20 mt-md-0 mt-sm-2 pr-2">
      <p><a href="https://transpordiamet.ee/estonian-flag" target="_blank">Laevad Eesti lipu alla</a><br /></p>
      <p><a href="https://meremees.transpordiamet.ee" target="_blank">Meremeeste infosüsteem</a><br /></p>
    </div>
  </div>
  <!-- <div class="col-20" style="text-align: center">
    <app-loading-spinner *ngIf="isLoading"></app-loading-spinner>
    <p *ngIf="!isLoading && version !== undefined && version !== null">
      {{ version?.version }} / {{ version?.buildTime }} / {{ version?.branch }} /
      <a href="https://tarkvara.datel.ee/bitbucket/projects/LIS/repos/laevis/commits/{{ version?.commit }}">{{
        version?.commit
      }}</a>
    </p>
  </div> -->
</footer>
