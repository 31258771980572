import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {API_ENDPOINT} from '../../app.constants';
import {AuthorityDetails} from 'src/app/_domain/authority.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {
  constructor(private http: HttpClient) {
  }

  searchAuthorities(parms: string) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/authorisation/search?' + parms)
      .pipe(map((response: any) => response));
  }

  getAuthoritiesDetails(id: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/authorisation/' + id);
  }

  getAuthoritiesVesselsByMandator(id: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/authorisation/vessel-by-mandator/' + id);
  }

  getAuthoritiesVesselsByRegisterCode(registerCode: string, countryId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/authorisation/vessel-by-register-code/' + registerCode + "/" + countryId);
  }

  getInspectorsListByPrivilegeCode(privilegeCode: string) {
    return this.http.get(API_ENDPOINT + 'api/authorisation/inspectors-list/' + privilegeCode)
      .pipe(map((response: any) => response));
  }

  validateAuthorities(formData: AuthorityDetails) {
    const outputData = {
      documentId: formData.documentId,
      mandatorId: formData.mandatorId,
      representorId: formData.representorId,

      unrestricted: formData.unrestricted === true,
      rightActivities: formData.unrestricted === false ? formData.rightActivities : false,
      rightAuthorisation: formData.unrestricted === false ? formData.rightAuthorisation : false,
      rightNewvessel: formData.unrestricted === false ? formData.rightNewvessel : false,
      rightSignature: formData.unrestricted === false ? formData.rightSignature : false,

      termless: formData.validTo === null,
      validTo: formData.validTo ? formData.validTo : null
    };
    return this.http.post<any>(API_ENDPOINT + 'api/authorisation/validate', outputData);
  }

  kinnitaAuthorities(formData: AuthorityDetails) {
    const outputData = {
      documentId: formData.documentId,
      mandatorId: formData.mandatorId,
      representorId: formData.representorId,

      unrestricted: formData.unrestricted === true,
      rightActivities: formData.unrestricted === false ? formData.rightActivities : false,
      rightAuthorisation: formData.unrestricted === false ? formData.rightAuthorisation : false,
      rightNewvessel: formData.unrestricted === false ? formData.rightNewvessel : false,
      rightSignature: formData.unrestricted === false ? formData.rightSignature : false,


      termless: formData.validTo === null,
      validTo: formData.validTo !== null ? formData.validTo : null,
      vesselIds: formData.vesselIds
    };
    return this.http.post<any>(API_ENDPOINT + 'api/authorisation/save', outputData);
  }

  cancelAuthorities(volitusId: number, deletionReason: string) {
    const outputData = {
      deletionReason,
      id: volitusId
    };
    return this.http.post<any>(API_ENDPOINT + 'api/authorisation/cancel', outputData);
  }
}
