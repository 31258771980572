<div class="z-0 d-flex flex-column m-0">
  <header class="position-relative">
    <nav class="navbar navbar-expand-lg navbar-dark static-top row flex-column mx-0">
      <div class="col-20 mt-0 px-0">
        <!--  -->
        <div id="menu-container" class="container-fluid ">
          <nav class="upperMenu container px-0 mt-0 d-flex justify-content-start h-100" id="menu-upper">
            <div class="container pl-0">
              <div class="row h-100">
                <div class="col-sm d-flex align-items-center">
                  <a class="mainLogoContainer pl-0" href="./">
                    <img src="assets/assets/images/logos/Transpordiamet_EST.svg" alt="" />
                  </a>
                </div>
                <div class="col-sm ">
                  <app-userbar class="navbar-right h-100 d-flex justify-content-end"></app-userbar>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
      <div class="col-20 lowerMenu pl-0">
        <app-mainnav></app-mainnav>
      </div>
    </nav>
  </header>
</div>
