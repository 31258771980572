import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    /**
     * TODO: Add real logic!
     * This is just a sample of how to restrict access to certain pages/components/modules
     */
    const isEverythingOk = true; // TODO: handle your specific ACL logic here

    if (isEverythingOk) {
      return true;
    } else {
      return false;
    }
  }
}
