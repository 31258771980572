import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, Subject, interval } from 'rxjs';
import { map, switchMap, takeWhile } from 'rxjs/operators';

import { API_ENDPOINT } from '../../app.constants';
import {
  AplRequest,
  AplSearch,
  AppVessel,
  AplStatusChangeDTO,
  AppEngine,
  CheckAppDTO,
  CallSigns,
  AppProceederChangeDTO,
  AplActualizeDTO,
  ApplicationCreateDTO,
  AutPerson,
  Country,
  PersonSearchFilter,
  AppOwnership,
  BaseVesselInspectionWebDTO,
  AppTechnical,
  Ship,
  ApplicationSignDTO,
  ApplicationRemoteSignDTO,
  ApplicationRemoteSignFinalizeDTO,
  AppCoordinatorChangeDTO
} from '../../_domain';
import { AplComment } from 'src/app/_domain/apl-comment.interface';
import { DatePipe } from '@angular/common';
import { PersonType } from '../../_enumeration/enums';
import { InspectionSubmit } from 'src/app/_domain/inspection.submit';

@Injectable({
  providedIn: 'root'
})
export class AplService {
  constructor(private http: HttpClient) { }
  public refreshAplSubject = new Subject<any>(); // star refreshing
  public refresedAplSubject = new Subject<any>(); // new data available
  public getAplSubject = new Subject<any>();

  // LAB Subject for tab changes as in
  // https://angular.io/guide/component-interaction#parent-and-children-communicate-via-a-service
  aplTabSelectedSource = new Subject<string>();
  aplTabSelected$ = this.aplTabSelectedSource.asObservable();
  public currentTab: string;

  currentShip: Ship;

  onAplTabSelect(tab: string) {
    this.currentTab = tab;
    this.aplTabSelectedSource.next(tab);
  }
  // END LAB

  searchApl(aplSearch: AplSearch): Observable<any> {
    const aplSearchObject = {};
    Object.keys(aplSearch).forEach(key => {
      if (aplSearch[key]) {
        if (aplSearch[key] instanceof Date) {
          aplSearchObject[key] = new DatePipe('et_EE').transform(aplSearch[key], 'yyyy-MM-dd');
        } else {
          aplSearchObject[key] = aplSearch[key];
        }
      }
    });
    return this.http
      .post<any>(API_ENDPOINT + 'api/appsearch', aplSearchObject)
      .pipe(map((response: any) => response));
  }

  saveApl(formData: AplRequest, env: string): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/edit/' + env, formData)
      .pipe(map((response: any) => response));
  }

  downloadSignature(appVersionId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/application/download-signature/' + appVersionId)
      .pipe(map((response: any) => response));
  }

  acceptCallSign(callSignOrderId: number, versionId: number): Observable<any> {
    return this.http
      .post<any>(
        API_ENDPOINT +
        'api/boat-application/accept-call-sign?callSignOrderId=' +
        callSignOrderId +
        '&versionId=' +
        versionId,
        { callSignOrderId, versionId }
      )
      .pipe(map((response: any) => response));
  }

  releaseCallSign(versionId: number): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/release-call-sign?versionId=' + versionId, {
        versionId
      })
      .pipe(map((response: any) => response));
  }

  checkApl(checkAppDTO: CheckAppDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/check', checkAppDTO)
      .pipe(map((response: any) => response));
  }

  checkInspection(checkAppDTO: CheckAppDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/check-inspection', checkAppDTO)
      .pipe(map((response: any) => response));
  }

  statusChange(aplStatusChangeDTO: AplStatusChangeDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/change', aplStatusChangeDTO)
      .pipe(map((response: any) => response));
  }

  endProc(aplActualizeDTO: AplActualizeDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/endproc', aplActualizeDTO)
      .pipe(map((response: any) => response));
  }

  createApplication(applicationCreateDTO: ApplicationCreateDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/application/create', applicationCreateDTO)
      .pipe(map((response: any) => response));
  }

  createInspection(dto: BaseVesselInspectionWebDTO, isAudit: boolean) {
    if (isAudit) {
      return this.http
        .post<any>(API_ENDPOINT + 'api/boat-application/audit/save', dto)
        .pipe(map((response: any) => response));
    } else {
      return this.http
        .post<any>(API_ENDPOINT + 'api/boat-application/inspection/save', dto)
        .pipe(map((response: any) => response));
    }
  }

  // create recreational ship inspection application
  createSmBoatInspection(appId: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/smboat-application/inspection/create/' + appId, {})
      .pipe(map((response: any) => response));
  }

  isHalfInspections(appId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/application/inspections-check/' + appId)
      .pipe(map((response: any) => response));
  }

  actualize(aplActualizeDTO: AplActualizeDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/actualize', aplActualizeDTO)
      .pipe(map((response: any) => response));
  }

  changeProceeder(appProceederChangeDTO: AppProceederChangeDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/changeproceeder', appProceederChangeDTO)
      .pipe(map((response: any) => response));
  }

  saveStateOwnershipApl(applicationId: number, versionId: number): Observable<any> {
    const outData = {
      applicationId,
      versionId
    };
    return this.http.post<any>(API_ENDPOINT + 'api/boat-application/state-ownership/save', outData);
  }

  aplCreate(aplCreateDTO: ApplicationCreateDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/application/create', aplCreateDTO)
      .pipe(map((response: any) => response));
  }

  getProceeders() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/application/proceeders')
      .pipe(map((response: any) => response));
  }

  searchProceederApplications(
    proceederId: number,
    aplPage: number,
    aplSize: number,
    sortedBy: string,
    sortedDir: string
  ): Observable<any> {
    const aplSearch = {
      page: aplPage,
      proceeder_id: proceederId,
      size: aplSize,
      sortBy: sortedBy,
      sortDir: sortedDir
    } as AplSearch;
    return this.http
      .post<any>(API_ENDPOINT + 'api/applicant_appsearch', aplSearch)
      .pipe(map((response: any) => response));
  }

  addAppEngine(engine: AppEngine): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/engine/add', engine)
      .pipe(map((response: any) => response));
  }

  deleteAppEngine(engine: AppEngine): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/engine/delete', engine)
      .pipe(map((response: any) => response));
  }

  searchWaitingApplications(
    appTypesByUserTypes: Array<number>,
    pageNr: number,
    sizeNr: number,
    sortedBy: string,
    sortedDir: string
  ): Observable<any> {
    const aplSearch = {
      app_type: appTypesByUserTypes,
      page: pageNr,
      size: sizeNr,
      status: [20011],
      sortBy: sortedBy,
      sortDir: sortedDir
    } as AplSearch;
    return this.http
      .post<any>(API_ENDPOINT + 'api/appsearch', aplSearch)
      .pipe(map((response: any) => response));
  }

  searchUserApplications(
    applicantId: number,
    pageNr: number,
    sizeNr: number,
    sortedBy: string,
    sortedDir: string,
    srch: AplSearch
  ): Observable<any> {
    const aplSearch = {
      applicant_search_str: null,
      page: pageNr,
      applicant_id: applicantId,
      size: sizeNr,
      sortBy: sortedBy,
      sortDir: sortedDir,
      registration_number: srch.registration_number,
      app_type: srch.app_type,
      vessel_search_str: srch.vessel_search_str,
      status: srch.status,
      date_from: srch.date_from,
      date_to: srch.date_to
    } as AplSearch;
    return this.http
      .post<any>(API_ENDPOINT + 'api/appsearch', aplSearch)
      .pipe(map((response: any) => response));
  }

  searchProceeder(searchstr: string): Observable<any> {
    // console.log(API_ENDPOINT);
    return this.http
      .get<any>(API_ENDPOINT + 'api/offrole/' + searchstr)
      .pipe(map((response: any) => response));
  }

  searchActiveAplByApplicantId(
    applicantId: number,
    pageNr: number,
    sizeNr: number,
    sortedBy: string,
    sortedDir: string
  ): Observable<any> {
    const aplSearch = {
      applicant_search_str: null,
      statusExclude: [20009, 20091, 20092],
      page: pageNr,
      applicant_id: applicantId,
      size: sizeNr,
      sortBy: sortedBy,
      sortDir: sortedDir
    } as AplSearch;
    return this.http
      .post<any>(API_ENDPOINT + 'api/appsearch', aplSearch)
      .pipe(map((response: any) => response));
  }

  isAllowedToEditApl(applicationId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/application/isEditMode/' + applicationId)
      .pipe(map((response: any) => response));
  }

  sendToTtja(versionId: number): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/application/sendToTtja/' + versionId, {})
      .pipe(map((response: any) => response));
  }

  getPrceederLog(applicationId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/application_log/' + applicationId)
      .pipe(map((response: any) => response));
  }

  getAddresses(input: string): Observable<string[]> {
    if (input) {
      input = input.replace(/ +/g, '_'); // REPLACES SPACES
      //  console.log(API_ENDPOINT + 'api/address/' + input);
      return this.http
        .get<Array<string>>(API_ENDPOINT + 'api/addresswithoid/' + input)
        .pipe(map((response: any) => response));
    } else {
      return new Observable(observer => {
        observer.next([]);
      });
    }
  }

  getApl(aplId: number, env: string): Observable<any> {
    let httpParams = new HttpParams();

    httpParams = httpParams.append('id', aplId.toString());

    return this.http
      .get<any>(API_ENDPOINT + 'api/boat-application/load/' + aplId + '/' + env)
      .pipe(map((response: any) => response));
  }

  linkAplDoc(versionId, documentId): Observable<any> {
    const params = new HttpParams()
      .set('versionId', versionId.toString())
      .set('documentId', documentId.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/link-document/', params)
      .pipe(map((response: any) => response));
  }

  linkUserDoc(versionId, documentId): Observable<any> {
    const params = new HttpParams()
      .set('versionId', versionId.toString())
      .set('documentId', documentId.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/ik/link-document/', params)
      .pipe(map((response: any) => response));
  }

  linkOfficialDoc(versionId, documentId): Observable<any> {
    const params = new HttpParams()
      .set('versionId', versionId.toString())
      .set('documentId', documentId.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/mk/link-document/', params)
      .pipe(map((response: any) => response));
  }

  /*
    APL COMMENTS
  */
  getAplComments(aplId: number, versionId: number): Observable<AplRequest> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/comment/' + aplId + '/' + versionId)
      .pipe(map((response: any) => response));
  }

  getCallSigns(): Observable<CallSigns> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/boat-application/call-signs')
      .pipe(map((response: CallSigns) => response));
  }

  deleteAplComment(id: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/comment/delete/' + id)
      .pipe(map((response: any) => response));
  }

  saveComment(comment: AplComment): Observable<any> {
    const saveDataProps = ['id', 'applicationId', 'versionId', 'commentText', 'commentId'];
    const aplCommentSearch = {};
    saveDataProps.forEach((propName: string) => {
      if (comment[propName] !== null) {
        aplCommentSearch[propName] = comment[propName];
      }
    });

    return this.http
      .post<any>(API_ENDPOINT + 'api/comment/save', aplCommentSearch)
      .pipe(map((response: any) => response));
  }

  searchBuilders(searchstr: string): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/search/builders?searchString=' + searchstr)
      .pipe(map((response: any) => response));
  }

  searchBuilderName(builderId: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/search/builder/' + builderId)
      .pipe(map((response: any) => response));
  }

  checkAndSaveUniqueVesselName(versionId: number, vesselName: string): Observable<any> {
    // console.log('checkVesselName', checkVesselName);
    const params = new HttpParams()
      .set('versionId', versionId.toString())
      .set('vesselName', vesselName.toString());

    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/accept-vessel-name', params)
      .pipe(map((response: any) => response));
  }

  releaseVesselName(versionId: number) {
    const params = new HttpParams().set('versionId', versionId.toString());
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/release-vessel-name', params)
      .pipe(map((response: any) => response));
  }

  getAutPerson(registryCode: string): Observable<AutPerson> {
    return this.http
      .get<AutPerson>(`${API_ENDPOINT + 'api/user/get'}/${registryCode}`)
      .pipe(map((response: AutPerson) => response));
  }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(API_ENDPOINT + 'api/classifier/country');
  }

  getPersonByCountryAndCodeAndType(
    countryId: number,
    regcode: number,
    personType: PersonType
  ): Observable<AutPerson[]> {
    return this.http
      .get<AutPerson[]>(
        `${API_ENDPOINT +
        'api/user/findByCountryAndCodeAndType/' +
        countryId +
        '/' +
        regcode +
        '/' +
        personType}`
      )
      .pipe(map((response: AutPerson[]) => response));
  }

  getForeignPersonByCode(
    countryId: number,
    searchStr: string,
    personType: PersonType
  ): Observable<AutPerson[]> {
    const requestPayload = {
      countryId: String(countryId),
      searchStr,
      personType
    } as PersonSearchFilter;
    return this.http
      .post<AutPerson[]>(`${API_ENDPOINT + 'api/user/findByCountryIdAndCode'}`, requestPayload)
      .pipe(map((response: AutPerson[]) => response));
  }

  getForeignPersonByName(
    countryId: number,
    searchStr: string,
    personType: PersonType
  ): Observable<AutPerson[]> {
    const requestPayload = {
      countryId: String(countryId),
      searchStr,
      personType
    } as PersonSearchFilter;
    return this.http
      .post<AutPerson[]>(`${API_ENDPOINT + 'api/user/findByCountryIdAndName'}`, requestPayload)
      .pipe(map((response: AutPerson[]) => response));
  }

  saveOwnership(appOwnership: AppOwnership): Observable<AppOwnership> {
    return this.http
      .put<AppOwnership>(`${API_ENDPOINT + 'api/boat-application/ownership/save'}`, appOwnership)
      .pipe(map((response: AppOwnership) => response));
  }

  updateOwnership(appOwnership: AppOwnership): Observable<AppOwnership> {
    return this.http
      .post<AppOwnership>(`${API_ENDPOINT + 'api/boat-application/ownership/update'}`, appOwnership)
      .pipe(map((response: AppOwnership) => response));
  }

  deleteOwnership(id: number): Observable<void> {
    return this.http
      .delete<void>(`${API_ENDPOINT + 'api/boat-application/ownership/delete'}/${id}`)
      .pipe(map((response: void) => response));
  }

  getOwnerships(applicationId: number, versionId: number): Observable<AppOwnership[]> {
    return this.http
      .get<AppOwnership[]>(`${API_ENDPOINT + 'api/boat-application/ownership'}/${applicationId}/${versionId}`)
      .pipe(map((response: AppOwnership[]) => response));
  }

  /* createInspection(dto: BaseVesselInspectionWebDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/inspection/save', dto)
      .pipe(map((response: any) => response));
  } */

  deleteInspection(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/inspection/delete/' + id, {})
      .pipe(map((response: any) => response));
  }

  confirmInspection(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/boat-application/inspection/confirm/' + id, {})
      .pipe(map((response: any) => response));
  }

  confirmSmallVesselInspectionForLargeVessel(id: number, versionId: number) {
    return this.http
      .post<any>(
        API_ENDPOINT + 'api/small-vessel/confirm-inspection-for-large-vessel/' + id + '/' + versionId,
        {}
      )
      .pipe(map((response: any) => response));
  }

  // fish vessel number
  genFishVesselNumber(prefix: string) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/ship-data/generate-vesselnr/' + prefix, {})
      .pipe(map((response: any) => response));
  }

  delFishVesselNumber(technicalId: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/ship-data/delete-vesselnr/' + technicalId, {})
      .pipe(map((response: any) => response));
  }

  getIceClasses() {
    return this.http.get<any>(API_ENDPOINT + 'api/classifier/iceclassgroups');
  }

  signApplication(applSignDTO: ApplicationSignDTO, file: File): Observable<any> {
    const formData = new FormData();
    Object.keys(applSignDTO).forEach(key => {
      if (applSignDTO[key]) {
        if (applSignDTO[key] instanceof Date) {
          formData.set(key, new DatePipe('et_EE').transform(applSignDTO[key], 'yyyy-MM-dd'));
        } else {
          formData.set(key, applSignDTO[key]);
        }
      }
    });
    if (file) {
      formData.set('file', file);
    }

    return this.http
      .post<any>(API_ENDPOINT + 'api/application/sign/', formData)
      .pipe(map((response: any) => response));
  }
  prepareSignApplication(applSignDTO: ApplicationRemoteSignDTO, file: File): Observable<any> {
    const formData = new FormData();
    Object.keys(applSignDTO).forEach(key => {
      if (applSignDTO[key]) {
        if (applSignDTO[key] instanceof Date) {
          formData.set(key, new DatePipe('et_EE').transform(applSignDTO[key], 'yyyy-MM-dd'));
        } else if (Array.isArray(applSignDTO[key])) {
          applSignDTO[key].forEach((item, index) => {
            Object.keys(item).forEach(subKey => {
              formData.set(`${key}[${index}].${subKey}`, item[subKey]);
            });
          });
        } else {
          formData.set(key, applSignDTO[key]);
        }
      }
    });
    if (file) {
      formData.set('file', file);
    }

    return this.http
      .post<any>(API_ENDPOINT + 'api/sign/prepare/', formData)
      .pipe(map((response: any) => response));
  }
  finalizeSign(finalizeSignature: ApplicationRemoteSignFinalizeDTO): Observable<any> {
    return this.http
      .post<ApplicationRemoteSignFinalizeDTO>(`${API_ENDPOINT + 'api/sign/finalize/'}`, finalizeSignature)
      .pipe(map((response: any) => response));
  }
  getChallengeId(regCode: string) {
    return interval(1000).pipe(
      switchMap(() => this.http.get<any>(API_ENDPOINT + 'api/session/challengeid/' + regCode)),
      takeWhile(response => response.data === null, true)
    );
  }

  changeCoordinator(appCoordinatorChangeDTO: AppCoordinatorChangeDTO) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/application/changecoordinator', appCoordinatorChangeDTO)
      .pipe(map((response: any) => response));
  }
}
