import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserInfo, UserRole, PersonInfo } from '../../_domain';
import { API_ENDPOINT } from '../../app.constants';
import { TranslateService } from '@ngx-translate/core';
import { Notifications } from 'src/app/_domain/notifications.interface';
import { NotificationsSearch } from 'src/app/_domain/notificationssearch.interface';
import { SortEvent } from 'src/app/_domain/sortevent.interface';
import { AutPersonAgreementDTO, PersonSearch } from '../../_domain/userinfo.interface';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  /**
   * NB! Seda saad kasutada süsteemiüleselt vajadusel kui login info peaks muutuma
   * ja UI kusagil alamkomponendis peab seda seisu peegeldama
   */
  public getUserSubject = new Subject<UserInfo>();
  public getUserEnvinronmentSubject = new Subject<string>();

  public notificationsCountSubject = new Subject<any>();
  private ifNewUserSubject = new Subject<any>();

  public userSubject = new Subject<any>();
  currentUser: UserInfo;

  constructor(private http: HttpClient, private translateService: TranslateService) {}

  sendNewRepresentativeEvent() {
    this.ifNewUserSubject.next();
  }

  getNewRepresentativeEvent(): Observable<any> {
    return this.ifNewUserSubject.asObservable();
  }

  triggerNotificationsCountChange() {
    this.notificationsCountSubject.next();
  }

  getUserSelectedRole() {
    return JSON.parse(sessionStorage.getItem('currentActiveRole'));
  }

  setUserSelectedRole(activeRoleName: string) {
    return sessionStorage.setItem('currentActiveRole', activeRoleName);
  }

  getUserSelectedRoleEnvinronment(): string {
    const activeRoleEnvinronmentName = sessionStorage.getItem('currentActiveRoleEnvinronment');
    return activeRoleEnvinronmentName;
  }

  setUserSelectedRoleEnvinronment(activeRoleEnvinronmentName: string, isActiveEnv = false) {
    this.getUserEnvinronmentSubject.next(activeRoleEnvinronmentName);
    if (isActiveEnv) {
      sessionStorage.setItem('currentActiveRoleEnvinronment', activeRoleEnvinronmentName);
      // save enviorment to session: mk/ik
      const env = activeRoleEnvinronmentName.indexOf('ROLE_ADMIN') === -1 ? 'ik' : 'mk';
      // console.log('setUserSelectedRoleEnvinronment', env, activeRoleEnvinronmentName);
      sessionStorage.setItem('currentActiveEnvinronment', env);
    }
  }

  getUserSelectedEnvinronment(): string {
    const activeRoleEnvinronmentName = sessionStorage.getItem('currentActiveEnvinronment');
    return activeRoleEnvinronmentName; // mk/ik
  }

  getUser() {
    return JSON.parse(sessionStorage.getItem('currentUser'));
  }

  loadUser(): Observable<UserInfo> {
    return this.http.get<UserInfo>(API_ENDPOINT + 'api/user').pipe(map((response: UserInfo) => response));
  }

  loadUserdetails(): Observable<any> {
    return this.http.get<any>(API_ENDPOINT + 'api/userdetails');
  }

  loadPerson(id: number): Observable<PersonInfo> {
    return this.http
      .get<PersonInfo>(API_ENDPOINT + 'api/user/' + id)
      .pipe(map((response: PersonInfo) => response));
  }

  loadPersonDetails(id: number): Observable<any> {
    return this.http.get<any>(API_ENDPOINT + 'api/person/userdetails/' + id);
  }

  saveAuthPerson(user: any): Observable<PersonInfo> {
    return this.http
      .post<PersonInfo>(API_ENDPOINT + 'api/user/create', user)
      .pipe(map((response: PersonInfo) => response));
  }

  saveNewPerson(user: any) {
    return this.http.post<PersonInfo>(API_ENDPOINT + 'api/person', user);
  }

  updatePerson(user: any): Observable<any> {
    const outputData = {
      id: user.id,
      firstName: user.firstName,
      name: user.name,
      notificationEmail: user.notificationEmail
    };
    return this.http.post<any>(API_ENDPOINT + 'api/user/update', outputData);
  }

  updateAuthPerson(user: any): Observable<PersonInfo> {
    const outputData = {
      id: user.id,
      jobName: user.jobName,
      department: user.department,
      email: user.email,
      phone: user.phone,
      roleIds: user.roleIds
    };
    return this.http.post<any>(API_ENDPOINT + 'api/user/update-official', outputData);
  }

  changePersonToAutOfficial(user: any): Observable<PersonInfo> {
    const outputData = {
      id: user.id,
      jobName: user.jobName,
      department: user.department,
      email: user.email,
      phone: user.phone,
      roleIds: user.roleIds
    };
    return this.http.post<PersonInfo>(API_ENDPOINT + 'api/user/create-official', outputData);
  }

  // FIXME: bad name for a get request.
  changeUserRole(roleId: string): Observable<UserInfo> {
    return this.http
      .post<UserInfo>(API_ENDPOINT + 'api/user/authorize/' + roleId, {})
      .pipe(map((response: UserInfo) => response));
  }

  addContactToUser(userId: number, type: string | 'EMAIL' | 'AADRESS', text: string): Observable<PersonInfo> {
    const contact = {
      contactText: text,
      contactType: type,
      personId: userId
    };
    return this.http.post<any>(API_ENDPOINT + 'api/user/add-contact', contact);
  }

  removeUserContactById(contactId): any {
    return this.http.delete<any>(API_ENDPOINT + 'api/user/remove-contact/' + contactId);
  }

  findUserById(id: number): Observable<any> {
    return this.http.post<any>(API_ENDPOINT + 'api/user/findById/', id);
  }

  acceptSysTerms(sysConditions: boolean, personalData: boolean): Observable<any> {
    const agreement: AutPersonAgreementDTO = {
      sysConditions,
      personalData
    } as AutPersonAgreementDTO;
    return this.http.post<any>(API_ENDPOINT + 'user/agree', agreement);
  }

  changeagreement(sysConditions: boolean, personalData: boolean): Observable<any> {
    const agreement: AutPersonAgreementDTO = {
      sysConditions,
      personalData
    } as AutPersonAgreementDTO;
    return this.http.post<any>(API_ENDPOINT + 'api/user/changeagreement', agreement);
  }

  setUser(data: UserInfo) {
    data.rolesSize = Object.keys(data.roles).length;
    data.entitiesSize = Object.keys(data.entities).length;
    data.entities = this.setUserRealEntityFirstInMap(data);

    sessionStorage.setItem('currentUser', JSON.stringify(data));
    this.getUserSubject.next(data);
  }

  logoutUser(): Observable<any> {
    return this.http.post<any>(API_ENDPOINT + 'api/logout', {});
  }

  cleanSession() {
    sessionStorage.clear();
    this.getUserSubject.next();
    this.getUserEnvinronmentSubject.next();
  }

  authOfficial(id: number): Observable<PersonInfo> {
    return this.http
      .get<PersonInfo>(API_ENDPOINT + 'api/user/official/' + id)
      .pipe(map((response: PersonInfo) => response));
  }

  logoutRedirectGet(url) {
    return this.http.get<any>(url).pipe(map((response: any) => response));
  }

  isLoggedIn() {
    return !!sessionStorage.getItem('currentUser');
  }

  /*
   * Sets the active role title based on language in userbar template
   */
  getActiveRoleTitle(userInfo: UserInfo) {
    let activeRoleTitle: string;
    if (userInfo !== null && userInfo !== undefined) {
      this.setUser(userInfo);
      if (userInfo.roles[userInfo.activeRoleEntity]) {
        // TODO: hook into the translationService and display title according to the language!
        // NB! this is just a sample .currentLang returns undefined at the moment!
        if (this.translateService.currentLang === 'en') {
          activeRoleTitle = userInfo.roles[userInfo.activeRoleEntity].rolenameEng;
        } else {
          activeRoleTitle = userInfo.roles[userInfo.activeRoleEntity].rolenameEst;
        }
      } else if (userInfo.entities[userInfo.activeRoleEntity]) {
        if (this.translateService.currentLang === 'en') {
          activeRoleTitle = userInfo.entities[userInfo.activeRoleEntity].rolenameEng;
        } else {
          activeRoleTitle = userInfo.entities[userInfo.activeRoleEntity].rolenameEst;
        }
      } else {
        console.error('Cannot set activeRoleTitle! Check back-end response for errors!');
      }
      return activeRoleTitle;
    }
  }

  /*
   Nõue: sisseloginud kasutajat peab kuvama esmase valikuna (LAEVIS-180)
 */
  setUserRealEntityFirstInMap(userInfo: UserInfo): Map<string, UserRole> {
    const rolekeys = Object.keys(userInfo.entities);
    // leiab kasutajale kuuluva entity
    // tagastab listi mille positsioon 0 vastab 'key'-le ja 1 vastab 'value'-le
    const userActiveRoleEntity = Object.entries(userInfo.entities).filter(([key, value]) => {
      return userInfo.autPersonId === value.id;
    })[0];

    const userPrivateRolePos = rolekeys.indexOf(userActiveRoleEntity[0]);
    if (userPrivateRolePos !== 0) {
      const newEntityMapWithNewOrder = {};
      const currentUserRoleKey = rolekeys[userPrivateRolePos];
      // eemaldab kasutaja rolli listist
      rolekeys.splice(userPrivateRolePos, 1);
      // lisab kasutaja rolli listi algusesse
      rolekeys.unshift(currentUserRoleKey);

      for (const roleKey of rolekeys) {
        newEntityMapWithNewOrder[roleKey] = userInfo.entities[roleKey] as UserRole;
      }
      return newEntityMapWithNewOrder as Map<string, UserRole>;
    }
    return userInfo.entities;
  }

  getTotalUnreadNotifications(): any {
    return this.http
      .get<any>(API_ENDPOINT + 'api/notification/count-unread')
      .pipe(map((response: any) => response));
  }

  getLatestNotifications(): Observable<Notifications> {
    return this.http.get<any>(API_ENDPOINT + 'api/notification/last').pipe(map((response: any) => response));
  }

  getNotificationTypes(): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/notification/filter/types')
      .pipe(map((response: any) => response));
  }

  setAllNotificationsToRead(lastInLine: number): any {
    return this.http
      .post<any>(API_ENDPOINT + 'api/notification/mark-read/' + lastInLine, {})
      .pipe(map((response: any) => response));
  }
  setUnread(id: number): any {
    return this.http
      .post<any>(API_ENDPOINT + 'api/notification/mark-unread/' + id, {})
      .pipe(map((response: any) => response));
  }
  searchNotifications(
    input: NotificationsSearch,
    sortBy: string,
    sortDir: string
  ): Observable<Notifications> {
    let params = new HttpParams();
    let sort = '';
    if (input) {
      // putting sort data here like we should, does not work with back-end
      for (const prop in input) {
        if (Object.prototype.hasOwnProperty.call(input, prop) && input[prop] !== null) {
          params = params.append(prop, input[prop]);
        }
      }
    }
    if (sortBy && sortDir) {
      sort = '?sort=' + sortBy + ',' + sortDir;
    }
    return this.http
      .get<any>(API_ENDPOINT + 'api/notification/search' + sort, { params })
      .pipe(map((response: any) => response));
  }

  getNotification(id: number): Observable<any> {
    return this.http.get<any>(API_ENDPOINT + 'api/notification/' + id);
  }

  deleteNotification(notificationId: number): any {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/notification/delete/' + notificationId)
      .pipe(map((response: any) => response));
  }

  getUserRepresentors(params: string): any {
    return this.http.get<any>(API_ENDPOINT + 'api/my/representors/search?' + params);
  }

  /* Antud volitused */
  getUserMandators(params: string): any {
    return this.http.get<any>(API_ENDPOINT + 'api/my/mandators/search?' + params);
  }

  markOneReaded(notificationId: number): Observable<UserInfo> {
    return this.http.post<any>(API_ENDPOINT + 'api/notification/mark-one-read/' + notificationId, {});
  }

  searchUsers(personSearch: PersonSearch): Observable<any> {
    const personSearchObject = {};
    Object.keys(personSearch).forEach(key => {
      if (personSearch[key]) {
        if (personSearch[key] instanceof Date) {
          personSearchObject[key] = new DatePipe('et_EE').transform(personSearch[key], 'yyyy-MM-dd');
        } else {
          personSearchObject[key] = personSearch[key];
        }
      }
    });
    return this.http
      .post<any>(API_ENDPOINT + 'api/personsearch', personSearchObject)
      .pipe(map((response: any) => response));
  }
}
