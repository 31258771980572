import { Injectable } from '@angular/core';
import { API_ENDPOINT } from '../../app.constants';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { InspectionSubmit } from '../../_domain/inspection.submit';
import { Observable, Subject } from 'rxjs';
import { IsothreePipe } from '../_pipes/isothree.pipe';
import { SortEvent } from 'src/app/_domain/sortevent.interface';
import { ChecklistFormData, ChecklistInterface } from '../../_domain/checklist.interface';
import { InspectionSearch } from 'src/app/_domain';
import { LangswitchService } from './langswitch.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionService {
  public mode: string;
  public getModeSubject = new Subject<string>();

  portListSessionKey = 'inspection-portList';

  checkformGroupsCount: Subject<number> = new Subject<number>();
  smallShipDossierMode: Subject<string> = new Subject<string>();

  insTabSelectedSource = new Subject<number>();
  insTabSelected$ = this.insTabSelectedSource.asObservable();
  private lastTabSelected: string;

  constructor(
    private http: HttpClient,
    private isothree: IsothreePipe,
    private langswitchService: LangswitchService
  ) {}

  setSmallShipDossierMode(value) {
    this.smallShipDossierMode.next(value);
  }

  getSmallShipDossierMode(): Observable<string> {
    return this.smallShipDossierMode.asObservable();
  }

  setCheckformGroupsCount(value) {
    this.checkformGroupsCount.next(value);
  }

  getCheckformGroupsCount(): Observable<number> {
    return this.checkformGroupsCount.asObservable();
  }

  getSelectedTab() {
    return this.lastTabSelected;
  }

  setSelectedTab(tab: string) {
    this.lastTabSelected = tab;
  }

  onInsTabSelect(tab: number) {
    this.insTabSelectedSource.next(tab);
  }

  getInspectionTypesList() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/inspectionTypesList')
      .pipe(map((response: any) => response));
  }

  getInspectionTeamList() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/inspectionTeamList')
      .pipe(map((response: any) => response));
  }

  getInspectionSVTypesList() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/inspectionSVTypesList')
      .pipe(map((response: any) => response));
  }

  getInspectionSVTeamList() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/inspectionSVTeamList')
      .pipe(map((response: any) => response));
  }

  deleteInspection(id: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/mk/inspection/delete/' + id)
      .pipe(map((response: any) => response));
  }

  cancelInspection(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/mk/inspection/cancel/' + id, {})
      .pipe(map((response: any) => response));
  }

  submitInspection(inspectionSubmit: InspectionSubmit) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/inspection/create', inspectionSubmit)
      .pipe(map((response: any) => response));
  }

  submitSmBoatInspection(inspectionSubmit: InspectionSubmit) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/smboat-application/inspection/save', inspectionSubmit)
      .pipe(map((response: any) => response));
  }

  checkTeamTimes(inspectionSubmit: InspectionSubmit) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/inspection/checkTeamTimes', inspectionSubmit)
      .pipe(map((response: any) => response));
  }

  getInspection(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/inspection/' + id).pipe(map((response: any) => response));
  }

  getInspectionDossier(inspectionId: number, env: string): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/' + env + '/inspection/dossier/' + inspectionId)
      .pipe(map((response: any) => response));
  }

  getExtShipDocumentsById(inspectionId: number, lang: string, sort?: Array<SortEvent>) {
    let params = new HttpParams().set('language', this.isothree.transform(lang));
    if (sort) {
      sort.forEach(s => {
        params = params.set('sort', s.sortColumn + ',' + s.sortDirection);
      });
    }
    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-document/inspection-extendable-list/' + inspectionId, { params })
      .pipe(map((response: any) => response));
  }

  getIssShipDocumentsById(inspectionId: number, lang: string, sort?: Array<SortEvent>) {
    let params = new HttpParams().set('language', this.isothree.transform(lang));
    if (sort) {
      sort.forEach(s => {
        params = params.append('sort', s.sortColumn + ',' + s.sortDirection);
      });
    }
    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-document/inspection-issued-list/' + inspectionId, { params })
      .pipe(map((response: any) => response));
  }

  deleteExtendableDocument(inspectionId: number, documentId: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/ship-document/inspection-extendable/delete/' + inspectionId, documentId)
      .pipe(map((response: any) => response));
  }

  deleteIssuedDocument(inspectionId: number, documentId: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/ship-document/inspection-issued/delete/' + inspectionId, documentId)
      .pipe(map((response: any) => response));
  }

  getVesselDocuments(inspectionId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-document/inspection-list/' + inspectionId)
      .pipe(map((response: any) => response));
  }

  addExtendableDocument(inspectionId: number, docIds: any) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/ship-document/inspection-extendable/add/' + inspectionId, docIds)
      .pipe(map((response: any) => response));
  }

  getIssuedDocuments() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/ship-document/issued-list')
      .pipe(map((response: any) => response));
  }

  addIssuedDocument(inspectionId: number, docDoctypeCode: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/ship-document/inspection-issued/add/' + inspectionId, docDoctypeCode, {
        headers: { 'Content-Type': 'application/json' }
      })
      .pipe(map((response: any) => response));
  }

  getChecklistTableRows(inspectionId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/dossier/checklist/' + inspectionId)
      .pipe(map((response: any) => response));
  }

  deleteChecklist(checklistId: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/dossier/checklist/delete/' + checklistId)
      .pipe(map((response: any) => response));
  }

  createChecklist(inspectionId: number) {
    return this.http
      .put<any>(API_ENDPOINT + 'api/dossier/checklist/create/' + inspectionId, inspectionId)
      .pipe(map((response: any) => response));
  }

  getPortList() {
    const regPort = JSON.parse(sessionStorage.getItem(this.portListSessionKey));
    if (regPort !== undefined && regPort !== null) {
      return new Observable(observer => {
        observer.next(regPort);
      });
    }
    return this.http.get<any>(API_ENDPOINT + 'api/inspection/portList').pipe(
      map((response: any) => {
        sessionStorage.setItem(this.portListSessionKey, JSON.stringify(response));
        return response;
      })
    );
  }

  getChecklistDocumentTable(inspectionId: number, sort?: any) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/dossier/checklist/documents/' + inspectionId)
      .pipe(map((response: any) => response));
  }

  linkInspectionDocument(inspectionId: number, documentId: number) {
    return this.http
      .put<any>(API_ENDPOINT + 'api/dossier/checklist/link-document/' + inspectionId + '/' + documentId, {
        inspectionId,
        documentId
      })
      .pipe(map((response: any) => response));
  }

  deleteChecklistDocument(inspectionId: number, documentId: number) {
    return this.http
      .delete<any>(API_ENDPOINT + 'api/dossier/checklist/delete-document/' + inspectionId + '/' + documentId)
      .pipe(map((response: any) => response));
  }

  getChecklistForm(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/checklist/form/' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveChecklistForm(checkList: ChecklistInterface) {
    // TODO any
    const submit = checkList.checklist;
    return this.http.post<any>(API_ENDPOINT + 'api/checklist/save', checkList).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getChecklistCheckedRequirements(id) {
    return this.http.get<any>(API_ENDPOINT + 'api/checklist/form/' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveInsCheckedRequirementForm(form) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/checklist/checked/requirements-save/', form)
      .pipe(map((response: any) => response));
  }

  getClaRequirementGroups() {
    return this.http.get<any>(API_ENDPOINT + 'api/checked-requirement/groups').pipe(
      map((response: any) => {
        if (response.success) {
          const lang = this.langswitchService.getCurrentLang();
          response.data.sort((a, b) => a.descr[lang].localeCompare(b.descr[lang]));
        }
        return response;
      })
    );
  }

  getClaRequirementsByGroup(id) {
    return this.http.get<any>(API_ENDPOINT + 'api/checked-requirement/requirements/' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  saveCheckedRequirement(submit) {
    return this.http.post<any>(API_ENDPOINT + 'api/checked-requirement/save', submit).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  deleteCheckedRequirement(id) {
    return this.http.delete<any>(API_ENDPOINT + 'api/checked-requirement/delete/' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  confirmChecklistForm(id: number) {
    return this.http.get<any>(API_ENDPOINT + 'api/checklist/confirm/' + id).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
  reopenChecklistForm(id: number) {
    return this.http.post<any>(API_ENDPOINT + 'api/checklist/reopen/' + id, {}).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  searchIns(formData: InspectionSearch): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + '/api/inspection/search', formData) // TODO: GET A NEW ENDPOINT
      .pipe(map((response: any) => response));
  }

  getIns(
    data: InspectionSearch,
    currentEnv: string,
    page: number,
    size: number,
    sort: string
  ): Observable<any> {
    const outputData = {
      vesselPersonStr: data.vesselPersonStr,
      vesselSearchStr: data.vesselSearchStr,
      inspectionTypeId: data.inspectionTypeId,
      //      allowedTypeIds: data.inspectionTypeId,
      dateFrom: data.dateFrom,
      dateTo: data.dateTo,
      committee: data.committee,
      status: data.status,
      vesselId: data.vesselId,
      claListitemByAppStatus: data.claListitemByAppStatus
    };

    return this.http
      .post<any>(
        API_ENDPOINT +
          'api/' +
          currentEnv +
          '/inspection/search?page=' +
          page +
          '&size=' +
          size +
          (sort ? '&sort=' + sort + ',' + data.sortDir : ''),
        outputData
      )
      .pipe(map((response: any) => response));
    /*
    return new Observable(observer => {
      observer.next(dummyInspectionData);
    });
    */
  }

  getDossierData(id: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/dossier/' + id)
      .pipe(map((response: any) => response));
  }

  getAuditDossier(id: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/audit/dossier/' + id)
      .pipe(map((response: any) => response));
  }

  saveDossierResultsData(data: any): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/inspection/dossier-save', data)
      .pipe(map((response: any) => response));
  }

  saveAuditDossierResultsData(data: any): Observable<any> {
    return this.http
      .post<any>(API_ENDPOINT + 'api/audit/dossier-save', data)
      .pipe(map((response: any) => response));
  }

  getAuditDossierDocuments(id: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/audit/result/document/' + id)
      .pipe(map((response: any) => response));
  }

  getInspectionResultDocument(id: number): Observable<any> {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/result/document/' + id)
      .pipe(map((response: any) => response));
  }

  saveInspectionResultDocument(id: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/inspection/result/create-document/' + id, {})
      .pipe(map((response: any) => response));
  }

  deleteInspectionResultDocument(inspectionId: number, documentId: number) {
    return this.http
      .post<any>(
        API_ENDPOINT + 'api/inspection/result/delete-document/' + inspectionId + '?documentId=' + documentId,
        {}
      )
      .pipe(map((response: any) => response));
  }

  controlInspectionDossierIsFinished(inspectionId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/dossier-finished/' + inspectionId)
      .pipe(map((response: any) => response));
  }

  refreshInspectionDossierSummary(inspectionId: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/inspection/dossier-summary/' + inspectionId, {})
      .pipe(map((response: any) => response));
  }

  /*
    DEFICIENCY
  */

  getDeficiencies(
    inspectionId: number,
    currentEnv: string,
    page: number,
    size: number,
    sort: string,
    sortDir = 'descr'
  ): Observable<any> {
    return this.http
      .post<any>(
        API_ENDPOINT +
          'api/inspection/deficiencydata/' +
          inspectionId +
          '?page=' +
          page +
          '&size=' +
          size +
          (sort ? '&sort=' + sort + '%2C' + sortDir : ''),
        {}
      )
      .pipe(map((response: any) => response));
  }

  deleteDeficiency(deficiencyId: number) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/deficiency-delete/' + deficiencyId, {})
      .pipe(map((response: any) => response));
  }

  getDeficiencyById(deficiencyId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/deficiency/' + deficiencyId)
      .pipe(map((response: any) => response));
  }

  createDeficiency(formData: any) {
    /* {
     "deadline": "string",
     "description": "string",
     "id": 0,
     "insInspectionId": 0,
     "rectifActionId": 0,
     "requirementId": 0
   } */
    return this.http
      .post<any>(API_ENDPOINT + 'api/deficiency/create ', formData)
      .pipe(map((response: any) => response));
  }

  getDeficiencyRequirementList() {
    return this.http
      .post<any>(API_ENDPOINT + 'api/deficiency/requirement/list ', {})
      .pipe(map((response: any) => response));
  }

  getDeficiencyRectifactionList() {
    return this.http
      .post<any>(API_ENDPOINT + 'api/deficiency/rectifaction/list ', {})
      .pipe(map((response: any) => response));
  }

  // saveAudit(audit: Audit) {
  saveAudit(audit: InspectionSubmit) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/auditing/create', audit)
      .pipe(map((response: any) => response));
  }

  getAuditionTeamList(listType: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/auditionTeamList/' + listType)
      .pipe(map((response: any) => response));
  }

  getAuditingTypesList() {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/auditingTypesList')
      .pipe(map((response: any) => response));
  }

  getInspectionContradictionList(inspectionId: number) {
    return this.http
      .get<any>(API_ENDPOINT + 'api/inspection/contradiction/table/' + inspectionId)
      .pipe(map((response: any) => response));
  }

  deleteAuditDocument(inspectionId: number, documentId: number) {
    return this.http
      .post<any>(
        API_ENDPOINT + 'api/audit/result/delete-document/' + inspectionId + '?documentId=' + documentId,
        {}
      )
      .pipe(map((response: any) => response));
  }

  saveAuditResultDocument(id: number, documentContext?: string) {
    return this.http
      .post<any>(
        API_ENDPOINT +
          'api/audit/result/create-document/' +
          id +
          (documentContext ? '?documentContext=' + documentContext : ''),
        {}
      )
      .pipe(map((response: any) => response));
  }

  /*
  createSmallVesselInspection(regNr: string) {
    return this.http
      .post<any>(API_ENDPOINT + 'api/small-vessel/create-inspection', regNr)
      .pipe(map((response: any) => response));
  }
*/

  createSmallVesselPreInspection() {
    return this.http
      .post<any>(API_ENDPOINT + 'api/small-vessel/create-preregistration-inspection', {})
      .pipe(map((response: any) => response));
  }

  createSmallVesselTechInspection(hashCode: string) {
    return this.http
      .post<any>(
        API_ENDPOINT + 'api/small-vessel/create-technical-inspection?smallVesselHashCode=' + hashCode,
        {}
      )
      .pipe(map((response: any) => response));
  }

  importRequirements(checkListId: number, file: File) {
    const formData = new FormData();
    formData.set('file', file);
    return this.http.post<any>(API_ENDPOINT + 'api/checked-requirement/import/' + checkListId, formData);
  }
  getPrices() {
    return this.http.get<any>(API_ENDPOINT + 'api/fin/statefeetaxes').pipe(map((response: any) => response));
  }
}
