import { Component, ChangeDetectionStrategy, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';


/*
  Kindlasti lisada pipe (| paginate: pagConf ;):
  <tr *ngFor="let result of searchResults  | paginate: pagConf ; let i = index">
*/
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PaginatorComponent {

  @Input()
  public showBoundaryLinks = false;
  @Input()
  config: PaginationInstance;
  @Output()
  pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  itemsPerPageChange: EventEmitter<any> = new EventEmitter<any>();

  pageSizeList = [3, 10, 25, 50, 100]; // TEMPLATE
  p: any; // TEMPLATE: #p="paginationApi

  show(value) {
    return value;
  }

  onItemsPerPageChange(pageSize: number): void {
    this.config.itemsPerPage = pageSize;
    this.itemsPerPageChange.emit();
  }

  // LAB allikas: https://koodivaramu.eesti.ee/veebiraamistik/angular/blob/TEHIK/libs/common/src/lib/table/pagination.component.ts
}
