<div class="row m-0 bg-white">
  <div *ngIf="notFinishedAplLoading" class="col-20 pt-5 pb-0">
    <app-loading-spinner [isBig]="true"></app-loading-spinner>
  </div>

  <div *ngIf="!notFinishedAplLoading" class="col-20 pt-5 pb-0">
    <div class="row  px-5">
      <div class="col-20 px-0 d-flex justify-content-between">
        <h1 class="mb-1">
          <span>{{ 'DS.TAB1' | translate }}</span>
        </h1>
        <div>
          <button info [style]="'square'" [icon]="'with-plus'" [popover]="newToimingTpl" [outsideClick]="true">
            <span>{{ 'DS.REG_YLEVAATUSE_TAOTLEMINE' | translate }}</span>
          </button>
        </div>
      </div>
      <!-- /.row -->
      <!-- andmete plokk -->
      <div *ngIf="isSearching" class="col-20 pt-5 px-0">
        <app-loading-spinner [msg]="'APL.SEARCH_APL_PROCEEDER_DETAILS'"></app-loading-spinner>
      </div>
      <div *ngIf="!isSearching && aplSearchResults && aplSearchResults.length === 0" class="col-20 py-5 px-0">
        <div class="text-center">
          <h2 class="my-3 font-weight-normal">{{ 'APL.SEARCH_APL_NORESULTS' | translate }}</h2>
        </div>
      </div>
      <div *ngIf="!isSearching && aplSearchResults && aplSearchResults.length > 0" class="col-20 py-5 px-1">
        <!-- <h3 class="results mb-4 font-weight-bold">{{ 'GLOBAL.RESULTS' | translate }} ({{ aplPagConf.totalItems }})</h3> -->
        <app-paginator showBoundaryLinks="true" [(config)]="aplPagConf" (pageChange)="getAplPage()"></app-paginator>
        <table appSortableTable="'aplTableId'" (sorted)="onSorted($event)" class="table table-hover table-adaptive table-cells table-sm table-md w-100">
          <!-- white-space-normal -->
          <thead class="table-head bg-white">
            <!-- TODO put styling into sortable-column-->
            <!-- sortable-column="registrationNumber" -->
            <th sortable-column="registrationNumber">
              <a>{{ 'APL.SEARCH_APL_NUMBER' | translate }}</a>
            </th>
            <!-- sortable-column="appType" -->
            <th sortable-column="claListitemByAppType">
              <a>{{ 'APL.SEARCH_APL_TYPE' | translate }}</a>
            </th>
            <!-- sortable-column="vesselName" -->
            <th sortable-column="vesselName">
              <a>{{ 'APL.SEARCH_APL_VESSEL' | translate }}</a>
            </th>
            <!-- sortable-column="compSubDate" -->
            <th sortable-column="compSubDate">
              <a>{{ 'APL.SEARCH_APL_PROC_DATE' | translate }}</a>
            </th>
            <!-- sortable-column="status" -->
            <th sortable-column="claListitemByAppStatus" class="d-block d-md-none d-lg-table-cell">
              <a>{{ 'APL.SEARCH_APL_STATUS' | translate }}</a>
            </th>
            <th>{{ 'APL.SEARCH_APL_ACTIONS' | translate }}</th>
          </thead>
          <tbody class="bg-white">
            <tr *ngFor="let apl of aplSearchResults | paginate: aplPagConf; let i = index">
              <td [attr.data-label]="( 'APL.SEARCH_APL_NUMBER' | translate) + ':'">
                {{ apl.registrationNumber }}
              </td>
              <td [attr.data-label]="('APL.SEARCH_APL_TYPE' | translate) + ':'">
                {{ apl.claListitemByAppType.descr[lang] }}
              </td>
              <td [attr.data-label]="('APL.SEARCH_APL_VESSEL'| translate) + ':'">
                <span class="has-comment">
                  {{ apl.vesselName }}
                  <span class="label-comment"> {{ apl.imo }} {{ apl.hinCinWin }} </span>
                </span>
              </td>
              <td *ngIf="(today | date: localDateFormat) === (apl.compSubDate | date: localDateFormat)" [attr.data-label]="('APL.SEARCH_APL_PROC_DATE' | translate) + ':'">
                <!-- <app-badge class="mr-2" [style]="'info'">{{ apl.compSubDate | date: localDateFormat
                }}</app-badge> -->
                {{ apl.compSubDate | date: localDateFormat
                }}
              </td>
              <td *ngIf="(today | date: localDateFormat) !== (apl.compSubDate | date: localDateFormat)" [attr.data-label]="('APL.SEARCH_APL_PROC_DATE'  | translate) + ':'">
                <!-- <app-badge class="mr-2" [style]="'default'">{{ apl.compSubDate | date: localDateFormat
                }}</app-badge> -->
                {{ apl.compSubDate | date: localDateFormat
                }}
              </td>
              <td class="d-block d-md-none d-lg-table-cell text-center" [attr.data-label]="('APL.SEARCH_APL_STATUS' | translate) + ':'">
                <!-- {{ apl.claListitemByAppStatus.descr[lang] }} -->
                <span class="badge" [ngClass]="{'20001':'badge-warning','20002':'badge-warning','20009':'badge-default',
                '20011':'badge-info','20012':'badge-info','20031':'badge-orange','20091':'badge-success','20092':'badge-danger'
              }[apl.claListitemByAppStatus?.code]">
                  <span class="badge-text">
                    {{ apl.claListitemByAppStatus.descr[lang]}}
                  </span>
                </span>
              </td>
              <td class="d-flex justify-content-center">
                <a class="" [routerLink]="[aplDetailRoutePath + apl.id]">
                  <button light [style]="'small'" [icon]="'eye'" title="{{ 'GLOBAL.LOOK' | translate }}"></button>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <app-paginator showBoundaryLinks="true" [(config)]="aplPagConf" (pageChange)="getAplPage()"></app-paginator>
      </div>
    </div>
  </div>
  <!-- MY UNPAYED STATE/FIN FEES -->
  <div *ngIf="unpaidFeesLoading" class="col-20 py-0">
    <app-loading-spinner [isBig]="true"></app-loading-spinner>
  </div>
  <div *ngIf="!unpaidFeesLoading && unpayedFinFeesPageConf?.totalItems > 0" class="col-20 py-0">
    <div class="row px-0">
      <h1 class="mb-1 col-20">{{ 'DS.TAB5' | translate }}</h1>
      <!-- /.row -->
      <!-- andmete plokk -->
      <div class="row col-20 bg-white m-0">
        <div *ngIf="isSearching" class="col-20 py-5 px-0">
          <app-loading-spinner [msg]="'APL.SEARCH_APL_PROCEEDER_DETAILS'"></app-loading-spinner>
        </div>
        <div *ngIf="!isSearching && (unpayedFinFees && unpayedFinFees.length==0)" class="col-20 py-5 px-0">
          <div class="text-center">
            <h2 class="my-3 font-weight-normal"> {{ 'APL.SEARCH_APL_NORESULTS' | translate }} </h2>
          </div>
        </div>
        <div *ngIf="!isSearching && unpayedFinFees && unpayedFinFees.length > 0" class="col-20 py-5 px-0">
          <app-paginator showBoundaryLinks="true" [(config)]="unpayedFinFeesPageConf" (pageChange)="getUnpayedFinFeePage()">
          </app-paginator>
          <table appSortableTable="insAuditSearchResTable" (sorted)="onUnpayedFinfeeSorted($event)" class="table table-hover table-adaptive table-cells table-sm table-md w-100">
            <thead class="table-head bg-white">
              <th sortable-column="created" sort-direction="desc" scope="col">
                <a>{{ 'FINFEE.CREATION_DATE' | translate }}</a>
              </th>
              <th sortable-column="amount" scope="col">
                <a>{{ 'FINFEE.SUM' | translate }}</a>
              </th>
              <th sortable-column="payedAmount" scope="col">
                <a>{{ 'FINFEE.PAYED_AMOUNT' | translate }}</a>
              </th>
              <th sortable-column="vesselName" scope="col">
                <a>{{ 'FINFEE.SHIP' | translate }}</a>
              </th>
              <th sortable-column="typeCode" scope="col">
                <a>{{ 'FINFEE.INSPECTION' | translate }}</a>
              </th>
              <th sortable-column="appType" scope="col">
                <a>{{ 'FINFEE.APPLICATION' | translate }}</a>
              </th>
            </thead>
            <tbody class="bg-white">
              <tr *ngFor="let fee of unpayedFinFees | paginate: unpayedFinFeesPageConf; let i = index">
                <td> {{ fee.created | date: localDateTimeFormat }} </td>
                <td> {{ fee.amount | number:'1.2-2' }} € </td>
                <td> {{ fee.payedAmount | number:'1.2-2' }} € </td>
                <td>
                  <span class="label has-comment">
                    {{ fee.vesselName }}
                    <span *ngIf="fee.vesselImo" class="label-comment">{{ fee.vesselImo }} </span>
                  </span>
                </td>
                <td> {{ fee.claInspectionSubtypeBySubtypeCode?.claInspectionTypeByTypeCode?.descr[lang] }}
                  {{ fee.claInspectionSubtypeBySubtypeCode?.claInspectionTypeByTypeCode?.descr[lang] ? ':' : ''}}
                  {{ fee?.claInspectionSubtypeBySubtypeCode?.descr[lang] }} </td>
                <td>
                  <span class="label has-comment">{{ fee.claListitemByAppType?.descr[lang] }}</span>
                  <span class="label-comment">{{ fee.registrationNumber}}</span> </td>
              </tr>
            </tbody>
          </table>
          <app-paginator showBoundaryLinks="true" [(config)]="unpayedFinFeesPageConf" (pageChange)="getUnpayedFinFeePage()">
          </app-paginator>
        </div>
      </div>
    </div>
  </div>
  <!-- MY SHIPS -->
  <!-- /.row -->
  <div *ngIf="shipsLoading" class="col-20 py-0">
    <app-loading-spinner [isBig]="true"></app-loading-spinner>
  </div>
  <div *ngIf="!shipsLoading" class="col-20 py-0">
    <div class="row px-5">
      <div class="col-20 px-0">
        <h1 class="mb-1">{{ 'DS.TAB2' | translate }}</h1>
        <!-- /.row -->
        <!-- andmete plokk -->
        <div class="row bg-white m-0">
          <div *ngIf="isSearching" class="col-20 py-5 px-0">
            <app-loading-spinner [msg]="'APL.SEARCH_APL_PROCEEDER_DETAILS'"></app-loading-spinner>
          </div>
          <div *ngIf="!isSearching && shipSearchResults && shipSearchResults.length == 0" class="col-20 py-5 px-0">
            <div class="text-center">
              <h2 class="my-3 font-weight-normal">{{ 'APL.SEARCH_APL_NORESULTS' | translate }}</h2>
            </div>
          </div>
          <div *ngIf="!isSearching && shipSearchResults && shipSearchResults.length > 0" class="col-20 py-5 px-0">
            <!-- <h3 class="results mb-4 font-weight-bold">{{ 'GLOBAL.RESULTS' | translate }} ({{ total }})</h3> -->
            <app-paginator showBoundaryLinks="true" [(config)]="shipPagConf" (pageChange)="getShipPage()">
            </app-paginator>
            <table class="table table-hover table-adaptive table-cells table-sm table-md w-100">
              <thead class="table-head bg-white">
                <!-- sortable-column="registrationNumber" -->
                <th>
                  {{ 'SHIPSEARCH.REGNAME' | translate }}
                </th>
                <!-- sortable-column="appType" -->
                <th>{{ 'SHIPSEARCH.NAME' | translate }} {{ 'SHIPSEARCH.IMO' | translate }}</th>
                <!-- sortable-column="vesselName" -->
                <th>
                  {{ 'SHIPSEARCH.OWNER' | translate }}
                </th>
                <!-- sortable-column="compSubDate" -->
                <th>
                  {{ 'SHIPSEARCH.TYPE' | translate }}
                </th>
                <!-- sortable-column="category" -->
                <th class="d-block d-md-none d-lg-table-cell">
                  {{ 'SHIPSEARCH.CAT' | translate }}
                </th>
                <!-- sortable-column="status" -->
                <th class="d-block d-md-none d-lg-table-cell">
                  {{ 'SHIPSEARCH.STATE' | translate }}
                </th>
                <th>{{ 'GLOBAL.ACTIVITY' | translate }}</th>
              </thead>
              <tbody class="bg-white">
                <tr *ngFor="let ship of shipSearchResults | paginate: shipPagConf; let i = index">
                  <td [attr.data-label]="('SHIPSEARCH.REGNAME' | translate) + ':'">
                    {{ (ship?.registryCl?.descr)[lang] }}
                    <!--<app-badge [style]="'info'">LR</app-badge>-->
                    <span class="label-comment">{{ ship.regNumber }}</span>
                  </td>
                  <td [attr.data-label]="('SHIPSEARCH.NAME' | translate) + ':'">
                    <span class=" max-width-200 has-comment d-inline-block">
                      {{ ship.vesselName }}
                      <span class="label-comment" [attr.data-label]="('SHIPSEARCH.IMO' | translate) + ':'">
                        {{ ship.imo ? ship.imo : '' + ship.hinCinWin !== 'null' ? ' / ' + ship.hinCinWin : '' }}
                      </span>
                    </span>
                  </td>
                  <td [attr.data-label]="('SHIPSEARCH.OWNER' | translate) + ':'">
                    <span class="has-comment" *ngFor="let owner of ship.owners">
                      {{ owner?.autPersonByPersonId?.firstName }} {{ owner?.autPersonByPersonId?.name }}
                      <span class="label-comment">{{ owner?.autPersonByPersonId?.registerCode }}</span>
                    </span>
                  </td>
                  <td [attr.data-label]="('SHIPSEARCH.TYPE' | translate) + ':'">
                    {{ (ship?.classTypeCl?.descr)[lang] }}
                  </td>
                  <td class="d-block d-md-none d-lg-table-cell" [attr.data-label]="('SHIPSEARCH.CAT' | translate) + ':'">
                    {{ ship?.category ? (ship.categoryCl?.descr)[lang] : '' }}
                  </td>
                  <td class="d-block d-md-none d-lg-table-cell  text-center" [attr.data-label]="('SHIPSEARCH.STATE' | translate) + ':'">
                    <!-- {{ (ship?.statusCl?.descr)[lang] }} -->
                    <span class="badge" [ngClass]="{'10001':'badge-info','10002':'badge-success','10003':'badge-orange',
                  '10004':'badge-default'
                }[ship?.statusCl?.code]">
                      <span class="badge-text">
                        {{ (ship?.statusCl?.descr)[lang] }}
                      </span>
                    </span>
                  </td>
                  <td class="d-flex justify-content-center">
                    <a class="float-left" [routerLink]="[shipDetailRoutePath + ship.id]">
                      <button [style]="'small'" light [icon]="'eye'" title="{{ 'GLOBAL.LOOK' | translate }}"></button>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <app-paginator showBoundaryLinks="true" [(config)]="shipPagConf" (pageChange)="getShipPage()">
            </app-paginator>
          </div>
        </div>
      </div>
    </div>
    <!-- /.row -->
  </div>
</div>


<ng-template #newToimingTpl>
  <div id="popover-client-select" class="chooseRepresentative pb-2">
    <div class="body p-2">
      <h3 class="mb-2 font-weight-normal">{{ 'DS.LBL2' | translate }}</h3>
      <ul>
        <li>
          <a (click)="openModal(confirmToimingTpl, 21001)">
            <span>{{ 'DS.LBL3' | translate }}</span>
          </a>
        </li>
        <li>
          <a (click)="openModal(confirmToimingTpl, 21002)">
            <span>{{ 'DS.LBL4' | translate }}</span>
          </a>
        </li>
        <!--  <li *ngIf="isLaevRiigihalduseksVisible">
          <a (click)="openModal(confirmToimingTpl, 21003)">
            <span>{{ 'DS.LBL5' | translate}}</span>
          </a>
        </li> -->
        <li>
          <a (click)="openModal(confirmToimingTpl, 21004)">
            <span>{{ 'DS.LBL6' | translate }}</span>
          </a>
        </li>
      </ul>
      <h3 class="my-2 font-weight-normal">{{ 'DS.LBL7' | translate }}</h3>
      <ul>
        <li>
          <a (click)="openModal(confirmToimingTpl, 21005)">
            <span>{{ 'DS.LBL8' | translate }}</span>
          </a>
        </li>
        <!--  <li>
          <a>
            <span>{{ 'DS.LBL9' | translate}}</span>
          </a>
        </li>
        <li>
          <a>
            <span>{{ 'DS.LBL10' | translate}}</span>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</ng-template>
<ng-template #confirmToimingTpl>
  <div class="modal-header pb-1">
    <h2 class="">{{ 'DS.LBL2' | translate }} {{ currentTaotluseNimi }}</h2>
    <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngTemplateOutlet="dialogContentTemplate"></ng-container>
  </div>
</ng-template>
<ng-template #confirmToimingContentOtherTpl>
  <div>
    <p class="font-weight-bold">
      {{ 'DS.LBL11' | translate }}
      {{ (currentUser?.entities)[currentUser?.activeRoleEntity].rolenameEst }}
      {{ 'DS.LBL12' | translate }}
    </p>
    <button info custom-class=" mb-3 px-2" [style]="'square'" (click)="regNew(currentTaotluseLiik)">
      {{ 'DS.LBL13' | translate
      }}<img src="assets/assets/images/arrowRight.svg" alt="" class="arrow-right-btn-icon ml-2" />
    </button>
    <p>
      {{ 'DS.LBL14' | translate }}
    </p>
    <p>
      {{ 'DS.LBL15' | translate }}
    </p>
  </div>
  <div>
    <button info custom-class="px-2" [style]="'square'" [popover]="roleChangeTpl" [outsideClick]="true">
      {{ 'DS.LBL17' | translate
      }}<img src="assets/assets/images/arrowRight.svg" alt="" class="arrow-right-btn-icon ml-2" />
    </button>
  </div>
</ng-template>
<ng-template #confirmToimingContent21004Tpl>
  <div>
    <div>
      <p class="font-weight-bold">
        {{ 'DS.LBL18' | translate }}
        {{ (currentUser?.entities)[currentUser?.activeRoleEntity].rolenameEst }}
        {{ 'DS.LBL19' | translate }}
      </p>
      <button info custom-class=" mb-3 px-2" [style]="'square'" (click)="regNew(currentTaotluseLiik)">
        {{ 'DS.LBL13' | translate
        }}<img src="assets/assets/images/arrowRight.svg" alt="" class="arrow-right-btn-icon ml-2" />
      </button>
      <p>
        {{ 'DS.LBL20' | translate }}
      </p>
      <p>
        {{ 'DS.LBL21' | translate }}
      </p>
    </div>
    <div>
      <button info custom-class="px-2" [style]="'square'" [popover]="roleChangeTpl" [outsideClick]="true">
        {{ 'DS.LBL17' | translate
        }}<img src="assets/assets/images/arrowRight.svg" alt="" class="arrow-right-btn-icon ml-2" />
      </button>
    </div>
  </div>
</ng-template>
<ng-template #confirmToimingContent21005Tpl>
  <div>
    <div>
      <p class="font-weight-bold">
        {{ 'DS.LBL29' | translate }}
        {{ (currentUser?.entities)[currentUser?.activeRoleEntity].rolenameEst }}
        {{ 'DS.LBL27' | translate }} {{ 'DS.LBL28' | translate }}
      </p>
      <button info custom-class=" mb-3 px-2" [style]="'square'" (click)="regNew(currentTaotluseLiik)">
        {{ 'DS.LBL13' | translate
        }}<img src="assets/assets/images/arrowRight.svg" alt="" class="arrow-right-btn-icon ml-2" />
      </button>
      <p>
        {{ 'DS.LBL30' | translate }}
      </p>
      <p>
        {{ 'DS.LBL31' | translate }}
      </p>
    </div>
    <div>
      <button info custom-class="px-2" [style]="'square'" [popover]="roleChangeTpl" [outsideClick]="true">
        {{ 'DS.LBL17' | translate
        }}<img src="assets/assets/images/arrowRight.svg" alt="" class="arrow-right-btn-icon ml-2" />
      </button>
    </div>
  </div>
</ng-template>
<ng-template #roleChangeTpl>
  <app-user-change-role (clickOnLink)="closeModal()"></app-user-change-role>
</ng-template>
