<!-- This is public homepage -->
<app-main-alert></app-main-alert>
<div class="home-page container">
    <div class="z-0 d-flex flex-column m-0">
        <header class="position-relative">
            <nav class="navbar navbar-expand-lg navbar-dark static-top row flex-column mx-0">
                <div class="col-20 mt-0 px-0">
                    <!--  -->
                    <div
                        id="menu-container"
                        class="container-fluid "
                    >
                        <nav
                            class="upperMenu container px-0 mt-0 d-flex justify-content-start h-100"
                            id="menu-upper"
                        >
                            <div class="container pl-0">
                                <div class="row h-100">
                                    <div class="col-sm d-flex align-items-center">
                                        <a
                                            class="mainLogoContainer pl-0"
                                            href="./"
                                        >
                                            <img
                                                src="assets/assets/images/logos/Transpordiamet_EST.svg"
                                                alt=""
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end home-langbar">
                                <!-- LANG -->
                                <div
                                    style="min-width: 80px;"
                                    class="v-align  px-0"
                                >
                                    <!-- nav-item -->
                                    <app-langbar class="align-middle"></app-langbar>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </nav>
        </header>
    </div>
    <div class="bg-image-container">
        <div class="bg-image">
        </div>
    </div>
    <div class="bg-white mx-auto d-flex flex-column home-content">
        <div class="d-lg-flex d-md-block justify-content-between">
            <!-- left -->
            <div class="mb-4">
                <h1 class="font-weight-bold">{{ 'LIS.TITLE' | translate }}</h1>
            </div>
        </div>
        <div>
            <p class="main-description mb-4">{{ 'LIS.DESCRIPTION' | translate }}</p>
            <button
                primary
                [style]="'round'"
                class="btn btn-primary btn-round"
                title="{{ 'GLOBAL.LOGIN' | translate }}"
                (click)="goToLogin()"
            >{{ 'GLOBAL.LOGIN' | translate }}</button>
        </div>
    </div>
</div>
<div class="home-page-footer">
    <app-footer></app-footer>
</div>