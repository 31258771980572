<pagination-template #p="paginationApi" [id]="config.id" (pageChange)="config.currentPage = $event; pageChange.emit()">
  <div class="pagination-block">
    <div class="pagination-total" *ngIf="config.totalItems">
      <!--  <ng-container *ngIf="config['paginationListLabel']">
        {{ config['paginationListLabel'] | translate }}
      </ng-container> -->
      {{ 'PAGINATOR.ROWS_DISPLAYED' | translate }}
      {{ (config.currentPage - 1) * config.itemsPerPage + 1 }} -
      {{
        ((config.currentPage * config.itemsPerPage) < (config.totalItems ? config.totalItems : pageSizeList?.length))
          ? config.currentPage * config.itemsPerPage
          : config.totalItems
          ? config.totalItems
          : pageSizeList?.length
      }}
      / {{ config.totalItems ? config.totalItems : pageSizeList?.length }}
    </div>

    <div class="pagination-list" aria-label="Page navigation">
      <ul class="pagination">
        <li class="page-item hidden-sm-down" *ngIf="showBoundaryLinks">
          <a (click)="p.setCurrent(1)" [class.disabled]="p.isFirstPage()" class="page-link arrow"
            aria-label="First">
            <span>‹‹</span>
          </a>
        </li>

        <li class="page-item">
          <a (click)="p.previous()" [class.disabled]="p.isFirstPage()"
            class="page-link page-link-previous arrow disabled " aria-label="Previous">
            <span>‹</span></a>
        </li>

        <li *ngFor="let page of p.pages" class="page-item">
          <a (click)="p.setCurrent(page.value)" class="page-link"
            [class.current]="p.getCurrent() === page.value">
            <span>{{ page.label }}</span>
          </a>
        </li>

        <li class="page-item">
          <a (click)="p.next()" [class.disabled]="p.isLastPage()"
            class="page-link page-link-next arrow" aria-label="Next">
            <span>›</span>
          </a>
        </li>

        <li class="page-item hidden-sm-down" *ngIf="showBoundaryLinks">
          <a (click)="p.setCurrent(p.getLastPage())" [class.disabled]="p.isLastPage()"
            class="page-link arrow" aria-label="Last">
            <span>››</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="pagination-per-page">
      <label for="per-page" class="mr-2">{{ 'PAGINATOR.ROWSPERPAGE' | translate }}</label>
      <div class="btn-group bootstrap-select form-control">
        <button
          id="per-page"
          type="button"
          class="btn dropdown-toggle btn-default"
          data-toggle="dropdown"
          role="button"
          data-id="per-page"
          title="10"
        >
          <span class="filter-option pull-left">{{ config.itemsPerPage }}</span>&nbsp;
          <span class="bs-caret">
            <span class="caret"></span>
          </span>
        </button>
        <div class="dropdown-menu show-tick open" role="combobox">
          <div class="dropdown-menu show-tick inner" role="listbox" aria-expanded="false">
            <a
              *ngFor="let pageSize of pageSizeList"
              class="dropdown-item  "
              data-original-index="0"
              (click)="onItemsPerPageChange(pageSize); p.setCurrent(1)"
            >
              <span tabindex="0"
                class="dropdown-item-inner d-flex justify-content-between {{ config?.itemsPerPage === pageSize ? 'selected': '' }}"
                data-tokens="null" role="option" aria-disabled="false"
                [attr.aria-selected]="config.itemsPerPage===pageSize">
                <span class="text">{{ pageSize }}</span>
                <span class="fa fa-check check-mark ">
                </span>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</pagination-template>
