    <a (click)="openDoc.emit(doc.id)">{{ doc?.docType?.descr[lang] }}</a>
    ({{ 'DOC.VALIDFROM' | translate | lowercase}} {{ doc?.validFrom | date: localDateFormat }} - 
    {{ 'DOC.VALIDTO' | translate | lowercase}} {{ doc?.validTo | date: localDateFormat }}) 
    <span *ngIf="doc?.fileOriginName">
        <!-- LAB a tag is around button because this is a direct link to BE method-->
        <a [href]="'api/document/download/' + doc?.id">
            <button
                id="docDownloadBtnId"
                type="button"
                info [style]="'small'"
                [icon]="'file-download'"
                title="{{'GLOBAL.DOWNLOAD' | translate}}">
            </button>
        </a>
    </span>
