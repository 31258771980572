import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../../_services';
import { BaseComponent } from 'src/app/shared/_common/base/base.component';
import { UserInfo } from 'src/app/_domain';
import { Notifications } from 'src/app/_domain/notifications.interface';

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.scss']
})
export class UserNotificationsComponent extends BaseComponent implements OnInit, OnDestroy {
  public currentUser: UserInfo;
  @Output() notificationsToRead: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() env = 'ik';
  @Input() lang = 'et';
  @Input() latestNotifications: Notifications;

  constructor() {
    super();
  }

  ngOnInit() {
  }

  setAllNotificationsToRead() {
    this.notificationsToRead.emit(true);
  }

}
