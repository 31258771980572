import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HomePageGuard implements CanActivate {
  constructor(
    private router: Router,
    private translateServ: TranslateService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const lang = this.translateServ.currentLang;
    if (sessionStorage.getItem('currentActiveRoleEnvinronment') === 'ROLE_ADMIN') {
      this.router.navigate([lang + '/mk/toolaud']);
    } else if (sessionStorage.getItem('currentActiveRoleEnvinronment') === 'ROLE_USER') {
      this.router.navigate([lang + '/ik/minu-asjad']);
    }
    return true;
  }
}
