import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { API_ENDPOINT } from '../../app.constants';
import {VersionInfo} from '../../_domain/versioninfo.interface';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  versionInfoKey = 'versionInfo';
  constructor(private http: HttpClient) {}

  getVersionInfo(): Observable<VersionInfo> {
    return this.http
      .get<any>(API_ENDPOINT + 'version')
      .pipe(map((response: any) => {
        sessionStorage.setItem(this.versionInfoKey, JSON.stringify(response.data));
        return response.data;
      }));
  }
}
