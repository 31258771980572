<div class="row mx-0 pt-5">
  <div class="col-20 pb-0" *ngIf="aplWaitingResults && aplWaitingResults.length > 0">
    <div class="row px-5">

      <div *ngIf="pendingRequestLoading" class="col-20 px-0">
        <app-loading-spinner [isBig]="true"></app-loading-spinner>
      </div>

      <div *ngIf="!pendingRequestLoading" class="col-20 px-0">
        <h1 class="mb-1">{{ 'MAINNAV.PENDING_REQUESTS' | translate }}</h1>
        <!-- Ootel taotlused -->
        <div *ngIf="isSearching" class="col-20 py-5 px-0">
          <app-loading-spinner [msg]="'APL.SEARCH_APL_PROCEEDER_DETAILS'"></app-loading-spinner>
        </div>
        <div *ngIf="!isSearching && (aplWaitingResults && aplWaitingResults.length === 0)" class="col-20 py-5 px-0">
          <app-no-results [mainText]="'APL.SEARCH_APL_NORESULTS'"></app-no-results>
        </div>
        <div *ngIf="!isSearching && aplWaitingResults && aplWaitingResults.length > 0" class="col-20 py-5 px-0">
          <app-paginator showBoundaryLinks="true" [(config)]="aplWaitConf" (pageChange)="getAplWaitingPage()">
          </app-paginator>
          <table id="aplWaitTableId" appSortableTable="'aplWaitTableId'"
            class="table table-hover table-adaptive table-cells table-sm table-md w-100">
            <thead class="table-head bg-white">
              <th>
                <!--  sortable-column="registrationNumber" -->
                {{ 'APL.SEARCH_APL_NUMBER' | translate }}
              </th>
              <th>
                <!--  sortable-column="claListitemByAppType" -->
                {{ 'APL.SEARCH_APL_TYPE' | translate }}
              </th>
              <th>
                <!--  sortable-column="vesselName" -->
                {{ 'APL.SEARCH_APL_VESSEL' | translate }}
              </th>
              <th class="tbl-header-break-words">
                <!-- sortable-column="compSubDate"  -->
                {{ 'APL.SEARCH_APL_PROC_DATE' | translate }}
              </th>
              <th class="d-block d-md-none d-lg-table-cell">
                <!--  sortable-column="applicantName" -->
                {{ 'APL.SEARCH_APPLICANT' | translate }}
              </th>
              <th class="d-block d-md-none d-lg-table-cell">
                <!--  sortable-column="claListitemByAppStatus" -->
                {{ 'APL.SEARCH_APL_STATUS' | translate }}
              </th>
              <th>{{ 'APL.SEARCH_APL_ACTIONS' | translate }}</th>
            </thead>
            <tbody class="bg-white">
              <tr *ngFor="let apl of aplWaitingResults | paginate: aplWaitConf ; let i = index">
                <td [attr.data-label]="('APL.SEARCH_APL_NUMBER' | translate) + ':'">
                  {{ apl.registrationNumber }}
                </td>
                <td [attr.data-label]="('APL.SEARCH_APL_TYPE' | translate) + ':'">
                  {{ apl.claListitemByAppType.descr[lang]}}
                </td>
                <td [attr.data-label]="('APL.SEARCH_APL_VESSEL' | translate) + ':'">
                  <span class="has-comment">
                    {{ apl.vesselName }}
                    <span class="label-comment">
                      {{ apl.imo }} {{ apl.hinCinWin }}
                    </span>
                  </span>
                </td>
                <td [attr.data-label]="('APL.SEARCH_APL_PROC_DATE' | translate) + ':'">
                  {{ apl.compSubDate | date: localDateFormat}}
                </td>
                <td [attr.data-label]="('APL.SEARCH_APPLICANT' | translate) + ':'"
                  class="d-block d-md-none d-lg-table-cell">
                  {{ apl.applicantName }}
                </td>
                <td [attr.data-label]="('APL.SEARCH_APL_STATUS' | translate) + ':'"
                  class="d-block d-md-none d-lg-table-cell text-center">
                  <span class="badge" [ngClass]="{'20001':'badge-warning','20002':'badge-warning','20009':'badge-default',
                  '20011':'badge-info','20012':'badge-info','20031':'badge-orange','20091':'badge-success','20092':'badge-danger'
                }[apl.claListitemByAppStatus?.code]">
                    <span class="badge-text">
                      {{ apl.claListitemByAppStatus.descr[lang]}}
                    </span>
                  </span>
                </td>
                <td class="d-flex justify-content-center">
                  <a class="float-left" [routerLink]="[aplDetailRoutePath + apl.id]">
                    <button [style]="'small'" light [icon]="'eye'" title="{{ 'GLOBAL.LOOK' | translate }}"></button>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
          <app-paginator showBoundaryLinks="true" [(config)]="aplWaitConf" (pageChange)="getAplWaitingPage()">
          </app-paginator>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="notFinishedAplLoading" class="col-20 py-0">
    <app-loading-spinner [isBig]="true"></app-loading-spinner>
  </div>

  <div *ngIf="!notFinishedAplLoading" class="col-20 py-0">
    <div class="row  px-5">
      <div class="col-20 px-0 d-flex justify-content-between">
        <h1 class="mb-1">
          <span>{{ 'DS.TAB1' | translate }}</span>
        </h1>
        <div>
          <button *ngIf="newApplicationIsVisible" info [style]="'square'" [icon]="'with-plus'" [popover]="newToimingTpl"
            [outsideClick]="true"><span>{{'DS.BTN1' | translate}}</span></button>
        </div>
      </div>
    </div>
    <!-- Minu pooleliolevad taotlused -->
    <div *ngIf="isSearching" class="col-20 py-5 px-0">
      <app-loading-spinner [msg]="'APL.SEARCH_APPLICATION_DATA'"></app-loading-spinner>
    </div>
    <div *ngIf="!isSearching && (aplProceederResults && aplProceederResults.length==0)" class="col-20 py-5 px-0">
      <div class="text-center">
        <h2 class="my-3 font-weight-normal"> {{ 'APL.SEARCH_APL_NORESULTS' | translate }} </h2>
      </div>
    </div>
    <div *ngIf="!isSearching && aplProceederResults && aplProceederResults.length > 0" class="col-20 py-5 px-0">
      <app-paginator showBoundaryLinks="true" [(config)]="aplProceederConf" (pageChange)="getAplProceederPage()">
      </app-paginator>
      <table id="aplProcTableId" appSortableTable="'aplProcTableId'" (sorted)="onSorted($event)"
        class="table table-hover table-adaptive table-cells table-sm table-md w-100">
        <thead class="table-head bg-white">
          <th>
            <!-- sortable-column="registrationNumber" -->
            {{ 'APL.SEARCH_APL_NUMBER' | translate }}
          </th>
          <th>
            <!-- sortable-column="appType" -->
            {{ 'APL.SEARCH_APL_TYPE' | translate }}
          </th>
          <th>
            <!-- sortable-column="vesselName" -->
            {{ 'APL.SEARCH_APL_VESSEL' | translate }}
          </th>
          <th class="tbl-header-break-words">
            <!-- sortable-column="compSubDate" -->
            {{ 'APL.SEARCH_APL_PROC_DATE' | translate }}
          </th>
          <th class="d-block d-md-none d-lg-table-cell">
            <!-- sortable-column="applicantName" -->
            {{ 'APL.SEARCH_APPLICANT' | translate }}
          </th>
          <th class="d-block d-md-none d-lg-table-cell">
            <!--  sortable-column="claListitemByAppStatus"  -->
            {{ 'APL.SEARCH_APL_STATUS' | translate }}
          </th>
          <th>{{ 'APL.SEARCH_APL_ACTIONS' | translate }}</th>
        </thead>
        <tbody class="bg-white">
          <tr *ngFor="let apl of aplProceederResults | paginate: aplProceederConf ; let i = index">
            <td [attr.data-label]="('APL.SEARCH_APL_NUMBER' | translate) + ':'">
              {{ apl.registrationNumber }}
            </td>
            <td [attr.data-label]="('APL.SEARCH_APL_TYPE' | translate) + ':'">
              {{ apl.claListitemByAppType.descr[lang]}}
            </td>
            <td [attr.data-label]="('APL.SEARCH_APL_VESSEL' | translate) + ':'">
              <span class="has-comment">
                {{ apl.vesselName }}
                <span class="label-comment">
                  {{ apl.imo }} {{ apl.hinCinWin }}
                </span>
              </span>
            </td>
            <td [attr.data-label]="('APL.SEARCH_APL_PROC_DATE' | translate) + ':'">
              {{ apl.compSubDate | date: localDateFormat}}
            </td>
            <td [attr.data-label]="('APL.SEARCH_APPLICANT' | translate) + ':'"
              class="d-block d-md-none d-lg-table-cell">
              {{ apl.applicantName }}
            </td>
            <td [attr.data-label]="('APL.SEARCH_APL_STATUS' | translate) + ':'"
              class="d-block d-md-none d-lg-table-cell text-center">
              <span class="badge" [ngClass]="{'20001':'badge-warning','20002':'badge-warning','20009':'badge-default',
                '20011':'badge-info','20012':'badge-info','20031':'badge-orange','20091':'badge-success','20092':'badge-danger'
              }[apl.claListitemByAppStatus?.code]">
                <span class="badge-text">
                  {{ apl.claListitemByAppStatus.descr[lang]}}
                </span>
              </span>
            </td>
            <td class="d-flex justify-content-center">
              <a class="float-left" [routerLink]="[aplDetailRoutePath + apl.id]">
                <button [style]="'small'" light [icon]="'eye'" title="{{ 'GLOBAL.LOOK' | translate }}"></button>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <app-paginator showBoundaryLinks="true" [(config)]="aplProceederConf" (pageChange)="getAplProceederPage()">
      </app-paginator>
    </div>
  </div>
</div>
<app-loading-spinner [isBig]="true" *ngIf="notFinishedInsLoading"></app-loading-spinner>
<div
  *ngIf="!notFinishedInsLoading && (userInfo.privileges.includes('VLAEV_INSPEKTOR') || userInfo.privileges.includes('YLEV_ANDMED'))"
  class="col-20 py-0">
  <div class="col-20 px-0">
    <div class="d-flex justify-content-between">
      <h1 class="mb-1">
        {{ 'DS.TAB4' | translate }}
      </h1>
      <button info [style]="'square'" [icon]="'with-plus'" (click)="createInspection()"
        *ngIf="userInfo.privileges.includes('VLAEV_INSPEKTOR')">
        <span>{{'DS.BTN2' | translate}}</span>
      </button>
    </div>
  </div>
  <div class="row px-5"
    *ngIf="userInfo.privileges.includes('VLAEV_ANDMED') || userInfo.privileges.includes('YLEV_ANDMED')">
    <!-- /.row -->
    <!-- andmete plokk -->
    <div class="col-20 px-0 row bg-white m-0">
      <div *ngIf="isSearching" class="col-20 py-5 px-0">
        <app-loading-spinner [msg]="'APL.SEARCH_APL_PROCEEDER_DETAILS'"></app-loading-spinner>
      </div>
      <div *ngIf="!isSearching && (inspectionSearchResults && inspectionSearchResults.length==0)"
        class="col-20 py-5 px-0">
        <div class="text-center">
          <h2 class="my-3 font-weight-normal"> {{ 'APL.SEARCH_APL_NORESULTS' | translate }} </h2>
        </div>
      </div>
      <div *ngIf="!isSearching && inspectionSearchResults && inspectionSearchResults.length > 0"
        class="col-20 py-5 px-0">
        <app-paginator showBoundaryLinks="true" [(config)]="inspectionPageConf" (pageChange)="getInspectionPage()">
        </app-paginator>
        <table appSortableTable="insAuditSearchResTable" (sorted)="onInsSorted($event)"
          class="table table-hover table-adaptive table-cells table-sm table-md w-100">
          <thead class="table-head bg-white">
            <th sortable-column="subtypeCode" scope="col">
              <a>{{ 'GLOBAL.INS_AUDIT_TYPE' | translate }}</a>
            </th>
            <th sortable-column="vesselName" scope="col">
              <a>{{ 'GLOBAL.SHIP' | translate }}</a>
            </th>
            <th sortable-column="appType" scope="col">
              <a>{{ 'GLOBAL.APL_TYPE' | translate }}</a>
            </th>
            <th sortable-column="startDate" sort-direction="desc" scope="col" class="tbl-header-break-words">
              <a>{{ 'GLOBAL.EXE_DATE' | translate }}</a>
            </th>
            <th sortable-column="place" scope="col">
              <a>{{ 'GLOBAL.PLACE' | translate }}</a>
            </th>
            <th sortable-column="teamMembers" scope="col" class="d-block d-md-none d-lg-table-cell">
              <a>{{ 'GLOBAL.COMMITTEE' | translate }}</a>
            </th>
            <th sortable-column="status" scope="col" class="d-block d-md-none d-lg-table-cell">
              <a>{{ 'INS.INS_STATUS' | translate }}</a>
            </th>
            <th class="text-right" scope="col">{{ 'GLOBAL.ACTIONS' | translate }}</th>
          </thead>
          <tbody class="bg-white">
            <tr *ngFor="let ins of inspectionSearchResults | paginate: inspectionPageConf; let i = index">
              <td [attr.data-label]="('GLOBAL.INS_AUDIT_TYPE' | translate) + ':'">
                <span class="d-block" *ngIf="ins?.type?.claInspectionTypeByTypeCode?.descr">
                  {{ ins?.type?.claInspectionTypeByTypeCode?.descr[lang] }}
                </span>
                <span class="d-block" *ngIf="ins?.type.descr">
                  {{ ins?.type?.descr[lang] }}
                </span>
              </td>
              <td [attr.data-label]="('GLOBAL.SHIP' | translate) + ':'">
                <label class="has-comment">
                  {{ ins.vesVesselByVesselId?.vesselName }}
                  <!-- if we have imo, then no hinCinWin and reverse is also true -->
                  <span *ngIf="ins.vesVesselByVesselId?.hinCinWin !== null" class="label-comment">
                    {{ ins.vesVesselByVesselId?.hinCinWin }}
                  </span>
                  <span *ngIf="ins.vesVesselByVesselId?.imo !== null" class="label-comment">
                    {{ ins.vesVesselByVesselId?.imo }}
                  </span>
                </label>
              </td>
              <td [attr.data-label]="('GLOBAL.APL_TYPE' | translate) + ':'">
                <label class="has-comment">
                  {{ ins.appApplicationByApplicationId?.claListitemByAppType?.descr[lang] }}
                  <span class="label-comment">
                    {{ ins.appApplicationByApplicationId?.registrationNumber }}
                  </span>
                </label>
              </td>
              <td [attr.data-label]="('GLOBAL.EXE_DATE' | translate) + ':'">
                <div *ngIf="ins.startDate !== null && ins.startDate !== undefined">
                  {{ ins.startDate | date: localDateTimeFormat }}
                  <!-- - {{ ins.endDate?.dateTime?.date }} -->
                </div>
              </td>
              <td [attr.data-label]="('GLOBAL.PLACE' | translate) + ':'">
                {{ ins.location }}
              </td>
              <td [attr.data-label]="('GLOBAL.COMMITTEE' | translate) + ':'" class="d-block d-md-none d-lg-table-cell">
                {{ ins.teamMembers }}
              </td>
              <td [attr.data-label]="('INS.INS_STATUS' | translate) + ':'"
                class="d-block d-md-none d-lg-table-cell text-center">
                <span class="badge" [ngClass]="{'30001':'badge-info','30002':'badge-success','30003':'badge-warning',
                '30009':'badge-info','30010':'badge-danger'
              }[ins.claListitemByStatus?.code]">
                  <span class="badge-text">
                    {{ ins.claListitemByStatus.descr[lang]}}
                  </span>
                </span>
                <!-- <div [ngSwitch]="ins.claListitemByStatus?.code">
                  <span *ngSwitchCase="30001" class="badge badge-info">
                    <span class="badge-text">
                      {{ 'INS.STATUSES.IN_PLANNING' | translate }}
                    </span>
                  </span>
                  <span *ngSwitchCase="30002" class="badge badge-success">
                    <span class="badge-text">
                      {{ 'INS.STATUSES.IN_PREPARATION' | translate }}
                    </span>
                  </span>
                  <span *ngSwitchCase="30003" class="badge badge-warning">
                    <span class="badge-text">
                      {{ 'INS.STATUSES.IN_FORMATION' | translate }}
                    </span>
                  </span>
                  <span *ngSwitchCase="30009" class="badge badge-info">
                    <span class="badge-text">
                      {{ 'INS.STATUSES.ENDED' | translate }}
                    </span>
                  </span>
                  <span *ngSwitchCase="30010" class="badge badge-danger">
                    <span class="badge-text">
                      {{ 'INS.STATUSES.CANCELED' | translate }}
                    </span>
                  </span>
                </div> -->
              </td>
              <td class="d-flex justify-content-center">
                <button light *ngIf="ins.claListitemByStatus.code !== 30001 && ins.type.typeCode === 100"
                  [routerLink]="['/', lang, 'mk', 'ylevaatused', 'ylevaatus', ins.id]" [style]="'small'" [icon]="'eye'"
                  title="{{ 'GLOBAL.LOOK' | translate }}"></button>
                <!-- kui type_code in (200, 300, 400),  siis kuvada ülevaatuse toimik -->
                <button light
                  *ngIf="ins.claListitemByStatus.code !== 30001 && (ins.type.typeCode === 200 || ins.type.typeCode === 300 || ins.type.typeCode === 400)"
                  [routerLink]="['/', lang, 'mk', 'ylevaatused', 'audit', ins.id]" [style]="'small'" [icon]="'eye'"
                  title="{{ 'GLOBAL.LOOK' | translate }}"></button>
                <button light *ngIf="ins.claListitemByStatus.code !== 30001 && ins.type.typeCode === 600"
                  [routerLink]="['/', lang, 'mk', 'ylevaatused', 'ylevaatus','vaikelaev', ins.id]" [style]="'small'"
                  [icon]="'eye'" title="{{ 'GLOBAL.LOOK' | translate }}"></button>
                <button *ngIf="ins.claListitemByStatus.code !== 30001 && ins.editRights === true" info type="button"
                  custom-class="" [style]="'small'" [icon]="'square-pencil2'" title="{{'GLOBAL.EDIT' | translate}}"
                  (click)="openAddEditModal(ins.id)">
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <app-paginator showBoundaryLinks="true" [(config)]="inspectionPageConf" (pageChange)="getInspectionPage()">
        </app-paginator>
      </div>
    </div>
  </div>
</div>

<ng-template #newToimingTpl>
  <div id="popover-client-select" class="chooseRepresentative pb-2">
    <div class="body p-2">
      <h3 class="mb-2 font-weight-normal">{{ 'DS.LBL2' | translate }}</h3>
      <ul>
        <li>
          <a (click)="regNew(21001)">
            <span>{{ 'DS.LBL3' | translate}}</span>
          </a>
        </li>
        <li>
          <a (click)="regNew(21002)">
            <span>{{ 'DS.LBL4' | translate}}</span>
          </a>
        </li>
        <li>
          <a (click)="regNew(21003)">
            <span>{{ 'DS.LBL5' | translate}}</span>
          </a>
        </li>
        <li>
          <a (click)="regNew(21004)">
            <span>{{ 'DS.LBL6' | translate}}</span>
          </a>
        </li>
      </ul>
      <h3 class="my-2 font-weight-normal">{{ "DS.LBL7" | translate }}</h3>
      <ul>
        <li>
          <a (click)="regNew(21005)">
            <span>{{ 'DS.LBL8' | translate}}</span>
          </a>
        </li>
        <!--  <li>
          <a>
            <span>{{ 'DS.LBL9' | translate}}</span>
          </a>
        </li>
        <li>
          <a>
            <span>{{ 'DS.LBL10' | translate}}</span>
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</ng-template>
