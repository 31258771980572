import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  HostBinding
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

import { Subscription } from 'rxjs';

import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { etLocale, enGbLocale } from 'ngx-bootstrap/locale';

import { LangswitchService } from '../../_services';
import { DatePipe } from '@angular/common';

etLocale.invalidDate = 'vale kuupäev'; // TODO LAB from translate
defineLocale('et', etLocale);
defineLocale('en', enGbLocale);

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DatepickerComponent,
      multi: true
    },
    DatePipe
  ]
})
export class DatepickerComponent extends FormControl implements OnInit, OnDestroy, ControlValueAccessor {
  DATE_TIME_FORMAT = 'DD.MM.YYYY';
  DATE_TIME_FORMAT_FOR_PIPE = 'dd.MM.yyyy';
  private langServSubs: Subscription;
  @Input()
  placeholder = '';
  @Input()
  maxDate = '';
  @Input()
  minDate = '';
  @Input()
  inputValue = null;
  // TODO
  // @Input()
  // disabled: any;
  @Input()
  formControlName: any;
  currentDisabled: boolean;
  @ViewChild('dateInput', { static: true, read: ElementRef })
  inputElementRef: ElementRef;

  public value: Date;

  constructor(
    private langswitchService: LangswitchService,
    private localeService: BsLocaleService,
    private renderer: Renderer2,
    public datepipe: DatePipe
  ) {
    super();
  }

  ngOnInit() {
    this.langServSubs = this.langswitchService.routeLanguage.subscribe(clang => {
      this.localeService.use(clang);
    });
  }

  ngOnDestroy() {
    this.langServSubs.unsubscribe();
  }

  onChange(event: Date) {
    // console.log('DatepickerComponent onChange', this.currentDisabled, this);
    this.inputValue = new DatePipe('et_EE').transform(event, this.DATE_TIME_FORMAT_FOR_PIPE);
    this.value = event;
    /* if (this.value) {
      this.value.setHours(0, 0, 0, 0);
    } */
    this.onChanged(this.value);
    this.onTouched();
  }

  onChanged: any = () => { };
  onTouched: any = () => { };

  writeValue(value) {
    // this.currentDisabled = this.inputElementRef.nativeElement.disabled;
    // console.log('datepicker writeval', this.inputElementRef.nativeElement.disabled, this);
    this.value = value;
    if (value) {
      const valueString = this.datepipe.transform(this.value, this.DATE_TIME_FORMAT_FOR_PIPE);
      this.renderer.setProperty(this.inputElementRef.nativeElement, 'value', valueString);
      this.inputValue = valueString;
    } else {
      this.inputValue = null;
    }
  }

  registerOnChange(fn: any) {
    // console.log('datepicker registeronChange', fn);
    this.onChanged = fn;
  }
  registerOnTouched(fn: any) {
    // console.log('datepicker registeronTouched', fn);
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    this.currentDisabled = isDisabled;
    this.inputElementRef.nativeElement.disabled = isDisabled;
  }
}
