import { Component, OnInit } from '@angular/core';
import { SystemService } from '../_services/system.service';
import { BaseComponent } from '../_common/base/base.component';
import { VersionInfo } from '../../_domain/versioninfo.interface';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
  public version: VersionInfo;
  public isLoading = false

  constructor(private systemService: SystemService) { super(); }

  ngOnInit() {
    this.isLoading = true;
    const versionInfo = JSON.parse(sessionStorage.getItem('versionInfo'));
    if (versionInfo !== undefined && versionInfo !== null) {
      this.version = versionInfo;
      this.isLoading = false;
    } else {
      this.addSubscriptionToList(this.systemService.getVersionInfo().subscribe(data => {
        this.version = data;
        this.isLoading = false;
      }));
    }
  }
}
