<div class="d-flex">
  <input
  [disabled]="currentDisabled"
    [placeholder]="placeholder"
    type="text"
    class="form-control"
    bsDatepicker
    #dp="bsDatepicker"
    #dateInput
    [bsConfig]="{ dateInputFormat: DATE_TIME_FORMAT, containerClass: 'theme-dark-blue' }"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [value]="inputValue"
    (bsValueChange)="onChange($event)"
  />
  <a class="picker-btn icon-calendar ml-1 mt-1" role="button" (click)="dp.show()"></a>
</div>
