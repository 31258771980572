import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-formgroup-multi-row',
  templateUrl: './formgroup-multi-row.component.html',
  styleUrls: ['./formgroup-multi-row.component.scss']
})
export class FormgroupMultiRowComponent implements OnInit {

  @Input() label1: any;
  @Input() label2: any;
  @Input() val1: any;
  @Input() val2: any;

  constructor() {
  }

  ngOnInit() {
  }

}
