import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Global shared module
import { SharedModule, HttpLoaderFactory } from '../shared/shared.module';

import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    SharedModule,
    HomeRoutingModule,
    TranslateModule
  ],
  exports: [HomeComponent],
})
export class HomeModule { }
