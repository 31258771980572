<div class="modal-header pb-1 mb-0">
  <h2>{{dialogTitle}}</h2>
  <button light [style]="'round'" [icon]="'exit'" title="{{ 'GLOBAL.CLOSE' | translate }}" (click)="closeModal()"></button>
</div>
<div class="modal-body  mb-0">
  <div class="row mb-0">
    <div class="col-20 mb-0">
      <!--
        Create ####################################################################
      -->
      <div [formGroup]="ownershipModalForm">
        <div *ngIf="!isUpdate" id="modalBodyCreateOwnershipId" class="col-20 px-0 row mx-0 w-100">
          <div class="form-group col-20 pl-5 pr-0 pb-0" *ngIf="(!autPerson && (citizenship === CitizenshipEnum.EST || citizenship === CitizenshipEnum.FGN))">
            <div class="form-check form-check-inline">
              <label class="form-check-label" for="check1">
                {{'APL.REQ_OWN_HOME_COUNTRY' | translate}}
              </label>
              <input class="form-check-input" id="check1" type="radio" [checked]="citizenship === CitizenshipEnum.EST" (click)="setCitizenshipAndUpdateValidators(CitizenshipEnum.EST)">
            </div>
            <div *ngIf="canAddPersonFromForeignCountry" class="form-check form-check-inline">
              <label class="form-check-label" for="check2">
                {{'APL.REQ_OWN_OTHER_COUNTRY' | translate}}
              </label>
              <input class="form-check-input" id="check2" type="radio" [checked]="citizenship === CitizenshipEnum.FGN" (click)="setCitizenshipAndUpdateValidators(CitizenshipEnum.FGN)">
            </div>
          </div>
          <hr *ngIf="!autPerson">
          <div *ngIf="!autPerson && citizenship === CitizenshipEnum.EST" class="col-20">
            <div class="form-row pb-3">
              <label class="col-form-label" for="codeinput">
                {{'APL.REQ_OWN_ICODE' | translate}}/{{'APL.REQ_OWN_RCODE' | translate}}: </label>
              <div>
                <input type="text" [ngClass]="formSubmitted && !ownershipModalForm.controls?.idOrRegistryCode?.valid ? 'form-control is-invalid' : 'form-control'" formControlName="idOrRegistryCode" [maxLength]="11" id="codeinput">
                <div *ngIf="formSubmitted && ownershipModalForm.controls?.idOrRegistryCode?.errors?.required" class="invalid-feedback">
                  {{'ERROR.REQUIRED' | translate}}
                </div>
                <div *ngIf="formSubmitted && ownershipModalForm.controls?.idOrRegistryCode?.errors?.pattern" class="invalid-feedback">
                  {{'ERROR.ID_OR_REGISTRY_CODE' | translate}}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <button [disabled]="+ownershipModalForm?.value?.idOrRegistryCode?.length === 0 || isReadDataLoading === true || ( +ownershipModalForm.value?.idOrRegistryCode?.length !== 8 && +ownershipModalForm.value?.idOrRegistryCode?.length !== 11 )"
                info [style]="'square'" type="button" custom-class="mt-1" (click)="getAutPerson(ESTONIA_COUNTRY_ID, ownershipModalForm.controls['idOrRegistryCode']?.value, null);">
                <span>
                  {{ 'APL.REQ_OWN_READ_DATA' | translate }}
                </span>
              </button>
              <div *ngIf="isReadDataLoading" class="ml-3">
                <app-loading-spinner></app-loading-spinner>
              </div>
            </div>
          </div>
          <div *ngIf="autPerson && citizenship === CitizenshipEnum.EST" class="col-20 px-0 w-100 row mx-0 px-5">
            <div class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
              <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0">{{ 'APL.REQ_OWN_COUNTRY' | translate }}:</label>
              <div class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                <div class="input-group">
                  <span>{{ countryMap.get(autPerson?.countryId) }}</span>
                </div>
              </div>
            </div>
            <div class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
              <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0">
                {{(autPerson?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_ICODE' : 'APL.REQ_OWN_RCODE') | translate}}: </label>
              <div class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                <div class="input-group">
                  <span>{{ autPerson?.registerCode }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="autPerson?.personType === PersonTypeEnum.F" class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
              <label class="col-xs-20 col-md-4 col-form-label pt-0 pl-0">
                {{'APL.REQ_OWN_FNAME' | translate}}: </label>
              <div class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                <div class="input-group">
                  <span>{{ autPerson?.firstName }}</span>
                </div>
              </div>
            </div>
            <div class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
              <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0">
                {{(autPerson?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_LNAME' : 'APL.REQ_OWN_NAME') | translate}}: </label>
              <div class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                <div class="input-group">
                  <span>{{ autPerson?.name }}</span>
                </div>
              </div>
            </div>
            <div *ngIf="showContactsFields">
              <ng-container *ngTemplateOutlet="contactsdata"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="fracture"></ng-container>
          </div>
          <!--
             Muu riik
           -->
          <div *ngIf="citizenship === CitizenshipEnum.FGN" class="form-group col-20 pl-1 pr-5 pr-0">
            <!-- Person type -->
            <div class="form-group ml-5 mb-3">
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="check4">{{'APL.REQ_OWN_J_PERSON' | translate}}</label>
                <input class="form-check-input" type="radio" id="check4" [checked]="personType === PersonTypeEnum.J" (click)="changePersonType(PersonTypeEnum.J)">
              </div>
              <div class="form-check form-check-inline">
                <label class="form-check-label" for="check3">{{'APL.REQ_OWN_F_PERSON' | translate}}</label>
                <input class="form-check-input" type="radio" id="check3" [checked]="personType === PersonTypeEnum.F" (click)="changePersonType(PersonTypeEnum.F)">
              </div>
            </div>
            <div class="row w-100 mx-0 mb-0">
              <label class="col-xs-20 col-md-4 col-form-label pl-0 text-right pt-1 requiredField" for="select_country">
                {{'APL.REQ_OWN_COUNTRY' | translate}}: </label>
              <div class="col-xs-20 col-md-16 mb-0 pl-0 pr-0 mb-2">
                <select class="form-control w-100 " id="select_country" formControlName="country" (ngModelChange)="selectCountry($event)" (click)="showSavedPerson = true; searchType = 5">
                  <option *ngFor="let country of countries" [ngValue]="country">
                    {{country.name[lang.toLowerCase()]}}
                  </option>
                </select>
                <div *ngIf="showSavedPerson && personsFound?.length > 0 && searchType === 5">
                  <!-- personsFound?.length > 0 && -->
                  <ng-container *ngTemplateOutlet="chooseSavedPersonTbl"></ng-container>
                  <!-- <span class="dropdown-item-custom" *ngFor="let person of personsFound" (click)="selectPerson(person)">
                    {{person?.registerCode + ', ' + (person?.firstName ? person?.firstName + ' ' + person?.name : person?.name)}}
                  </span> -->
                </div>
              </div>
              <label class="col-xs-20 col-md-4 col-form-label pl-0 text-right pt-1" for="code1">
                {{ (personType === PersonTypeEnum.F ? 'APL.REQ_OWN_ICODE' : 'APL.REQ_OWN_RCODE') | translate }}: </label>
              <div class="col-xs-20 col-md-16 mb-0 pl-0 pr-0 mb-2">
                <input id="code1" autocomplete="off" type="text" [maxLength]="20" formControlName="idOrRegistryCode" class="form-control requiredField" (click)="showSavedPerson = true; searchType = 1">
                <div *ngIf="showSavedPerson && personsFound?.length > 0 && searchType === 1">
                  <!-- personsFound?.length > 0 &&  -->
                  <ng-container *ngTemplateOutlet="chooseSavedPersonTbl"></ng-container>
                  <!-- <span class="dropdown-item-custom" *ngFor="let person of personsFound" (click)="selectPerson(person)">
                    {{person?.registerCode + ', ' + (person?.firstName ? person?.firstName + ' ' + person?.name : person?.name)}}
                  </span> -->
                </div>
              </div>
              <div *ngIf="personType === PersonTypeEnum.F" class="form-group col-20 px-0 row mx-0">
                <label for="firstname" class="col-xs-20 col-md-4 col-form-label pl-0 text-right pt-1" [ngClass]="!ownershipModalForm.value.idOrRegistryCode ? 'requiredField': ''">
                  {{'APL.REQ_OWN_FNAME' | translate}}: </label>
                <div class="col-xs-20 col-md-16 mb-0 pl-0 pr-0">
                  <input type="text" class="form-control" id="firstname" formControlName="firstName" [ngClass]="formSubmitted && !ownershipModalForm.controls?.firstName?.valid ? 'form-control is-invalid' : 'form-control'">
                  <div *ngIf="formSubmitted && ownershipModalForm.controls?.firstName?.errors?.required" class="invalid-feedback">
                    {{'ERROR.REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div class="form-group col-20 px-0 row mx-0">
                <label class="col-xs-20 col-md-4 col-form-label pl-0 text-right pt-1" for="code2" [ngClass]="!ownershipModalForm.value.idOrRegistryCode ? 'requiredField': ''">
                  {{(personType === PersonTypeEnum.F ? 'APL.REQ_OWN_LNAME' : 'APL.REQ_OWN_NAME') | translate}}: </label>
                <div class="col-xs-20 col-md-16 mb-0 pl-0 pr-0">
                  <input id="code2" autocomplete="off" type="text" class="form-control" formControlName="name" (click)="showSavedPerson = true; searchType = 2"
                    [ngClass]="formSubmitted && !ownershipModalForm.controls?.name?.valid ? 'form-control is-invalid' : 'form-control'">
                  <div *ngIf="showSavedPerson && personsFound?.length > 0 && searchType === 2">
                    <!--  -->
                    <ng-container *ngTemplateOutlet="chooseSavedPersonTbl"></ng-container>
                  </div>
                  <div *ngIf="formSubmitted && ownershipModalForm.controls?.name?.errors?.required" class="invalid-feedback">
                    {{'ERROR.REQUIRED' | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="showContactsFields">
              <ng-container *ngTemplateOutlet="contactsdata"></ng-container>
            </div>
            <ng-container *ngTemplateOutlet="fracture"></ng-container>
          </div>
          <div class="footer row" *ngIf="(citizenship === CitizenshipEnum.EST && autPerson) || citizenship === CitizenshipEnum.FGN" class="col-20 d-flex justify-content-end mb-0 mt-4 px-0">
            <div class="mb-0">
              <button id="addIsikSaveBtnId" success [style]="'square'" type="button" (click)="save();" [icon]="'save'" [disabled]="!emailValid || citizenship === CitizenshipEnum.FGN  && (
                (personType === PersonTypeEnum.F && (!ownershipModalForm.controls['country']?.value || (ownershipModalForm.controls['country']?.value && ownershipModalForm.controls['country']?.value?.id === 0 )
                   || !ownershipModalForm.value.firstName || !ownershipModalForm.value.name)
                ) ||
                (personType === PersonTypeEnum.J &&
                  (!ownershipModalForm.controls['country']?.value || (ownershipModalForm.controls['country']?.value && ownershipModalForm.controls['country']?.value?.id === 0 )
                   || !ownershipModalForm.value.name)
                ))">
                <span>{{'GLOBAL.SAVE' | translate}}</span> </button><!-- createOwnership() -->
            </div>
            <div class="ml-2 mb-0">
              <button danger [style]="'square'" type="button" (click)="closeModal()" [icon]="'cancel'">
                <span>{{'GLOBAL.CANCEL' | translate}}</span> </button>
            </div>
          </div>
        </div>
        <!--
          Update ####################################################################
        -->
        <div *ngIf="isUpdate" id="modalBodyUpdateOwnershipId" class="col-20 px-0 w-100 mx-0 pl-0 pr-5">
          <div class="row px-0">
            <div class="col-20">
              <div class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
                <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0">{{'APL.REQ_OWN_COUNTRY' | translate}}:</label>
                <div class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                  <div class="input-group">
                    <span>{{ countryMap.get(editedOwnership?.personCountryId) }}</span>
                  </div>
                </div>
              </div>
              <div class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
                <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0">
                  {{(editedOwnership?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_ICODE' : 'APL.REQ_OWN_RCODE') | translate}}: </label>
                <div *ngIf="citizenship !== CitizenshipEnum.FGN" class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                  <div class="input-group">
                    <span>{{ editedOwnership?.personRegCode }}</span>
                  </div>
                </div>
                <div *ngIf="citizenship === CitizenshipEnum.FGN" class="col-xs-20 col-md-16 pr-0 mb-2 pl-0">
                  <input type="text" class="form-control" id="updateIdOrRegistryCodeId" formControlName="idOrRegistryCode"
                    [ngClass]="formSubmitted && ownershipModalForm.controls?.idOrRegistryCode?.errors?.required ? 'form-control is-invalid' : 'form-control'">
                  <div *ngIf="formSubmitted && ownershipModalForm.controls?.idOrRegistryCode?.errors?.required" class="invalid-feedback">
                    {{'ERROR.REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div *ngIf="editedOwnership?.personType === PersonTypeEnum.F" class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
                <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0" [ngClass]=" citizenship === CitizenshipEnum.FGN ?'requiredField': ''">
                  {{'APL.REQ_OWN_FNAME' | translate}}: </label>
                <div *ngIf="citizenship !== CitizenshipEnum.FGN" class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                  <div class="input-group">
                    <span>{{ editedOwnership?.personFirstName }}</span>
                  </div>
                </div>
                <div *ngIf="citizenship === CitizenshipEnum.FGN" class="col-xs-20 col-md-16 mb-2 pl-0 pr-0">
                  <input type="text" class="form-control" id="firstname" formControlName="firstName" [ngClass]="formSubmitted && !ownershipModalForm.controls?.firstName?.valid ? 'form-control is-invalid' : 'form-control'">
                  <div *ngIf="formSubmitted && ownershipModalForm.controls?.firstName?.errors?.required" class="invalid-feedback">
                    {{'ERROR.REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div class="form-group col-20 px-0 pb-0 row mx-0 mb-0">
                <label class="col-xs-20  col-md-4 col-form-label pt-0 pl-0" [ngClass]=" citizenship === CitizenshipEnum.FGN ?'requiredField': ''">
                  {{(editedOwnership?.personType === PersonTypeEnum.F ? 'APL.REQ_OWN_LNAME' : 'APL.REQ_OWN_NAME') | translate}}: </label>
                <div *ngIf="citizenship !== CitizenshipEnum.FGN" class="col-xs-20 col-md-16 pr-0 mb-0 pl-0">
                  <div class="input-group">
                    <span>{{ editedOwnership?.personName }}</span>
                  </div>
                </div>
                <div *ngIf="citizenship === CitizenshipEnum.FGN" class="col-xs-20 col-md-16 mb-2 pl-0 pr-0">
                  <input id="code2" autocomplete="off" type="text" class="form-control" formControlName="name" [ngClass]="formSubmitted && !ownershipModalForm.controls?.name?.valid ? 'form-control is-invalid' : 'form-control'">
                  <div *ngIf="formSubmitted && ownershipModalForm.controls?.name?.errors?.required" class="invalid-feedback">
                    {{'ERROR.REQUIRED' | translate}}
                  </div>
                </div>
              </div>
              <div *ngIf="showContactsFields && editedOwnership?.personType !== 'R'">
                <ng-container *ngTemplateOutlet="contactsdata"></ng-container>
              </div>
              <ng-container *ngTemplateOutlet="fracture"></ng-container>
              <div class="footer row w-100  mx-0">
                <div class="col-20 d-flex justify-content-end mb-0 mt-4 px-0">
                  <div class="mb-0">
                    <button success [style]="'square'" type="button" (click)="save();" [icon]="'save'" [disabled]=" citizenship === CitizenshipEnum.FGN  && (
                        ( personType === PersonTypeEnum.F &&
                          ((editedOwnership?.personCountryId === 0)
                          || !ownershipModalForm.value.firstName || !ownershipModalForm.value.name)
                        ) ||
                        (personType === PersonTypeEnum.J &&
                          ((editedOwnership?.personCountryId === 0)
                          || !ownershipModalForm.value.name)
                        ))">
                      <span>{{'GLOBAL.SAVE' | translate}}</span> </button><!-- updateOwnership() -->
                  </div>
                  <div class="mb-0 ml-2">
                    <button danger [style]="'square'" type="button" (click)="closeModal()" [icon]="'cancel'">
                      <span>{{'GLOBAL.CANCEL' | translate}}</span> </button>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- ./row -->
        </div>
      </div>
    </div>
  </div>
</div> <!-- ./modal-body -->
<!--
   TEMPLATES ##################################################################
-->
<!-- Contacts(address and email) template start -->
<ng-template #contactsdata>
  <div [formGroup]="ownershipModalForm" class="form-group col-20 p-0 row mx-0 mb-0">
    <label class="col-xs-20  col-md-4 col-form-label pl-0 text-right pt-1" for="address">
      {{'APL.REQ_OWN_ADDRESS' | translate}}: </label>
    <div class="col-xs-20 col-md-16 mb-0 pl-0 pr-0">
      <div class="form-group pb-0 mb-1">
        <div id="addressSearchFieldsContainer" class="d-flex flex-row align-items-start mb-0 position-relative">
          <app-loading-spinner *ngIf="showIsSearching"></app-loading-spinner>
          <input id="addressInputId" type="text" class="form-control mr-2" formControlName="address" autocomplete="off" (click)='showSearchedAddresses()'>
          <button type="button" [style]="'square'" info [icon]="'contacts'" title="{{ 'GLOBAL.LOOK' | translate }}" [popover]="addressesPopoverTpl" [outsideClick]="true" placement="down" (click)='showSavedAddresses()'>
          </button>
          <div *ngIf="addresses && hideAddressList === false" class="addressDataList data-list-custom ">
            <span title="{{ address.key }}" class="dropdown-item-custom overflow-hidden" *ngFor="let address of addresses | keyvalue" (click)="selectAutPersonsAddress(address.value)">
              {{address.key}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <label class="col-xs-20  col-md-4 col-form-label pl-0 text-right pt-1" for="email">
      {{'APL.REQ_OWN_EMAIL' | translate}}: </label>
    <div class="col-xs-20 col-md-16 pl-0 pr-0 mt-1" [ngClass]="ownershipType === OwnershipTypeEnum.OWNER ? ' mb-0 ' : 'mb-4'">
      <div class="d-block">
        <div class="d-flex flex-row align-items-start mb-0">
          <input type="text" [ngClass]="formSubmitted && !ownershipModalForm.controls?.email?.valid ? 'form-control is-invalid mr-2' : 'form-control mr-2'" id="email" formControlName="email" autocomplete="off">
          <button type="button" [style]="'square'" info [icon]="'contacts'" title="{{ 'GLOBAL.LOOK' | translate }}" [popover]="emailPopoverTpl" [outsideClick]="true" placement="left" (click)='showSavedEmails()'>
          </button>
        </div>
       
        <div *ngIf="(formSubmitted && ownershipModalForm.controls?.email?.errors?.email) || !this.ownershipModalForm.controls.email.valid" class="invalid-feedback">
          {{'ERROR.EMAIL' | translate}}
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Contacts(address and email) template end -->
<!-- Fracture template start -->
<ng-template #fracture>
  <div id="fractureDivId" [formGroup]="ownershipModalForm" *ngIf="ownershipType === OwnershipTypeEnum.OWNER" class="col-20 row mx-0 p-0 mt-1">
    <div class="col-xs-20  col-md-4 pl-4">
      <label class="col-form-label" for="fractureparts">{{'APL.REQ_OWN_FRACTURE_PARTS' | translate}}:</label>
    </div>
    <div class="col-20 col-md-15 pr-0 pl-0 mb-0">
      <div class="d-flex flex-row pl-0 justify-content-start mb-0">
        <div class="d-flex flex-row w-auto justify-content-start mb-0">
          <div class="input-group w-auto mb-0 pt-1">
            <input id="fractureparts" type="text" [maxLength]="4" class="max-width-75 form-control" formControlName="fractureParts">
          </div>
        </div>
        <div class="d-flex flex-row w-auto pl-3 justify-content-start mb-0">
          <label class="col-form-label" for="fracturewhole">{{'APL.REQ_OWN_FRACTURE_WHOLE' | translate}}:</label>
          <div class="input-group w-auto mb-0 pt-1 ml-2">
            <input id="fracturewhole" type="text" [maxLength]="4" class="max-width-75 form-control" formControlName="fractureWhole">
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- Fracture template end -->
<ng-template #addressesPopoverTpl>
  <app-loading-spinner *ngIf="loadingAddresses"></app-loading-spinner>
  <div *ngIf="!loadingAddresses && userAddresses?.length === 0">{{ 'GLOBAL.NO_RESULTS' | translate }}</div>
  <div *ngIf="!loadingAddresses && userAddresses?.length > 0" class="popover-data-list-custom mb-0">
    <span title="{{ address }}" class="dropdown-item-custom overflow-hidden" *ngFor="let address of userAddresses" (click)="selectAutPersonsAddress(address)">
      {{address}}
    </span>
  </div>
</ng-template>
<!-- USER SAVED EMAILS POPOVER  -->
<ng-template #emailPopoverTpl>
  <div *ngIf="showEmailList && (!emails || emails?.length === 0)">{{ 'GLOBAL.NO_RESULTS' | translate }}</div>
  <div *ngIf="showEmailList && emails?.length > 0" class="popover-data-list-custom mb-0">
    <span title="{{ email }}" class="dropdown-item-custom overflow-hidden" *ngFor="let email of emails" (click)="selectAutPersonsEmail(email)">
      {{ email }}
    </span>
  </div>
</ng-template>
<ng-template #chooseSavedPersonTbl>
  <div *ngIf="personsFound?.length > 0" class="data-list-custom pb-1">
    <span class="dropdown-item-custom" *ngFor="let person of personsFound" (click)="selectPerson(person)">
      {{(person?.firstName ? person?.firstName + ' ' + person?.name : person?.name)}}
      {{person?.registerCode ? '(' + person?.registerCode + ')' : ''}}
    </span>
  </div>
</ng-template>
