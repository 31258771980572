import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';

import { UserInfo, UserRole } from '../../../_domain';
import { API_ENDPOINT } from '../../../app.constants';

import { UserService } from '../../_services';
import { BaseComponent } from '../../_common/base/base.component';
import { Router } from '@angular/router';
import { Notifications } from 'src/app/_domain/notifications.interface';
import { GlobalConstants } from 'src/app/common/global-constants';

@Component({
  selector: 'app-userbar',
  templateUrl: './userbar.component.html',
  styleUrls: ['./userbar.component.scss']
})
export class UserbarComponent extends BaseComponent implements OnInit, OnDestroy {
  unreadNotificationsCount = 0;
  latestNotifications: Notifications;
  showPersonalDataHeader = false;
  public currentUser: UserInfo;
  public activeRoleTitle: string;
  public isRoleUser = false;

  env: string;
  lastInLine: any;

  notificationsInterval = GlobalConstants.NOTIFICATION_RETRIEVAL_INTERVAL;
  constructor(
    private router: Router) {
    super();
  }

  async ngOnInit() {
    this.currentUser = this.userService.getUser();
    // amazing fix, if url after first / is #/et or #/en, then showPersonalDataHeader = false
    if (!this.currentUser) {
      await this.getUser();
    }
    //  console.log('userbar', this.currentUser);
    this.addSubscriptionToList(
      this.userService.notificationsCountSubject.subscribe(res => {
        // TODO: Maybe some conditional checks
        this.getUnreadNotifications();
        this.getLatestNotifications();
      }));

    if (this.currentUser === null || this.currentUser === undefined) {
      await this.login();
    } else {
      this.activeRoleTitle = this.userService.getActiveRoleTitle(this.currentUser);
      this.setRoleUserBoolean();
      if (this.currentUser.activeEnvinronment === 'ROLE_ADMIN') {
        this.showPersonalDataHeader = true;
        this.env = 'mk';
      } else {
        this.env = 'ik';
      }
      this.getUnreadNotifications();
      this.getLatestNotifications();
    }
    // muudab role title-i
    this.addSubscriptionToList(
      this.userService.getUserSubject.subscribe(userInfo => {
        // console.log(userInfo);

        if (this.currentUser !== userInfo) {
          this.currentUser = userInfo;
          this.setActiveRoleTitle(userInfo);
        }
        if (this.currentUser.activeEnvinronment === 'ROLE_ADMIN') {
          this.env = 'mk';
        } else {
          this.env = 'ik';
        }
      })
    );

    this.addSubscriptionToList(
      this.addSubscriptionToList(
        this.langswitchService.routeLanguage.subscribe(clang => {
          this.lang = clang;
        }))
    );

    if (this.currentUser) {
      this.getUnreadNotifications();
      this.getLatestNotifications();
    }

    setInterval(() => { // WE GET NEW NOTIFICATIONS EVERY 60 SECONDS
      this.getUnreadNotifications();
      this.getLatestNotifications();
    }, this.notificationsInterval);

    this.addSubscriptionToList(this.userService.getNewRepresentativeEvent().subscribe(() => {
      this.showPersonalDataHeader = true; // SHOW USER ICON AND NOTIFICATIONS AFTER ROLE SELECT
    }));
  }

  async getUser() {
    try {
      const userInfo = await this.userService.loadUser().toPromise();
      if (userInfo === undefined || userInfo === null) {
        // this.logout();
        // this.userBarC.logout();
        this.logout();
      } else {
        userInfo.rolesSize = Object.keys(userInfo.roles).length;
        userInfo.entitiesSize = Object.keys(userInfo.entities).length;
        userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);
        this.currentUser = userInfo;
        if (this.currentUser) {
          this.userService.userSubject.next(userInfo);
        }
        this.userService.currentUser = userInfo;
        this.userService.setUser(userInfo);

      }
    } catch (error) {
      // "Unauthorized"
      if (error.status === 401) {
        this.logout();
      } else {
        this.getLongMsgError(error.message);
      }
    }
  }

  setNotificationsToRead(value: boolean) {
    if (value === true) {
      const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
      this.addSubscriptionToList(this.userService.setAllNotificationsToRead(this.lastInLine)
        .subscribe(response => {
          if (response.success === true) {
            this.getUnreadNotifications();
            this.getLatestNotifications();
          } else {
            this.showDbErrorMessage(defaultErrorMsgLabel, response);
          }
        },
          error => {
            this.showDbErrorMessage(defaultErrorMsgLabel, error);
          }));
    }
  }

  ngOnDestroy() {
    this.unsubscribeAllFromList();
  }

  async getUnreadNotifications() {
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    try {
      const unreadResponse = await this.userService.getTotalUnreadNotifications().toPromise();
      if (unreadResponse.success === true) {
        this.unreadNotificationsCount = unreadResponse.data;
      } else {
        this.showDbErrorMessage(defaultErrorMsgLabel, unreadResponse);
      }
    } catch (error) {
      this.showDbErrorMessage(defaultErrorMsgLabel, error);
    }
  }

  async getLatestNotifications() {
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    try {
      const latestNotificationsResponse = await this.userService.getLatestNotifications().toPromise();
      if (latestNotificationsResponse.success === true) {
        this.latestNotifications = latestNotificationsResponse as Notifications;
        if (this.latestNotifications.data && this.latestNotifications.data.length > 0) {
          this.lastInLine = this.latestNotifications.data[0].id;
        } else {
          this.lastInLine = 0;
        }
      } else {
        this.showDbErrorMessage(defaultErrorMsgLabel, latestNotificationsResponse);
      }
    } catch (error) {
      this.showDbErrorMessage(defaultErrorMsgLabel, error);
    }
  }
  /**
   * Käivitatakse kui browseri aken/tab suletakse
   *
   */
  @HostListener('window:beforeunload', ['$event'])
  closeWindowOrTab($event) {
    const p = performance.getEntriesByType('navigation')[0] as PerformanceNavigationTiming;
    if (p.type !== 'reload' && p.type !== 'navigate') {
      // logi kasutaja välja
      this.logout();
    }
  }

  setActiveRoleTitle(userInfo: UserInfo) {
    this.activeRoleTitle = this.userService.getActiveRoleTitle(userInfo);
    this.setRoleUserBoolean();
  }

  setRoleUserBoolean() {
    if (this.currentUser !== undefined && this.currentUser !== null) {
      this.isRoleUser = this.activeRoleTitle === this.currentUser.firstName + ' ' + this.currentUser.name;
    }
  }

  /**
   * Attempt to retrieve the UserInfo object via API
   * If successful > set the data into localStorage for faster IO operations
   */
  login(): void {
    this.addSubscriptionToList(
      this.userService.loadUser().subscribe(
        (userInfo: UserInfo) => {
          userInfo.rolesSize = Object.keys(userInfo.roles).length;
          userInfo.entitiesSize = Object.keys(userInfo.entities).length;
          userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);
          this.currentUser = userInfo;
          this.setActiveRoleTitle(userInfo);
          this.getUnreadNotifications();
          this.getLatestNotifications();
          // MIKS EI SAA LAEVIS-1188 NORMAALSELT LAHENDADA ?
          // SEST UUEL TABIL MILLEGI AVAMISEL EI OLE UUES TABIS FE SERVERIL AKTIIVSET KASUTAJAT, TEHAKSE UUS KASUTAJAANDMETE KÜSIMINE BE-ST
          // USER ALATI DEFAULTITAKSE IK tavakasutaja useri peale
          // PROBLEEM SEISNEB SELLES, ET TÄPSELT SAMADE MEETODITEGA, ME KÜSIME SEDA NII AUTENTIMISE LEHELE MAANDUDES, KUI KA UUEL TABIL
          // MUL EI OLE MILLEGAGI ERISTADA SEDA, ET KAS MA OLEN TAOTLUSE, ÜV, AUDITI VMS LEHEL..
          // MA SAAN PANNA, ET PEALE ANDMETE KÜSIMIST, NÄITAKS TEAVITUSI JA MINU ANDMEID,
          // SEST PEALE logini ON MEIL TAVAKASUTAJA ROLL LIHTSALT NAGUNII.

          // TEHA USERBARIS URLI VÕRDLUS, PARIM ÄKK MILLEGA LAGEDALE TULIME.

        },
        error => {
          if (error.message) {
            this.getLongMsgError(error.message);
          }
        }
      )
    );
  }


  logout(): void {
    this.addSubscriptionToList(this.userService.logoutUser().subscribe(
      response => {
        this.userService.cleanSession();
        if (API_ENDPOINT.charAt(API_ENDPOINT.length - 1) === '/') {
          window.location.href = API_ENDPOINT;
        } else {
          window.location.href = API_ENDPOINT + '/';
        }
      },
      error => {
        this.getLongMsgError(error.message);
      }));
  }

  goToLogin(): void {
    window.location.href = API_ENDPOINT + 'app/login';
  }
}
