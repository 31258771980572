import { Component, OnInit, Input, SimpleChanges, OnChanges, HostBinding } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent extends BaseComponent implements OnInit, OnChanges {
  @HostBinding('class.w-100')
  @Input() lang;
  @Input() list;
  @Input() showProperty;
  @Input() value;
  @Input() formControl: FormControl = new FormControl();
  constructor() { super(); }

  ngOnInit() {
    this.uiHelper.initSelectPicker();
    this.parseFormControl();
    // console.log('select ngOnInit');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.list) {
      this.uiHelper.refreshSelectPicker();
    }
  }

  parseFormControl() {
    if (this.formControl && this.formControl.value) {
      this.value = this.formControl.value;
    }
  }

  changeValue(selectedValue: any) {
    this.value = selectedValue;
    this.outputFormControlValue(selectedValue);
  }

  outputFormControlValue(selectedValue: any) {
    if (this.formControl) {
      this.formControl.setValue(selectedValue);
    }
  }

}
