import { Component, OnDestroy, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppInjector } from 'src/app/app.injector.service';
import { NotificationComponent } from '../notification/notification.component';
import { DateConstant } from '../../_utils';
import { FormGroup } from '@angular/forms';
import { LangswitchService, UserService } from '../../_services';
import { UiHelper } from '../../_utils/ui';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DatePipe, Location } from '@angular/common';
import { WindowInterruptSource } from '@ng-idle/core';


@Component({
  selector: 'app-base',
  template: '',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent extends NotificationComponent implements OnDestroy {
  REGEX_INT_NUMBER = '^[0-9]*$';

  fieldLabels = {};
  translationMap = {};

  DB_DATE_FORMAT = 'yyyy-MM-dd';
  DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm';
  DATE_TIME_FORMAT_FOR_PIPE = 'dd.MM.yyyy HH:mm';
  DATE_FORMAT_FOR_PIPE = 'dd.MM.yyyy';
  DATE_TIME_FORMAT_FOR_PIPE_TO_TRANSFORM_DATE_OBJ = 'dd.MM.yyyy HH:m';
  public localDateFormat = DateConstant.DateFormat; // 'dd.MM.yyyy'
  public localDateTimeFormat = DateConstant.DateTimeFormat; // 'dd.MM.yyyy HH:mm'
  protected langswitchService: LangswitchService;
  protected uiHelper: UiHelper;
  protected modalService: BsModalService;
  protected datePipe: DatePipe;
  protected routingLocation: Location;
  protected userService: UserService;
  /*
     Subscriptions
   */
  public subscriptions: Subscription[] = [];
  public bsModalRef: BsModalRef;
  modalDialogRef: any;
  modalRef: BsModalRef;

  lang: string;

  // stop modal backdrop
  modalNoBackdropConfig = {
    backdrop: false,
    ignoreBackdropClick: true
  };

  @ViewChild('dialogTemplate') dialogTemplate: TemplateRef<any>;
  dialogContentPlaceholder: TemplateRef<any>;
  dialogFooterPlaceholder: TemplateRef<any>;

  /*
    Add class 'table-sm' to table if browser windows is too small
  */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.addTableSmClassToTableElementByWinSize(event.target);
  }

  constructor() {
    super();
    try {
      const injector = AppInjector.getInjector();
      this.langswitchService = injector.get(LangswitchService);
      this.uiHelper = injector.get(UiHelper);
      this.modalService = injector.get(BsModalService);
      this.datePipe = injector.get(DatePipe);
      this.routingLocation = injector.get(Location);
      this.userService = injector.get(UserService);
    } catch (e) {
      console.error('Failed initializing dependencies', e);
    }
    this.addTableSmClassToTableElementByWinSize(window);
    // console.log('DEBUG winInnerWidth', window.innerWidth);

    this.uiHelper.initSelectPicker();
    // this.uiHelper.initDraggableDialog();
    // this.modalDialogRef = window.document.getElementsByClassName('modal-dialog');
  }

  addSpinnerLayer() {
    window.document.body.classList.add('show-spinner-layer');
  }

  removeSpinnerLayer() {
    window.document.body.classList.remove('show-spinner-layer');
  }

  getDateObject(date: string): Date {
    if (!date) { return null; }
    let newDate = new Date(date);
    if (newDate instanceof Date && !isNaN(newDate.getTime())) {
      return newDate;
    }
    newDate = new Date(this.datePipe.transform(date, this.DATE_TIME_FORMAT_FOR_PIPE_TO_TRANSFORM_DATE_OBJ));
    if (newDate instanceof Date && !isNaN(newDate.getTime())) {
      return newDate;
    } else {
      // For debug
      this.getLongMsgError('ERROR.INVALID_DATE', ': ' + date);
      return newDate; // returns invalid date object
    }

  }

  setFormGroupFields(controls: any, initData: any) {
    Object.keys(controls).forEach(key => {
      if (initData.hasOwnProperty(key)) {
        controls[key].setValue(initData[key]);
      }
    });

  }

  /*
    Add class 'table-sm' to table if browser windows is too small
  */
  addTableSmClassToTableElementByWinSize(window: any) {
    const tableList = window.document.getElementsByTagName('table');
    if (+window.innerWidth <= 1200) {
      for (const i in tableList) {
        if (
          tableList && tableList.length > 0 &&
          tableList[i].classList &&
          tableList[i].classList.value.indexOf('table-sm') === -1) {
          tableList[i].classList.add('table-sm');
        }
      }
    } else {
      for (const i in tableList) {
        if (
          tableList && tableList.length > 0 &&
          tableList[i].classList &&
          tableList[i].classList.value.indexOf('table-sm') !== -1) {
          tableList[i].classList.remove('table-sm');
        }
      }
    }
  }

  /*
    First init this.translationMap
  */
  initFieldLabels() {
    if (this.fieldLabels) {
      // field labels for error messages
      this.addSubscriptionToList(this.translateServ.get(Object.values(this.fieldLabels)).subscribe(translatedLabelsList => {
        this.translationMap = translatedLabelsList;
      }));
    }
  }

  /*
    Returns select list first default object: {id: null, name: {et: "-", en: "-"}, description: "-"}
  */
  getSelectDefaultFirstElement(defaultValue = '-') {
    const nameDescrListForFirstElement = this.getDefaultNameRecordMap(defaultValue);


    return {
      id: null, code: null, name: nameDescrListForFirstElement, description: defaultValue, descr: nameDescrListForFirstElement
    };
  }

  getDefaultNameRecordMap(defaultValue = '-'): any {
    const nameDescrListForFirstElement = {};
    this.langswitchService.getLangList().forEach(langObject => {
      nameDescrListForFirstElement[langObject.lang] = defaultValue;
    });
    return nameDescrListForFirstElement;
  }

  /* Returns select list first default cla_listitem: {"code": null, "descr": {"en": "-","et": "-"}}*/
  getSelectDefaultFirstClaListItem(defaultValue = '-') {
    return {
      code: null, descr: this.getDefaultNameRecordMap(defaultValue)
    };
  }

  /*
    Kui extend-iv komponent seda üle ei kirjuta siis käivitatakse automaatselt
  */
  ngOnDestroy(): void {
    // console.log('Base ngOnDestroy');
    this.unsubscribeAllFromList();
  }

  addSubscriptionToList(subscription: any) {
    this.subscriptions.push(subscription);
  }

  unsubscribeAllFromList() {
    this.subscriptions.forEach(subscription => {
      if (subscription !== undefined) {
        subscription.unsubscribe();
      }
    });
  }

  /*
    res (response)
    fieldLabelList: is {fieldName: fieldNameTranslation}
  */
  async showDbErrorMessage(defaultErrorMsgLabel: string, res: any, fieldLabelList = {}, showErrorNotification = false, form?: FormGroup) {
    if (res.parameters && res.parameters.message === 'error.http.500') {
      this.getLongMsgError(res.parameters.message);
    }
    if (res.parameters && res.parameters.message === 'error.http.403') {
      this.getLongMsgError(res.parameters.message);
    } else if (showErrorNotification && res.data && res.data.length > 0 && res.data[0].field === 'xtee') {
      this.getLongMsgError(res.data[0].message);
    } else if ((showErrorNotification || form) && res.error.parameters &&
      res.error.parameters.fieldErrors && res.error.parameters.fieldErrors.length > 0) {
      const fieldErrors = res.error.parameters.fieldErrors;
      // get field names from translation
      await this.initFieldLabels();
      // make a list of error labels
      const labels = [];
      fieldErrors.forEach(error => {
        if (fieldLabelList && fieldLabelList[error.field]) {
          if (labels.indexOf(error.message) === -1) {
            labels.push(error.message);
          }
        }
      }); // forEach END
      let errorText = '';
      if (labels.length > 0) {
        // create error message
        this.addSubscriptionToList(this.translateServ.get(labels).subscribe(translatedLabelsList => {
          fieldErrors.forEach(error => {
            if (translatedLabelsList && translatedLabelsList[error.message]) {
              errorText += this.translationMap[fieldLabelList[error.field]] + ': ' + translatedLabelsList[error.message] + '.<br/>';
            }
          });
          if (showErrorNotification) {
            // show errors Msg Notification
            this.getLongMsgError(errorText);
          }
          // set errors to Form => Field
          if (form) {
            const errorsByFieldMap = {};
            fieldErrors.forEach(error => {
              if (!errorsByFieldMap[error.field]) {
                errorsByFieldMap[error.field] = new Map<string, string>();
              }
              errorsByFieldMap[error.field].set(
                (error.message as string).substring((error.message as string).indexOf('.') + 1),
                error.message);
            });
            Object.keys(errorsByFieldMap).forEach(field => {
              if (form.controls[field]) {
                form.controls[field].setErrors(errorsByFieldMap[field]);
              }
            });
          }
        }));
      }
    } else if (res.errorMessage !== undefined && res.errorMessage !== null) {
      const currentLang = this.translateServ.currentLang;
      if (res.errorMessage[currentLang] !== undefined && res.errorMessage[currentLang] !== null) {
        this.getLongMsgError(res.errorMessage[currentLang]);
      } else {
        if (defaultErrorMsgLabel) {
          this.addSubscriptionToList(this.translateServ.get(defaultErrorMsgLabel).subscribe(label => {
            this.getLongMsgError(label);
          }));
        }
      }
    } else {
      if (res.message) {
        console.error(res.message);
      }
      if (res.stack) {
        console.error(res.stack);
      }
      if (defaultErrorMsgLabel) {
        this.getLongMsgError(defaultErrorMsgLabel);
      }

      /* this.addSubscriptionToList(this.translateServ.get(defaultErrorMsgLabel).subscribe(label => {
        this.getLongMsgError(label);
      })); */
    }

  }

  // debuggimiseks
  show(value: any, msg = '') {
    console.log('show ' + msg, value);
    return value;
  }

  closePopover(): void {
    const popover = document.querySelector('popover-container');
    // TODO button click bug!
    if (popover !== null) {
      popover.classList.add('d-none');
    }
  }

  closeModal() {
    this.modalRef.hide();
  }


  // RVÄ: Näide võetud siit: https://embed.plnkr.co/Z7ps0E/
  dateLessThan(from: string, to: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      if (
        f.value !== undefined && t.value !== undefined &&
        f.value !== null && t.value !== null &&
        f.value > t.value) {
        return {
          dates: 'ERROR.NEGATIVE_DATES'
        };
      }
      return {};
    };
  }

  checkIfDateLessThan(startDate: string, endDate: string) {
    return (group: FormGroup) => {
      const startDateInput = group.controls[startDate];
      const endDateInput = group.controls[endDate];
      if (startDateInput.value && endDateInput.value) {
        const start = new Date(startDateInput.value);
        const end = new Date(endDateInput.value);
        if (start.getTime() > end.getTime()) {
          return startDateInput.setErrors({ endBeforeStart: true });
        } else {
          startDateInput.setErrors(null);
        }
      } else {
        startDateInput.setErrors(null);
      }
    };
  }

  dateLessThanWithStatus(from: string, to: string, status: string, activateStatusNr: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const f = group.controls[from];
      const t = group.controls[to];
      const s = group.controls[status];
      if (
        s !== null && s !== undefined && +s.value === +activateStatusNr &&
        f.value !== undefined && t.value !== undefined &&
        f.value !== null && t.value !== null &&
        f.value > t.value) {
        // console.log('dateLessThanWithStatus 2 ' + s.value);
        return {
          dates: 'ERROR.NEGATIVE_DATES'
        };
      }
      return {};
    };
  }

  isDateInFuture(date: string) {
    return (group: FormGroup): { [key: string]: any } => {
      const df = group.controls[date];
      if (
        df.value !== undefined &&
        df.value !== null &&
        df.value < Date.now()) {
        return {
          dates: 'ERROR.NEGATIVE_DATE'
        };
      }
      return {};
    };
  }
  isFormValueDateInFuture(date: string, formGroup: FormGroup) {
    const df = formGroup.controls[date];
    if (
      df.value !== undefined &&
      df.value !== null &&
      df.value < Date.now()) {
      return 'ERROR.NEGATIVE_DATE';
    }
    return null;
  }

  /*
    example: this.initDynamicGetterForForm(this.deficiancyForm.controls, this.deficiancyForm);
    result:
        get requirementId() { return this.deficiancyForm.get('requirementId'); }
        get description() { return this.deficiancyForm.get('description'); }
        get rectifActionId() { return this.deficiancyForm.get('rectifActionId'); }
        get deadline() { return this.deficiancyForm.get('deadline'); }

  */
  /* initDynamicGetterForForm(controlsMap: any, formGroup: FormGroup) {
    for (const key of Object.keys(controlsMap)) {
      Object.defineProperty(this, key, {
        get: () => formGroup.get(key)
      });
    }
  } */

  groupByCategory(objectArray, property): object {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      // console.log(acc);
      return acc;
    }, {});
  }

  initLang() {
    this.lang = this.translateServ.currentLang;
    this.addSubscriptionToList(this.langswitchService.routeLanguage.subscribe(
      clang => {
        this.lang = clang;
        this.uiHelper.refreshSelectPicker();
      }
    ));
  }

  transformDate(value: string, format: string) {
    return new DatePipe('et_EE').transform(value, format);
  }

  goBack() {
    this.routingLocation.back();
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  selectDefaultUserRole() {
    const selectedRoleEnvinronment = this.userService.getUserSelectedRoleEnvinronment();
    if (!selectedRoleEnvinronment) {
      const currentUser = this.userService.getUser();
      this.userService.setUser(currentUser);
      this.userService.setUserSelectedRoleEnvinronment(currentUser.activeEnvinronment);
    }
  }

  /*  async translate(label: string) {
     try {
       const translation = await this.translateServ.get([label]).toPromise();
       return translation[label];
     } catch (error) {
       this.getLongMsgError('ERROR.TRANSLATE_ERROR');
     }
   } */
}
