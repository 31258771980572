import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserInfo } from '../../_domain';
import { API_ENDPOINT } from '../../app.constants';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TableSessionService {
  defaultSessionNamePart = '-table-session-data';

  saveTableSessionParams(tableName: string, tableParams: any, formTable: any, pagConf: any) {
    const tableParamData = { table: tableParams, form: formTable, pageConf: pagConf };
    sessionStorage.setItem(tableName + this.defaultSessionNamePart, JSON.stringify(tableParamData));
  }

  getTableSessionParams(tableName: string) {
    return JSON.parse(sessionStorage.getItem(tableName + this.defaultSessionNamePart));
  }

  clearTableSessionFor(tableName: string) {
    sessionStorage.removeItem(tableName + this.defaultSessionNamePart);
  }
}
