import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { LANGUAGE_OPTIONS_DEFAULT } from '../../app.constants';

@Injectable({
  providedIn: 'root'
})
export class LangswitchService {
  langList = [{ lang: 'et', descr: 'EST' }, { lang: 'en', descr: 'ENG' }];

  public routeLanguage = new BehaviorSubject<string>(LANGUAGE_OPTIONS_DEFAULT);

  constructor() { }

  getCurrentLang() {
    return this.routeLanguage.value;
  }

  getLangList() {
    return this.langList;
  }

  getLangDescription(lang: string): string {
    for (const item of this.langList) {
      if (item.lang === lang) {
        return item.descr;
      }
    }
  }

  updateCurrentLang(lang: string): void {
    this.routeLanguage.next(lang);
  }
}
