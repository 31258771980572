<app-loading-spinner
  *ngIf="isLoading"
  [msg]="''"
  [smallMsg]="''"
  [isBig]="true"
></app-loading-spinner>
<div
  *ngIf="!isLoading"
  class="home-user-role-change"
>
  <div class="bg-white ml-auto mr-auto col-11 representativeBox">
    <div class="d-lg-flex d-md-block justify-content-between">
      <!-- left -->
      <div>
        <h2 class="font-weight-bold">LAEVADE INFOSÜSTEEM</h2>
      </div>
      <!-- right -->
      <div class="d-flex justify-content-end">
        <!-- LANG -->
        <div
          style="min-width: 80px;"
          class="v-align  px-0"
        >
          <!-- nav-item -->
          <app-langbar class="align-middle"></app-langbar>
        </div>
        <!-- LogOut -->
        <div
          class="d-inline v-align align-middle pl-2"
          placement="bottom"
          tooltip="{{ 'GLOBAL.LOGOUT' | translate }}"
          title="{{ 'GLOBAL.LOGOUT' | translate }}"
        >
          <!-- nav-item nav-logout -->
          <a
            id="logOutId"
            class="p-0 align-middle"
            (click)="logout()"
          >
            <img
              src="assets/assets/images/logOut.svg"
              alt=""
            />
          </a>
        </div>
      </div>

    </div>
    <app-user-change-role></app-user-change-role>
  </div>
</div>