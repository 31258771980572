import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { BaseComponent } from '../shared/_common/base/base.component';
import {
  ShipService,
  UserService,
  LangswitchService,
  ClassifierService,
  AplService
} from '../shared/_services';
import { Apl, AplSearch, ClassificatorObjectElement, InspectionSearch, UserInfo } from '../_domain';
import { PaginationInstance } from 'ngx-pagination';
import { Router, ActivatedRoute } from '@angular/router';
import { DateConstant } from '../shared/_utils/date';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { InspectionInterface } from '../_domain/inspection.interface';
import { InspectionService } from '../shared/_services/inspection.service';
import { TableSessionService } from '../shared/_services/table-session.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit, OnDestroy {
  public newApplicationIsVisible: boolean;
  isMainPage: boolean;
  currentEnv: string;
  inspectionsVisible: string;
  aplDetailRoutePath: string;
  shipDetailRoutePath: string;
  public aplWaitConf: PaginationInstance;
  public aplProceederConf: PaginationInstance;
  currentTaotlus: Apl;
  aplWaitingResults: Apl[];
  privileges: string[] = [];
  aplProceederResults: Apl[];
  public localDateFormat = DateConstant.DateFormat;
  appTypesByUserTypes: Array<number> = new Array();
  // taotlused
  myAplTablePaginationData = {
    sortBy: '',
    sortDir: '',
    page: 0,
    size: 3
  };
  myAplProcPaginationData = {
    sortBy: '',
    sortDir: '',
    page: 0,
    size: 3
  };
  aplSearch: AplSearch = {
    sortBy: '',
    sortDir: '',
    page: 0,
    size: 3
  };
  inspectionTablePaginationData = {
    sortBy: 'startDate',
    sortDir: 'desc',
    page: 0,
    size: 3
  };

  loadingServicesCount = 0;

  @ViewChild('addEditModalTpl') addEditModalTpl: TemplateRef<any>;
  addEditModalRef: BsModalRef;
  isAudit: boolean;
  inspectionSearchResults: InspectionInterface[];
  insTablePaginationData = {
    page: 0,
    size: 3
  };
  currentInspectionId: number;
  insSearch: InspectionSearch;
  totalInspections: number;
  inspectionPageConf: PaginationInstance;
  isAplSearching: boolean;
  applicantId: number;
  // aplSearch: AplSearch;
  isSearching: boolean;

  // pagination
  page: number;
  size: number;
  lang: string;
  public today: Date;
  userInfo: UserInfo;

  pendingRequestLoading = false;
  notFinishedAplLoading = false;
  notFinishedInsLoading = false;

  constructor(
    private shipService: ShipService,
    private aplService: AplService,
    private classifierService: ClassifierService,
    private router: Router,
    private route: ActivatedRoute,
    private inspectionService: InspectionService,
    private tableSessionService: TableSessionService
  ) {
    super();
    this.isAplSearching = false;
  }

  ngOnInit() {
    this.insSearch = {
      vesselPersonStr: null,
      vesselSearchStr: null,
      inspectionTypeId: null,
      dateFrom: null,
      dateTo: null,
      committee: [],
      status: [],
      claListitemByAppStatus: undefined,
      page: 0,
      size: 10,
      sortBy: 'startDate',
      sortDir: 'desc'
    };

    this.route.data.subscribe(data => {
      this.isMainPage = data.isMainPage;
      this.currentEnv = data.env;
      // for routerLink
      this.aplDetailRoutePath = this.isMainPage === true ? './taotlused/taotlus/' : '../taotlused/taotlus/';
      this.shipDetailRoutePath = this.isMainPage === true ? './laevad/laev/' : '../laevad/laev/';
    });
    this.page = 0;
    this.size = 10; // TODO võtta konfist - tulemuste arv leheküljel */
    const aplWaitParam = this.initTableParamsFromSession('aplWaitTableId');
    if (!aplWaitParam) {
      this.aplWaitConf = {
        id: 'aplWaitTableId',
        itemsPerPage: this.myAplTablePaginationData.size,
        currentPage: this.myAplTablePaginationData.page + 1,
        totalItems: 0
      };
    } else {
      this.aplWaitConf = aplWaitParam.pageConf;
    }

    const aplProceederParam = this.initTableParamsFromSession('aplProcTableId');
    if (!aplProceederParam) {
      this.aplProceederConf = {
        id: 'aplProcTableId',
        itemsPerPage: this.myAplProcPaginationData.size,
        currentPage: this.myAplProcPaginationData.page + 1,
        totalItems: 0
      };
    } else {
      this.aplProceederConf = aplProceederParam.pageConf;
    }
    const insAuditSearchResParam = this.initTableParamsFromSession('insAuditSearchResTable');
    if (!insAuditSearchResParam) {
      this.inspectionPageConf = {
        id: 'shipTableId',
        itemsPerPage: this.inspectionTablePaginationData.size,
        currentPage: this.inspectionTablePaginationData.page + 1,
        totalItems: 0
      };
    } else {
      this.inspectionPageConf = insAuditSearchResParam.pageConf;
    }

    this.today = new Date();

    // init table
    const userInfo = this.userService.getUser();
    // console.log('ngOnInit', userInfo);
    if (userInfo !== null && userInfo !== undefined) {
      this.initPageData(userInfo);
    }
    /*
      subciptions
    */
    this.addSubscriptionToList(
      this.langswitchService.routeLanguage.subscribe(clang => {
        this.lang = clang;
      })
    );

    this.addSubscriptionToList(
      this.userService.getUserSubject.subscribe((user: UserInfo) => {
        this.initPageData(user);
      })
    );
    this.userInfo = userInfo;
  }

  ngOnDestroy(): void {
    this.unsubscribeAllFromList();
  }

  initPageData(user: UserInfo) {
    if (user) {
      this.applicantId = user.autPersonId;
      this.privileges = user.privileges;
      this.getWaitingApls(this.applicantId, this.aplWaitConf.currentPage - 1, this.aplWaitConf.itemsPerPage, 'compSubDate', 'desc');
      this.getProceederApplications(
        this.applicantId,
        this.aplProceederConf.currentPage - 1,
        this.aplProceederConf.itemsPerPage,
        'compSubDate',
        'desc'
      );

      if (user.privileges.includes('VLAEV_INSPEKTOR') || user.privileges.includes('YLEV_ANDMED')) {
        this.getInspections(user);
      }

      this.newApplicationIsVisible = this.privileges.some(x => x === 'REGISTREERIJA');
    } else {
      this.getLongMsgError('ERROR.USER.NOT_LOADED');
    }
  }

  regNew(taotluseLiik: number) {
    this.addSubscriptionToList(
      this.shipService.createDraftMk(taotluseLiik).subscribe(
        (res: any) => {
          const defaultErrorMsgLabel = 'ERROR.CREATE_DATA';
          if (res.success === true) {
            this.currentTaotlus = res.data as Apl;
            this.translateServ.get('DS.TAOTLUS_SUCCESS').subscribe(label => {
              this.getLongMsgSuccess(label + ' (nr: ' + this.currentTaotlus.registrationNumber + ')');
            });
            this.goToPage(this.lang + '/' + this.currentEnv + '/taotlused/taotlus/' + this.currentTaotlus.id);
          } else if (res.errorMessage !== undefined && res.errorMessage !== null) {
            const currentLang = this.translateServ.currentLang;
            if (res.errorMessage[currentLang] !== undefined && res.errorMessage[currentLang] !== null) {
              this.getLongMsgError(res.errorMessage[currentLang]);
            } else {
              this.translateServ.get(defaultErrorMsgLabel).subscribe(label => {
                this.getLongMsgError(label);
              });
            }
          } else {
            this.translateServ.get(defaultErrorMsgLabel).subscribe(label => {
              this.getLongMsgError(label);
            });
          }
        },
        error => {
          this.getLongMsgError(error.message);
        }
      )
    );
  }

  goToPage(path: string) {
    this.router.navigate([path]);
  }

  initSpinner() {
    if (this.loadingServicesCount === 0) {
      this.addSpinnerLayer();
    }
    this.loadingServicesCount += 1;
  }

  cancelSpinner() {
    this.loadingServicesCount -= 1;
    if (this.loadingServicesCount === 0) {
      this.removeSpinnerLayer();
    }
  }

  getProceederApplications(
    proceederId: number,
    page: number,
    size: number,
    sortedBy: string,
    sortedDir: string
  ) {
    // this.initSpinner();

    this.pendingRequestLoading = true;

    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    this.addSubscriptionToList(
      this.aplService.searchProceederApplications(proceederId, page, size, sortedBy, sortedDir).subscribe(
        response => {
          if (response.success) {
            if (response.data !== undefined && response.data !== null) {
              this.aplProceederResults = response.data as Apl[];
            } else {
              this.aplProceederResults = [];
            }
            this.aplProceederConf.totalItems = response.total;
          } else {
            this.showDbErrorMessage(defaultErrorMsgLabel, response);
          }
          // this.cancelSpinner();
          this.pendingRequestLoading = false;
        },
        error => {
          this.showDbErrorMessage(defaultErrorMsgLabel, error);
          // this.cancelSpinner();
          this.pendingRequestLoading = false;
        }
      )
    );
  }

  getWaitingApls(applicantId: number, page: number, size: number, sortedBy: string, sortedDir: string) {

    this.appTypesByUserTypes = [];
    if (this.privileges.some(x => x === 'YLEV_JUHT')) {
      for (const i of [21031, 21032, 21033]) {
        if (this.appTypesByUserTypes.indexOf(i) === -1) {
          this.appTypesByUserTypes.push(i);
        }
      }
    }
    if (this.privileges.some(x => x === 'REGISTREERIJA')) {
      for (const i of [
        21001,
        21002,
        21003,
        21004,
        21005,
        21011,
        21012,
        21013,
        21014,
        21015,
        21016,
        21017,
        21018,
        21019,
        21041,
        21042
      ]) {
        this.appTypesByUserTypes.push(i);
      }
    }
    if (this.appTypesByUserTypes.length === 0) {
      this.appTypesByUserTypes.push(0);
    }

    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';

    // this.initSpinner();
    this.notFinishedAplLoading = true;

    this.addSubscriptionToList(
      this.aplService
        .searchWaitingApplications(this.appTypesByUserTypes, page, size, sortedBy, sortedDir)
        .subscribe(
          response => {
            if (response.success) {
              if (response.data !== undefined && response.data !== null) {
                this.aplWaitingResults = response.data as Apl[];
              } else {
                this.aplWaitingResults = [];
              }
              this.aplWaitConf.totalItems = response.total;
            } else {
              this.showDbErrorMessage(defaultErrorMsgLabel, response);
            }
            // this.cancelSpinner();
            this.notFinishedAplLoading = false;
          },
          error => {
            this.showDbErrorMessage(defaultErrorMsgLabel, error);
            // this.cancelSpinner();
            this.notFinishedAplLoading = false;
          }
        )
    );
  }

  onSorted(event: any) {
    this.aplSearch.sortBy = event.sortColumn;
    this.aplSearch.sortDir = event.sortDirection;
    this.aplSearch.page = 0;
    this.aplProceederConf.currentPage = 1;
    const user = this.userService.getUser();
    if (user) {
      this.getProceederApplications(
        null,
        0,
        this.aplProceederConf.itemsPerPage,
        this.aplSearch.sortBy,
        this.aplSearch.sortDir
      );
    }
  }

  getAplWaitingPage() {
    this.saveTableParamsToSession('aplWaitTableId', this.aplWaitConf);
    this.aplSearch.page = this.aplWaitConf.currentPage - 1;
    this.aplSearch.size = this.aplWaitConf.itemsPerPage;
    this.myAplTablePaginationData.page = this.aplWaitConf.currentPage - 1;
    this.myAplTablePaginationData.size = this.aplWaitConf.itemsPerPage;
    this.getWaitingApls(
      this.userService.getUser().autPersonId,
      this.aplWaitConf.currentPage - 1,
      this.aplWaitConf.itemsPerPage,
      'compSubDate',
      'desc'
    );
  }

  getAplProceederPage() {
    this.saveTableParamsToSession('aplProcTableId', this.aplProceederConf);
    this.aplSearch.page = this.aplProceederConf.currentPage - 1;
    this.aplSearch.size = this.aplProceederConf.itemsPerPage;
    this.myAplProcPaginationData.page = this.aplProceederConf.currentPage - 1;
    this.myAplProcPaginationData.size = this.aplProceederConf.itemsPerPage;
    this.getProceederApplications(
      null,
      this.aplProceederConf.currentPage - 1,
      this.aplProceederConf.itemsPerPage,
      'compSubDate',
      'desc'
    );
  }

  getInspections(userInfo: UserInfo) {
    const defaultErrorMsgLabel = 'ERROR.DEFAULT_DATA';
    this.insSearch.status.push(30001, 30002, 30003);
    this.insSearch.committee.push(this.applicantId.toString());

    // this.initSpinner();
    this.notFinishedInsLoading = true;

    this.addSubscriptionToList(
      this.inspectionService
        .getIns(
          this.insSearch,
          'mk',
          this.inspectionPageConf.currentPage - 1,
          this.inspectionPageConf.itemsPerPage,
          this.insSearch.sortBy
        )
        .subscribe(
          (res: any) => {
            this.isSearching = true;
            if (res.success === true) {
              for (const item of res.data) {
                if (item.teamMembers !== null) {
                  item.editRights =
                    item.claListitemByStatus.code === 30001 && userInfo.privileges.indexOf('YLEV_JUHT') > -1;
                }
              }
              this.inspectionSearchResults = res.data as InspectionInterface[];

              this.totalInspections = res.total;
              this.inspectionPageConf.totalItems = this.totalInspections;
              this.isSearching = false;
            } else {
              this.showDbErrorMessage(defaultErrorMsgLabel, res);
            }
            this.isSearching = false;

            // this.cancelSpinner();
            this.notFinishedInsLoading = false;
          },
          error => {
            this.showDbErrorMessage(defaultErrorMsgLabel, error);
            this.isSearching = false;
            this.inspectionSearchResults = [];

            // this.cancelSpinner();
            this.notFinishedInsLoading = false;
          }
        )
    );
  }

  openAddEditModal(id) {
    this.currentInspectionId = id;
    const i = this.inspectionSearchResults.find(x => x.id === id);
    if (i.type.typeCode === 200 || i.type.typeCode === 300 || i.type.typeCode === 400) {
      this.isAudit = true;
    } else {
      this.isAudit = false;
    }
    // open modal
    this.addEditModalRef = this.modalService.show(
      this.addEditModalTpl,
      Object.assign({}, { class: 'modal-lg' }, this.modalNoBackdropConfig)
    );
  }

  getInspectionPage() {
    this.saveTableParamsToSession('insAuditSearchResTable', this.inspectionPageConf);
    this.insTablePaginationData.page = this.inspectionPageConf.currentPage - 1;
    this.insTablePaginationData.size = this.inspectionPageConf.itemsPerPage;

    this.getInspections(this.userInfo);
  }

  onInsSorted(event: any) {
    this.insSearch.sortBy = event.sortColumn;
    this.insSearch.sortDir = event.sortDirection;
    this.insSearch.page = 0;
    this.inspectionPageConf.currentPage = 1;
    this.saveTableParamsToSession('insAuditSearchResTable', this.inspectionPageConf);
    this.getInspections(this.userInfo);
  }

  createInspection() {
    this.addSubscriptionToList(
      this.inspectionService.createSmallVesselPreInspection().subscribe(
        (res: any) => {
          this.isSearching = true;
          this.getLongMsgSuccess('DS.YLEVAATUS_SUCCESS', ' (nr: ' + res + ')');
          this.goToPage(this.lang + '/' + this.currentEnv + '/ylevaatused/ylevaatus/vaikelaev/' + res);
        },
        error => {
          this.getLongMsgError(error);
        }
      )
    );
  }

  /*
   TABLE session
 */
  saveTableParamsToSession(tableSessionName, pagConf) {
    this.tableSessionService.saveTableSessionParams(tableSessionName, null, null, pagConf);
  }

  clearTableParamsToSession(tableSessionName) {
    this.tableSessionService.clearTableSessionFor(tableSessionName);
  }

  initTableParamsFromSession(tableSessionName) {
    const params = this.tableSessionService.getTableSessionParams(tableSessionName);
    /* if (params !== null && params !== undefined) {
      if (params.pageConf !== undefined) {
        pagConf = params.pageConf;
      }
    } */
    return params;
  }
}
