<div class="modal-header pb-1">
  <h2 *ngIf="mode === 'rw' && docId">{{ 'DOC.EDIT' | translate }}</h2>
  <h2 *ngIf="mode === 'rw' && !docId">{{ 'DOC.ADD' | translate }}</h2>
  <h2 *ngIf="mode === 'ro' && !currentDoc?.archived">{{ 'DOC.VIEW' | translate }}</h2>
  <h2 *ngIf="mode === 'ro' && currentDoc?.archived">{{ 'DOC.ARCHIVE_VIEW' | translate }}</h2>
  <button
    light
    [style]="'round'"
    [icon]="'exit'"
    title="{{ 'GLOBAL.CLOSE' | translate }}"
    (click)="close()"
  ></button>
</div>
<div *ngIf="mode === 'rw'; else ro" class="modal-body">
  <div class="col-20 py-0 px-0">
    <div *ngIf="isGenerating">
      <app-loading-spinner [msg]="'DOC.GENERATE'"></app-loading-spinner>
    </div>
    <form *ngIf="!isGenerating" [formGroup]="documentForm" (ngSubmit)="onSubmit()">
      <div class="form-group pb-0">
        <div class="row mx-0 px-0">
          <!-- doctype -->
          <label for="docTypePicker" class="col-20 pl-0 col-form-label text-left requiredField"
            >{{ 'DOC.DOCTYPE' | translate }}:</label
          >
          <div class="col-20">
            <div class="input-group">
              <select
                formControlName="docType"
                id="docTypePicker"
                class="form-control selectpicker clearable"
                title="{{ 'DOC.DOCTYPE' | translate }}"
              >
                <optgroup *ngFor="let group of doctypeGroups; let i = index" label="{{ group.descr[lang] }}">
                  <option *ngFor="let docType of doctypesByGroup[group.code]" [value]="docType.code">{{
                    docType.descr[lang]
                  }}</option>
                </optgroup>
              </select>
            </div>
          </div>
          <!-- docform -->
          <label for="docFormPicker" class="col-20 pl-0 col-form-label text-left"
            >{{ 'DOC.DOCFORM' | translate }}:</label
          >
          <div class="col-20">
            <div class="input-group">
              <!--TODO disabled style-->
              <select
                formControlName="docForm"
                id="docFormPicker"
                class="form-control selectpicker clearable"
                title="{{ 'DOC.DOCFORM' | translate }}"
              >
                <option value="">{{ '--' }}</option>
                <option *ngFor="let dform of docForms" [value]="dform.code">{{ dform.descr[lang] }}</option>
                <!-- dform.descr[lang] -->
              </select>
            </div>
          </div>
          <!-- title -->
          <label for="titleTxt" class="col-20 pl-0 col-form-label text-left"
            >{{ 'DOC.TITLE' | translate }}:</label
          >
          <div class="col-20">
            <div class="input-group">
              <input formControlName="docTitle" id="titleTxt" class="form-control" type="text" />
            </div>
          </div>
          <!-- issuerId -->
          <label for="issuerPicker" class="col-form-label">{{ 'DOC.ISSUER' | translate }}:</label>
          <div class="col-20">
            <div class="input-group">
              <select
                formControlName="issuerId"
                id="issuerPicker"
                class="form-control selectpicker clearable"
                title="{{ 'DOC.ISSUER' | translate }}"
              >
                <option value="">{{ '--' }}</option>
                <option *ngFor="let issuer of issuers" [value]="issuer.id">{{ issuer.description }}</option>
              </select>
            </div>
          </div>
          <!-- issuer disabled if issuer_id is not null -->
          <div class="col-20 mt-3">
            <div class="input-group">
              <!-- <label for="issuerTxt" class="col-form-label">{{ 'DOC.ISSUER' | translate }}</label> -->
              <input formControlName="issuer" id="issuerTxt" class="form-control" type="text" />
            </div>
          </div>
          <!--row-->

          <div class="row mx-0 px-0 w-100">
            <div class="col-10 mx-0 pl-1 pr-0 mt-3">
              <div class="row mx-0 pl-0 pr-2">
                <!-- docNumber -->
                <label for="docNumberTxt" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.DOCNO' | translate
                }}</label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div class="input-group">
                    <div class="d-flex">
                      <input
                        formControlName="docNumber"
                        id="docNumberTxt"
                        type="text"
                        class="form-control"
                        placeholder=""
                      />
                      <button
                        *ngIf="vtaMode"
                        id="getDocNoBtn"
                        info
                        [style]="'small'"
                        [icon]="'with-plus'"
                        type="button"
                        title="{{ 'DOC.GET_NO' | translate }}"
                        (click)="getDocNumber()"
                      >
                        <span></span>
                      </button>
                    </div>
                    <!-- <div *ngIf="vtaMode" class="input-group-append">
                      <span (click)="getDocNumber()" class="input-group-text btn-info btn-with-plus border-0"></span>
                    </div> -->
                  </div>
                </div>
              </div>
              <!--row-->
              <div class="row mx-0 pl-0 pr-2">
                <!-- validFrom -->
                <label for="validFromPicker" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.VALIDFROM' | translate
                }}</label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div
                    class="input-group"
                    [ngClass]="{ 'has-danger': !documentForm.controls.validFrom?.valid }"
                  >
                    <app-datepicker formControlName="validFrom" id="validFromPicker"></app-datepicker>
                  </div>
                  <div
                    class=" invalid-feedback"
                    *ngIf="documentForm.controls.validFrom?.errors?.invalidDateFormat"
                  >
                    {{ 'GLOBAL.INVALID_DATE' | translate }}
                  </div>
                  <div class=" invalid-feedback" *ngIf="documentForm.controls.validFrom?.errors?.issueDate">
                    {{ 'ERROR.LESS_THAN_ISSUED_DATE' | translate }}
                  </div>
                </div>
              </div>
              <!--row-->
              <!-- verifiedTo -->
              <div *ngIf="env === 'mk'" class="row mx-0 pl-0 pr-2">
                <label for="verifiedToPicker" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.VERIFIED' | translate
                }}</label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div
                    class="input-group"
                    [ngClass]="{ 'has-danger': !documentForm.controls.verifiedTo?.valid }"
                  >
                    <app-datepicker formControlName="verifiedTo" id="verifiedToPicker"></app-datepicker>
                  </div>
                  <div
                    class=" invalid-feedback"
                    *ngIf="documentForm.controls.verifiedTo?.errors?.invalidDateFormat"
                  >
                    {{ 'GLOBAL.INVALID_DATE' | translate }}
                  </div>
                </div>
              </div>
              <!--row-->
              <!-- blankNr -->
              <div *ngIf="env === 'mk'" class="row mx-0 pl-0 pr-2">
                <label for="blankNrTxt" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.BLANKNO' | translate
                }}</label>
                <div class="col-11 pr-0 pl-2 pr-0 mt-1">
                  <div class="input-group">
                    <input formControlName="blankNr" id="blankNrTxt" type="text" class="form-control" />
                  </div>
                </div>
              </div>
              <!--row-->
            </div>

            <div class="col-10 mx-0 pl-1 pr-0 mt-3">
              <div class="row mx-0 pl-0 pr-2">
                <!-- issueDate -->
                <label for="issueDatePicker" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.ISSUED' | translate
                }}</label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div
                    class="input-group"
                    [ngClass]="{ 'has-danger': !documentForm.controls.issueDate?.valid }"
                  >
                    <app-datepicker formControlName="issueDate" id="issueDatePicker"></app-datepicker>
                  </div>
                  <div
                    class=" invalid-feedback"
                    *ngIf="documentForm.controls.issueDate?.errors?.invalidDateFormat"
                  >
                    {{ 'GLOBAL.INVALID_DATE' | translate }}
                  </div>
                  <div
                    class=" invalid-feedback"
                    *ngIf="documentForm.controls.issueDate?.errors?.invalidDateFuture"
                  >
                    {{ 'ERROR.LESS_THAN_TODAY_DATE' | translate }}
                  </div>
                </div>
              </div>
              <!--row-->
              <!-- validTo -->
              <div class="row  mx-0 pl-0 pr-2">
                <label for="validToPicker" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.VALIDTO' | translate
                }}</label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div
                    class="input-group"
                    [ngClass]="{ 'has-danger': !documentForm.controls.validTo?.valid }"
                  >
                    <app-datepicker formControlName="validTo" id="validToPicker"></app-datepicker>
                  </div>
                  <div
                    class=" invalid-feedback"
                    *ngIf="documentForm.controls.validTo?.errors?.invalidDateFormat"
                  >
                    {{ 'GLOBAL.INVALID_DATE' | translate }}
                  </div>
                  <div *ngIf="documentForm.controls.validTo?.errors?.startDate" class="invalid-feedback mt-1">
                    {{ 'ERROR.DATE_START_BEFORE_END' | translate }}
                  </div>
                </div>
              </div>
              <!--row-->
              <!-- validationTerminated -->
              <div *ngIf="env === 'mk'" class="row mx-0 pl-0 pr-2">
                <label for="validationTerminatedPicker" class="col-20 pl-0 col-form-label text-left">{{
                  'DOC.VALIDTERM' | translate
                }}</label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div
                    class="input-group"
                    [ngClass]="{ 'has-danger': !documentForm.controls.validationTerminated?.valid }"
                  >
                    <app-datepicker formControlName="validationTerminated" id="validationTerminatedPicker">
                    </app-datepicker>
                  </div>
                  <div
                    class=" invalid-feedback"
                    *ngIf="documentForm.controls.validationTerminated?.errors?.invalidDateFormat"
                  >
                    {{ 'GLOBAL.INVALID_DATE' | translate }}
                  </div>
                </div>
              </div>
              <!--row-->
              <!-- officialOnly -->
              <div *ngIf="env === 'mk'" class="row mx-0 pl-0 pr-2">
                <!-- magic checkbox styling only works with input label combo-->
                <label class="col-20 pl-0 col-form-label text-left">
                  <!-- {{ 'DOC.OFFICIALONLY' | translate }} -->&nbsp;
                </label>
                <div class="col-20 pr-0 pl-2 pr-0 mt-1">
                  <div class="vertical-center input-group">
                    <div class="custom-control custom-checkbox">
                      <input
                        formControlName="officialOnly"
                        class="custom-control-input"
                        type="checkbox"
                        id="officialOnlyChk"
                      />
                      <label class="custom-control-label" for="officialOnlyChk">
                        {{ 'DOC.OFFICIALONLY' | translate }}</label
                      >
                    </div>
                  </div>
                </div>
                <!--col-->
              </div>
              <!--row-->
            </div>
            <!--col-10-->
          </div>

          <div class="row mx-0 px-0 py-3 w-100">
            <label class="w-100 pl-0 col-form-label text-left pr-0">
              <span>{{ 'DOC.FILE' | translate }} </span>
            </label>
            <div *ngIf="mode === 'rw' && docId" class="col-10 pl-1 pr-0 mt-3">
              <span>{{ 'DOC.SIGNED_BY' | translate }}</span
              >: <span>{{ currentDoc?.signedByFullName }}</span>
            </div>
            <div *ngIf="mode === 'rw' && docId" class="col-10 pl-1 pr-0 mt-3">
              <span>{{ 'DOC.SIGNED_TIME' | translate }}</span
              >: <span>{{ currentDoc?.signed | date: localDateFormat }}</span>
            </div>
          </div>
          <!-- row -->
          <!-- file upload -->
          <div class="row mx-0 px-0 w-100">
            <div class="col-10 mx-0 pl-1 pr-0">
              <!-- upload from TEHIK-->
              <div *ngIf="!documentForm.value['fileOriginName']" class="file-upload-inline">
                <div class="file-upload-frame">
                  <label class="text-center" for="fileInput" [innerHTML]="'DOC.ADDFILE' | translate"></label>
                  <input class="file-input" id="fileInput" type="file" (change)="getFile($event)" />
                  <!-- <span class="icon-upload"></span> -->
                  <!-- LAB meil on see iconfont.svg-s glyph-name="uniEC6E" -->
                </div>
              </div>
              <!-- END upload from TEHIK-->
              <div *ngIf="documentForm.value['fileOriginName']">
                <!-- DELETE BUTTON -->
                <span class="d-flex align-items-center">
                  <a *ngIf="!file" [href]="'api/document/download/' + documentForm.value['id']">
                    {{ documentForm.value['fileOriginName'] }}
                  </a>
                  <span *ngIf="file">{{ documentForm.value['fileOriginName'] }}</span>
                  <div class="ml-2">
                    <button
                      type="button"
                      danger
                      [style]="'square'"
                      [icon]="'trashcan'"
                      title="{{ 'DOC.REMOVE_DOC' | translate }}"
                      (click)="removeFile()"
                    ></button>
                  </div>
                  <div class="ml-2">
                    <button
                      *ngIf="showCreatePdfBtn()"
                      type="button"
                      info
                      [style]="'square'"
                      title="{{ 'DOC.CREATE_PDF' | translate }}"
                      (click)="createPdf()"
                    >
                      <span>{{ 'DOC.CREATE_PDF' | translate }}</span>
                    </button>
                  </div>
                </span>
              </div>
            </div>
            <!--col-->

            <div
              class="col-10 mx-0 pl-1 pr-0 mt-3"
              *ngIf="
                (inspectionId || versionId) &&
                env === 'mk' &&
                hasTemplate &&
                (+documentForm.value['issuerId'] === TRANSPORDIAMET_CODE ||
                  +documentForm.value['issuerId'] === VEETEEDE_AMET_CODE) &&
                !documentForm.value['fileOriginName']
              "
            >
              <div class="mt-3">
                <button
                  *ngIf="
                    (inspectionId || versionId) &&
                    env === 'mk' &&
                    hasTemplate &&
                    (+documentForm.value['issuerId'] === TRANSPORDIAMET_CODE ||
                      +documentForm.value['issuerId'] === VEETEEDE_AMET_CODE) &&
                    !documentForm.value['fileOriginName']
                  "
                  id="previewBtn"
                  light
                  type="button"
                  custom-class=""
                  [style]="'square'"
                  (click)="saveAndPreviewDoc()"
                >
                  <span>{{ 'DOC.PREVIEW' | translate }}</span>
                </button>
              </div>
              <div class="mt-3">
                <button
                  *ngIf="
                    (inspectionId || versionId) &&
                    env === 'mk' &&
                    hasTemplate &&
                    (+documentForm.value['issuerId'] === TRANSPORDIAMET_CODE ||
                      +documentForm.value['issuerId'] === VEETEEDE_AMET_CODE) &&
                    !documentForm.value['fileOriginName']
                  "
                  id="genDocBtn"
                  success
                  type="button"
                  custom-class=""
                  class="mt-3"
                  [style]="'square'"
                  [icon]="'check'"
                  (click)="generateDoc()"
                >
                  <span>{{ 'DOC.GENERATE' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
          <!--row-->
          <div class="row mx-0 px-0 w-100">
            <div class="col-20 px-0">
              <hr class="mb-0" />
              <div class="d-flex flex-row justify-content-end py-3">
                <button
                  *ngIf="!currentDoc?.archived && showArchiveBtn"
                  type="button"
                  light
                  [style]="'square'"
                  title="{{ 'DOC.ARCHIVE' | translate }}"
                  (click)="archive()"
                >
                  <span>{{ 'DOC.ARCHIVE' | translate }}</span>
                </button>
                <div class="ml-2">
                  <button [disabled]="!documentForm.valid" success [style]="'square'" [icon]="'save'">
                    <span>{{ 'GLOBAL.SAVE' | translate }}</span>
                  </button>
                </div>
                <div class="ml-2">
                  <button
                    type="button"
                    danger
                    [style]="'square'"
                    [icon]="'trashcan'"
                    title="{{ 'GLOBAL.LEAVE' | translate }}"
                    (click)="onCancel()"
                  >
                    <span>{{ 'GLOBAL.LEAVE' | translate }}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!-- row -->
        </div>
        <!--form-group-->
      </div>
    </form>
  </div>
</div>

<!--ro template-->
<ng-template #ro>
  <div class="modal-body">
    <div *ngIf="!isLoading && currentDoc" class="col-20 py-2">
      <div>
        <!-- doctype -->
        <div class="row form-group">
          <div class="col-10">
            <div class="row align-items-center">
              <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.DOCTYPE' | translate }}:</label>
              <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.docTypeDescr">
                <div class="input-group">{{ currentDoc.docTypeDescr[lang] }}</div>
              </div>
            </div>
            <!--end row-->

            <div class="row align-items-center">
              <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.DOCFORM' | translate }}:</label>
              <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.docFormDescr">
                <div class="input-group">{{ currentDoc.docFormDescr[lang] }}</div>
              </div>
            </div>
            <!--end row-->

            <div class="row align-items-center">
              <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.TITLE' | translate }}:</label>
              <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.docTitle">
                <div class="input-group">{{ currentDoc.docTitle }}</div>
              </div>
            </div>
            <!--end row-->

            <!-- issuer -->
            <div class="row align-items-center">
              <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.ISSUER' | translate }}:</label>
              <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.issuer">
                <div class="input-group">{{ currentDoc.issuer }}</div>
              </div>
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="form-group">
          <div class="row">
            <div class="col-10">
              <!-- docNumber -->
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.DOCNO' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.docNumber">
                  <div class="input-group">{{ currentDoc.docNumber }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
            <!-- issueDate -->
            <div class="col-10">
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.ISSUED' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.issueDate">
                  <div class="input-group">{{ currentDoc.issueDate | date: localDateFormat }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <!-- validFrom -->
            <div class="col-10">
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.VALIDFROM' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.validFrom">
                  <div class="input-group">{{ currentDoc.validFrom | date: localDateFormat }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
            <!-- validTo -->
            <div class="col-10">
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.VALIDTO' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.validTo">
                  <div class="input-group">{{ currentDoc.validTo | date: localDateFormat }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <!-- verifiedTo -->
            <div class="col-10">
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.VERIFIED' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.verifiedTo">
                  <div class="input-group">{{ currentDoc.verifiedTo | date: localDateFormat }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
            <!-- validationTerminated -->
            <div class="col-10">
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.VALIDTERM' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.validationTerminated">
                  <div class="input-group">{{ currentDoc.validationTerminated | date: localDateFormat }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-10">
              <div class="row align-items-center">
                <!-- blankNr -->
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.BLANKNO' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.blankNr">
                  <div class="input-group">{{ currentDoc.blankNr }}</div>
                </div>
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-10">
              <div class="row align-items-center">
                <label class="col-xs-20 col-md-10 col-form-label">{{ 'DOC.FILE' | translate }}:</label>
                <div class="col-xs-20 col-md-10 pl-0" *ngIf="currentDoc.fileOriginName">
                  <!-- <span>{{ file.name }}{{' ' + file.size/1000 + ' KB' }}</span> -->
                  <a [href]="'api/document/download/' + docId">
                    {{ currentDoc.fileOriginName }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!--end row-->
        </div>

        <div
          *ngIf="currentDoc?.archived && showArchiveBtn"
          class="row mt-3 d-flex align-items-end flex-column"
        >
          <button
            type="button"
            light
            [style]="'square'"
            title="{{ 'DOC.RESTORE' | translate }}"
            (click)="restore()"
          >
            <span>{{ 'DOC.RESTORE' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- edit file form modal -->
<ng-template #modalEditFileTpl class="raised">
  <div class="modal-header pb-1">
    <h2 class="modalTitle">
      <!-- {{ 'BASICDATA.ENDISEDNIMED' | translate }} -->
    </h2>
    <button
      light
      [style]="'round'"
      [icon]="'exit'"
      title="{{ 'GLOBAL.CLOSE' | translate }}"
      (click)="closeFileEditModal()"
    ></button>
  </div>
  <div class="modal-body">
    <!-- <form [formGroup]="exNameForm"> -->
    <div [formGroup]="editDocFileForm">
      <!-- <div formArrayName="appExnamesById"> -->

      <div class="form-group row d-flex align-items-center">
        <label class="col-xs-20 col-md-5 col-form-label requiredField">{{ 'DOC.FILE' | translate }}:</label>
        <div class="col-xs-20 col-md-10 pl-0">
          <div *ngIf="!editDocFileForm.value['fileOriginName']" class="file-upload-inline">
            <div class="file-upload-frame">
              <label class="text-center" for="fileInput" [innerHTML]="'DOC.ADDFILE' | translate"></label>
              <input
                class="file-input"
                id="fileInput"
                type="file"
                accept=".docx"
                (change)="getTemplateFile($event)"
              />
            </div>
          </div>

          <!-- fileOriginName and DELETE BUTTON -->
          <div *ngIf="editDocFileForm.value['fileOriginName']">
            {{ editDocFileForm.value['fileOriginName'] }}
            <button
              danger
              [style]="'small'"
              [icon]="'trashcan'"
              title="{{ 'GLOBAL.REMOVE' | translate }}"
              (click)="removeTemplateFile()"
            ></button>
          </div>
        </div>
        <div class="d-flex">
          <div *ngIf="fileLoading" style="transform: translate(0px, -3px);">
            <app-loading-spinner></app-loading-spinner>
          </div>
          <button
            [disabled]="
              (!editDocFileForm.value['fileOriginName'] && !fileLoading) ||
              (editDocFileForm.value['fileOriginName'] && fileLoading)
            "
            success
            [style]="'square'"
            type="button"
            [icon]="'save'"
            (click)="saveNewDocFile()"
          >
            <span>{{ 'GLOBAL.FINISHED' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
