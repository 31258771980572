<form
  [formGroup]="inspectionDetailForm"
  (ngSubmit)="onSubmit()"
>
  <div class="modal-header pb-1">
    <h2 *ngIf="!edit && !isAudit">{{ 'INS.H1LISA' | translate }}</h2>
    <h2 *ngIf="!edit && isAudit">{{ 'AUDIT.ADD' | translate }}</h2>
    <h2 *ngIf="edit && !isAudit">{{ 'INS.H1MUUT' | translate }}</h2>
    <h2 *ngIf="edit && isAudit">{{ 'AUDIT.UPDATE' | translate }}</h2>
    <button
      light
      [style]="'round'"
      [icon]="'exit'"
      title="{{ 'GLOBAL.CLOSE' | translate }}"
      (click)="closeModal()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group mx-0 pb-0">
      <div class="mb-2">
        <label
          class="col-form-label requiredField"
          for="type2"
        >{{
          (isAudit ? 'AUDIT.TYPE' : 'INS.LIIK') | translate
          }}</label>
        <select
          formControlName="subtypeCode"
          value="{{ inspectionDetailForm?.value?.subtypeCode }}"
          class="form-control selectpicker"
          id="type"
          title="{{ 'GLOBAL.SELECT' | translate }}"
          required
        >
          <optgroup
            id="{{ 'auditType' + t.code }}"
            class=""
            *ngFor="let t of typeList"
            [label]="t.descr[lang]"
          >
            <option
              id="{{ tt.code }}"
              [value]="tt.code"
              *ngFor="let tt of t.claInspectionSubtypeEntityByCode"
            >{{ tt.descr[lang] }}
            </option>
          </optgroup>
        </select>
        <span
          *ngIf="isAudit && currentType"
          class="label-comment"
        >{{ currentType.descr[lang] }}</span>
        <!-- *ngIf="!edit || (edit && inspectionDetailForm?.value?.status === 30001)" -->
        <!-- disabled select -->
        <!--  <select *ngIf="edit && inspectionDetailForm?.value?.status !== 30001"  formControlName="subtypeCode"
          class="form-control" id="type2" title="{{ 'GLOBAL.SELECT' | translate }}" required>
          <optgroup class="form-control" *ngFor="let t of typeList" [label]="t.descr[lang]">
            <option [value]="tt.code" *ngFor="let tt of t.claInspectionSubtypeEntityByCode">{{tt.descr[lang]}}
            </option>
          </optgroup>
        </select> -->
        <!-- error -->
        <div
          *ngIf="submitted && !inspectionDetailForm?.valid"
          class="invalid-feedback"
        >
          <div *ngIf="inspectionDetailForm.controls?.subtypeCode?.errors?.required">
            {{ 'ERROR.REQUIRED' | translate }}
          </div>
        </div>
      </div>

      <!-- kuupäeva vahemik-->
      <div class="mb-2">
        <div class="mb-2 pt-2">
          <label class="col-form-label requiredField">{{
            (isAudit ? 'AUDIT.TIME' : 'INS.AEG') | translate
            }}</label>
          <div class="row range-group mx-0">
            <div class="col-10 range-from pl-0 pr-3">
              <app-date-time-picker
                [minDate]="nowDate"
                (ngModelChange)="dateChange()"
                formControlName="startDate"
                placeholder="{{ 'APL.SEARCH_APL_PROC_DATE_FROM' | translate }}"
                required
              ></app-date-time-picker>
              <!-- error -->
              <div
                *ngIf="submitted && !inspectionDetailForm?.valid"
                class="invalid-feedback"
              >
                <div *ngIf="inspectionDetailForm.controls?.startDate?.errors?.required">
                  {{ 'ERROR.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <div class="col-10 pl-3 pr-0">
              <app-date-time-picker
                [minDate]="nowDate"
                (ngModelChange)="dateChange()"
                formControlName="endDate"
                placeholder="{{ 'APL.SEARCH_APL_PROC_DATE_TO' | translate }}"
              ></app-date-time-picker>
              <!-- error -->
              <div
                *ngIf="submitted && !inspectionDetailForm?.valid"
                class="invalid-feedback"
              >
                <div *ngIf="inspectionDetailForm.controls?.endDate?.errors?.required">
                  {{ 'ERROR.REQUIRED' | translate }}
                </div>
              </div>
            </div>
            <!-- errors -->
            <div
              *ngIf="submitted"
              class="invalid-feedback"
            >
              <div *ngIf="inspectionDetailForm.errors?.dates">
                {{ inspectionDetailForm.errors?.dates | translate }}
              </div>
              <div *ngFor="let error of dateErrors">{{ error }}</div>
            </div>
          </div>
        </div>
        <!-- kuupäeva vahemik-->
        <!-- <div class="col-xs-20 col-md-8 border-right px-4">
          </div> -->
      </div>
      <!-- Juht -->
      <div class="mb-2">
        <label
          class="col-form-label requiredField"
          for="teamLeader"
        >{{
          (isAudit ? 'AUDIT.HEAD' : 'INS.KOMISJONI_JUHT') | translate
          }}</label>
        <select
          (change)="teamMemberChange($event.target.value)"
          formControlName="teamLeader"
          data-live-search="true"
          class="form-control selectpicker searchable"
          id="teamLeader"
          title="{{ 'GLOBAL.SELECT' | translate }}"
        >
          <option
            id="{{ t.id }}"
            [value]="t.id"
            *ngFor="let t of generalTeamList"
            [label]=""
          >{{
            t.firstName + ' ' + t.name
            }}</option>
        </select>
        <!-- errors -->
        <div
          *ngIf="submitted && !inspectionDetailForm?.valid"
          class="invalid-feedback"
        >
          <div *ngIf="inspectionDetailForm.controls?.teamLeader?.errors?.required">
            {{ 'ERROR.REQUIRED' | translate }}
          </div>
        </div>
        <div
          *ngIf="teamMembersErrorsMap?.teamLeader"
          class="invalid-feedback"
        >
          {{ teamMembersErrorsMap.teamLeader }}
        </div>
      </div>
      <!-- Liikmed -->
      <div class="mb-2">
        <label
          class="col-form-label"
          for="select3"
        >{{
          (isAudit ? 'AUDIT.COMMITTEE' : 'INS.KOMISJON') | translate
          }}</label>
        <!-- TODO selecti translate korda -->
        <select
          (change)="teamMemberChange2()"
          formControlName="teamMembers"
          class="selectpicker form-control searchable select-all clearable"
          id="select3"
          multiple="multiple"
          data-live-search="true"
          data-actions-box="true"
          data-select-all-text="Vali kõik"
          data-deselect-all-text="Tühjenda"
          title="{{ 'GLOBAL.MULTISELECT_AUTOMATIC_MESSAGE' | translate }}"
        >
          <option
            id="{{ t.id }}"
            [value]="t.id"
            *ngFor="let t of teamMemberList"
            value="t.code"
          >{{
            t.firstName + ' ' + t.name
            }}</option>
        </select>
        <!-- error -->
        <div
          *ngIf="submitted && teamMembersErrorsMap?.teamMembers"
          class="invalid-feedback"
        >
          {{ teamMembersErrorsMap.teamMembers }}
        </div>
        <!-- 'GLOBAL.CHOOSE_ALL' -->
        <!-- ${ this.preTranslatedLabels['GLOBAL.CLEAR'] } -->
      </div>

      <div class="mb-2">
        <label
          class="col-form-label"
          for="radioStacked1"
        >{{
          (isAudit ? 'AUDIT.PLACE' : 'INS.KOHT') | translate
          }}</label>
        <div class="custom-control custom-radio">
          <input
            (change)="foreignChange(false)"
            [(ngModel)]="isForeign"
            [ngModelOptions]="{ standalone: true }"
            [value]="false"
            id="radioStacked1"
            name="radio-stacked"
            type="radio"
            class="custom-control-input"
            checked="checked"
          />
          <label
            class="custom-control-label"
            (click)="foreignChange(false)"
            for="radioStacked1"
          >{{
            'INS.EST' | translate
            }}</label>
        </div>
        <div class="custom-control custom-radio">
          <input
            (change)="foreignChange(true)"
            [(ngModel)]="isForeign"
            [ngModelOptions]="{ standalone: true }"
            [value]="true"
            id="radioStacked2"
            name="radio-stacked"
            type="radio"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            (click)="foreignChange(true)"
            for="radioStacked2"
          >{{
            'INS.VALISMAA' | translate
            }}</label>
        </div>
      </div>

      <div
        class="mb-2"
        *ngIf="!isForeign"
      >
        <label
          class="col-form-label requiredField"
          for="port"
        >{{ 'INS.SADAM' | translate }}</label>

        <select
          formControlName="portId"
          class="form-control selectpicker"
          id="port"
          title="{{ 'GLOBAL.SELECT' | translate }}"
        >
          <option
            id="{{ r.id }}"
            [value]="r.id"
            *ngFor="let r of inspectionPortList"
          >{{ r.description }} {{ r.knrObjId ? '(Kohanimeregister)' : '' }}</option>
        </select>
        <!-- errors -->
        <div
          *ngIf="submitted"
          class="invalid-feedback"
        >
          <div *ngFor="let portError of portErrors">{{ portError }}</div>
        </div>
      </div>
      <div
        *ngIf="isForeign"
        class="mb-2"
      >
        <label
          for="countryListId"
          class="col-form-label requiredField"
        >{{ 'INS.RIIK' | translate }}</label>
        <select
          formControlName="countryId"
          value="{{ inspectionDetailForm?.value?.countryId }}"
          class="form-control selectpicker"
        >
          <option
            id="{{ c.id }}"
            [value]="c.id"
            id="countryListId"
            *ngFor="let c of countryClassifier"
          >{{ c.name[lang] }}
          </option>
        </select>
        <!-- errors -->
        <div
          *ngIf="submitted"
          class="invalid-feedback"
        >
          <div *ngFor="let countryError of countryErrors">{{ countryError }}</div>
        </div>

        <label class="col-form-label">{{ 'INS.LINN' | translate }}</label>
        <input
          formControlName="location"
          type="text"
          class="form-control"
          id="example2"
        />
      </div>
    </div>
    <div
      *ngIf="inspectionDetailForm.value.status === 30001"
      class="form-group row"
    >
      <div class="col-12">
        <div class="custom-control custom-checkbox">
          <input
            [value]="this.inspectionDetailForm.value.approv"
            formControlName="approv"
            type="checkbox"
            id="approv"
            class="custom-control-input"
          />
          <label
            class="custom-control-label"
            for="approv"
          >{{
            isAudit ? ('AUDIT.APPROV' | translate) : ('INS.APPROV' | translate)
            }}</label>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="mb-2 mx-2 d-flex">
      <div>
        <button  success [style]="'success square'"
          [icon]="'with-plus'">{{'INS.SALVESTA' | translate}}
        </button>
      </div>
      <div class="ml-2">
        <button type="button" danger (click)="closeModal()">{{'INS.KATKESTA' | translate}}</button>
      </div>

    </div> -->
  <div class="row mx-0 px-0 w-100">
    <div class="col-20 px-0">
      <hr class="mb-0" />
      <div class="p-3 w-100 d-flex justify-content-end">
        <div
          id="insSave"
          class="mb-0"
        >
          <!-- will submit form -->
          <button
            success
            [style]="'square'"
            (click)="submitted = true"
            [icon]="'save'"
          >
            <span>{{ 'GLOBAL.SAVE' | translate }}</span>
          </button>
        </div>
        <div class="ml-2 mb-0">
          <button
            danger
            [style]="'square'"
            type="button"
            (click)="closeModal()"
            [icon]="'cancel'"
          >
            <span>{{ 'GLOBAL.LEAVE' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- row -->
</form>

<ng-template #checkTeamTimesModalTpl>
  <div class="mb-2">
    <div class="modal-header">
      <h3 class="font-weight-bold">
        <b>{{ 'INS.WARNTEXT' | translate }}</b> <br />
        <ul>
          <li *ngFor="let f of foundTeamDates">
            {{ f.vesVesselByVesselId?.vesselName }} <br />
            {{ (f.type?.claInspectionTypeByTypeCode?.descr)[lang] }}
            {{ (f.type?.descr)[lang] }} <br />
            {{ f.startEndDateString }} <br />
            {{ f.teamMembers }} <br />
            <br />
          </li>
        </ul>
      </h3>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-20">
          <div class="form-group pb-0">
            <h3 class="font-weight-bold">{{ 'INS.CONFIRMTXT' | translate }}</h3>
            <div class="w-100 d-flex justify-content-end">
              <div class="mb-0">
                <button
                  id="userInsLIstBtnYes"
                  success
                  [style]="'square'"
                  type="button"
                  (click)="saveAndCloseTeamModal()"
                  [icon]="'save'"
                >
                  <span>{{ 'GLOBAL.YES' | translate }}</span>
                </button>
              </div>
              <div class="ml-2 mb-0">
                <button
                  id="userInsLIstBtnNo"
                  danger
                  [style]="'square'"
                  type="button"
                  (click)="closeTeamModal()"
                  [icon]="'cancel'"
                >
                  <span>{{ 'GLOBAL.NO' | translate }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.row -->
    </div>
    <!-- /.modal-body -->
  </div>
</ng-template>