import { API_ENDPOINT } from '../app.constants';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../shared/_services';
import { BaseComponent } from '../shared/_common/base/base.component';
import { UserInfo } from '../_domain';
import { ActivatedRoute } from '@angular/router';



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
  public currentUser: UserInfo;
  isLoading = false;
  env = '';

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.currentUser = this.userService.getUser();
    if (this.currentUser && this.currentUser.xteeMsg) {
      this.getLongMsgWarningSticky(this.currentUser.xteeMsg);
    }
    this.addSubscriptionToList(this.route.data.subscribe(data => {
      this.env = data.env;
    }));
    if (this.currentUser === null || this.currentUser === undefined) {
      this.isLoading = true;
      // TODO: kui võimalik siis võtta see meetot kuskilt centraalsest kohast
      this.login();
    }
    this.addSubscriptionToList(this.userService.getUserSubject.subscribe(user => {
      this.currentUser = user;
    }));
  }

  showNotification(type: string) {
    // this.alertNotifierServ.notify(type, 'You are awesome! clicked on-> ' + type);
  }

  /*
   * Attempt to retrieve the UserInfo object via API
   * If successful > set the data into localStorage for faster IO operations
   */
  login(): void {
    this.addSubscriptionToList(
      this.userService.loadUser().subscribe(
        (userInfo: UserInfo) => {
          userInfo.rolesSize = Object.keys(userInfo.roles).length;
          userInfo.entitiesSize = Object.keys(userInfo.entities).length;
          userInfo.entities = this.userService.setUserRealEntityFirstInMap(userInfo);

          this.currentUser = userInfo;
          this.userService.getActiveRoleTitle(userInfo);
          this.isLoading = false;
        },
        error => {
          if (error.message) {
            this.getLongMsgError(error.message);
          }
          this.isLoading = false;
        }
      )
    );
  }

  async logout() {
    this.addSubscriptionToList(this.userService.logoutUser().subscribe(
      response => {
        this.userService.cleanSession();
        if (API_ENDPOINT.charAt(API_ENDPOINT.length - 1) === '/') {
          window.location.href = API_ENDPOINT;
        } else {
          window.location.href = API_ENDPOINT + '/';
        }
      },
      error => {
        this.getLongMsgError(error.message);
      }));
  }
}
